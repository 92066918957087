import React, { useEffect, useState } from "react";

import "../../../assets/styles/division-selection.css";

import { useHistory } from "react-router-dom";
import { Route, Redirect, Switch } from "react-router-dom";
import Guidelines from "../../components/FormSteps/Guidelines";
import ChooseLicenseType from "../ChooseLicenceDetails";
import Form from "../../components/FormSteps/Form";
import SupportingDocument from "../../components/FormSteps/SupportingDocument";
import Preview from "../../components/FormSteps/Preview";
import TransactionDetails from "../../components/FormSteps/TransactionDetails";
import ApplicationHeading, {
  ApplicationHeadingWithActions,
} from "../../components/ApplicationHeading";
import ProgressStep from "../../components/Steps/ProgressStep";
import SupportingDocumentWithoutDefinedTypes from "../../components/FormSteps/SupportingDocumentWithoutDefinedTypes";
import Payment from "../../components/FormSteps/Payment";
import { useDispatch, useSelector } from "react-redux";
import { APP_SUBMISSION_CONFIG } from "../../config/application-submission-config";

import { getCurrentTransaction } from "../../redux/actions/current-transaction";

export default function FisherWorkerIdApplicationSubmission(props) {
  const history = useHistory();

  let appType = useSelector((state) => {
    return state.currentTransaction.appType;
  });
  let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

  let stepCount = 0;
  for (const element of appDetails.steps) {
    if (element.currentStep === history.location.pathname) break;
    stepCount++;
  }

  const progress = (stepCount * 100) / appDetails.totalSteps;

  return (
    <div>
      {/* <ApplicationHeading
          title={appDetails?.application?.applicationDesc}
        ></ApplicationHeading> */}

      <ApplicationHeadingWithActions
        title={appDetails?.application?.applicationDesc}
        actions={[
          {
            title: "Click here to Learn How to Apply",
            className: "btn btn-sm btn-custom-warning btn-no-padding btn-border",
            onClick: () => history.replace(appDetails?.detailsPage),
          },
        ]}
      />

      <ProgressStep
        steps={[
          "Licence Type",
          "Fill Form",
          "Upload Documents",
          "Payment of Fees",
          "Preview",
          "Status",
        ]}
        percent={progress}
        stepSequence={[
          "type_selection",
          "form",
          "document",
          "payment",
          "preview",
          "transaction",
        ]}
      ></ProgressStep>
      {/* <img src={'./images/Background FTC.png'}></img> */}
      <div className="bg-image">
        {/* <BrowserRouter>  if uncommented then will not work with some router*/}
        <Switch>
          <Route exact path="/application/fisher-worker-id/type">
            <ChooseLicenseType
              application={appDetails.application}
              appDetails={appDetails}
            ></ChooseLicenseType>
          </Route>

          <Route exact path="/application/fisher-worker-id/form">
            <Form
              application={appDetails.application}
              appDetails={appDetails}
            ></Form>
          </Route>


          <Route exact path="/application/fisher-worker-id/form-render">
            <Form
              application={appDetails.application}
              appDetails={appDetails}
            ></Form>
          </Route>
          <Route
            exact
            path="/application/fisher-worker-id/supporting-documents"
          >
            <SupportingDocument application={appDetails.application} />
          </Route>

          <Route exact path="/application/fisher-worker-id/payment">
            <Payment application={appDetails.application} />
          </Route>

          <Route exact path="/application/fisher-worker-id/preview">
            <Preview
              appDetails={appDetails}
              application={appDetails.application}
            />
          </Route>
          <Route exact path="/application/fisher-worker-id/transaction-details">
            <TransactionDetails
              appDetails={appDetails}
              application={appDetails.application}
            />
          </Route>

          <Route path="*">
            <Redirect to="/application/fisher-worker-id/type"></Redirect>
          </Route>
        </Switch>
      </div>
      {/* </BrowserRouter> */}
    </div>
  );
}
