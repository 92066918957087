import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { FLISContext } from '../context/FLISContext';
function PermitDetails(props) {

    const history = useHistory();

    const {clearSelectedMap} = useContext(FLISContext);

    return (
      <div>
        <div className="custom-banner-bg">
          <div style={{ fontSize: "40px", fontWeight: "bold" }}>
            Application for Permit
          </div>



    <div className="d-flex flex-spacing-10">
            <button
              type="button"
              className="btn btn-custom-warning large-button"
              onClick={async () => {
                const details = { appType: "APPLICATION_FOR_PERMIT" };

                const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                let transNumber = "";
                if (appDetails.isLoginRequired) {
                  if (!localStorage.getItem("token")) {
                    history.push("/login");
                    return;
                  }

                  transNumber = await getUniqueTransNumber(details.appType);
                } else {
                  transNumber = await getUniqueTransNumber(details.appType);
                }

                store.dispatch({
                  type: SET_CURRENT_TRANSACTION,
                  temp_trans_id: transNumber,
                  appType: details.appType,
                  transaction: {},
                  submitted_transaction: null,
                });

                clearSelectedMap();

                if (!!details)
                  history.push(`${appDetails.steps[0].currentStep}`);
              }}
            >
              Apply now for your Permit
            </button>

          </div> 
        </div>

        <div
          
          style={{
            padding: "40px 14%",
            color: "black",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          <div>
            {/* <h2 style={{ fontWeight: 800 }}>Application for Permit</h2> */}
            <div>
              Outside of the traditional fishing activities, you can also apply
              for a permit for the following activities or persons:
            </div>
            <br />
            <ul>
              <li>Fishing in a tournament</li>
              <li>Fishing while on a short-stay trip (less than a month)</li>
              <li>
                Crew members working and fishing on an industrial fishing vessel
                or carrier vessel
              </li>
              <li>
                Artisanal Fishers who intend to remain on the Morant and Pedro
                Cays to fish
              </li>
            </ul>
            <br />
            You can also apply for a special permit to conduct a related fishing
            activity that does not fall within the traditional category.
          </div>

          <br/>
          <div className="d-flex flex-spacing-10">
            <button
              type="button"
              className="btn btn-custom-warning large-button"
              onClick={async () => {
                const details = { appType: "APPLICATION_FOR_PERMIT" };

                const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                let transNumber = "";
                if (appDetails.isLoginRequired) {
                  if (!localStorage.getItem("token")) {
                    history.push("/login");
                    return;
                  }

                  transNumber = await getUniqueTransNumber(details.appType);
                } else {
                  transNumber = await getUniqueTransNumber(details.appType);
                }

                store.dispatch({
                  type: SET_CURRENT_TRANSACTION,
                  temp_trans_id: transNumber,
                  appType: details.appType,
                  transaction: {},
                  submitted_transaction: null,
                });

                clearSelectedMap();

                if (!!details)
                  history.push(`${appDetails.steps[0].currentStep}`);
              }}
            >
              Apply now for your Permit
            </button>

          </div>
        </div>
      </div>
    );
}

export default PermitDetails;