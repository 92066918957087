import React, { useContext, useEffect, forwardRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { emailPattern } from "../../components/DataSource";

export const VesselNamesInfo = forwardRef(
  ({ formState, control, register, onSubmit, ...props }, ref) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: "vessel_names",
    });

    const { errors, isDirty, isValid } = formState;

    return (
      <div className="form-container">
        <fieldset>
          <form onSubmit={onSubmit}>
          <div className="d-flex flex-row form-heading">
            <div>Vessel Name Information</div>
            </div>
           <div className="shadow p-3 ">
           {fields.map((group, groupIndex) => {
              return (
                <div
                  key={group.id}
                  className="p-1"
                >
                  <div className="d-flex flex-row form-heading">
                    {/* <div style={{ marginRight: "10px" }}>{`${"Vessels "} #${
                      groupIndex + 1
                    }`}</div> */}

                   
                  </div>
                  <div className="d-flex-wrap flex-row justify-content-start align-items-start">
                    <div className="individual-field">
                      <label>{`Vessel Name -  ${groupIndex + 1}`} </label>

                      <input
                        type="text"
                        required
                        {...register(`vessel_names[${groupIndex}].name`, {
                          required: "This field is required",
                          maxLength: {
                            value: 100,
                            message: "Max length is 100",
                          },
                        })}
                        className="form-control"
                        placeholder="Name"
                      />
                      {errors.vessel_names?.[groupIndex]?.name && (
                        <span>This field is required</span>
                      )}
                    </div>

                    <div>
                      <button
                        className="btn btn-warning "
                        style={{marginTop: '35px'}}
                        onClick={() => {
                          remove(groupIndex);
                        }}
                      >
                        Delete
                      </button>
                    </div>



                  </div>
                </div>
              );
            })}


<div className="d-flex flex-row ml-3 mt-2 ">
          <button onClick={() => {
            append({ id: Math.random() });
          }} type="button" className="btn btn-primary">
            Add Vessel Name
          </button>
        </div>

           </div>
          </form>
        </fieldset>

    
      </div>
    );
  }
);
