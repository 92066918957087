import React, { useEffect, useState } from "react";
import HorizontalLine from "../components/HorLine";
import { Declarations } from "../components/DataSource";
import { FormHookFileUploadHandler } from "../components/FormHook/FormHookDocument";
import { SignaturePad } from "../components/SignatureCanvas";
import { FormHookCheckbox } from "../components/FormHook/ReactFormHookFields";

const GeneralDeclarations = ({
  register,
  errors,
  watch,
  setValue,
  isDisabled
}) => {
  const [isGDChecked, setIsGDChecked] = useState(false);

  // const declaration_list = watch('declaration_details.declaration_list');

  // useEffect(() => {
  //   if(declaration_list?.length === Declarations["Fisher Licence"].length){
  //     setIsGDChecked(true);
  //   }else{
  //     setIsGDChecked(false);
  //   }
    
  // }, declaration_list)

  return (
    <>
      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="sub-form-header">Declaration of Knowledge Form</div>
        <HorizontalLine></HorizontalLine>

        <div style={{ width: "100%", marginTop: "10px" }}>
          <div className="form-inline">
            <div
              className="d-flex-wrap bottom-spacing-10 flex-row "
              style={{ width: "95%" }}
            >
              <b style={{ margin: "0 5px" }}>I </b>
              <div>
                <input
                  style={{ width: "300px" }}
                  type="text"
                  className="form-control form-control-sm flex-grow-2"
                  {...register("declaration_details.name", {
                    required: "This is required",
                  })}
                  placeholder="name"
                />
                <div className="error-message">
                  {errors.declaration_details?.name?.message}
                </div>
              </div>
              <b style={{ margin: "0 5px" }}> of address</b>
              <div style={{ flexGrow: 4 }}>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  className="form-control form-control-sm"
                  {...register("declaration_details.address", {
                    required: "This is required",
                  })}
                  placeholder="address"
                />
                <span className="error-message">
                  {errors.declaration_details?.address?.message}
                </span>
              </div>
            </div>
          </div>

          <div>
            <b style={{ margin: "0 5px" }}>
              {" "}
              as an applicant for a fishing licence in accordance with Section
              25 of the Fisheries Act, 2018, declare that I am aware of the
              following:
            </b>
          </div>

          <div style={{marginTop: '20px'}}>

             {
              !isDisabled &&  <label
              className="checkbox-container"
              style={{ marginLeft: "10px" }}
            >
              <b style={{ color: "green", fontSize: "20px" }}>
                {"Select All"}
              </b>
              <input
                type="checkbox"
                onChange={(event) => {
                  setIsGDChecked(event.target.checked);
                  if (event.target.checked) {
                    setValue(
                      "declaration_details.declaration_list",
                      Declarations["Fisher Licence"]
                    );
                  } else {
                    setValue("declaration_details.declaration_list", []);
                  }
                  
                }}
                value={isGDChecked}
                name="declaration"
              />
              <span className="checkbox-checkmark"></span>
            </label>
             }


            {/* {Declarations["Fisher Licence"]?.map((declaration) => {
              return (
                <>
                  <label className="checkbox-container">
                    {declaration}
                    <input
                      type="checkbox"
                      value={""}
                      checked={isGDChecked}
                      // disabled={true}
                      name="declaration"
                    />
                    <span className="checkbox-checkmark"></span>
                  </label>
                </>
              );
            })} */}


              <FormHookCheckbox
                label=""
                register={register}
                regFieldName="declaration_details.declaration_list"
                options={Declarations["Fisher Licence"]}
                fieldClassName=""
                error={errors?.declaration_details?.declaration_list?.message}
              />

          </div>
        </div>
      </div>

      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="sub-form-header">Signature</div>
        <HorizontalLine></HorizontalLine>

        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>
            <b>
              I/We declare that the information given above is true and correct to the best of my/our knowledge and belief.
            </b>
          </div>

          <div className="error-message">
            {!watch("applicant_signature") &&
              !watch("signature_draw_path") && (
                <span>Atleast one field is required from below</span>
              )}
          </div>

          <div className="d-flex-wrap flex-row justify-content-start">
            <FormHookFileUploadHandler
              error={
                errors?.applicant_signature
                  ?.message
              }
              register={register}
              watch={watch}
              label="Upload Applicant's Signature"
              accept=".jpg, .jpeg, .png"
              regFieldName="applicant_signature"
              fieldClassName="field-block-control"
              isPreviewable={true}
              isRequired={false}
              removeFile={(id) => setValue(id, null)}
              setValue={setValue}
            ></FormHookFileUploadHandler>

            <SignaturePad
              isDisabled={isDisabled}
              currentValue={watch(
                "signature_draw_path"
              )}
              setSignatureValue={(id, base64) => setValue(id, base64)}
              id="signature_draw_path"
              label="Or Draw Your Signature Below"
            />
          </div>
          </div>
      </div>
    </>
  );
};

export default GeneralDeclarations;
