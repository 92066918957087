import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { getUniqueTransNumber } from "../utils/utils";
import { useDispatch } from "react-redux";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";

function ApplicationType({ app }) {

  const {clearSelectedMap} = useContext(FLISContext);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div
      className={`application-type-container `}
      onClick={async (event) => {
        // const details = props.app;
        // const appDetails = APP_SUBMISSION_CONFIG[details.appType];
        // let transNumber = '';
        // if(appDetails.isLoginRequired){
        //     if(!localStorage.getItem('token')){
        //         history.push('/login');
        //         return;
        //     }
        //     transNumber = await getUniqueTransNumber(details.appType);
        // }else{
        //     transNumber = await getUniqueTransNumber(details.appType);
        // }

        // store.dispatch({
        //     type: SET_CURRENT_TRANSACTION,
        //     temp_trans_id: transNumber,
        //     appType: details.appType,
        //     transaction: {},
        //     submitted_transaction:null
        // });

        // if (!!details)
        //     history.push(`${appDetails.steps[0].currentStep}`);


        if(app.isLoginRequired){
          if (!localStorage.getItem("token")) {
            history.push("/login");
            return;
          }
        }
        let transNumber = '';
        if (app.initiateState) {
          transNumber = await getUniqueTransNumber(app.appType);

          dispatch({
            type: SET_CURRENT_TRANSACTION,
            temp_trans_id: transNumber,
            appType: app.appType,
            transaction: {},
            submitted_transaction: null,
          });
          
        }
        clearSelectedMap();
        history.push(app.redirectTo);
      }}
    >
      {app.dispImage ? (
        <img src={app.dispImage} alt="here" height={300} width={390}></img>
      ) : (
        <img
          src="./images/licensing.png"
          alt="here"
          height={300}
          width={400}
        ></img>
      )}
      <div
        style={{
          backgroundColor: "#f0ef8a",
          width: "100%",
          opacity: "0.8",
          color: "#111",
          minHeight: "85px",
          verticalAlign: "middle",
        }}
      >
        {app.title}
      </div>
    </div>
  );
}

export default ApplicationType;
