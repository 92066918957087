import React, { useState } from "react"
import { FormHookInput, FormHookSelect } from "../components/FormHook/ReactFormHookFields";
import BoxChoiceContainer from "../components/BoxChoiceContainer";
import { useForm } from "react-hook-form";
import { emailPattern, formatPhoneNumber, telephonePattern } from "../components/DataSource";
import { emailNotValid, telephoneNotValid } from "../utils/messages";
import { alertService } from "../_services";
import { postData } from "../services/service-call";
import { ROUTES } from "../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const ForgotPassword = () => {

    const [resetMethod, setResetMethod] = useState('');

    const [selectedMap, setSelectedMap] = useState({});


    function isEmailAddress(str) {
        var pattern = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
        return pattern.test(str);
      }

    const {
        register,
        handleSubmit,
        watch,
        formState,
        setValue,
        getValues,
        trigger,
        control,
      } = useForm({
        defaultValues: {},
        mode: "all",
      });

      const { errors } = formState;

      const history = useHistory();

    return (
      <div className="regitration-container">
        <div className="login-form-container">
          {!resetMethod && (
            <BoxChoiceContainer
              listingHeading={"Reset Password Using"}
              listingOptions={["Email", "Telephone Number"]}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={1}
              prop_key={"reset_method"}
              onClick={(value, i) => {
                setResetMethod(value);
              }}
              listingStyle="justify-content-center"
              listingDescriptions={[]}
            ></BoxChoiceContainer>
          )}

          {resetMethod === "Email" && (
            <div>
              <div>
                <FormHookInput
                  register={register}
                  error={errors?.forgot_user_name?.message}
                  label="Enter email address used at the time of registration"
                  regFieldName="forgot_user_name"
                  placeholder="Email"
                  validations={{
                    pattern: {
                      value: emailPattern,
                      message: emailNotValid,
                    },
                  }}
                  fieldClassName="form-control"
                />
              </div>

              <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    setResetMethod("");
                  }}
                >
                  Back
                </button>

                <button
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    const data = getValues();
                    const isValid = isEmailAddress(data.forgot_user_name);
                    if (!isValid) {
                      alertService.error("Email not valid", {
                        autoClose: true,
                        keepAfterRouteChange: true,
                      });
                      return;
                    } else {
                      const result = await postData({
                        url: ROUTES.resetPassword,
                        body: { email: data.forgot_user_name, reset_method: resetMethod },
                      });
                      if (result.statusCode === 200) {
                        alertService.success(
                          "Password reset link has been sent to email address",
                          { autoClose: true, keepAfterRouteChange: true }
                        );
                        history.push("/login");
                      } else {
                        alertService.error(result.data.msg, {
                          autoClose: true,
                          keepAfterRouteChange: true,
                        });
                      }
                    }
                  }}
                  className="btn btn-primary"
                  type="button"
                >
                  Reset
                </button>
              </div>
            </div>
          )}

          {resetMethod === "Telephone Number" && (
            <div>
              <div>
                <FormHookInput
                  register={register}
                  error={errors?.telephone_number?.message}
                  label="Enter cell phone number used at the time of registration"
                  regFieldName="telephone_number"
                  placeholder={`Telephone Number`}
                  fieldClassName="form-control"
                  validations={{
                    pattern: {
                      value: telephonePattern,
                      message: telephoneNotValid,
                    },
                  }}
                  onChange={formatPhoneNumber}
                />

                <FormHookInput
                  register={register}
                  error={errors?.email?.message}
                  label="Enter email address"
                  regFieldName="email"
                  placeholder="Email"
                  validations={{
                    pattern: {
                      value: emailPattern,
                      message: emailNotValid,
                    },
                  }}
                  fieldClassName="form-control"
                />

                <FormHookInput
                  register={register}
                  error={errors?.date_of_birth_individual_or_date_of_registration?.message}
                  label="Date of Birth (Individual) or Date of Registration (Company) - Security"
                  regFieldName="date_of_birth_individual_or_date_of_registration"
                  type="date"
                />
              </div>

              <div className="mt-4 ml-2 mr-2 d-flex justify-content-between">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {
                    setResetMethod("");
                  }}
                >
                  Back
                </button>

                <button
                  style={{ marginLeft: "10px" }}
                  onClick={async () => {
                    const isValid = await trigger();
                    if(!isValid) {
                        return;
                    }
                    const data = getValues();
                    const result = await postData({
                        url: ROUTES.resetPassword,
                        body: {
                            ...data,
                            reset_method: resetMethod
                        },
                      });
                      if (result.statusCode === 200) {
                        alertService.success(
                          "Password reset link has been sent to email address",
                          { autoClose: true, keepAfterRouteChange: true }
                        );
                        history.push("/login");
                      } else {
                        alertService.error(result.data.msg, {
                          autoClose: true,
                          keepAfterRouteChange: true,
                        });
                      }
                  }}
                  className="btn btn-primary"
                  type="button"
                >
                  Reset
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
}