import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { getUniqueTransNumber } from "../utils/utils";
import store from "../redux/store";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";
function QuotaFishingDetails(props) {
  const history = useHistory();

  const { clearSelectedMap } = useContext(FLISContext);

  return (
    <div>
      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }}>
          Application for Quota and Fishing Rights
        </div>

       

    <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = { appType: 'APPLICATION_QUOTA_FISHING' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }

                            transNumber = await getUniqueTransNumber(details.appType);

                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });
                        clearSelectedMap();
                        if (!!details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Apply now for Quota and Fishing Rights
                    </button>

                </div> 
      </div>

      <div
        style={{
          padding: "40px 14%",
          color: "black",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div>
          The Conch, Lobster and Sea Cucumber fisheries are managed by a
          quota-based system. This means that you will be required to submit an
          application and if successful, a quota will be granted to you that
          will state how much quantity of fish you can catch for the season.
          Quota applications are open before the start of the fishing season and
          for each fishery, the process is different.
        </div>

        <br />
        <div className="d-flex flex-spacing-10">
          <button
            type="button"
            className="btn btn-custom-warning large-button"
            onClick={async () => {
              const details = { appType: "APPLICATION_QUOTA_FISHING" };

              const appDetails = APP_SUBMISSION_CONFIG[details.appType];
              let transNumber = "";
              if (appDetails.isLoginRequired) {
                if (!localStorage.getItem("token")) {
                  history.push("/login");
                  return;
                }

                transNumber = await getUniqueTransNumber(details.appType);
              } else {
                transNumber = await getUniqueTransNumber(details.appType);
              }

              store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: details.appType,
                transaction: {},
                submitted_transaction: null,
              });
              clearSelectedMap();
              if (!!details) history.push(`${appDetails.steps[0].currentStep}`);
            }}
          >
            Apply now for Quota and Fishing Rights
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuotaFishingDetails;
