import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { FLISContext } from '../context/FLISContext';
function FisherWorkerIdDetails(props) {

    const history = useHistory();

    const {clearSelectedMap} = useContext(FLISContext);

    return (
        <div>

            <div className='custom-banner-bg'>
                <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                     Application for Fisher Worker ID
                </div>


                <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = props.app ?? { appType: 'APPLICATION_FOR_FISHER_WORKER_ID' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }

                            transNumber = await getUniqueTransNumber(details.appType);

                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });
                        clearSelectedMap();

                        if (!!details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Apply now for Fisher Worker ID
                    </button>


                    {/* <button type="button" className="btn btn-custom-plain-yellow-b large-button">
                        Join the discussion
                    </button>

                    <button onClick={() => {
                        history.push('/how-to-apply');
                    }} type="button" className="btn btn-custom-plain-yellow-b large-button">
                        How to apply
                    </button> */}

                </div>
            </div>






       
        </div>
    );
}
export default FisherWorkerIdDetails;