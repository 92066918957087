import React, { useContext, useEffect, forwardRef } from "react";
import HorizontalLine from "../../components/HorLine";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";

const inlineFormClassProps = {
  parentClass: "d-flex-wrap flex-row m-2",
  fieldClassName: "form-control form-control-sm max-200",
  // fieldWrapperClassName: "individual-field",
  labelClassName: "horizontal-field-label-min-250",
};

export const InvestmentAndProductionCapacity = forwardRef(
  ({ formState, control, register, onSubmit, setValue, isDisabled = false, ...props }, ref) => {
    const { errors, isDirty, isValid } = formState;

    return (
      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="sub-form-header">
          Investment and Production Capacity
        </div>
        <HorizontalLine></HorizontalLine>
        <div className="d-flex-wrap flex-row mt-1 ">
          <div className="d-flex-wrap sub-form-container flex-column mr-2 mt-2">
            <div className="sub-form-header-second">
              Facility And Vessel Investment (JMD)
            </div>

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.fixed_assets?.message
              }
              label="Fixed Assets"
              regFieldName="investment_production_capacity.fixed_assets"
              placeholder="Fixed Assets"
              type="number"
              isDisabled={isDisabled}
              fieldClassName="form-control form-control-sm max-100"
              {...inlineFormClassProps}

            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.property_leasehold_value
                  ?.message
              }
              label="Property / Leasehold value"
              regFieldName="investment_production_capacity.property_leasehold_value"
              placeholder="Property / Leasehold value"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.processing_equipment
                  ?.message
              }
              label="Processing Equipment"
              regFieldName="investment_production_capacity.processing_equipment"
              placeholder="Processing Equipment"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.fishing_vessel?.message
              }
              label="Fishing Vessel"
              regFieldName="investment_production_capacity.fishing_vessel"
              placeholder="Fishing Vessel"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.fishing_gear?.message
              }
              label="Fishing Gear"
              regFieldName="investment_production_capacity.fishing_gear"
              placeholder="Fishing Gear"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.fishing_vessel_total
                  ?.message
              }
              label="Total"
              regFieldName="investment_production_capacity.fishing_vessel_total"
              placeholder="Total"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <HorizontalLine />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.total_investments_jmd
                  ?.message
              }
              label="Total Investments (JMD)"
              regFieldName="investment_production_capacity.total_investments_jmd"
              placeholder="Total Investments (JMD)"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />
          </div>

          <div className="d-flex-wrap sub-form-container flex-column mr-2 mt-2">
            <div className="sub-form-header-second">
              Production Capacity (Meters)
            </div>

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.raw_materials_freezer
                  ?.message
              }
              label="Raw Materials Freezer"
              regFieldName="investment_production_capacity.raw_materials_freezer"
              placeholder="Raw Materials Freezer"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.raw_materials_chill_room
                  ?.message
              }
              label="Raw Materials Chill Room"
              regFieldName="investment_production_capacity.raw_materials_chill_room"
              placeholder="Raw Materials Chill Room"
              isDisabled={isDisabled}
              type="number"
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.ice_making?.message
              }
              label="Ice Making"
              regFieldName="investment_production_capacity.ice_making"
              placeholder="Ice Making"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.blast_freezing?.message
              }
              label="Blast Freezing"
              regFieldName="investment_production_capacity.blast_freezing"
              placeholder="Blast Freezing"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.finished_product_holding
                  ?.message
              }
              label="Finished Product Holding"
              regFieldName="investment_production_capacity.finished_product_holding"
              placeholder="Finished Product Holding"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity
                  ?.production_capacity_total?.message
              }
              label="Total"
              regFieldName="investment_production_capacity.production_capacity_total"
              placeholder="Total"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <HorizontalLine />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity?.total_jamaicans_employed
                  ?.message
              }
              label="Total Jamaicans Employed:"
              regFieldName="investment_production_capacity.total_jamaicans_employed"
              placeholder="Total Jamaicans Employed:"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />
          </div>
        </div>
      </div>
    );
  }
);
