// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  InternalNavigator,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { validateKeys } from "../../utils/utils";
import {
  formInvalidMessage,
  inputPreviousNumber,
  selectionNotFound,
} from "../../utils/messages";
import { alertService } from "../../_services";
import {
  aquaFacilityTypeofApplicant,
  aquaFacilityTypeofLicence,
  investmentAndEmployment,
  marketingStrategyType,
  productionUnits,
  sizeOfAquacultureFacility,
  typeOfProductionSystems,
  typeOfTransactions,
} from "./AquaFacilityDataSource";
import { TypeofOperation } from "./TypeofOperation";
import { useForm } from "react-hook-form";
import { ProductionUnitInformation } from "./ProductionUnitInformation";
import { TargetFish } from "../AquacultureFishingVessel/TargetFish";
import { typeOfFish } from "../AquacultureFishingVessel/AquaFishingVesselDataSource";
import { InvestmentAndEmployment } from "./InvestmentAndEmployment";
import { MarketingStrategy } from "./MarketingStrategy";
import { RegulatoryApproval } from "./RegulatoryApproval";
import { HistoricalPlannedProduction } from "./HistoricalPlannedProduction";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";

export default function AquaFacilityTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 1);


  const onSubmit = (data) => {
    console.log(data);
  };

  const typeOfOperation = useForm({
    defaultValues: {
      type_of_operation: selectedMap.type_of_operation ?? {},
    },
    mode: "all",
  });

  const productionUnit = useForm({
    defaultValues: {
      production_unit: selectedMap.production_unit ?? {},
    },
    mode: "all",
  });

  const targetFishForm = useForm({
    defaultValues: {
      target_fish: selectedMap.target_fish ?? {},
    },
    mode: "all",
  });

  const investmentAndEmploymentForm = useForm({
    defaultValues: {
      investment_and_employment: selectedMap.investment_and_employment ?? {},
      total_jamaican_employees: selectedMap.total_jamaican_employees,
      total_foreign_employees: selectedMap.total_foreign_employees,
      estimated_investment_value: selectedMap.estimated_investment_value
    },
    mode: "all",
  });

  const marketingStrategyForm = useForm({
    defaultValues: {
      marketing_strategy: selectedMap.marketing_strategy ?? {},
    },
    mode: "all",
  });

  const regulatoryApprovalForm = useForm({
    defaultValues: {
      regulatory_approval: selectedMap.regulatory_approval ?? {},
    },
    mode: "all",
  });

  const historicalPlannedProductionForm = useForm({
    defaultValues: {
      historical_production: selectedMap.historical_production ?? [
        { id: Math.random() },
      ],
      planned_production: selectedMap.planned_production ?? [
        { id: Math.random() },
      ],
      production_type: selectedMap.production_type,
      historical_output_total: selectedMap.historical_output_total,
      historical_production_unit_total:
        selectedMap.historical_production_unit_total,
      planned_output_total: selectedMap.planned_output_total,
      planned_production_unit_total: selectedMap.planned_production_unit_total,
    },
    mode: "all",
  });

  const {
    handleSubmit: handleHPPSubmit,
    control: hppFormControl,
    trigger: triggerValidateHPP,
    getValues: getHistoricalPlannedProdValues,
    register: registerHPP,
    formState: hppFormState,
    setValue: setHPPValue,
    reset: resetHPP,
    watch: watchHPP,
  } = historicalPlannedProductionForm;

  const {
    handleSubmit: handleRASubmit,
    control: raFormControl,
    trigger: triggerValidateRA,
    getValues: getRegulatoryApprovalValues,
    register: registerRA,
    formState: raFormState,
    setValue: setRAValue,
    reset: resetRA,
    watch: watchRA,
  } = regulatoryApprovalForm;

  const {
    handleSubmit: handleMSSubmit,
    control: msFormControl,
    trigger: triggerValidateMS,
    getValues: getMarketingStrategyValues,
    register: registerMS,
    formState: msFormState,
    setValue: setMSValue,
    reset: resetMS,
    watch: watchMS,
  } = marketingStrategyForm;

  const {
    handleSubmit: handleIAESubmit,
    control: iaeFormControl,
    trigger: triggerValidateIAE,
    getValues: getInvestmentAndEmploymentValues,
    register: registerIAE,
    formState: iaeFormState,
    setValue: setIAEValue,
    reset: resetIAE,
    watch: watchIAE,
  } = investmentAndEmploymentForm;

  const {errors: iaeErrors} = iaeFormState;

  const {
    handleSubmit: handleTOSubmit,
    control: toFormControl,
    trigger: triggerValidateTO,
    getValues: getTypeOfOperationValues,
    register: registerTO,
    formState: toFormState,
    setValue: setTOValue,
    reset: resetTO,
    watch: watchTO,
  } = typeOfOperation;

  const {
    handleSubmit: handlePUSubmit,
    control: puFormControl,
    trigger: triggerValidatePU,
    getValues: getProductionUnitValues,
    register: registerPU,
    formState: puFormState,
    setValue: setPUValue,
    reset: resetPU,
    watch: watchPU,
  } = productionUnit;

  const {
    handleSubmit: handleTFSubmit,
    control: tfFormControl,
    trigger: triggerValidateTF,
    getValues: getTargetFishValues,
    register: registerTF,
    formState: tfFormState,
    setValue: setTFValue,
    reset: resetTf,
    watch: watchTF,
  } = targetFishForm;

  const validateHPPForm = async () => {
    try {
      const isFormValid = await triggerValidateHPP();

      // can get values from this
      const data = await getHistoricalPlannedProdValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            historical_production: data.historical_production,
            planned_production: data.planned_production,
            production_type: data.production_type,
            historical_output_total: data.historical_output_total,
            historical_production_unit_total: data.historical_production_unit_total,
            planned_output_total: data.planned_output_total,
            planned_production_unit_total: data.planned_production_unit_total
          };
        });
        handleTOSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateRAForm = async () => {
    try {
      const isFormValid = await triggerValidateRA();

      // can get values from this
      const data = await getRegulatoryApprovalValues();

      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            regulatory_approval: data.regulatory_approval,
          };
        });
        handleTOSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateIAEForm = async () => {
    try {
      const isFormValid = await triggerValidateIAE();

      // can get values from this
      const data = await getInvestmentAndEmploymentValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            investment_and_employment: data.investment_and_employment,
            total_jamaican_employees: data.total_jamaican_employees,
            total_foreign_employees: data.total_foreign_employees,
            estimated_investment_value: data.estimated_investment_value
          };
        });
        handleTOSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateMSForm = async () => {
    try {
      const isFormValid = await triggerValidateMS();

      // can get values from this
      const data = await getMarketingStrategyValues();
      
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            marketing_strategy: data.marketing_strategy,
          };
        });
        handleTOSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateTOForm = async () => {
    try {
      const isFormValid = await triggerValidateTO();

      // can get values from this
      const data = await getTypeOfOperationValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            type_of_operation: data.type_of_operation,
          };
        });
        handleTOSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validatePUForm = async () => {
    try {
      const isFormValid = await triggerValidatePU();

      // can get values from this
      const data = await getProductionUnitValues();
      const production_unit = data.production_unit;
      if (isFormValid) {

        const puKeys = Object.keys(production_unit);
        const isChecked = puKeys.some((key) => {
          return production_unit[key].isChecked;
        });


        if(isChecked){
          setSelectedMap((map) => {
            return {
              ...map,
              production_unit: data.production_unit,
            };
          });
          handleTOSubmit(onSubmit)();
        }else {
          alertService.warn(selectionNotFound, {
            autoClose: true,
          });
          return false;
        }
       
       
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateTFForm = async () => {
    try {
      const isFormValid = await triggerValidateTF();

      // can get values from this
      const data = await getTargetFishValues();

      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            target_fish: data.target_fish,
          };
        });
        handleTFSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 1 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select Type of Facility?"}
            listingOptions={aquaFacilityTypeofLicence}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"facility_type"}
            onClick={() => {
              setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                history.goBack();
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["facility_type"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 2 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Transaction"}
            listingOptions={typeOfTransactions}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_transaction"}
            onClick={(value, i) => {
              if (value === "New") setSelectionCategory(selectionCategory + 2);
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          {["Renewal", "Amendment"].indexOf(selectedMap.type_of_transaction) >=
            0 && (
            <CustomInputContainer
              heading={"Enter the Licence/Permit Number"}
              prop_key={"previous_licence_number"}
              placeholder={"Enter Licence/Permit number"}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
            ></CustomInputContainer>
          )}

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (
                  ["Renewal", "Amendment"].indexOf(
                    selectedMap.type_of_transaction
                  ) >= 0
                ) {
                  if (!validateKeys(selectedMap, ["previous_licence_number"])) {
                    alertService.warn(
                      inputPreviousNumber("Licence/Permit Number"),
                      { autoClose: true }
                    );
                    return;
                  }
                }

                setSelectionCategory(selectionCategory + 2);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 3 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select Appplicant Type"}
            listingOptions={aquaFacilityTypeofApplicant}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"applicant_type"}
            onClick={() => {
              setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["applicant_type"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 4 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select size of Aquaculture Facility"}
            listingOptions={sizeOfAquacultureFacility}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"size_of_aquaculture_facility"}
            onClick={() => {
              setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 2);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["size_of_aquaculture_facility"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 5 && (
        <>
          <TypeofOperation
            formState={toFormState}
            register={registerTO}
            control={toFormControl}
            onSubmit={onSubmit}
            setValue={setTOValue}
            reset={resetTO}
            values={getTypeOfOperationValues}
            watch={watchTO}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateTOForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 6 && (
        <>
          <ProductionUnitInformation
            formState={puFormState}
            register={registerPU}
            control={puFormControl}
            onSubmit={onSubmit}
            setValue={setPUValue}
            reset={resetPU}
            values={getProductionUnitValues}
            watch={watchPU}
            source={productionUnits}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validatePUForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 7 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select type of Production System"}
            listingOptions={typeOfProductionSystems}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_production_system"}
            isMultiSelect={true}
            listingStyle="justify-content-around"
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_production_system"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 8 && (
        <>
          <TargetFish
            formState={tfFormState}
            register={registerTF}
            control={tfFormControl}
            onSubmit={onSubmit}
            setValue={setTFValue}
            reset={resetTf}
            values={getTargetFishValues}
            watch={watchTF}
            source={typeOfFish}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateTFForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 9 && (
        <>
          <InvestmentAndEmployment
            formState={iaeFormState}
            register={registerIAE}
            control={iaeFormControl}
            onSubmit={onSubmit}
            setValue={setIAEValue}
            reset={resetIAE}
            values={getInvestmentAndEmploymentValues}
            watch={watchIAE}
            source={investmentAndEmployment}
            formKey={"investment_and_employment"}
            showHeading={true}
          />

          <div>
            <label>Total Number of Employees & Investment Details:</label>

            <span className="d-flex-wrap sub-form-container">
              <FormHookInput
                register={registerIAE}
                error={iaeErrors?.total_jamaican_employees?.message}
                label="Jamaican"
                regFieldName="total_jamaican_employees"
                type="number"
              />

              <FormHookInput
                register={registerIAE}
                error={iaeErrors?.total_foreign_employees?.message}
                label="Foreign"
                regFieldName="total_foreign_employees"
                type="number"
                isRequired={false}
              />

<FormHookInput
            parentClass="ml-2 max-500"
                register={registerIAE}
                error={iaeErrors?.estimated_investment_value?.message}
                label="Estimated Value (JMD) of Investment for the Facility"
                regFieldName="estimated_investment_value"
                isRequired={false}
                type="number"
              />
            </span>
          </div>

          <div>
          
          </div>

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateIAEForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 10 && (
        <>
          <HistoricalPlannedProduction
            formState={hppFormState}
            register={registerHPP}
            control={hppFormControl}
            onSubmit={onSubmit}
            setValue={setHPPValue}
            reset={resetHPP}
            values={getHistoricalPlannedProdValues}
            watch={watchHPP}
            showHeading={true}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateHPPForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 11 && (
        <>
          <MarketingStrategy
            formState={msFormState}
            register={registerMS}
            control={msFormControl}
            onSubmit={onSubmit}
            setValue={setMSValue}
            reset={resetMS}
            values={getMarketingStrategyValues}
            watch={watchMS}
            source={marketingStrategyType}
            formKey={"marketing_strategy"}
            showHeading={true}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateMSForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 12 && (
        <>
          <RegulatoryApproval
            formState={raFormState}
            register={registerRA}
            control={raFormControl}
            onSubmit={onSubmit}
            setValue={setRAValue}
            reset={resetRA}
            values={getRegulatoryApprovalValues}
            watch={watchRA}
            showHeading={true}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateRAForm()) {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory
                    }
                  })

                  props?.next();
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
