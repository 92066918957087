import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/styles/tracker.css";
import { Provider } from "react-redux";
import store from "./js/redux/store";
import { BrowserRouter, Route, Switch} from "react-router-dom";
import "material-icons/iconfont/material-icons.css";
import LandingContainer from "./js/containers/LandingContainer";
import { Alert } from "./js/components/Alert";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

let currentUrl = window.location.href;


const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          position: "fixed",
          left: "0px",
          top: "0px",
          backgroundColor: "lightskyblue",
          opacity: 0.3,
          width: "100%",
          height: "100vh",
          zIndex: 99999998,
        }}
      >
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, 0)",
            zIndex: 99999999,
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      </div>
    )
  );
};



function App() {

 



  return (
    <Provider store={store}>

        <BrowserRouter>
          <LoadingIndicator />
          <Alert />
          <Switch>
            <Route component={LandingContainer}></Route>
          </Switch>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
