import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../assets/styles/form.css";
import { alertService } from "../_services";
import store from "../redux/store";
import { ROUTES } from "../config";
import { postData } from "../services/service-call";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
  ReactInput,
} from "../components/FormHook/ReactFormHookFields";
import {
  emailPattern,
  formatPhoneNumber,
  formatTRN,
  telephonePattern,
  trnPattern,
} from "../components/DataSource";
import { emailNotValid, telephoneNotValid, trnNotValid } from "../utils/messages";
import {
  createAccountIcon,
  helpIcon,
  signInIcon,
} from "../../assets/icons/icons_svg";
import HorizontalLine from "../components/HorLine";
import AlertDialogSlide from "../components/Modal/Modal";
import { ResponsiveTable } from "../components/ResponsiveTable";
import { getNameOfApplicant } from "../utils/utils";
import { ForgotPassword } from "./ForgotPassword";

export const Login = (props) => {
  const history = useHistory();

  const [isMultiple, setIsMultiple] = useState(false);
  const [entities, setEntities] = useState([]);
  const [userCred, setUserCred] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const selectedLoginWith = watch("login_with");

  let [validationPattern, setValidationPattern] = useState({});

 
  useEffect(() => {
    if (selectedLoginWith === "email") {
      setValidationPattern({
        pattern: {
          value: emailPattern,
          message: emailNotValid,
        },
      });
    } else if (selectedLoginWith === "taxation_registration_number") {
      setValidationPattern({
        pattern: {
          value: trnPattern,
          message: trnNotValid,
        },
      });
    } else if (selectedLoginWith === "telephone_number") {
      setValidationPattern({
        pattern: {
          value: telephonePattern,
          message: telephoneNotValid,
        },
      });
    } else {
      setValidationPattern({});
    }
  }, [selectedLoginWith]);

  const { errors } = formState;


  let passwordMap = {};


  const [forgotPass, setForgotPass] = useState(false);

  function isEmailAddress(str) {
    var pattern = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return pattern.test(str);
  }
  

  const resetPassword = async (value) => {
    const isValid = isEmailAddress(value);
    if (!isValid) {
      alertService.error("Email not valid", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      return;
    } else {
      const result = await postData({
        url: ROUTES.resetPassword,
        body: { email: value },
      });
      if (result.statusCode === 200) {
        alertService.success(
          "Password reset link has been sent to email address",
          { autoClose: true, keepAfterRouteChange: true }
        );
      } else {
        alertService.error(result.data.msg, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }

      setForgotPass(false);
    }
  };


  const SelectEntityToLogin = ({entities = [], userValues = {}}) => {

    return (<div>
        <ResponsiveTable
        tableClass="table-striped"
        headerClass="thead-dark"
          headers={[
            "S. No.",
            "NFA Reg. #",
            "TRN",
            "Applicant Type",
            "Applicant Name",
            "Email",
            "Tel. #",
            "Entity #",
            "Date of Reg./Birth",
            ['taxation_registration_number', 'nfa_registration_number'].includes(selectedLoginWith) ? "Password" : undefined ,
            "Login",
          ].filter(e => e)}>

          <tbody>
            {entities.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.nfa_registration_number}</td>
                  <td>{item.taxation_registration_number}</td>
                  <td>{item.applicant_type}</td>
                  <td>{getNameOfApplicant(item)}</td>
                  <td>{['taxation_registration_number', 'nfa_registration_number'].includes(selectedLoginWith) ? item.email : item.applicant_email_address}</td>
                  <td>{['taxation_registration_number', 'nfa_registration_number'].includes(selectedLoginWith) ? item.cell_phone_user : item.applicant_cell_phone}</td>
                  <td>{item.entity_number}</td>
                  <td>
                    {item.applicant_type === "Individual"
                      ? item?.date_of_birth_individual?.substring(0, 10)
                      : item.date_of_registration}
                  </td>

                  {
                   ['taxation_registration_number', 'nfa_registration_number'].includes(selectedLoginWith) && <td>
                    <ReactInput regFieldName={`password_${index}`} type="password" placeholder="Password" hideLabel={true} onChange={(e) => {
                      passwordMap[index] = e.target.value;
                    }}  />
                    </td>
                  }
                  
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={async () => {

                        if(['taxation_registration_number', 'nfa_registration_number'].includes(selectedLoginWith) && !passwordMap[index]) {
                          alertService.warn("Please input password in the field", {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                          return;
                        }
                        const values = {
                          entity_id: item.entity_id,
                          user_id: item.user_id,
                          password: passwordMap[index] || userValues.password 
                        };

                        const result = await postData({
                          url: ROUTES.loginUser,
                          body: values,
                        });

                        if (result.statusCode === 200) {
                          setIsMultiple(result.data.isMultiple);

                          alertService.success("Logged in successfully", {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                          localStorage.setItem("token", result.data.token);
                          store.dispatch({
                            type: "LOGIN",
                            token: result.data.token,
                          });
                          const url = localStorage.getItem('url_to_navigate');
                          if(url) {
                            history.push(url);
                          } else {
                            history.push("/application-types");
                          }
                        } else if (result.statusCode === 401) {
                          alertService.error(result.data.msg, {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                        }
                      }}
                    >
                      Login
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
    </div>)
  }

  return (
    <div className="regitration-container">
      <AlertDialogSlide
        fullWidth={true}
        open={isMultiple}
        title={"Select Applicant/User to Login With"}
        titleStyle={{ fontWeight: 700, fontSize: "24px" }}
        handleCloseSecondary={() => {
          setIsMultiple(false);
        }}
        style={{
          width: "1300px",
          maxWidth: "100%",
          margin: "auto",
          maxHeight: "100vh",
          height: "100%",
        }}
        secondaryActionName={"Close"}
        content={
          <SelectEntityToLogin
            entities={entities}
            userValues={userCred}
          ></SelectEntityToLogin>
        }
      />

      <div className="login-form-container">
        <div className="d-flex-wrap flex-column justify-content-center  registration-margin">
          {!forgotPass && (
            <div
              style={{
                marginLeft: "12px",
                fontSize: "26px",
                fontWeight: "700",
              }}
            >
              Login to the IrieFINS Portal
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                You can enter one of the following - as your User Name
                <ul style={{ marginBottom: "0px" }}>
                  <li>Email Address</li>
                  <li>Mobile #</li>
                  <li>TRN</li>
                  <li>NFA Registration #</li>
                  <li>Fisher ID</li>
                </ul>
              </div>
            </div>
          )}

          {!forgotPass && (
            <>
              <FormHookSelect
                label="Login with"
                register={register}
                error={errors?.login_with?.message}
                regFieldName="login_with"
                options={[
                  { value: "email", label: "Email" },
                  {
                    value: "nfa_registration_number",
                    label: "Fisher ID",
                  },
                  { value: "taxation_registration_number", label: "TRN" },
                  { value: "telephone_number", label: "Telephone #" },
                ]}
                fieldClassName="form-control"
                isKeyValue={true}
                parentClass="individual-field"
              />

              <FormHookInput
                register={register}
                error={errors?.user_name?.message}
                label="User Name"
                regFieldName="user_name"
                placeholder={`Enter Email/Telephone Number/TRN/NFA Reg. Number`}
                fieldClassName="form-control"
                validations={validationPattern}
                onChange={
                  selectedLoginWith === "telephone_number"
                    ? formatPhoneNumber
                    : selectedLoginWith === "taxation_registration_number"
                    ? formatTRN
                    : () => {}
                }
              />

              {(selectedLoginWith === "email" ||
                selectedLoginWith === "telephone_number") && (
                <FormHookInput
                  register={register}
                  error={errors?.password?.message}
                  label="Password"
                  regFieldName="password"
                  placeholder="Password"
                  type="password"
                  fieldClassName="form-control"
                />
              )}

              {(selectedLoginWith || true) && (
                <div className="mt-3 d-flex-wrap flex-row justify-content-between">

                  <button
                    style={{ margin: "0px 10px" }}
                    className="btn btn-custom-success mb-4"
                    onClick={async () => {
                      const isValid = await trigger();
                      if (isValid) {
                        const values = getValues();
                        setUserCred(values);
                        const result = await postData({
                          url: ROUTES.loginUser,
                          body: values,
                        });

                        if (result.statusCode === 200) {
                          setIsMultiple(true);
                          setEntities(result.data.all_entities);
                        } else {
                          alertService.error(result.data.msg, {
                            autoClose: true,
                            keepAfterRouteChange: true,
                          });
                        }
                      }
                    }}
                    type="submit"
                  >
                    Sign In {signInIcon}
                  </button>
                </div>
              )}

              <HorizontalLine />
              <marquee>
                Applications should only be accessed by authorized users.
              </marquee>
            </>
          )}

          {!forgotPass && (
            <div className="mt-1 d-flex justify-content-between">
              <button
                className="btn btn-custom-success"
                type="submit"
                style={{ marginLeft: "10px" }}
                onClick={() => history.push("/register")}
              >
                Create Account {createAccountIcon}
              </button>

              <button
                className="btn btn-custom-warning"
                type="submit"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                    history.push('/forgot-password');
                }}
              >
                Forgot Password {helpIcon}
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
