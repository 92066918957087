import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { useHistory, Link } from 'react-router-dom';
import '../../assets/styles/form.css';

import { alertService } from '../_services';

import store from '../redux/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from 'react-google-login';

import { ROUTES } from '../config';
import { postData } from '../services/service-call';
import ReactGA from 'react-ga4';

export const Login = (props) => {
    const history = useHistory();

    const responseGoogle = async (response) => {
        console.log(response);


        const { tokenId } = response;
        const result = await postData({ url: `${ROUTES.verifyToken}?token=${tokenId}&actionType=login&email=${response.profileObj?.email}` });
        if (result.statusCode !== 200) {
            alertService.error(result.data?.msg, { autoClose: true, keepAfterRouteChange: true })
        } else if (result.statusCode === 200) {
            alertService.success(result.data?.msg, { autoClose: true, keepAfterRouteChange: true });
            localStorage.setItem('token', result.data.token);
            store.dispatch({
                type: 'LOGIN',
                token: result.data?.token
            });
            history.push('/application-types');
        }
    }

    const handleCredentialResponse = (response) => {
        // decodeJwtResponse() is a custom function defined by you
        // to decode the credential response.

        // const responsePayload = decodeJwtResponse(response.credential);

        // console.log("ID: " + responsePayload.sub);
        // console.log('Full Name: ' + responsePayload.name);
        // console.log('Given Name: ' + responsePayload.given_name);
        // console.log('Family Name: ' + responsePayload.family_name);
        // console.log("Image URL: " + responsePayload.picture);
        // console.log("Email: " + responsePayload.email);
    }

    // function onSignIn(googleUser) {
    //     var profile = googleUser.getBasicProfile();
    //     console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    //     console.log('Name: ' + profile.getName());
    //     console.log('Image URL: ' + profile.getImageUrl());
    //     console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    //   }

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required('Required'),
            password: Yup.string()
                .required('This field is required'),
        }),
        onSubmit: async values => {
            const result = await postData({ url: ROUTES.loginUser, body: values });
            if (result.statusCode === 200) {
                alertService.success('Logged in successfully', { autoClose: true, keepAfterRouteChange: true })
                localStorage.setItem('token', result.data.token);
                store.dispatch({
                    type: 'LOGIN',
                    token: result.data.token
                });
                history.push('/application-types');
            } else if (result.statusCode === 401) {
                alertService.error(result.data.msg, { autoClose: true, keepAfterRouteChange: true })
            }

            // localStorage.setItem('isloogedIn', true);
            // window.location.reload();
        },
    });

    const { from } = props.location.state || { from: { pathname: '/' } };
    const [forgotPass, setForgotPass] = useState(false);

    const forgotPassword = () => {
        setForgotPass(true);
    }

    function isEmailAddress(str) {
        var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
        return pattern.test(str);  // returns a boolean 
    }

    const resetPassword = async (value) => {

        const isValid = isEmailAddress(value);
        if (!isValid) {
            alertService.error('Email not valid', { autoClose: true, keepAfterRouteChange: true });
            return;
        } else {

            const result = await postData({
              url: ROUTES.resetPassword,
              body: { email: value },
            });
            if (result.statusCode === 200) {
              alertService.success(
                "Password reset link has been sent to email address",
                { autoClose: true, keepAfterRouteChange: true }
              );
            } else {
              alertService.error(result.data.msg, {
                autoClose: true,
                keepAfterRouteChange: true,
              });
            }
            
            setForgotPass(false);
        }
    
}

    // const onClick = () => {
    //     store.dispatch({
    //         type: 'LOGIN'
    //     });
    //     history.push(from);
    // }

    // useEffect(() => {
    //     function handleCredentialResponse(response) {
    //         console.log("Encoded JWT ID token: " + response.credential);
    //       }
    //       window.onload = function () {
    //         google.accounts.id.initialize({
    //           client_id: "287642303536-oaeblbu4dejtas0gfo2nl0kg9h4k4h9b.apps.googleusercontent.com",
    //           callback: handleCredentialResponse
    //         });
    //         google.accounts.id.renderButton(
    //           document.getElementById("buttonDiv"),
    //           { theme: "outline", size: "large" }  // customization attributes
    //         );
    //         // google.accounts.id.prompt(); // also display the One Tap dialog
    //       }
    // }, []);

    return (
        <div className="regitration-container">
            <div className="login-form-container">

                {/* <div className="form-top-heading">Complaints Portal</div> */}

                <div className="form-top-heading">Login</div>

                <div style={{color: 'red', textAlign: 'center', fontSize: '20px'}}>The application should only be accessed by authorized users.</div>

                <form onSubmit={formik.handleSubmit}>

                    <div className="row">
                        <div className="col">
                            <label htmlFor="user_name">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.user_name}
                            />
                            <span>{formik.touched.user_name && formik.errors.user_name ? (
                                <div>{formik.errors.user_name}</div>
                            ) : null}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label htmlFor="password">Password</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <span>{formik.touched.password && formik.errors.password ? (
                                <div>{formik.errors.password}</div>
                            ) : null}</span>
                        </div>

                    </div>

                    <div className="row mt-3">
                        <div className="col mt-2 d-flex justify-content-center">
                            <button className="btn btn-custom-success" type="submit">Login</button>
                        </div>
                    </div>

                    <div className="row mt-3 ">
                        <div className="col d-flex justify-content-center">
                            <h4>OR</h4>
                        </div>
                    </div>

                    {
                        !forgotPass &&
                        <div className="row mt-3">
                            <div className="col mt-2 d-flex justify-content-center">
                                <button onClick={() => { forgotPassword() }} className="btn btn-secondary" type="button">Forgot Password</button>
                            </div>
                        </div>
                    }


                    {
                        forgotPass && (
                            <div>
                                <div className="row mt-3">



                                    <div className="col">
                                        <label htmlFor="forgot_user_name">Email address to reset password</label>
                                        <input
                                            id="forgot_user_name"
                                            name="forgot_user_name"
                                            type="email"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.forgot_user_name}
                                        />
                                        <span>{formik.touched.forgot_user_name && formik.errors.forgot_user_name ? (
                                            <div>{formik.errors.forgot_user_name}</div>
                                        ) : null}</span>
                                    </div>



                                </div>

                                <div className="col mt-2 d-flex justify-content-center">
                                    <button onClick={() => { 
                                        resetPassword(formik.values.forgot_user_name)
                                        ReactGA.event({
                                            category: 'User',
                                            action: 'Clicked Button',
                                            label: 'Reset Password Using Email'
                                          });
                                        }} className="btn btn-secondary" type="button">Reset</button>
                                </div>
                            </div>

                        )

                    }

                    {/* <div className="g-signin2" data-onsuccess={onSignIn}></div> */}


                    {/* <div className="row mt-3">
                    <div className="col d-flex justify-content-center">
                    <GoogleLogin
                                // clientId="1015323313638-8357jafp58qd58h6r1s3ch6onju186et.apps.googleusercontent.com"
                                clientId="287642303536-oaeblbu4dejtas0gfo2nl0kg9h4k4h9b.apps.googleusercontent.com"
                                buttonText="Login with Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                    </div>

                    </div> */}


                    <div id="buttonDiv"></div>

                    {/* <div id="g_id_onload"
                                data-client_id={"287642303536-oaeblbu4dejtas0gfo2nl0kg9h4k4h9b.apps.googleusercontent.com"}
                                // data-login_uri="https://your.domain/your_login_endpoint"
                                data-callback={handleCredentialResponse}
                                
                                data-auto_prompt="false">
                            </div>
                            <div className="g_id_signin"
                                data-type="standard"
                                data-size="large"
                                data-theme="outline"
                                data-text="sign_in_with"
                                data-shape="rectangular"
                                data-logo_alignment="left">
                            </div> */}


                    <div className="row mt-3">
                        <div className="col d-flex justify-content-center">
                            <div>New to the complaints portal? &nbsp;</div>
                            <Link className="" to="register">&nbsp;Register</Link>

                        </div>
                    </div>

                </form>
            </div>


        </div>
    );
}

Login.propTypes = {

}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
