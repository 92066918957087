import React from 'react';


import DashboardUpdates from '../components/DashboardUpdates';
import CommunityHeadlines from '../components/CommunityHeadlines';
export const Dashboard = (props) => {

    // const history = useHistory();
    return (
        <div style={{ backgroundColor: '#eee' }}>

            <div className='custom-banner-bg'>
                <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                    Your Dashboard
                </div>

                <div>
                    <h5>Keep up to date with all the required insights from the system</h5>
                </div>

            </div>


            <div className='row col-sm-12 mt-3'>
                <div className='col-sm-1'></div>
                <div className='col-sm-6'>
                    {/* Dashboard Updates */}

                    <div className='d-flex justify-content-between'>
                        <div style={{ fontSize: '32px', fontWeight: '600' }}>Dashboard Updates</div>
                        <div>
                            <button className='btn btn-custom-plain-yellow-black-text'>See all your applications</button>
                        </div>
                    </div>

                    <div className='d-updates'>
                        <DashboardUpdates sequence={0} />
                        <DashboardUpdates sequence={1} />
                        <DashboardUpdates sequence={2} />
                    </div>
                </div>


                <div className='col-sm-4'>
                    {/* Community Updates */}

                    <div className='d-flex justify-content-between'>
                        <div style={{ fontSize: '32px', fontWeight: '600' }}>Community Updates</div>

                        <div>
                            <button className='btn btn-custom-plain-green-b' style={{ color: '#00b7fd', border: '4px solid #00b7fd' }}>View all communities</button>
                        </div>
                    </div>



                    <div className=' mt-2 p-2' style={{background: 'white'}}>
                        <div style={{fontSize: '24px', fontWeight: '600'}}>Your Discussions</div>


                        <CommunityHeadlines sequence={0}/>
                       
                        <CommunityHeadlines sequence={1}/>
                       
                        <CommunityHeadlines sequence={2}/>
                       
                    </div>

                </div>


            </div>

        </div>
    );
}
