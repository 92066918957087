import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";
import {
  InternalNavigator,
  Declarations,
  Break,
  telephonePattern,
  formatPhoneNumber,
  emailPattern
} from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FLISContext } from "../../context/FLISContext";
import { FormHookCheckbox, FormHookFileUpload, FormHookInput, FormHookRadioInput, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { SignaturePad } from "../../components/SignatureCanvas";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { SUBSTITUTE_ID_CARD, typeOfLetterRequested } from "./GeneralAdministrationDataSource";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import GeneralDeclarations from "../GeneralDeclaration";
import { ROUTES } from "../../config";
import { emailNotValid, telephoneNotValid } from "../../utils/messages";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForGeneralAdministration = (props) => {

  const {selectedMap, setSelectedMap} = useContext(FLISContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonSubmitRef = useRef();

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    reset,
    control,
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const {errors} = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });

  const onSubmit = async (data) => {
    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }


    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();

    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }


    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };

  const setSignatureValue = (id, base64) => {
    setValue(id, base64);
  }

  const [selectedForm , setSelectedForm] = useState(1);

  useEffect(() => {
    trigger();
  }, []);

  const typeOfLetterRequestedWatch = watch('type_of_letter_requested');
  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Letters & Other Services"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Letters & Other Services</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookCheckbox
                      label="Type of Letter Requested"
                      options={typeOfLetterRequested}
                      register={register}
                      error={errors?.type_of_letter_requested?.message}
                      regFieldName={`type_of_letter_requested`}
                      isMulti={false}
                      isDisabled={true}
                      fullWidth={true}
                    />

                    <Break />

                    {typeOfLetterRequestedWatch === SUBSTITUTE_ID_CARD && (
                      <FormHookInput
                        register={register}
                        error={errors?.substitute_id_card_number?.message}
                        label="Licence/Permit #"
                        regFieldName="substitute_id_card_number"
                        placeholder="Licence/Permit #"
                        isDisabled={true}
                      />
                    )}
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Contact Details</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.first_name?.message}
                      label="First Name"
                      regFieldName="first_name"
                      placeholder="First Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.middle_name?.message}
                      label="Middle Name"
                      regFieldName="middle_name"
                      placeholder="Middle Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.last_name?.message}
                      label="Last Name"
                      regFieldName="last_name"
                      placeholder="Last Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.telephone_number?.message}
                      label="Telephone #"
                      regFieldName="telephone_number"
                      placeholder="Telephone #"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <Break />

                    <FormHookTextArea
                      register={register}
                      cols={69}
                      rows={1}
                      error={errors?.address?.message}
                      label="Address"
                      regFieldName="address"
                      placeholder="Address"
                    />

                    <FormHookTextArea
                      cols={69}
                      register={register}
                      error={errors?.purpose_of_letter?.message}
                      label="Purpose of Letter"
                      regFieldName="purpose_of_letter"
                      placeholder="Purpose of Letter"
                      validations={{
                        maxLength: { value: 500, message: "Max length is 500" },
                      }}
                      rows={1}
                    />

                    <Break/>

                    <FormHookInput
                      register={register}
                      error={errors?.email_address?.message}
                      label="Email Address"
                      regFieldName="email_address"
                      placeholder="Email Address"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.date_required?.message}
                      label="Date Required"
                      regFieldName="date_required"
                      type="date"
                    />

                    <FormHookFileUploadHandler
                      error={errors?.upload?.message}
                      register={register}
                      watch={watch}
                      label="Upload Supporting Document"
                      accept="*"
                      regFieldName="upload"
                      fieldClassName="field-block-control"
                      // isPreviewable={true}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    />
                  </div>
                </div>
                <SimpleDeclaration register={register} errors={errors}/>

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>

        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForGeneralAdministration);
