import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, Link, useParams } from 'react-router-dom';
import { alertService } from '../../_services';
import queryString from 'query-string';
import HorizontalLine from '../HorLine';
import background from "../../../assets/images/fisheries.jpeg";


export const Navbar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    isLoggedIn = localStorage.getItem('logindata') ?? "{}";
    const [login, setLogin] = useState(JSON.parse(isLoggedIn));

    isLoggedIn = localStorage.getItem('isloogedIn') === 'true' ?? false;
    // let queryParams = useParams();
    let queryParams = queryString.parse(props.location?.search)

    const [commission, setCommission] = useState(queryParams.route ?? localStorage.getItem('route'));

    setTimeout(() => {
        setCommission(queryParams.route ?? localStorage.getItem('route'));
    }, 200);

    return (
      <div>
        <div
        className='d-flex justify-content-between'
          style={{
            padding: "0px 1% ",
            backgroundColor: "#00b7fd",
            color: "white",
          }}
        >
          <div
            onClick={() => history.push("/home")}
            className='d-flex justify-content-between'
            style={{
              padding: "10px 0px 10px 0px",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <div>
              <img
                alt="Fisheries"
                height={65}
                style={{ flexGrow: 1 }}
                src="./images/flis_logo.png"
              ></img>
            </div>

            <div
              style={{
                fontSize: "28px",
                fontWeight: "600",
                paddingLeft: "10px",
              }}
            >
              <div>National Fisheries Authority</div>
            </div>
          </div>

          {!isLoggedIn && (
            <div
            className='d-flex justify-content-center align-items-center'
              style={{
                flexDirection: "row",
                padding: "15px 10px 10px 10px",
                fontWeight: 800,
                fontSize: "18px",
              }}
            >
              <div style={{ verticalAlign: "center" }}>
                <a href="/login" style={{ color: "white" }}>
                  Log In
                </a>
              </div>

              <div style={{ paddingLeft: "10px", verticalAlign: "middle" }}>
                <a
                  href="/register"
                  onClick={() => {
                    // history.push('/dashboard')
                  }}
                  style={{ color: "white" }}
                >
                  Create Account
                </a>
              </div>
            </div>
          )}

          {isLoggedIn && (
            <div
            className='d-flex justify-content-center align-items-center'
              style={{
                padding: "15px 10px 10px 10px",
                fontWeight: 800,
                fontSize: "18px",
              }}
            >
              <div>
                {`Hello ${
                  login.userType === "Organization"
                    ? login.company_name
                    : `${login.first_name}`
                }`}
              </div>

              <div style={{ paddingLeft: "60px", verticalAlign: "middle" }}>
                <a
                  href="/login"
                  onClick={() => {
                    localStorage.setItem("isloogedIn", false);
                  }}
                  style={{ color: "green" }}
                >
                  Logout
                </a>
              </div>
            </div>
          )}
        </div>

        <HorizontalLine />

        <div
          style={{ padding: "10px 0% 10px 0%", cursor: "pointer" }}
          className="d-flex"
          
        >

<div>
              <img
              onClick={() => history.push("/home")}
                alt="Fisheries"
                height={65}
                style={{ flexGrow: 1 }}
                src="./images/IrieFINS_Logo-01.png"
              ></img>
            </div>


          <div
            onClick={() => {
              history.push("/home");
            }}
            style={{
              maxWidth: "800px",
              textAlign: "",
              fontSize: "28px",
              fontWeight: "800",
              paddingTop: '10px'
            }}
          >
            FISHERIES LICENCE INFORMATION SYSTEM
          </div>

          <div className="d-flex justify-content-end flex-grow-1 flex-spacing-10">
            {/* <div>
                        <button type="button" className="btn btn-custom-success large-button">Community</button>
                    </div> */}

            <div>
              <button
                onClick={() => history.push("/support")}
                type="button"
                className="btn btn-custom-success large-button"
              >
                Help & Support
              </button>
            </div>

            <div onClick={() => history.push("/home")}>
              {/* <button
                type="button"
                className="btn btn-custom-success large-button"
              >
                Discussion
              </button> */}
            </div>

            <div
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <button
                type="button"
                className="btn btn-custom-success large-button"
              >
                Dashboard
              </button>
            </div>

            {/* <div style={{ marginRight: 0 }}>
              <button
                onClick={() => history.push("/chat-with-us")}
                type="button"
                className="btn btn-custom-success large-button"
              >
                Chat With Us
              </button>
            </div> */}
          </div>
        </div>

      </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    isLoggedIn: state.auth.isLoggedIn
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => dispatch({
        type: 'LOGOUT'
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
