// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fishingArea,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { validateKeys } from "../../utils/utils";

import {
  selectionNotFound,
} from "../../utils/messages";
import { alertService } from "../../_services";
import { SUBSTITUTE_ID_CARD, typeOfLetterRequested} from "./GeneralAdministrationDataSource";

export default function GeneralAdministrationTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 1);


  const [mapRef, setMapRef] = useState();


  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}>

      {selectionCategory === 1 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select Type of Letter Requested"}
            listingOptions={typeOfLetterRequested}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_letter_requested"}
            onClick={(trans, i) => {
              if(trans === SUBSTITUTE_ID_CARD){
                setSelectionCategory(selectionCategory + 1);
              }else {
                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory
                  }
                })

                props?.next();
              }
            }}
            listingStyle="justify-content-around"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                history.goBack();
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_letter_requested"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if(selectedMap.type_of_letter_requested === SUBSTITUTE_ID_CARD){
                  setSelectionCategory(selectionCategory + 1);
                }else {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory
                    }
                  })
                  props?.next();
                }
                
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 2 && (
        <div>
          <CustomInputContainer
            heading={"Substitute ID Card (Add the specific Licence Number of the Card)"}
            prop_key={"substitute_id_card_number"}
            placeholder={"Substitute ID Card Number"}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
          ></CustomInputContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["substitute_id_card_number"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory
                  }
                })
                props?.next();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
