import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../redux/actions/transactions";
import { desc } from "../config/application-submission-config";
import { apiHeaders, getData, postData } from "../services/service-call";
import { ROUTES } from "../config";
import { alertService } from "../_services";
import { getCaseRow } from "../components/DataSource";
import axios from "axios";
import { SpaceBar } from "@material-ui/icons";
import AlertDialogSlide from "../components/Modal/Modal";
import DocumentPreviewWithBlob from "../components/DocumentPreviewWithBlob";
import { searchLicenceUIColumns } from "./AdminModule/AdminDataSource";
import { FLISContext } from "../context/FLISContext";

const AllLicences = () => {
  const [licences, setAllLicences] = useState([]);

  const [dialog, showDialog] = useState(false);

  const { currentEntity } = useContext(FLISContext);

  const [currentTrans, setCurrentTrans] = useState({});

  const [blob, setBlob] = useState(null);

  const [preview, showPreview] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await postData({
        url: `${ROUTES.getAllIssuedCertificates}`,
      });

      if (Array.isArray(result)) {
        setAllLicences(result);
      } else {
        alertService.warn(
          "Error while getting issued certificates, please try again."
        );
      }
    })();
  }, []);

  
  
  
  const downloadCertificate = async (trans, type) => {
    // Assuming you're using axios or fetch to make the API call
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${ROUTES.downloadLicenceCertificate}/${trans.propertiesMap.FLIS_TransactionId}`,
        {
          certificate_type: type,
        },
        {
          headers: apiHeaders(),
          responseType: "blob", // Set the response type to blob
        }
      )
      .then((response) => {
        // Create a blob from the response data
        const mimeType = response.headers['content-type'];
        const blob = new Blob([response.data], {
          type: mimeType,
        });

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "document.pdf";
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };


  const previewCertificate = async (trans, type) => {
    // Assuming you're using axios or fetch to make the API call
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}${ROUTES.downloadLicenceCertificate}/${trans.propertiesMap.FLIS_TransactionId}`,
        {
          certificate_type: type,
        },
        {
          headers: apiHeaders(),
          responseType: "blob", // Set the response type to blob
        }
      )
      .then((response) => {
        // Create a blob from the response data
        const mimeType = response.headers['content-type'];
        const blob = new Blob([response.data], {
          type: mimeType
        });

        setBlob(blob);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
      });
  };

  return (
    <>
      <AlertDialogSlide
        fullWidth={false}
        open={dialog}
        title={"Documents"}
        handleClose={async () => {}}
        handleCloseSecondary={() => {
          showDialog(false);
        }}
        secondaryActionName={"Close"}
        content={
          <div
            style={{
              minWidth: "400px",
            }}
          >
            <div className="d-flex-wrap flex-row mb-4 justify-content-between">
              <div>
                <button
                  type="button"
                  onClick={() => {
                    previewCertificate(currentTrans, "licence");
                    showDialog(false);
                    showPreview(true);
                  }}
                  className="btn btn-primary btn-block w-100"
                >
                  Preview Licence
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    downloadCertificate(currentTrans, "licence");
                  }}
                  className="btn btn-secondary btn-block w-100"
                >
                  Download Licence
                </button>
              </div>
            </div>
            <div className="d-flex-wrap flex-row  justify-content-between">
              <div>
                <button
                  onClick={() => {
                    previewCertificate(currentTrans, "card");
                    showDialog(false);
                    showPreview(true);
                  }}
                  type="button"
                  className="btn btn-primary btn-block w-100"
                >
                  Preview ID Card
                </button>
              </div>
              <div
                onClick={() => {
                  downloadCertificate(currentTrans, "card");
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary btn-block w-100"
                >
                  Download ID Card
                </button>
              </div>
            </div>
          </div>
        }
      />

      <AlertDialogSlide
        fullWidth={false}
        open={preview}
        title={"Preview"}
        handleClose={async () => {}}
        handleCloseSecondary={() => {
          showPreview(false);
        }}
        secondaryActionName={"Close"}
        content={
          <div
            style={{
              minWidth: "800px",
              maxWidth: "100%",
              maxHeight: "80vh",
            }}
          >
            {blob ? (
              <DocumentPreviewWithBlob blob={blob} />
            ) : (
              <div>Loading document...</div>
            )}
          </div>
        }
      />

      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="custom-table-header">
            <tr>
              {searchLicenceUIColumns.map((header) => {
                return <th>{header}</th>;
              })}
              <th>Apply for Renewal</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {licences?.map((trans) => {
              const caseRow = getCaseRow(trans);
              if (!caseRow) return null;
              return (
                <tr>

                  <td>
                  {currentEntity.nfa_registration_number}
                  </td>
                  {caseRow}

                  <td>
                    <button
                      className="d-flex justify-content-center flex-column btn btn-warning"
                      onClick={async (e) => {
                        // downloadCertificate(trans, "licence");
                        setCurrentTrans(trans);
                        showDialog(true);
                      }}
                    >
                      View
                    </button>
                  </td>

                  <td>
                    <button
                      onClick={async () => {
                        const result = await getData({
                          url: `${ROUTES.getTransaction}${trans.propertiesMap.FLIS_TransactionId}`,
                        });
                      }}
                      className="btn btn-sm btn-success"
                    >
                      Apply for Renewal
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllLicences;
