import React from "react";
import { FormHookCheckbox, FormHookInput } from "./FormHook/ReactFormHookFields";
import ReactJson from "react-json-view";


export const SubCategorizedChoices = ({source, formKey, formState,control, register, onSubmit, setValue,values,watch, disabled, showHeading=true}) => {

    const {errors} = formState;

    const operationType= watch(formKey, {deep: true});
    return (
      <div className=" p-3 mb-3 ">
        <div className="d-flex form-heading">
          {showHeading && (
            <div
              style={{ marginRight: "10px" }}
            >{`${"Type of Operation"}`}</div>
          )}
        </div>
        {/* <ReactJson src={operationType} ></ReactJson> */}
        <div className="d-flex-wrap justify-content-center ">
          {source.map(({ key, title, categories }) => (
            <div key={key} className="d-flex-wrap flex-column sub-form-container m-2 flex-grow-1">
              <FormHookCheckbox
                parentClass=""
                options={[title]}
                register={register}
                regFieldName={`${formKey}.${key}.isEnabled`}
                isDisabled={disabled}
                hideLabel={true}
                isRequired={false}
              />

              <div className="d-flex-wrap flex-column ml-3">
                <div>
                  <b>Categories</b>
                </div>
                {categories.map((entry, i) => (
                  <div key={entry.value} className="d-flex-wrap flex-row ">
                    <FormHookCheckbox
                      parentClass=""
                      fieldClassName=""
                      options={[entry.value]}
                      register={register}
                      error={errors?.[formKey]?.[key]?.sub_categories?.message}
                      regFieldName={`${formKey}.${key}.sub_categories`}
                      hideLabel={true}
                      isRequired={
                        operationType?.[key]?.isEnabled
                          ? "This field is required"
                          : false
                      }
                      isDisabled={
                        !operationType?.[key]?.isEnabled || disabled
                          ? "This field is required"
                          : false
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};

