import React from "react";
import { FormHookCheckbox, FormHookInput } from "../../components/FormHook/ReactFormHookFields";
import { investmentAndEmployment } from "./AquaFacilityDataSource";

import {get} from 'lodash';
import ReactJson from 'react-json-view'

// need to revisit for structure of data
export const InvestmentAndEmployment = ({source, formKey, formState,control, register, onSubmit, setValue,values,watch, disabled, showHeading=true}) => {

    const {errors} = formState;

    const watchValue= watch(formKey, {deep: true});
    return (<>
    {/* //   <fieldset disabled={disabled}> */}
        {/* <form onSubmit={onSubmit}> */}
          <div className="p-3">
            <div className="d-flex  form-heading">
              {
                showHeading && <div
                style={{ marginRight: "10px" }}
              >{`${"Investment and Employment"}`}</div>
              }

            </div>
            {/* {
                showHeading && 
                <ReactJson src={watchValue} />
              } */}
            <div className="d-flex-wrap justify-content-center ">
              {source.map(({key, title, subcategorized, categories}) => (
                <div key={key} className="d-flex-wrap flex-column sub-form-container m-2 flex-grow-1">

                  <FormHookCheckbox
                  parentClass=""
                    options={[title]}
                    register={register}
                    regFieldName={`${formKey}.${key}.isEnabled`}
                    hideLabel={true}
                    isRequired={false}
                    // isKeyValue={true}
                    isDisabled={disabled}
                  />

                  {
                    key === 'aquaculture_processing_plant' && 

                    <FormHookInput
                    parentClass="individual-field max-300"
                    label="Size(In Sq. Meters)"
                    register={register}
                    error={errors?.investment_and_employment?.aquaculture_processing_plant?.size_in_sm?.message}
                    type="number"
                    regFieldName={`investment_and_employment.aquaculture_processing_plant.size_in_sm`}
                    isRequired={watchValue?.[key]?.isEnabled ? ("This field is required") : false}
                    isDisabled={(!watchValue?.[key]?.isEnabled || disabled) ? ("This field is required") : false}
                  />

                  }


                  <div className="d-flex-wrap flex-column">

                    { !subcategorized && categories.map((entry, i) => (
                      <div key={entry.value} className="d-flex-wrap flex-row ">
                        
                        <FormHookCheckbox
                        parentClass=""
                        fieldClassName=""
                          options={[entry.value]}
                          register={register}
                          error={get(errors, formKey)?.[key]?.sub_categories?.message}
                          regFieldName={`${formKey}.${key}.sub_categories`}
                          hideLabel={true}
                          isRequired={watchValue?.[key]?.isEnabled ? ("This field is required") : false}
                          isDisabled={(!watchValue?.[key]?.isEnabled || disabled) ? ("This field is required") : false}
                        />

                        {
                            entry.isInput && watchValue?.[key]?.sub_categories && watchValue?.[key]?.sub_categories?.indexOf(entry.value) >= 0 && 
                            <FormHookInput
                            register={register}
                            error={
                              errors?.[formKey]?.[key]?.[`${entry.value}_input`]?.message
                            }
                            hideLabel={true}
                            label={entry.value}
                            regFieldName={`${formKey}.${key}.${entry.value}_input`}
                            type="text"
                            isRequired={"This field is required"}
                            isDisabled={disabled}
                          />
                        }
                      </div>
                    ))}


                    {
                        subcategorized && <InvestmentAndEmployment
                        formState={formState}
                        register={register}
                        control={control}
                        onSubmit={onSubmit}
                        setValue={setValue}
                        values={values}
                        watch={watch}
                        source={categories}
                        formKey={`${formKey}.${key}`}
                        showHeading={false}
                        disabled={!watchValue?.[key]?.isEnabled || disabled}
                      />
                    }

                    
                  </div>


                  {
                    key === 'aquaculture_processing_plant' && 

                    <FormHookInput
                    parentClass="individual-field max-300 ml-3"
                    label="Certified by Standard:"
                    register={register}
                    error={errors?.investment_and_employment?.aquaculture_processing_plant?.certified_by_standard?.message}
                    regFieldName={`investment_and_employment.aquaculture_processing_plant.certified_by_standard`}
                    isRequired={watchValue?.[key]?.isEnabled ? ("This field is required") : false}
                    isDisabled={(!watchValue?.[key]?.isEnabled || disabled) ? ("This field is required") : false}
                  />

                  }
                </div>
              ))}
            </div>



          </div>
        {/* // </form> */}
    {/* //   </fieldset> */}
    </>
    );
};

