
export const desc = {
    'APPLICATION_FOR_DECLARATION': 'Application for Declarations',
    'APPLICATION_QUOTA_FISHING': 'Application for Quota and Fishing Rights',
    'APPLICATION_FOR_AUTHORIZATION': 'Application For Authorization',
    "APPLICATION_FOR_PERMIT": "Application For Permit",
    "APPLICATION_FOR_LICENCE": "Application for Fisher Licence",
    "APPLICATION_FOR_FISHING_VESSEL": "Application For Fishing Vessel Licence",
    "APPLICATION_FOR_AQUACULTURE_FISHER": "Application for Aquaculture Fish Farmer Licence",
    "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL": "Application for Aquaculture Fishing Vessel",
    "APPLICATION_FOR_AQUACULTURE_FACILITY": "Application for Aquaculture Facility",
    "APPLICATION_FOR_LANDING_CATCH_DECLARATION": "Landing Catch Declaration",
    "APPLICATION_FOR_GENERAL_ADMINISTRATION": "Application for Letters & Other Services",
    "APPLICATION_FOR_FISHER_WORKER_ID": "Application for Fisher Worker Id",
    "NOTICE_OF_VIOLATION": "Notice of Violation"
}

export const shortDesc = {
    'APPLICATION_FOR_DECLARATION': 'Declarations',
    'APPLICATION_QUOTA_FISHING': 'Quota and Fishing Rights',
    'APPLICATION_FOR_AUTHORIZATION': 'Authorization',
    "APPLICATION_FOR_PERMIT": "Permit",
    "APPLICATION_FOR_LICENCE": "Fisher Licence",
    "APPLICATION_FOR_FISHING_VESSEL": "Fishing Vessel Licence",
    "APPLICATION_FOR_AQUACULTURE_FISHER": "Aquaculture Fish Farmer Licence",
    "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL": "Aquaculture Fishing Vessel",
    "APPLICATION_FOR_AQUACULTURE_FACILITY": "Aquaculture Facility",
    "APPLICATION_FOR_LANDING_CATCH_DECLARATION": "Landing Catch Declaration",
    "APPLICATION_FOR_GENERAL_ADMINISTRATION": "Letters & Other Services",
    "APPLICATION_FOR_FISHER_WORKER_ID": "Fisher Worker Id",
    "NOTICE_OF_VIOLATION": "Notice of Violation"
}

export const statusShortMap = {
    "Application Received by the NFA": "Received by the NFA"
}

export const applicationConstants = {
    'APPLICATION_FOR_DECLARATION': {
        feeHeader: "Declaration Fee"
    },
    'APPLICATION_QUOTA_FISHING': {
        feeHeader: "Quota & Fishing Rights Fee"
    },
    'APPLICATION_FOR_AUTHORIZATION': {
        feeHeader: "Authorizations Fee"
    },
    "APPLICATION_FOR_PERMIT": {
        feeHeader: "Permit Fee"
    },
    "APPLICATION_FOR_LICENCE": {
        feeHeader: "Licence Fee",
        feesTypeHeader: "Fishing Category"
    },
    "APPLICATION_FOR_FISHING_VESSEL": {
        feeHeader: "Fishing Vessel Fee"
    },
    "APPLICATION_FOR_AQUACULTURE_FISHER": {
        feeHeader: "Aquaculture Fish Farmer Fee"
    },
    "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL": {
        feeHeader: "Aquaculture Fishing Vessel Fee"
    },
    "APPLICATION_FOR_AQUACULTURE_FACILITY": {
        feeHeader: "Aquaculture Facility Fee"
    },
    "APPLICATION_FOR_LANDING_CATCH_DECLARATION": {
        feeHeader: "Landing Catch Declaration Fee"
    },
    "APPLICATION_FOR_GENERAL_ADMINISTRATION": {
        feeHeader: "Letters & Other Services",
        feesTypeHeader: "Letter Category"
    },
    "APPLICATION_FOR_FISHER_WORKER_ID": {
        feeHeader: "Fisher Worker Id Fee"
    }
}

export const APP_SUBMISSION_CONFIG = {

    "APPLICATION_FOR_DECLARATION" : {
        application: {
            type: 'APPLICATION_FOR_DECLARATION',
            applicationDesc: desc['APPLICATION_FOR_DECLARATION']
        },
        totalSteps: 5,
        filePath: '/forms/Merger_Application_Form.docx',
        detailsPage: '/declaration-details',
        isLoginRequired:true,
        steps : [
            {
                name: "Declaration Type",
                next: '/application/declaration/form',
                previous: '/home',
                currentStep : '/application/declaration/declaration-type'
            },
            {
                name: "Complaint Form",
                next: '/application/declaration/supporting-documents',
                previous: '/application/declaration/licence-type',
                currentStep: '/application/declaration/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/declaration/preview',
                previous: '/application/declaration/form',
                currentStep: '/application/declaration/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/declaration/transaction-details',
                previous: '/application/declaration/supporting-documents',
                currentStep: '/application/declaration/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/declaration/transaction-details'
            }
        ]
    },

    "APPLICATION_QUOTA_FISHING" : {
        application: {
            type: 'APPLICATION_QUOTA_FISHING',
            applicationDesc: desc['APPLICATION_QUOTA_FISHING']
        },
        totalSteps: 4,
        filePath: '/forms/Request_for_Information.docx',
        isLoginRequired:true,
        detailsPage: '/quota-fishing-details',
        steps : [
            {
                name: "Quota Fishing Type",
                next: '/application/quota-fishing/form',
                previous: '/home',
                currentStep : '/application/quota-fishing/licence-type'
            },
            {
                name: "Complaint Form",
                next: '/application/quota-fishing/supporting-documents',
                previous: '/application/quota-fishing/licence-type',
                currentStep: '/application/quota-fishing/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/quota-fishing/preview',
                previous: '/application/quota-fishing/form',
                currentStep: '/application/quota-fishing/supporting-documents'
            },
            {
                name: "Preview",
                next: '/application/quota-fishing/transaction-details',
                previous: '/application/quota-fishing/supporting-documents',
                currentStep: '/application/quota-fishing/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/quota-fishing/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_AUTHORIZATION" : {
        application: {
            type: 'APPLICATION_FOR_AUTHORIZATION',
            applicationDesc: desc['APPLICATION_FOR_AUTHORIZATION']
        },
        totalSteps: 5,
        isLoginRequired:true,
        detailsPage: '/authorization-details',
        steps : [
            {
                name: "Authorization Type",
                next: '/application/authorization/form',
                previous: '/home',
                currentStep : '/application/authorization/licence-type'
            },
            {
                name: "Complaint Form",
                next: '/application/authorization/supporting-documents',
                previous: '/application/authorization/licence-type',
                currentStep: '/application/authorization/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/authorization/payment',
                previous: '/application/authorization/form',
                currentStep: '/application/authorization/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/authorization/preview',
                previous: '/application/authorization/supporting-documents',
                currentStep : '/application/authorization/payment'
            },
            {
                name: "Preview",
                next: '/application/authorization/transaction-details',
                previous: '/application/authorization/payment',
                currentStep: '/application/authorization/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/authorization/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_PERMIT" : {
        application: {
            type: 'APPLICATION_FOR_PERMIT',
            applicationDesc: desc['APPLICATION_FOR_PERMIT']
        },
        filePath: '/forms/Application _for_Authorization_Under_Section_29_of_the_FCA.docx',
        totalSteps: 5,
        isLoginRequired:true,
        detailsPage: '/permit-details',
        steps : [
            {
                name: "Permit Type",
                next: '/application/permit/form',
                previous: '/home',
                currentStep : '/application/permit/licence-type'
            },
            {
                name: "Complaint Form",
                next: '/application/permit/supporting-documents',
                previous: '/application/permit/licence-type',
                currentStep: '/application/permit/form'
            },
            {
                name: "Supporting Documents",
                next: '/application/permit/payment',
                previous: '/application/permit/form',
                currentStep: '/application/permit/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/permit/preview',
                previous: '/application/permit/supporting-documents',
                currentStep : '/application/permit/payment'
            },

            {
                name: "Preview",
                next: '/application/permit/transaction-details',
                previous: '/application/permit/payment',
                currentStep: '/application/permit/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep: '/application/permit/permit/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_LICENCE" : {
        application : {
            type: 'APPLICATION_FOR_LICENCE',
            applicationDesc: desc['APPLICATION_FOR_LICENCE']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/application-details',
        steps : [
            {
                name: "Licence Type",
                next: '/application/licence/form-render',
                previous: '/home',
                currentStep : '/application/licence/licence-type'
            },
            {
                name: "Application Form",
                next: '/application/licence/supporting-documents',
                previous: '/application/licence/licence-type',
                currentStep : '/application/licence/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/licence/payment',
                previous: '/application/licence/form-render',
                currentStep : '/application/licence/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/licence/preview',
                previous: '/application/licence/supporting-documents',
                currentStep : '/application/licence/payment'
            },

            {
                name: "Preview",
                next: '/application/licence/transaction-details',
                previous: '/application/licence/payment',
                currentStep : '/application/licence/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/licence/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_FISHING_VESSEL" : {
        application : {
            type: 'APPLICATION_FOR_FISHING_VESSEL',
            applicationDesc: desc['APPLICATION_FOR_FISHING_VESSEL']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/fishing-vessel-details',
        steps : [
            {
                name: "Licence Type",
                next: '/application/fishing-vessel/form-render',
                previous: '/home',
                currentStep : '/application/fishing-vessel/licence-type'
            },
            {
                name: "Application Form",
                next: '/application/fishing-vessel/supporting-documents',
                previous: '/application/fishing-vessel/licence-type',
                currentStep : '/application/fishing-vessel/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/fishing-vessel/payment',
                previous: '/application/fishing-vessel/form-render',
                currentStep : '/application/fishing-vessel/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/fishing-vessel/preview',
                previous: '/application/fishing-vessel/supporting-documents',
                currentStep : '/application/fishing-vessel/payment'
            },

            {
                name: "Preview",
                next: '/application/fishing-vessel/transaction-details',
                previous: '/application/fishing-vessel/payment',
                currentStep : '/application/fishing-vessel/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/fishing-vessel/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_AQUACULTURE_FISHER" : {
        application : {
            type: 'APPLICATION_FOR_AQUACULTURE_FISHER',
            applicationDesc: desc['APPLICATION_FOR_AQUACULTURE_FISHER']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/aquaculture-fisher-details',
        steps : [
            {
                name: "Licence Type",
                next: '/application/aquaculture-fisher/form-render',
                previous: '/home',
                currentStep : '/application/aquaculture-fisher/licence-type'
            },
            {
                name: "Application Form",
                next: '/application/aquaculture-fisher/supporting-documents',
                previous: '/application/aquaculture-fisher/licence-type',
                currentStep : '/application/aquaculture-fisher/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/aquaculture-fisher/payment',
                previous: '/application/aquaculture-fisher/form-render',
                currentStep : '/application/aquaculture-fisher/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/aquaculture-fisher/preview',
                previous: '/application/aquaculture-fisher/supporting-documents',
                currentStep : '/application/aquaculture-fisher/payment'
            },

            {
                name: "Preview",
                next: '/application/aquaculture-fisher/transaction-details',
                previous: '/application/aquaculture-fisher/payment',
                currentStep : '/application/aquaculture-fisher/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/aquaculture-fisher/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL" : {
        application : {
            type: 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL',
            applicationDesc: desc['APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/aquaculture-fishing-vessel-details',
        steps : [
            {
                name: "Licence Type",
                next: '/application/aquaculture-fishing-vessel/form-render',
                previous: '/home',
                currentStep : '/application/aquaculture-fishing-vessel/licence-type'
            },
            {
                name: "Application Form",
                next: '/application/aquaculture-fishing-vessel/supporting-documents',
                previous: '/application/aquaculture-fishing-vessel/licence-type',
                currentStep : '/application/aquaculture-fishing-vessel/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/aquaculture-fishing-vessel/payment',
                previous: '/application/aquaculture-fishing-vessel/form-render',
                currentStep : '/application/aquaculture-fishing-vessel/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/aquaculture-fishing-vessel/preview',
                previous: '/application/aquaculture-fishing-vessel/supporting-documents',
                currentStep : '/application/aquaculture-fishing-vessel/payment'
            },

            {
                name: "Preview",
                next: '/application/aquaculture-fishing-vessel/transaction-details',
                previous: '/application/aquaculture-fishing-vessel/payment',
                currentStep : '/application/aquaculture-fishing-vessel/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/aquaculture-fishing-vessel/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_AQUACULTURE_FACILITY" : {
        application : {
            type: 'APPLICATION_FOR_AQUACULTURE_FACILITY',
            applicationDesc: desc['APPLICATION_FOR_AQUACULTURE_FACILITY']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/aquaculture-facility-details',
        steps : [
            {
                name: "Licence Type",
                next: '/application/aquaculture-facility/form-render',
                previous: '/home',
                currentStep : '/application/aquaculture-facility/licence-type'
            },
            {
                name: "Application Form",
                next: '/application/aquaculture-facility/supporting-documents',
                previous: '/application/aquaculture-facility/licence-type',
                currentStep : '/application/aquaculture-facility/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/aquaculture-facility/preview',
                previous: '/application/aquaculture-facility/form-render',
                currentStep : '/application/aquaculture-facility/supporting-documents'
            },
            // {
            //     name: "Payment",
            //     next: '/application/aquaculture-facility/preview',
            //     previous: '/application/aquaculture-facility/supporting-documents',
            //     currentStep : '/application/aquaculture-facility/payment'
            // },

            {
                name: "Preview",
                next: '/application/aquaculture-facility/transaction-details',
                previous: '/application/aquaculture-facility/supporting-documents',
                currentStep : '/application/aquaculture-facility/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/aquaculture-facility/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_LANDING_CATCH_DECLARATION" : {
        application : {
            type: 'APPLICATION_FOR_LANDING_CATCH_DECLARATION',
            applicationDesc: desc['APPLICATION_FOR_LANDING_CATCH_DECLARATION']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/landing-logs-details',
        steps : [
            {
                name: "Landing Catch Type",
                next: '/application/landing-logs/form-render',
                previous: '/home',
                currentStep : '/application/landing-logs/licence-type'
            },
            {
                name: "Landing Catch Form",
                next: '/application/landing-logs/supporting-documents',
                previous: '/application/landing-logs/licence-type',
                currentStep : '/application/landing-logs/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/landing-logs/payment',
                previous: '/application/landing-logs/form-render',
                currentStep : '/application/landing-logs/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/landing-logs/preview',
                previous: '/application/landing-logs/supporting-documents',
                currentStep : '/application/landing-logs/payment'
            },

            {
                name: "Preview",
                next: '/application/landing-logs/transaction-details',
                previous: '/application/landing-logs/payment',
                currentStep : '/application/landing-logs/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/landing-logs/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_GENERAL_ADMINISTRATION" : {
        application : {
            type: 'APPLICATION_FOR_GENERAL_ADMINISTRATION',
            applicationDesc: desc['APPLICATION_FOR_GENERAL_ADMINISTRATION']
        },
        isLoginRequired: true,
        totalSteps: 4,
        detailsPage: '/general-administration-details',
        steps : [
            {
                name: "Type",
                next: '/application/general-administration/form-render',
                previous: '/home',
                currentStep : '/application/general-administration/type'
            },
            {
                name: "Form",
                next: '/application/general-administration/payment',
                previous: '/application/general-administration/type',
                currentStep : '/application/general-administration/form-render'
            },
            // {
            //     name: "Supporting Documents",
            //     next: '/application/general-administration/payment',
            //     previous: '/application/general-administration/form-render',
            //     currentStep : '/application/general-administration/supporting-documents'
            // },
            {
                name: "Payment",
                next: '/application/general-administration/preview',
                previous: '/application/general-administration/form-render',
                currentStep : '/application/general-administration/payment'
            },

            {
                name: "Preview",
                next: '/application/general-administration/transaction-details',
                previous: '/application/general-administration/payment',
                currentStep : '/application/general-administration/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/general-administration/transaction-details'
            }
        ]
    },

    "APPLICATION_FOR_FISHER_WORKER_ID" : {
        application : {
            type: 'APPLICATION_FOR_FISHER_WORKER_ID',
            applicationDesc: desc['APPLICATION_FOR_FISHER_WORKER_ID']
        },
        isLoginRequired: true,
        totalSteps: 5,
        detailsPage: '/fisher-worker-details',
        steps : [
            {
                name: "Type",
                next: '/application/fisher-worker-id/form-render',
                previous: '/home',
                currentStep : '/application/fisher-worker-id/type'
            },
            {
                name: "Form",
                next: '/application/fisher-worker-id/supporting-documents',
                previous: '/application/fisher-worker-id/type',
                currentStep : '/application/fisher-worker-id/form-render'
            },
            {
                name: "Supporting Documents",
                next: '/application/fisher-worker-id/payment',
                previous: '/application/fisher-worker-id/form-render',
                currentStep : '/application/fisher-worker-id/supporting-documents'
            },
            {
                name: "Payment",
                next: '/application/fisher-worker-id/preview',
                previous: '/application/fisher-worker-id/supporting-documents',
                currentStep : '/application/fisher-worker-id/payment'
            },

            {
                name: "Preview",
                next: '/application/fisher-worker-id/transaction-details',
                previous: '/application/fisher-worker-id/payment',
                currentStep : '/application/fisher-worker-id/preview'
            },
            {
                name: "Transaction Details",
                next: '/transactions', // to be converted to other transaction details
                previous: '',
                currentStep : '/application/fisher-worker-id/transaction-details'
            }
        ]
    }
}
