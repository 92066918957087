import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { FLISContext } from '../context/FLISContext';
function FishingVesselApplicationDetails(props) {

    const history = useHistory();

    const {clearSelectedMap} = useContext(FLISContext);

    return (
      <div>
        <div className="custom-banner-bg">
          <div style={{ fontSize: "40px", fontWeight: "bold" }}>
            Application for Fishing Vessel Licence
          </div>

          <div className="d-flex flex-spacing-10">
            <button
              type="button"
              className="btn btn-custom-warning large-button"
              onClick={async () => {
                const details = props.app ?? {
                  appType: "APPLICATION_FOR_FISHING_VESSEL",
                };

                const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                let transNumber = "";
                if (appDetails.isLoginRequired) {
                  if (!localStorage.getItem("token")) {
                    history.push("/login");
                    return;
                  }

                  transNumber = await getUniqueTransNumber(details.appType);
                } else {
                  transNumber = await getUniqueTransNumber(details.appType);
                }

                store.dispatch({
                  type: SET_CURRENT_TRANSACTION,
                  temp_trans_id: transNumber,
                  appType: details.appType,
                  transaction: {},
                  submitted_transaction: null,
                });
                clearSelectedMap();

                if (!!details)
                  history.push(`${appDetails.steps[0].currentStep}`);
              }}
            >
              Apply now for a Fishing Vessel Licence
            </button>
          </div>
        </div>

        <div>
          <div
            className="d-flex-wrap justify-content-between flex-spacing-10"
            style={{
              padding: "40px 14%",
              color: "black",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            <div>
              <h2 style={{ fontWeight: 800 }}>
                Apply for Fishing Vessel Licence{" "}
              </h2>
              <div>
                Any vessel that is used for fishing in Jamaican waters must have
                a fishing vessel licence. The type of vessel licence is based on
                the make and size of your fishing boat, the type and amount of
                fish you intend to catch, and where you plan to fish.
              </div>

              <br />

              <div>
                To apply for a vessel licence, the following are needed:
              </div>
              <br />

              <ul>
                <li>
                  Your personal fishing licence, as vessel owner (“apply here”
                  for your licence if you are without)
                </li>
                <li>Valid Identification for the owner</li>
                <li>Proof of ownership for the vessel and engine(s)</li>
                <ul>
                  <li>MAJ certificate and Small Vessel Safety Certificate,</li>
                  <li>Transfer of Ownership Form/letter, </li>
                  <li>Bill of Sale, </li>
                  <li>Receipt for purchase of vessel and/or engine, </li>
                  <li>Receipt for materials used to build vessel, or </li>
                  <li>
                    Letter from a Justice of the Peace verifying that you
                    constructed and/or own the vessel)
                  </li>
                </ul>
              </ul>
            </div>

            <div className="d-flex flex-spacing-10">
              <button
                type="button"
                className="btn btn-custom-warning large-button"
                onClick={async () => {
                  const details = props.app ?? {
                    appType: "APPLICATION_FOR_FISHING_VESSEL",
                  };

                  const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                  let transNumber = "";
                  if (appDetails.isLoginRequired) {
                    if (!localStorage.getItem("token")) {
                      history.push("/login");
                      return;
                    }

                    transNumber = await getUniqueTransNumber(details.appType);
                  } else {
                    transNumber = await getUniqueTransNumber(details.appType);
                  }

                  store.dispatch({
                    type: SET_CURRENT_TRANSACTION,
                    temp_trans_id: transNumber,
                    appType: details.appType,
                    transaction: {},
                    submitted_transaction: null,
                  });
                  clearSelectedMap();

                  if (!!details)
                    history.push(`${appDetails.steps[0].currentStep}`);
                }}
              >
                Apply now for a Fishing Vessel Licence
              </button>
            </div>

            <br />
          </div>
        </div>
      </div>
    );
}

export default FishingVesselApplicationDetails;