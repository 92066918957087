import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";

import {
  Declarations,
  emailPattern
} from "../../components/DataSource";

import ImageUpload from "../../components/ImageUpload";
import { FormHookCheckbox, FormHookInput, FormHookRadioInput, FormHookSelect, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { countries } from "../AuthorizationLicence/AuthorizationDataSource";
import { emailNotValid } from "../../utils/messages";
import { FLISContext } from "../../context/FLISContext";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { SignaturePad } from "../../components/SignatureCanvas";
import { investmentAndEmployment, marketingStrategyType, ownershipOfFacility, productionUnits, sizeOfAquacultureFacility, typeOfProductionSystems, typeOfTransactions } from "./AquaFacilityDataSource";
import { TypeofOperation } from "./TypeofOperation";
import { ProductionUnitInformation } from "./ProductionUnitInformation";
import { TargetFish } from "../AquacultureFishingVessel/TargetFish";
import { typeOfFish } from "../AquacultureFishingVessel/AquaFishingVesselDataSource";
import { InvestmentAndEmployment } from "./InvestmentAndEmployment";
import { HistoricalPlannedProduction } from "./HistoricalPlannedProduction";
import { MarketingStrategy } from "./MarketingStrategy";
import { RegulatoryApproval } from "./RegulatoryApproval";
import GeneralDeclarations from "../GeneralDeclaration";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import { ROUTES } from "../../config";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForPermit = (props) => {

  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const history = useHistory();
  const buttonSubmitRef = useRef();

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
    reset
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const {errors} = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });

  const onSubmit = async (data) => {

    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();

    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn(fillInAllTheFields, {
        autoClose: true
      });
      return; 
    }


    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();

  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };

  

  const [selectedForm , setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <FormHookCheckbox
                      label="Transaction Type"
                      options={typeOfTransactions ?? []}
                      register={register}
                      error={errors?.type_of_transaction?.message}
                      regFieldName={`type_of_transaction`}
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.name_of_applicant?.message}
                      label="Name of Applicant"
                      regFieldName="name_of_applicant"
                      placeholder="Name of Applicant"
                      type="text"
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.address_of_aquaculture_facility?.message}
                      label="Address of Aquaculture Facility:"
                      regFieldName="address_of_aquaculture_facility"
                      placeholder="Address of Aquaculture Facility:"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact Name"
                      regFieldName="contact_name"
                      placeholder="Contact Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_phone?.message}
                      label="Contact Phone"
                      regFieldName="contact_phone"
                      placeholder="Contact Phone"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_email?.message}
                      label="Contact Email"
                      regFieldName="contact_email"
                      placeholder="Contact Email"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      isRequired={false}
                    />

                    <FormHookCheckbox
                      label="Size of Aquaculture Facility:"
                      options={sizeOfAquacultureFacility ?? []}
                      register={register}
                      error={errors?.size_of_aquaculture_facility?.message}
                      regFieldName={`size_of_aquaculture_facility`}
                    />

                    <FormHookCheckbox
                      label="Ownership of Facility:"
                      options={ownershipOfFacility}
                      register={register}
                      error={errors?.ownership_of_facility?.message}
                      regFieldName={`ownership_of_facility`}
                      isMulti={false}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Type of Operation</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <TypeofOperation
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      showHeading={false}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Production Unit</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <ProductionUnitInformation
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      showHeading={false}
                      disabled={true}
                      source={productionUnits}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Type of Production System
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookCheckbox
                      label=""
                      options={typeOfProductionSystems ?? []}
                      register={register}
                      error={errors?.type_of_production_system?.message}
                      regFieldName={`type_of_production_system`}
                      isDisabled={true}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Type of Fish</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <TargetFish
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      source={typeOfFish}
                      showHeading={false}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Investment & Employment</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <InvestmentAndEmployment
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      source={investmentAndEmployment}
                      showHeading={false}
                      disabled={true}
                      formKey={"investment_and_employment"}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Historical & Planned Production
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <HistoricalPlannedProduction
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      showHeading={false}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Marketing Strategy</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <MarketingStrategy
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      showHeading={false}
                      disabled={true}
                      source={marketingStrategyType}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Regulatory Approval</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <RegulatoryApproval
                      formState={formState}
                      register={register}
                      control={control}
                      onSubmit={onSubmit}
                      setValue={setValue}
                      reset={reset}
                      values={getValues}
                      watch={watch}
                      showHeading={false}
                      disabled={true}
                    />
                  </div>
                </div>

                <SimpleDeclaration register={register} errors={errors} />

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>

              // <>
              //   <div className="shadow p-3 mb-3 bg-white rounded">
              //     <div className="sub-form-header">
              //       Declaration of Knowledge Form
              //     </div>
              //     <HorizontalLine></HorizontalLine>

              //     <div style={{ width: "100%", marginTop: "10px" }}>
              //       <div className="form-inline">
              //         <div
              //           className="d-flex-wrap bottom-spacing-10 flex-row "
              //           style={{ width: "95%" }}
              //         >
              //           <b style={{ margin: "0 5px" }}>I </b>
              //           <div>
              //             <input
              //               style={{ width: "300px" }}
              //               type="text"
              //               className="form-control form-control-sm flex-grow-2"
              //               {...register("declaration_details.name", {
              //                 required: false,
              //               })}
              //               placeholder="name"
              //             />
              //             {errors.declaration_details?.name && (
              //               <div className="form-error-container">
              //                 This field is required
              //               </div>
              //             )}{" "}
              //           </div>
              //           <b style={{ margin: "0 5px" }}> of address</b>
              //           <div style={{ flexGrow: 4 }}>
              //             <input
              //               style={{ width: "100%" }}
              //               type="text"
              //               className="form-control form-control-sm"
              //               {...register("declaration_details.address", {
              //                 required: false,
              //               })}
              //               placeholder="address"
              //             />
              //             {errors.declaration_details?.address && (
              //               <div className="form-error-container">
              //                 This field is required
              //               </div>
              //             )}
              //           </div>
              //         </div>
              //       </div>

              //       <div>
              //         <b style={{ margin: "0 5px" }}>
              //           {" "}
              //           as an applicant for a fishing licence in accordance with
              //           Section 25 of the Fisheries Act, 2018, declare that I am
              //           aware of the following:
              //         </b>
              //       </div>

              //       <div style={{ padding: "0 2%" }}>
              //         {Declarations[
              //           "Fisher Licence"
              //         ]?.map((declaration) => {
              //           return (
              //             <>
              //               <label className="checkbox-container">
              //                 {declaration}
              //                 <input
              //                   type="checkbox"
              //                   value={""}
              //                   name="declaration"
              //                 />
              //                 <span className="checkbox-checkmark"></span>
              //               </label>
              //             </>
              //           );
              //         })}
              //       </div>

              //     </div>
              //   </div>

              //   <div className="shadow p-3 mb-3 bg-white rounded">
              //     <div className="sub-form-header">Signature</div>
              //     <HorizontalLine></HorizontalLine>

              //     <div style={{ width: "100%", marginTop: "10px" }}>
              //       <div>
              //         <b>
              //           I / We declare that the information given is true and
              //           correct to the best of my / our knowledge and belief.
              //         </b>
              //       </div>
              //       <ImageUpload></ImageUpload>
              //     </div>
              //   </div>
              // </>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForPermit);
