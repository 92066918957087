// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { validateKeys } from "../../utils/utils";

import {
  formInvalidMessage,
  inputPreviousNumber,
  selectionNotFound,
} from "../../utils/messages";
import { alertService } from "../../_services";

import { useForm } from "react-hook-form";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";
import { landingLogsFor, typeOfApplicant } from "./LandingLogsDataSource";

export default function LandingLogTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 1);


  const onSubmit = (data) => {
    console.log(data);
  };


  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 1 && (
        <>
          <BoxChoiceContainer
            listingHeading={"Select Landing Catch Declaration for"}
            listingOptions={landingLogsFor}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"landing_logs_for"}
            onClick={() => {
              setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                history.goBack();
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["landing_logs_for"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </>
      )}

      {selectionCategory === 2 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Applicant"}
            listingOptions={typeOfApplicant}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_applicant"}
            onClick={(value, i) => {

              setSelectedMap((map) => {
                return {
                  ...map,
                  selectionCategory
                }
              })

              props?.next();
            }}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_applicant"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory
                  }
                })
                props?.next();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
