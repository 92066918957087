import { combineReducers } from 'redux';


import authReducer from './auth.reducer';
import currentTransReducer from './current-transaction.reducer';
import guidelinesReducer from './guidelines.reducer';
import documentsReducer from './documents.reducer';
import transactionsReducer from './transactions.reducer';


const rootReducer = combineReducers({
    auth : authReducer,
    currentTransaction : currentTransReducer,
    guidelines : guidelinesReducer,
    documents : documentsReducer,
    transactions: transactionsReducer
});

export default rootReducer;