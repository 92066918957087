import {cloneDeep} from 'lodash';
import { concatValidData, dateTimeOptions } from '../utils/utils';


export const parishList = [
  "Clarendon",
  "Hanover",
  "Kingston",
  "Manchester",
  "Portland",
  "St.Andrew",
  "St.Ann",
  "St.Catherine",
  "St.Elizabeth",
  "St.James",
  "St.Mary",
  "St.Thomas",
  "Trelawny",
  "Westmoreland",
];

export const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const telephonePattern = /^\d{3}-\d{3}-\d{4}$/;

export const trnPattern = /^\d{3}-\d{3}-\d{3}$/;

export const getMaxDateForAtleastYear = (atleast = 0) => {
   // Calculate the date which is atleast years ago
   const today = new Date();
   const maxDate = new Date();
   maxDate.setFullYear(today.getFullYear() - atleast);

   // Format the date in YYYY-MM-DD format for the input
  const formattedMaxDate = maxDate.toISOString().split('T')[0];
  return formattedMaxDate;

}

export const getMinDateForAtleastYear = (atleast = 0) => {
  // Calculate the date which is atleast years ago
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - atleast);

  // Format the date in YYYY-MM-DD format for the input
 const formattedMinDate = minDate.toISOString().split('T')[0];

 return formattedMinDate;

}


export const getMaxDateTimeForAtleastYear = (atleast = 0) => {
  // Calculate the date which is atleast years ago
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() - atleast);

  const formattedMaxDateTime = maxDate.toISOString().split('T');
  return `${formattedMaxDateTime[0]}T${formattedMaxDateTime[1].substring(0,5)}`;

}

export const getMinDateTimeForAtleastYear = (atleast = 0) => {
 // Calculate the date which is atleast years ago
 const today = new Date();
 const minDate = new Date();
 minDate.setFullYear(today.getFullYear() - atleast);

const formattedMinDateTime = minDate.toISOString().split('T');
return `${formattedMinDateTime[0]}T${formattedMinDateTime[1].substring(0,5)}`;

}



export const mountedStyle = { animation: "inAnimation 250ms ease-in" };
export const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards"
};

export const getAnimationStyle = (condition) => {
  return condition ? mountedStyle : unmountedStyle;
}

export const FISHING_VESSEL_LICENCE = "Fishing Vessel Licence";
export const FISHER_LICENCE = "Fisher Licence";
export const FISHER_WORKER_ID = "Fisher Worker ID";
export const AQUACULTURE_CULTURE = "Aquaculture Licence";

export const ACCESS_AGREEMENTS_FOREIGN_FISHING = "Foreign"
export const RECREATIONAL = "Recreational";
export const COMMERCIAL = "Commercial";
export const ARTISANAL_FISHING = "Artisanal Fishing";
export const INDUSTRIAL_FISHING = "Industrial Fishing";
export const RECREATIONAL_FISHING = "Recreational Fishing";
export const COMMERCIAL_FISHING = "Commercial Fishing";
export const CARRIER_FISHING = "Carrier Fishing";

export const ARTISANAL = "Artisanal";
export const INDUSTRIAL = "Industrial";


export const SPORTS_CHARTER = "Sports Charter";
export const SUBSISTENCE = "Subsistence";
export const TOURNAMENT = "Tournament";
export const ENTERTAINMENT = "Entertainment";



export const transactionType = [
  FISHER_LICENCE,
  FISHING_VESSEL_LICENCE,
  AQUACULTURE_CULTURE,
  // 'Renewal',
  // 'Substitute (Replacement)',
  // 'Registration Only'
];

export const licenceTypeMapping = {
  [FISHER_LICENCE]: [
    // ARTISANAL_FISHING,
    // INDUSTRIAL_FISHING,
    // RECREATIONAL_FISHING,
    COMMERCIAL_FISHING,
    RECREATIONAL_FISHING,
    
  ]
};

export const fisherCommercialLicenceCategory = [ARTISANAL, INDUSTRIAL];

export const fisherVesselLicenceCategory = {
    [FISHING_VESSEL_LICENCE]: [
    COMMERCIAL,
    RECREATIONAL,
    ACCESS_AGREEMENTS_FOREIGN_FISHING,
    // "Aquaculture",
  ],
};

export const fisherVesselLicenceTypeSubcateory = {
  [COMMERCIAL]: [ARTISANAL_FISHING, INDUSTRIAL_FISHING, CARRIER_FISHING],
  [RECREATIONAL]: [
    SPORTS_CHARTER,
    SUBSISTENCE,
    TOURNAMENT,
    ENTERTAINMENT,
  ],
};

export const fishingVesselLicenceTypeDecription = {
  [COMMERCIAL]: "",
  [RECREATIONAL]: "You can apply for a recreational fishing vessel licence if your purpose for fishing is not commercial (to earn a profit). Examples include fishing for fun and fishing in a tournament. ",
  [ACCESS_AGREEMENTS_FOREIGN_FISHING]: "Select this category if your vessel is licensed to fish in a country outside of Jamaica for commercial purposes. This will require an access agreement or arrangement between Jamaica and your state, inter-governmental organization or the association that is representing you, the vessel owner. Please note that this does not apply to recreational fishing."
}

export const fishingVesselLicenceTypeSubCategoryDescription = {
  [ARTISANAL_FISHING]: "Select this category if your vessel is an open type canoe (non-decked or partially decked) that uses no engine or at least one detachable engine.",
  [INDUSTRIAL_FISHING]: "Select this category if your vessel is a fully decked boat with an inboard engine and the capacity to store a large quantity of fish over an extended time at sea.",
  [CARRIER_FISHING]: "Select this category if you will be transporting fish and fish products on behalf of an industrial commercial fisher. You are not allowed to fish with this category of licence."
}

export const typeOfApplicantMapping = {
  "Artisanal Fishing": ["Individual", "Company"],
  "Industrial Fishing": ["Individual", "Company"],
  "Recreational Fishing": ["Individual", "Company"],
  "Commercial Fishing": ["Individual", "Company"],
};

export const NEW_LICENCE = "New Licence";
export const AMENDMENT = "Amendment";
export const RENEWAL = "Renewal";
export const TRANSFER_OWNERSHIP = "Transfer Ownership";

export const typeOfTransactions = {
  [FISHER_LICENCE]: [
    NEW_LICENCE,
    AMENDMENT,
    RENEWAL,
    // "Substitute (Replacement)",
  ],
  [FISHING_VESSEL_LICENCE]: [
    NEW_LICENCE,
    RENEWAL,
    TRANSFER_OWNERSHIP,
    // "Replacement Licence",
  ],
  [FISHER_WORKER_ID]: [
    NEW_LICENCE,
    RENEWAL,
  ]
};

export const fisherWorkerIDTransactionsMapping = {
  [NEW_LICENCE]: `New  Fish  Worker 
  ID Card`,
  [RENEWAL]: `Renewal Fish Worker 
  ID Card`
}

export const yesNo = ["Yes", "No"];

export const individualYesDocList = [
  "Passport",
  "Drivers Licence",
  "Voter ID",
  "Fisher ID",
  "NIDS",
];

export const individualNoDocList = ["Birth Certificate", "Picture"];

export const LOCAL = "Local";
export const FOREIGN = "Foreign";
export const nationality = [LOCAL, FOREIGN];



let counter = 0;
export const fishingArea = [
  { "id": 1, "location": "North Shelf", "lat": 18.48, "lng": -77.65 },
  { "id": 2, "location": "South Shelf", "lat": 17.86986, "lng": -77.56612 },
  { "id": 3, "location": "New Bank", "lat": 18.43647, "lng": -77.237 },
  { "id": 4, "location": "Blossom Bank", "lat": 0, "lng": 0 }, // not found
  { "id": 5, "location": "Walton Bank", "lat": 0, "lng": 0 }, // not found
  { "id": 6, "location": "Mackerel Bank", "lat": 0, "lng": 0 }, // not found
  { "id": 7, "location": "California Bank", "lat": 17.75, "lng": -76.78333 },
  { "id": 8, "location": "Dingle Bank", "lat": 0, "lng": 0 }, // not found
  { "id": 9, "location": "Norseman Bank", "lat": 17.833333, "lng": -76.15 },
  { "id": 10, "location": "Portland Bight", "lat": 17.833333, "lng": -77.13333 },
  { "id": 11, "location": "Albatross Bank", "lat": 17.66667, "lng": -75.66667 },
  { "id": 12, "location": "Henry Holmes", "lat": 18.11667, "lng": -76.08333 },
  { "id": 13, "location": "Grappler Bank", "lat": 18.38333, "lng": -75.98333 },
  { "id": 18, "location": "Bowditch Bank", "lat": 0 , "lng": 0}, // not found
  { "id": 14, "location": "Formigas Bank", "lat": 0, "lng": 0 }, // not found - but just above to the right of Grappler Bank
  { "id": 15, "location": "Pedro Bank", "lat": 17.08333, "lng": -78.33333 },
  { "id": 16, "location": "Morant Bank", "lat": 0, "lng": 0 }, // not found but just on bottom and left of Albatross Bank
  // { "id": 17, "location": "Economic Zone EEZ", "lat": 0, "lng": 0 } // not found
  // { "id": 17, "location": "Special Economic Zone", "lat": 0, "lng": 0 } 
  { "id": 17, "location": "Exclusive Economic Zone", "lat": 0, "lng": 0 } 
];

export const fishingAreaLocations = fishingArea?.map(area => area.location);

export const fishingLocationList = fishingArea.map((area) => area.location);



export const fishingAreaFishingVessel = [
  { "id": 1, "location": "North Shelf", "lat": 17.8000, "lng": -77.2000 },
  { "id": 2, "location": "South Shelf", "lat": 17.9000, "lng": -77.3000 },
  { "id": 3, "location": "New Bank", "lat": 0, "lng": 0 },
  { "id": 4, "location": "Blossom Bank", "lat": 0, "lng": 0 },
  { "id": 5, "location": "Walton Bank", "lat": 0, "lng": 0 },
  { "id": 6, "location": "Mackerel Bank", "lat": 0, "lng": 0 },
  { "id": 7, "location": "California Bank", "lat": 0, "lng": 0 },
  { "id": 8, "location": "Dingle Bank", "lat": 0, "lng": 0 },
  { "id": 9, "location": "Norseman Bank", "lat": 0, "lng": 0 },
  { "id": 10, "location": "Portland Bank", "lat": 18.2000, "lng": -76.3000 },
  { "id": 11, "location": "Albatross Bank", "lat": 0, "lng": 0 },
  { "id": 12, "location": "Henry Holmes", "lat": 0, "lng": 0 },
  { "id": 13, "location": "Grappler Bank", "lat": 0, "lng": 0 },
  { "id": 18, "location": "Bowditch Bank", "lat": 0 , "lng": 0},
  { "id": 14, "location": "Formigas Bank", "lat": 0, "lng": 0 },
  { "id": 15, "location": "Pedro Bank", "lat": 0, "lng": 0 },
  { "id": 16, "location": "Morant Bank", "lat": 0, "lng": 0 },
  { "id": 17, "location": "Exclusive Economic Zone", "lat": 0, "lng": 0 }
];

export const fishingAreaLocationsFishingVessel = fishingAreaFishingVessel?.map(area => area.location);

const licenceNumber = "licenceNumber";

export const IRISH_MOSS_SEA_WEEDS = "Irish Moss / Sea Weeds";

export const typeOfSpecies = [
  "Bait",
  "Basa (Pangasius)",
  "Coastal Pelagics",
  // "Conch (Other conch)",
  // "Conch (Queen conch)",
  "Crabs",
  "Deep Slope / Snapper",
  "Glass eels",
  IRISH_MOSS_SEA_WEEDS,
  "Inland FinFish",
  "Octopus / Squid",
  "Offshore Pelagics",
  "Ornamentals",
  "Oyster",
  "Reef Fish",
  "Shrimp Freshwater",
  "Shrimp Marine",
  "Tilapia",
  "Other Aquatic Plants",
  "Marlin"
  // "Reef Fish", //mapped
  // "Coastal Pelagics", //mapped
  // "Shrimp", // mapped to FS
  // "Bait", // mapped
  // "Glass Eel",// mapped
  // "Octopus/Squid", // mapped
  // "Crab", // mapped
  // "Deep / Slope Snapper", // mapped
  // // "Other", Elena asked to remove
  // "Sea Moss / Weed", // mapped 
  // "Offshore Tunas / Pelagics", // mapped
];

export const CONCH = "Conch";
export const SEA_CUCUMBER = "Sea Cucumber";
export const SPINY_LOBSTER = "Spiny Lobster";

export const specialTypeOfSpecies = [SPINY_LOBSTER, CONCH, SEA_CUCUMBER];

export const conchTypes = ["Conch (Other conch)", "Conch (Queen conch)"];



export let specieal_species_allow = {
  [CONCH]: {
    start_date: '03-01',
    end_date: '08-07',
    description: `There will be a Close Season from 08 August to 29 February 2024 during which time fishing of ${CONCH} is strictly prohibited`,
    shouldIncreaseyear: false,
  },
  [SEA_CUCUMBER]: {
    start_date: '09-01',
    end_date: '02-29',
    description: `There will be a Close Season from 01 March to 31 August 2024 during which time fishing of ${SEA_CUCUMBER} is strictly prohibited`,
    shouldIncreaseyear: true,
  },
  [SPINY_LOBSTER]: {
    start_date: '06-01',
    end_date: '03-31',
    description: `There will be a Close Season from 01 April to 30 June 2024 during which time fishing of ${SPINY_LOBSTER} is strictly prohibited`,
    shouldIncreaseyear: true,
  }
}

export const resetSpeciesSeasons = (speciesSeasons = []) => {

  speciesSeasons?.forEach(season => {
    specieal_species_allow[season.specie_name].start_date = season.end_date;
    specieal_species_allow[season.specie_name].end_date = season.start_date;
    specieal_species_allow[season.specie_name].description = season.description;
  })
}



export const gearTypeToBeLicenced = [
  "Traps",
  "Nets",
  "Lines",
  "Scuba",
  "Free Lung",
  "Other",
];

export const applicantRoleInFisheries = [
  "Vessel Owner",
  "Processor",
  "Captain",
  "Supplier",
  "Diver",
  "Researcher",
  "Crew",
  "Fish Purveyor/Vendor",
  // "Fishery Worker", removed for fisher Licence
  "Other",
];


// not relevant
export const TargetFish_Commercial_Industrial = {
    [FISHING_VESSEL_LICENCE]: {
        "Commercial": {
            "Carrier Fishing" : {
                "Industrial Carrier": {
                  "Species": ["Fin Fish"],
                  "Special_Species": ["Lobster", "Conch", "Sea Cucumber"]
                  // "Mixed" removed from above option
                }
            },
            "Industrial Fishing": ["Lobster", "Conch", "Fin Fish", "Sea Cucumber", "Other"]
        }   
    }
}

export const FisheryTarget = {
  a: [
    'Spiny Lobster', "Fin Fish", "Sea Cucumber", "Other"
  ],
  b: [
    "Conch", "Fin Fish", "Sea Cucumber", "Other"
  ]
};


export const CommercialCarrierTypeofCarrier = {
    [FISHING_VESSEL_LICENCE]: {
        "Commercial": {
            "Carrier Fishing": ["Industrial Carrier", "Artisanal Carrier"]
        }
    }
};

export const CommercialCarrierTypeOfCarrierVessel = {
  "Artisanal Carrier": [
    "Carrier Vessel > 50ft- operating with Industrial Vessel",
    "Carrier Vessel < 50ft- operating with Industrial Vessel",
    "Independent Carrier Vessel >50 ft) not more than 4000 lbs whole lobster per trip",
    "Independent Carrier Vessel <50 ft) not more than 2000 lbs whole lobster per trip",
  ]
};  

export const NON_MOTORISED = "Non-motorised";
// update (Finfish removed);
export const MOTORISED_FINFISH = "Motorised";
export const FIN_FISH = "Finfish";
export const REGULAR_LOBSTER = "Regular lobster and finfish artisanal (no more than 100 whole lobsters per trip)";
export const COMMERCIAL_LOBSTER = "Commercial lobster artisanal (no more than 600 whole lobsters per trip)";
export const CONCH_TYPE_OF_VESSEL = "Conch";
export const SEA_CUCUMBER_TYPE_OF_VESSEL = "Sea Cucumber";
export const SEA_MOSS = "Sea Moss";
// changed
export const OTHER_FISHER = "Other Fishery";

export const CommercialTypeOfVessel = {
  "Artisanal Fishing": [
    NON_MOTORISED,
    MOTORISED_FINFISH,
  ]
}

export const CommercialTypeofFish = {
  "Artisanal Fishing": [
    FIN_FISH,
    REGULAR_LOBSTER,
    COMMERCIAL_LOBSTER,
    CONCH_TYPE_OF_VESSEL, 
    SEA_CUCUMBER_TYPE_OF_VESSEL,
    SEA_MOSS,
    OTHER_FISHER
  ]
}


// export const TargetFish_CommercialCarrierIndustrialCarrier = 

// not used currently
export const Vessel_TargetFish_A_OR_B = {
  [FISHER_LICENCE]: {
    Commercial: {
      "Industrial Fishing": {
        a: ["Lobster", "Fin Fish", "Sea Cucumber", "Other"],
        b: ["Conch", "Fin Fish", "Sea Cucumber", "Other"],
      },
      "Carrier Fishing": {},
    },
  },
};

// this is not used anywhere
// export const Carrier_Vessel_Type = {
//   "Industrial Carrier": {
//     "Lobster": [
//       "Carrier Vessel > 50ft- operating with Industrial Vessel",
//       "Carrier Vessel < 50ft- operating with Industrial Vessel",
//       "Independent Carrier Vessel >50 ft) nore more that 4000 lbs whole lobster per trip",
//       "Independent Carrier Vessel <50 ft) nore more that 2000 lbs whole lobster per trip",
//     ]
//   },
//   Commercial_artisanal: [
//     "Non-motorised",
//     "Mororised-(Finfish)",
//     "Conch",
//     "Sea Cucumber",
//     "Regular lobster and finfish artisanal (no more than 100 whole lobsters per trip)",
//     "Commercial lobster artisanal (no more than 600 whole lobsters per trip)",
//     "Sea Moss",
//     "Other Fisher",
//   ],
// };

export const recreation_type_of_licence = {
  [SPORTS_CHARTER]: {
    key: "sports_charter_vessel_class",
    values: [
      "Decked Vessel (Including yacht)",
      "Non-Decked (Motorized)",
      "Non-Decked (Non -motorized)",
    ]
  },
  [ENTERTAINMENT]: {
    key: "pleasure_vessel_class",
    values: [
      "Decked Vessel (Including yacht)",
      "Non-Decked (Motorized)",
      "Non-Decked (Non -motorized)",
      "Temporary Certificate",
    ]
  },
  [TOURNAMENT]: {
    key: "tournament_vessel_class",
    values: [
      "Decked Vessel (Including yacht)",
      "Non-Decked (Motorized)",
      "Non-Decked (Non -motorized)",
      "Temporary Certificate",
    ]
  },
  [SUBSISTENCE]: {
    key: "subsistence_vessel_class", 
    values: [
      "Decked Vessel (Including yacht)",
      "Non-Decked (Motorized)",
      "Non-Decked (Non -motorized)",
    ]
  },
};

// export const VesselClass = [
//   "Non-Decked (Canoe)",
//   "Partial Decked",
//   "Full Descked (Trawler)",
//   "Yacht / Sail",
// ]

export const VesselClasses = {
  [COMMERCIAL]: [
    "Non-Decked",
    "Partially Decked",
    "Fully Decked"
  ],
}

export const Vessel_Fishing_GearType = [
  "Handline",
  "Dropline",
  "Troll Line",
  "Rod and Reel",
  "Palanca", // to be corrected in BPM
  "Long Line",
  "Trammel Net",
  "Sprat Net",
  "China Net",
  "Beach Seine",
  "Cast Net",
  "Shrimp Net",
  "Bait Net",
  "Shove Net",
  "Z-Traps",
  "Wooden Traps",
  "Scuba",
  "Hoocha", // earlier it was Hooker - to be corrected in BPM
  "Spear Gun",
  "Other",
];

export const MainGearOwnedBy = [
  "Boat Owner",
  "Borrowed / Rent",
  "Crew / Captain",
  "Other",
];


// export const Declarations = {
//   "Fisher Licence": [
//     "NO person or vessel shall engage in fishing or related activity in the fisheries waters or beyond without the appropriate licence, authorization or permit from the National Fisheries Authority (NFA).",
//     "NO person or vessel shall engage in fishing or related activity in the fisheries waters or beyond without the appropriate licence, authorization or permit from the National Fisheries Authority (NFA).",
//     "NO fishing of any kind is to be done in a Fish Sanctuary unless there is written authorization from the NFA.",
//     "ALL persons engaged in fishing in or beyond the fisheries waters must carry upon his/her person, their fishing licence at all times.",
//     "NO person shall catch and bring ashore, or destroy, any berried or immature (under 3” carapace length) Caribbean Spiny (Panulirus argus) lobster throughout the year.",
//     "ALL persons shall observe the laws related to close seasons for species of spiny lobster (April 1 to June 30), sea cucumbers (March 1 to August 31), queen conch (as announced by the NFA) and any other species for which such management plans will be imposed.",
//     "NO person shall fish for conch without a valid licence and certificate to show their Individual Conch Quota as issued to them by the NFA.",
//     "It is an OFFENCE to permit to use, use, attempt or participate in the use of any explosives, poison or noxious substance or electrical current fishing device in the fisheries waters.",
//     "It is an OFFENCE to carry or have in one’s possession or control, any explosives, poison or noxious substance or electrical current fishing device while in the fisheries waters.",
//     "It is an OFFENCE to land, sell, receive or have in one’s possession, fish or fish products which have been taken in contravention of the Fisheries Act, 2018.",
//     "It is an OFFENCE to interfere with an aquaculture facility or harvest products from an aquaculture facility without the consent of the licensed owner of that facility.",
//     "Failure to report lost or unserviceable vessels; failure to comply with a lawful query by a fishery inspector; failure to reasonably ensure safety of a fishery inspector; organising a recreational fishing activity without approval; failure to stow fishing gear in prescribed manner; leaving fish remains or offal on shore; and/or leaving decaying fish in net or other equipment; are all OFFENCES that a person if convicted, will pay a fixed fine.",
//     "If a person is refused a licence or their licence is suspended cancelled or revoked by the Authority, that person has the right to appeal in 21 days of the date of the decision.",
//   ]
// }


export const fisheryMapping = {

  [FIN_FISH]: {
    [licenceNumber]: "GL"
  },
  "Bait": {
    [licenceNumber]: "BT"
  },
  "Basa (Pangasius)": {
    [licenceNumber]: "PG"
  },
  "Coastal Pelagics": {
    [licenceNumber]: "CP"
  },
  "Conch": {
    // to be confirmed which conch to be used
    [licenceNumber]: "QC"
  },
  "Conch (Other conch)": {
    [licenceNumber]: "OC"
  },
  "Conch (Queen conch)": {
    [licenceNumber]: "QC"
  },
  "Sea Cucumber": {
    [licenceNumber]: "SC"
  },
  "Crabs": {
    [licenceNumber]: "CB"
  },
  "Deep Slope / Snapper": {
    [licenceNumber]: "DS"
  },
  "Glass eels": {
    [licenceNumber]: "GE"
  },
  // [IRISH_MOSS_SEA_WEEDS]
  "Irish Moss / Sea Weeds"  : {
    [licenceNumber]: "SW"
  },
  "Inland FinFish": {
    [licenceNumber]: "IF"
  },
  "Sea Moss / Weed": {
    [licenceNumber]: "SW"
  },
  "Spiny Lobster": {
    [licenceNumber]: "SL"
  },
  "Octopus / Squid": {
    [licenceNumber]: "OS"
  },
  "Offshore Pelagics": {
    [licenceNumber]: "OP"
  },
  "Ornamentals": {
    [licenceNumber]: "OM"
  },
  "Oyster": {
    [licenceNumber]: "OY"
  },
  "Reef Fish": {
    [licenceNumber]: "RF"
  },
  "Shrimp Freshwater": {
    [licenceNumber]: "FS"
  },
  "Shrimp Marine": {
    [licenceNumber]: "MS"
  },
  "Tilapia": {
    [licenceNumber]: "TL"
  },
  "Other Aquatic Plants": {
    [licenceNumber]: "AQ"
  },
  "Marlin": {
    [licenceNumber]: "MA"
  },
}





export const Declarations = {
  "Fisher Licence": [
    "NO person or vessel shall engage in fishing or related activity in the fisheries waters or beyond without the appropriate licence, authorization or permit from the National Fisheries Authority (NFA)",
    "NO person shall install or operate a commercial aquaculture facility or engage in commercial aquaculture without a licence from the NFA.",
    "NO fishing of any kind is to be done in a Fish Sanctuary unless there is written authorization from the NFA.",
    "ALL persons engaged in fishing in or beyond the fisheries waters must carry upon his/her person, their fishing licence at all times.",
    "NO person shall catch and bring ashore, or destroy, any berried or immature (under 3” carapace length) Caribbean Spiny (Panulirus argus) lobster throughout the year.",
    "ALL persons shall observe the laws related to close seasons for species of spiny lobster (April 1 to June 30), sea cucumbers (March 1 to August 31), queen conch (as announced by the NFA) and any other species for which such management plans will be imposed.",
    "NO person shall fish for conch without a valid licence and certificate to show their Individual Conch Quota as issued to them by the NFA.",
    "It is an OFFENCE to carry, have in one’s possession or control, or permit to use, use, attempt or participate in the use of any explosives, poison or noxious substance or electrical current fishing device in the fisheries waters.",
    "A person shall NOT between the hours of 6:00pm and 5:00am, use any impaling device (including speargun or Hawaiian sling) for fishing.",
    "It is an OFFENCE to land, sell, receive or have in one’s possession, fish or fish products which have been taken in contravention of the Fisheries Act, 2018.",
    "It is an OFFENCE to interfere with an aquaculture facility or harvest products from an aquaculture facility without the consent of the licensed owner of that facility.",
    "Failure to report lost or unserviceable vessels; failure to comply with a lawful query by a fishery inspector; failure to reasonably ensure safety of a fishery inspector; organising a recreational fishing activity without approval; failure to stow fishing gear in prescribed manner; leaving fish remains or offal on shore; and/or leaving decaying fish in net or other equipment; are all OFFENCES that a person if convicted, will pay a fixed fine.",
    "If a person is refused a licence or their licence is suspended cancelled or revoked by the Authority, that person has the right to appeal in 21 days of the date of the decision."
  ]
}



export const getSVGImage = (width, height, fill) => {
  fill = "#00b7fd";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 73 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66699 72H24.667L36.667 84L48.667 72H64.667C69.067 72 72.667 68.4 72.667 64V8C72.667 3.6 69.067 0 64.667 0H8.66699C4.26699 0 0.666992 3.6 0.666992 8V64C0.666992 68.4 4.26699 72 8.66699 72ZM8.66699 8H64.667V64H45.347L42.987 66.36L36.667 72.68L30.307 66.32L27.987 64H8.66699V8ZM36.667 60L44.187 43.52L60.667 36L44.187 28.48L36.667 12L29.147 28.48L12.667 36L29.147 43.52L36.667 60Z"
        fill={fill}
      />
    </svg>
  );
};

export const getRectBoxStylingWithMultiValue = (selectedMap, key, value) => {
  return {
    background: selectedMap[key]?.indexOf?.(value) >= 0 ? "#cbf1ff" : "",
    color: selectedMap[key]?.indexOf?.(value) >= 0 ? "black" : "",
  };
};

export const getRectBoxStylingWithSingleValue = (selectedMap, key, value) => {
  return {
    background: selectedMap[key] === value ? "#cbf1ff" : "",
    color: selectedMap[key] === value ? "black" : "",
  };
};

export const getRectBoxStylingWithMultiTypeValues = (
  selectedMap,
  key,
  value
) => {
  return {
    background:
      selectedMap[key] === value || selectedMap[key]?.indexOf(value) >= 0
        ? "#cbf1ff"
        : "",
    color:
      selectedMap[key] === value || selectedMap[key]?.indexOf(value) >= 0
        ? "black"
        : "",
  };
};




export const InternalNavigator = (props) => {
  return (
    <div className="mt-4 mb-4">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {!!props.prevValue && (
            <button
              onClick={() => {
                props.previousAction && props.previousAction();
              }}
              type="button"
              className="btn btn-custom-warning btn-custom-previous"
              style={{fontSize: "20px", fontWeight: 600,minWidth: "100px"}}
            >
              {props.prevValue || "Previous"}
            </button>
          )}
        </div>

        <div>
          {!!props.middleValue && (
            <button
              onClick={() => {
                props.middleAction && props.middleAction();
              }}
              type="button"
              className="btn btn-custom-success"
              style={{fontSize: "20px", fontWeight: 600,minWidth: "100px"}}
            >
              {props.middleValue || "Middle"}
            </button>
          )}
        </div>

        <div>
          {!!props.nextValue && (
            <button
              onClick={() => {
                props.nextAction && props.nextAction();
              }}
              style={{ fontSize: "20px", fontWeight: 600, minWidth: "200px" }}
              type="button"
              className={
                props.nextBtnClass
                  ? props.nextBtnClass
                  : `btn btn-custom-success`
              }
            >
              {props.nextValue || "Next"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export const Break = () => <div className="break"></div>;

export const onChangeMultiSelect = (e, selectedMap, setSelectedMap) => {
  let values = cloneDeep(selectedMap[e.target.name] || []);

  const index = values.indexOf(e.target.value);
  if(typeof values === 'string') values = [values];
  if (index === -1) {
    values.push(e.target.value);
  } else {
    values.splice(index, 1);
  }
  setSelectedMap((map) => {

    const result = {
      ...map,
      [e.target.name]: values,
    };

    return result;
  });
};


// Generate a list of years for the dropdown (e.g., from the current year to 1900)
export const years = Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (_, i) => 1900 + i).reverse();

export const yearsInAdvance = (total) => {

  return Array.from({length: total}, (_, i) => i + new Date().getFullYear());
}


export const divideArray = (arr = [], chunkSize = 1) => {
  const dividedArrays = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      dividedArrays.push(arr.slice(i, i + chunkSize));
  }
  return dividedArrays;
}


export const yearFromTo = (from, to = new Date().getFullYear()) => {
  const years = [];

  for (let i = from; i <= to; i++) {
    years.push(`${i}-${i + 1}`);
  }

  return years;
};

export const isRequiredIndividual = (selectedMap) => {
  return selectedMap?.applicant_type === "Individual" ? "This field is required" : false;
}

export const isRequiredCompany = (selectedMap) => {
  return selectedMap?.applicant_type === "Company" ? "This field is required" : false;
}


export const formatPhoneNumber = (e) => {
  if (!e.target?.value) return;
  const value = e.target.value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) {
    e.target.value = phoneNumber;
    return;
  }
  if (phoneNumber.length < 7) {
    e.target.value = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return;
  }
  e.target.value = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const formatTRN = (e) => {
  if (!e.target?.value) return;
  const value = e.target.value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) {
    e.target.value = phoneNumber;
    return;
  }
  if (phoneNumber.length < 7) {
    e.target.value = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return;
  }
  e.target.value = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 9)}`;
};

export const educationTypes = [
  "Primary", 
  "Secondary",
  "Tertiary",
  "College/University", 
  "Other"
]

export const LIC_PER_RENEWAL = "Lic/Permit Renewal";
export const ID_CARD_RENEWAL = "ID Card Renewal";
export const LIC_PER_ID_CARD_RENEWAL = "Lic/Permit + ID Card Renewal";

export const RenewalTypeOptions = () =>  {return <>
  <option value="">---Select---</option>
  <option value={LIC_PER_RENEWAL}>{LIC_PER_RENEWAL}</option>
  <option value={ID_CARD_RENEWAL}>{ID_CARD_RENEWAL}</option>
  <option value={LIC_PER_ID_CARD_RENEWAL}>{LIC_PER_ID_CARD_RENEWAL}</option>
  </>
};

export const RenewalTypeOptionsList = [LIC_PER_RENEWAL, ID_CARD_RENEWAL, LIC_PER_ID_CARD_RENEWAL];


export const BASE_LOCATION_PORT = "Fishing Port";
export const BASE_LOCATION_INLAND = "Inland";

const VesselPortRegMark = "VesselPortRegMarkNumber";
const NFARegNumber = "NFARegistrationNumber";

export const baseLocations = [BASE_LOCATION_PORT, BASE_LOCATION_INLAND];

export const InlandList = ["Hope River", "Morant River", "Plantain Garden River", "Yallahs River", "Buff Bay River", "Hector's River", "Rio Grande", "Rio Nuevo", "Wag Water River", "White River", "Martha Brae River", "Great River", "Montego River", "Lucea West River", "Lucea East River", "Cabaritta River", "Deans Valley River", "Negril River", "Black River", "Milk River", "Rio Minho", "Rio Cobre", "Great Salt Pond", "Great Pedro Pond", "Other"]

export const InlandCodeMapping = {
  "Hope River": {
    [VesselPortRegMark]: "IHR"
  },
  "Morant River": {
    [VesselPortRegMark]: "IMR"
  },
  "Plantain Garden River": {
    [VesselPortRegMark]: "IPG"
  },
  "Yallahs River": {
    [VesselPortRegMark]: "IYR"
  },
  "Buff Bay River": {
    [VesselPortRegMark]: "IBB"
  },
  "Hector's River": {
    [NFARegNumber]: "L",
    [VesselPortRegMark]: "HR"
  },
  "Rio Grande": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IRG"
  },
  "Rio Nuevo": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IRN"
  },
  "Wag Water River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IWW"
  },
  "White River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "WR"
  },
  "Martha Brae River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IMB"
  },
  "Great River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IGR"
  },
  "Montego River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IMO"
  },
  "Lucea West River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "ILW"
  },
  "Lucea East River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "ILE"
  },
  "Cabaritta River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "ICR"
  },
  "Deans Valley River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IDV"
  },
  "Negril River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "INR"
  },
  "Black River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "BR"
  },
  "Milk River": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IMK"
  },
  "Rio Minho": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IRM"
  },
  "Rio Cobre": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IRC"
  },
  "Great Salt Pond": {
    [NFARegNumber]: "AAAA",
    [VesselPortRegMark]: "IGS"
  },
  "Great Pedro Pond": {
    [VesselPortRegMark]: "IGP"
  }
}

// regions
const KingstonAndAndrew = "Kingston and Andrew";
const St_Cathrine = "St. Catherine";
const Clarendon = "Clarendon";
const Manchester = "Manchester";
const St_Elizabeth = "St. Elizabeth";
const Westmoreland = "Westmoreland";
const Hanover = "Hanover";
const St_James = "St. James";
const Trelawny = "Trelawny";
const St_Ann = "St. Ann";
const St_Mary = "St. Mary";
const Portland = "Portland";
const St_Thomas = "St. Thomas";
const Pedro_cays = "Pedro Cays";
const Morant_Cays = "Morant Cays";

// ports
const BullBay = "Bull Bay";
const SevenMies = "Seven Mies";
const HarbourHead = "Harbour Head";
const Gunboat = "Gunboat";
const PortRoyal = "Port Royal";
const Kingston = "Kingston";
const RaeTown = "Rae Town (Paradise Street, Fleet Street, and Water Lane)";
const GreenwichTown = "Greenwich Town";
const HuntsBay = "Hunts Bay";

const WreckBay = "Wreck Bay";
const OldHarbourBay = "Old Harbour Bay";
const PassageFort = "Passage Fort";
const PortHenderson = "Port Henderson";
const Hellshire = "Hellshire";

const SaltRiver = "Salt River";
const Welcome = "Welcome";
const MitchellTown = "Mitchell Town";
const Bournmouth = "Bournmouth";
const BarnswellDale = "Barnswell Dale";
const RockyPoint = "Rocky Point";
const JacksonBay = "Jackson Bay";
const CarlisleBay = "Carlisle Bay";
const Beauchamp = "Beauchamp";
const Farquhar = "Farquhar  (Alligator Hole)";

const GuttsRiver = "Gutts River";
const HudsonBay = "Hudson Bay";
const BoilerBeach = "Boiler Beach";
const AlligatorPond = "Alligator Pond (Top & Bottom Bay)";

const CutlassHole = "Cutlass Hole";
const GreatBay = "Great Bay";
const CalabashBay = "Calabash Bay";
const FrenchmansBay = "Frenchman's Bay";
const BillysBay = "Billy's Bay";
const FortCharles = "Fort Charles";
const Parottee = "Parottee";
const Hodges = "Hodges";
const LongAcre = "Long Acre";
const BlackRiver = "Black River (River Side and Heavy Sand)";

const ScottsCove = "Scotts Cove";
const WhitehouseGillings = "Whitehouse (Gillings Gully)";
const Braughton = "Braughton";
const Auchendown = "Auchendown (Culloden)";
const MountEdgecomb = "Mount Edgecomb";
const MarketBeach = "Market Beach";
const Belmont = "Belmont";
const Cave = "Cave";
const StAnne = "St. Anne";
const Smitbsfield = "Smitbsfield";
const StMary = "St. Mary";
const HopeWharf = "Hope Wharf";
const SalmonPoint = "Salmon Point";
const LittleBay = "Little Bay";
const Revival = "Revival";
const OrangeHill = "Orange Hill";
const Ironshore = "Ironshore";
const Negril = "Negril";

const BloodyBay = "Bloody Bay";
const OrangeBay = "Orange Bay";
const GreenIsland = "Green Island";
const IndustryCove = "Industry Cove";
const CousinsCove = "Cousins Cove";
const LancesBay = "Lances Bay";
const HaughtonCourt = "Haughton Court";
const Lucea = "Lucea";
const JohnsonTown = "Johnson Town";
const ElginTown = "Elgin Town";
const Paradise = "Paradise";
const MosquitoCove = "Mosquito Cove";
const SandyBay = "Sandy Bay";
const Hopewell = "Hopewell (Orchard add Parkins)";
const GreatRiver = "Great River (Rum Bottle Cove)";

const SpringGarden = "Spring Garden";
const Reading = "Reading";
const RiverBay = "River Bay";
const WhitehouseFlanker = "Whitehouse (Flanker)";
const SuccessRoseHall = "Success (Rose Hall)";
const GrangePen = "Grange Pen";
const HarveysSaltSpring = "Harvey's (Salt Spring)";

const SaltMarsh = "Salt Marsh (Scarlett Hall)";
const Falmouth = "Falmouth (Gentles, Seaboard St., and Charlotte)";
const Rock = "Rock";
const CoopersGoodHope = "Cooper's (Good Hope)";
const Darby = "Darby (Stewart Castle and Silver Sand)";
const Braco = "Braco";
const RioBueno = "Rio Bueno";

const OldFolly = "Old Folly (Alloah)";
const DiscoveryBay = "Discovery Bay (Fort)";
const RunawayBay = "Runaway Bay";
const Salem = "Salem";
const Laughlands = "Laughlands (Llandovery)";
const Priory = "Priory";
const StAnnsBay = "St. Ann's Bay";
const MammeeBay = "Mammee Bay";
const OchoRios = "Ocho Rios (Sailors Hole)";
const WhiteRiver = "White River";

const RioNuevo = "Rio Nuevo";
const Boscobel = "Boscobel (Playboy Fishermen Beach, Salt Gut and Breakfast Gap)";
const Oracabessa = "Oracabessa (White and Magnus)";
const Pagee = "Pagee";
const MahoeBay = "Mahoe Bay";
const CarltonBeach = "Carlton Beach";
const RobinsBay = "Robins Bay";
const AnnottoBay = "Annotto Bay (Sea View)"; 
const StewartTown = "Stewart Town";
const Dover = "Dover (Windsor Castle)";

const BuffBay = "Buff Bay";
const OrangeBayEastAndWest = "Orange Bay (East and West)";
const HopeBay = "Hope Bay";
const StMargaretsBay = "St. Margaret's Bay";
const SnowHill = "Snow Hill";
const Norwich = "Norwich";
const PortAntonio = "Port Antonio (East and West Harbours, Bryans Bay)";
const Williamsfield = "Williamsfield (Turtle Crawl)";
const BottomBeach = "Bottom Beach";
const Drapers = "Drapers";
const FairyHill = "Fairy Hill";
const BlueHole = "Blue Hole";
const PriestmanRiver = "Priestman's River";
const Boston = "Boston";
const LongBay = "Long Bay";
const DiversRiver = "Divers River";
const SanacoBay = "Sanaco Bay";
const Manchioneal = "Manchioneal";
const InnesBay = "Innes Bay";
const HectorsRiver = "Hectors River";

const HollandBay = "Holland Bay";
const RockyPoint2 = "Rocky Point";
const Dalvey = "Dalvey";
const PeraOldAndNew = "Pera (Old and New)";
const PortMorant = "Port Morant";
const LcithHall = "Lcith Hall";
const ProsDect = "ProsDect";
const Lyssons = "Lyssons";
const PrincessMargaretHospitalBeach = "Princess Margaret Hospital Beach";
const MorantBay = "Morant Bay";
const DuhaneyPen = "Duhaney Pen";
const WhiteHorses = "White Horses";
const Yallahs = "Yallahs";
const GreenBay = "Green Bay";
const CowBay = "Cow Bay";
const GrantsPen = "Grants Pen";
const NineMiles = "Nine Miles";

const TopCay = "Top Cay";
const MiddleCay = "Middle Cay";


const MorantCays = "Morant Cays";

export const regionParishList = [
  KingstonAndAndrew,
  St_Cathrine,
  Clarendon,
  Manchester,
  St_Elizabeth,
  Westmoreland,
  Hanover,
  St_James,
  Trelawny,
  St_Ann,
  St_Mary,
  Portland,
  St_Thomas,
  Pedro_cays,
  Morant_Cays
];

export const regionListForInland = [
  KingstonAndAndrew,
  St_Thomas,
  Portland,
  St_Mary,
  St_Ann,
  Trelawny,
  St_James,
  Hanover,
  Westmoreland,
  St_Elizabeth,
  Clarendon,
  St_Cathrine,
  St_Elizabeth
]


export const regionParishPort = {
  [KingstonAndAndrew]: [
    "Bull Bay", "Seven Mies", "Harbour Head", "Gunboat", "Port Royal", "Kingston", "Rae Town (Paradise Street, Fleet Street, and Water Lane)", "Greenwich Town", "Hunts Bay"
  ],
  [St_Cathrine]: ["Wreck Bay", "Old Harbour Bay", "Passage Fort", "Port Henderson", "Hellshire"],
  [Clarendon]: ["Salt River", "Welcome", "Mitchell Town", "Bournmouth", "Barnswell Dale", "Rocky Point", "Jackson Bay", "Carlisle Bay", "Beauchamp", "Farquhar  (Alligator Hole)"],
  [Manchester]: ["Gutts River", "Hudson Bay", "Boiler Beach", "Alligator Pond (Top & Bottom Bay)"],
  [St_Elizabeth]: ["Cutlass Hole", "Great Bay", "Calabash Bay", "Frenchman's Bay", "Billy's Bay", "Fort Charles", "Parottee", "Hodges", "Long Acre", "Black River (River Side and Heavy Sand)"],
  [Westmoreland]: ["Scotts Cove", "Whitehouse (Gillings Gully)", "Braughton", "Auchendown (Culloden)", "Mount Edgecomb", "Market Beach", "Belmont", "Cave", "St. Anne", "Smitbsfield", "St. Mary", "Hope Wharf", "Salmon Point", "Little Bay", "Revival", "Orange Hill", "Ironshore", "Negril"],
  [Hanover]: ["Bloody Bay", "Orange Bay", "Green Island", "Industry Cove", "Cousins Cove", "Lances Bay", "Haughton Court", "Lucea", "Johnson Town", "Elgin Town", "Paradise", "Mosquito Cove", "Sandy Bay", "Hopewell (Orchard add Parkins)", "Great River (Rum Bottle Cove)"],
  [St_James]: ["Spring Garden", "Reading", "River Bay", "Whitehouse (Flanker)", "Success (Rose Hall)", "Grange Pen", "Harvey's (Salt Spring)"],
  [Trelawny]: ["Salt Marsh (Scarlett Hall)", "Falmouth (Gentles, Seaboard St., and Charlotte)", "Rock", "Cooper's (Good Hope)", "Darby (Stewart Castle and Silver Sand)", "Braco", "Rio Bueno"],
  [St_Ann]: ["Old Folly (Alloah)", "Discovery Bay (Fort)", "Runaway Bay", "Salem", "Laughlands (Llandovery)", "Priory", "St. Ann's Bay", "Mammee Bay", "Ocho Rios (Sailors Hole)", "White River"],
  [St_Mary]: ["Rio Nuevo", "Boscobel (Playboy Fishermen Beach, Salt Gut and Breakfast Gap)", "Oracabessa (White and Magnus)", "Pagee", "Mahoe Bay", "Carlton Beach", "Robins Bay", "Annotto Bay (Sea View)", "Stewart Town", "Dover (Windsor Castle)"],
  [Portland]: ["Buff Bay", "Orange Bay (East and West)", "Hope Bay", "St. Margaret's Bay", "Snow Hill", "Norwich", "Port Antonio (East and West Harbours, Bryans Bay)", "Williamsfield (Turtle Crawl)", "Bottom Beach", "Drapers", "Fairy Hill", "Blue Hole", "Priestman's River", "Boston", "Long Bay", "Divers River", "Sanaco Bay", "Manchioneal", "Innes Bay", "Hectors River"],
  [St_Thomas]: ["Holland Bay", "Rocky Point", "Dalvey", "Pera (Old and New)", "Port Morant", "Lcith Hall", "ProsDect", "Lyssons", "Princess Margaret Hospital Beach", "Morant Bay", "Duhaney Pen", "White Horses", "Yallahs", "Green Bay", "Cow Bay", "Grants Pen", "Nine Miles"],
  [Pedro_cays]: ["Top Cay", "Middle Cay"],
  [Morant_Cays]: ["Morant Cays"]
}

export const regionInland = {
  [KingstonAndAndrew]: ["Hope River"],
  [St_Thomas]: ["Morant River", "Plantain Garden River", "Yallahs River"],
  [Portland]: ["Buff Bay River", "Hector's River", "Rio Grande"],
  [St_Mary]: ["Rio Nuevo", "Wag Water River"],
  [St_Ann]: ["White River"],
  [Trelawny]: ["Martha Brae River"],
  [St_James]: ["Great River", "Montego River"],
  [Hanover]: ["Lucea West River", "Lucea East River"],
  [Westmoreland]: ["Cabaritta River", "Deans Valley River", "Negril River"],
  [St_Elizabeth]: ["Black River"],
  [Clarendon]: ["Milk River", "Rio Minho"], 
  [St_Cathrine]: ["Rio Cobre", "Great Salt Pond"],
  [St_Elizabeth]: ["Great Pedro Pond"]
}


export const RegionPortMapping = {
  [KingstonAndAndrew]: {
    [BullBay]: {
      [VesselPortRegMark]: "BB",
    },
    [SevenMies]: {
      [VesselPortRegMark]: "SM",
    },
    [HarbourHead]: {
      [VesselPortRegMark]: "HH",
    },
    [Gunboat]: {
      [VesselPortRegMark]: "GU",
    },
    [PortRoyal]: {
      [VesselPortRegMark]: "PR",
    },
    [Kingston]: {
      [VesselPortRegMark]: "K",
    },
    [RaeTown]: {
      [VesselPortRegMark]: "RT",
    },
    [GreenwichTown]: {
      [VesselPortRegMark]: "GT",
    },
    [HuntsBay]: {
      [VesselPortRegMark]: "HB",
    },
  },

  [St_Cathrine]: {
    [WreckBay]: {
      [VesselPortRegMark]: "WB",
    },
    [OldHarbourBay]: {
      [VesselPortRegMark]: "OHB",
    },
    [PassageFort]: {
      [VesselPortRegMark]: "PF",
    },
    [PortHenderson]: {
      [VesselPortRegMark]: "PH",
    },
    [Hellshire]: {
      [VesselPortRegMark]: "H",
    },
  },

  [Clarendon]: {
    [SaltRiver]: {
      [VesselPortRegMark]: "SR",
    },
    [Welcome]: {
      [VesselPortRegMark]: "W",
    },
    [MitchellTown]: {
      [VesselPortRegMark]: "MT",
    },
    [Bournmouth]: {
      [VesselPortRegMark]: "BM",
    },
    [BarnswellDale]: {
      [VesselPortRegMark]: "BD",
    },
    [RockyPoint]: {
      [VesselPortRegMark]: "RP",
    },
    [JacksonBay]: {
      [VesselPortRegMark]: "JB",
    },
    [CarlisleBay]: {
      [VesselPortRegMark]: "CB",
    },
    [Beauchamp]: {
      [VesselPortRegMark]: "BE",
    },
    [Farquhar]: {
      [VesselPortRegMark]: "F",
    },
  },

  [Manchester]: {
    [GuttsRiver]: {
      [VesselPortRegMark]: "GR",
    },
    [HudsonBay]: {
      [VesselPortRegMark]: "HD",
    },
    [BoilerBeach]: {
      [VesselPortRegMark]: "BO",
    },
    [AlligatorPond]: {
      [VesselPortRegMark]: "AP",
    },
  },

  [St_Elizabeth]: {
    [CutlassHole]: {
      [VesselPortRegMark]: "CH",
    },
    [GreatBay]: {
      [VesselPortRegMark]: "GB",
    },
    [CalabashBay]: {
      [VesselPortRegMark]: "CA",
    },
    [FrenchmansBay]: {
      [VesselPortRegMark]: "FB",
    },
    [BillysBay]: {
      [VesselPortRegMark]: "BI",
    },
    [FortCharles]: {
      [VesselPortRegMark]: "FC",
    },
    [Parottee]: {
      [VesselPortRegMark]: "PT",
    },
    [Hodges]: {
      [VesselPortRegMark]: "HG",
    },
    [LongAcre]: {
      [VesselPortRegMark]: "LA",
    },
    [BlackRiver]: {
      [VesselPortRegMark]: "BR",
    },
  },

  [Westmoreland]: {
    [ScottsCove]: {
      [VesselPortRegMark]: "SC",
    },
    [WhitehouseGillings]: {
      [VesselPortRegMark]: "WH",
    },
    [Braughton]: {
      [VesselPortRegMark]: "B",
    },
    [Auchendown]: {
      [VesselPortRegMark]: "A",
    },
    [MountEdgecomb]: {
      [VesselPortRegMark]: "ME",
    },
    [MarketBeach]: {
      [VesselPortRegMark]: "MB",
    },
    [Belmont]: {
      [VesselPortRegMark]: "BL",
    },
    [Cave]: {
      [VesselPortRegMark]: "C",
    },
    [StAnne]: {
      [VesselPortRegMark]: "SA",
    },
    [Smitbsfield]: {
      [VesselPortRegMark]: "S",
    },
    [StMary]: {
      [VesselPortRegMark]: "ST. M",
    },
    [HopeWharf]: {
      [VesselPortRegMark]: "HW",
    },
    [SalmonPoint]: {
      [VesselPortRegMark]: "SP",
    },
    [LittleBay]: {
      [VesselPortRegMark]: "LT",
    },
    [Revival]: {
      [VesselPortRegMark]: "RV",
    },
    [OrangeHill]: {
      [VesselPortRegMark]: "OH",
    },
    [Ironshore]: {
      [VesselPortRegMark]: "I",
    },
    [Negril]: {
      [VesselPortRegMark]: "NG",
    },
  },

  [Hanover]: {
    [BloodyBay]: {
      [VesselPortRegMark]: "BY",
    },
    [OrangeBay]: {
      [VesselPortRegMark]: "OG",
    },
    [GreenIsland]: {
      [VesselPortRegMark]: "GI",
    },
    [IndustryCove]: {
      [VesselPortRegMark]: "IC",
    },
    [CousinsCove]: {
      [VesselPortRegMark]: "CC",
    },
    [LancesBay]: {
      [VesselPortRegMark]: "LB",
    },
    [HaughtonCourt]: {
      [VesselPortRegMark]: "HC",
    },
    [Lucea]: {
      [VesselPortRegMark]: "LC",
    },
    [JohnsonTown]: {
      [VesselPortRegMark]: "JT",
    },
    [ElginTown]: {
      [VesselPortRegMark]: "E",
    },
    [Paradise]: {
      [VesselPortRegMark]: "PD",
    },
    [MosquitoCove]: {
      [VesselPortRegMark]: "MC",
    },
    [SandyBay]: {
      [VesselPortRegMark]: "SD",
    },
    [Hopewell]: {
      [VesselPortRegMark]: "HP",
    },
    [GreatRiver]: {
      [VesselPortRegMark]: "GTR",
    }
  },

  [St_James]: {
    [SpringGarden]: {
      [VesselPortRegMark]: "SG",
    },
    [Reading]: {
      [VesselPortRegMark]: "RD",
    },
    [RiverBay]: {
      [VesselPortRegMark]: "RB",
    },
    [WhitehouseFlanker]: {
      [VesselPortRegMark]: "WT",
    },
    [SuccessRoseHall]: {
      [VesselPortRegMark]: "SCS",
    },
    [GrangePen]: {
      [VesselPortRegMark]: "GE",
    },
    [HarveysSaltSpring]: {
      [VesselPortRegMark]: "HV",
    }
  },

  [Trelawny]: {
    [SaltMarsh]: {
      [VesselPortRegMark]: "SLM",
    },
    [Falmouth]: {
      [VesselPortRegMark]: "FA",
    },
    [Rock]: {
      [VesselPortRegMark]: "R",
    },
    [CoopersGoodHope]: {
      [VesselPortRegMark]: "CO",
    },
    [Darby]: {
      [VesselPortRegMark]: "D",
    },
    [Braco]: {
      [VesselPortRegMark]: "BC",
    },
    [RioBueno]: {
      [VesselPortRegMark]: "RI",
    }
  },

  [St_Ann]: {
    [OldFolly]: {
      [VesselPortRegMark]: "OF",
    },
    [DiscoveryBay]: {
      [VesselPortRegMark]: "DB",
    },
    [RunawayBay]: {
      [VesselPortRegMark]: "RU",
    },
    [Salem]: {
      [VesselPortRegMark]: "SL",
    },
    [Laughlands]: {
      [VesselPortRegMark]: "L",
    },
    [Priory]: {
      [VesselPortRegMark]: "PY",
    },
    [StAnnsBay]: {
      [VesselPortRegMark]: "SAB",
    },
    [MammeeBay]: {
      [VesselPortRegMark]: "MMM",
    },
    [OchoRios]: {
      [VesselPortRegMark]: "OR",
    },
    [WhiteRiver]: {
      [VesselPortRegMark]: "WR",
    }
  },

  [St_Mary]: {
    [RioNuevo]: {
      [VesselPortRegMark]: "RN",
    },
    [Boscobel]: {
      [VesselPortRegMark]: "BCB",
    },
    [Oracabessa]: {
      [VesselPortRegMark]: "O",
    },
    [Pagee]: {
      [VesselPortRegMark]: "PG",
    },
    [MahoeBay]: {
      [VesselPortRegMark]: "MA",
    },
    [CarltonBeach]: {
      [VesselPortRegMark]: "CL",
    },
    [RobinsBay]: {
      [VesselPortRegMark]: "BO",
    },
    [AnnottoBay]: {
      [VesselPortRegMark]: "AB",
    },
    [StewartTown]: {
      [VesselPortRegMark]: "ST",
    },
    [Dover]: {
      [VesselPortRegMark]: "DO",
    }
  },

  [Portland]: {
    [BuffBay]: {
      [VesselPortRegMark]: "BF",
    },
    [OrangeBayEastAndWest]: {
      [VesselPortRegMark]: "OB",
    },
    [HopeBay]: {
      [VesselPortRegMark]: "HE",
    },
    [StMargaretsBay]: {
      [VesselPortRegMark]: "SMB",
    },
    [SnowHill]: {
      [VesselPortRegMark]: "SH",
    },
    [Norwich]: {
      [VesselPortRegMark]: "N",
    },
    [PortAntonio]: {
      [VesselPortRegMark]: "PA",
    },
    [Williamsfield]: {
      [VesselPortRegMark]: "WF",
    },
    [BottomBeach]: {
      [VesselPortRegMark]: "BT",
    },
    [Drapers]: {
      [VesselPortRegMark]: "DP",
    },
    [FairyHill]: {
      [VesselPortRegMark]: "FH",
    },
    [BlueHole]: {
      [VesselPortRegMark]: "BU",
    },
    [PriestmanRiver]: {
      [VesselPortRegMark]: "PMR",
    },
    [Boston]: {
      [VesselPortRegMark]: "BS",
    },
    [LongBay]: {
      [VesselPortRegMark]: "LO",
    },
    [DiversRiver]: {
      [VesselPortRegMark]: "DR",
    },
    [SanacoBay]: {
      [VesselPortRegMark]: "SB",
    },
    [Manchioneal]: {
      [VesselPortRegMark]: "M",
    },
    [InnesBay]: {
      [VesselPortRegMark]: "IB",
    },
    [HectorsRiver]: {
      [VesselPortRegMark]: "HR",
    }
  },

  [St_Thomas]: {
    [HollandBay]: {
      [VesselPortRegMark]: "HO",
    },
    [RockyPoint2]: {
      [VesselPortRegMark]: "RK",
    },
    [Dalvey]: {
      [VesselPortRegMark]: "DV",
    },
    [PeraOldAndNew]: {
      [VesselPortRegMark]: "PE",
    },
    [PortMorant]: {
      [VesselPortRegMark]: "PM",
    },
    [LcithHall]: {
      [VesselPortRegMark]: "LH",
    },
    [ProsDect]: {
      [VesselPortRegMark]: "P",
    },
    [Lyssons]: {
      [VesselPortRegMark]: "LY",
    },
    [PrincessMargaretHospitalBeach]: {
      [VesselPortRegMark]: "PMH",
    },
    [MorantBay]: {
      [VesselPortRegMark]: "MO",
    },
    [DuhaneyPen]: {
      [VesselPortRegMark]: "DU",
    },
    [WhiteHorses]: {
      [VesselPortRegMark]: "WTH",
    },
    [Yallahs]: {
      [VesselPortRegMark]: "Y",
    },
    [GreenBay]: {
      [VesselPortRegMark]: "GN",
    },
    [CowBay]: {
      [VesselPortRegMark]: "CW",
    },
    [GrantsPen]: {
      [VesselPortRegMark]: "GP",
    },
    [NineMiles]: {
      [VesselPortRegMark]: "NM",
    }
  },

  [Pedro_cays]: {
    [TopCay]: {
      [VesselPortRegMark]: "PNC",
    },
    [MiddleCay]: {
      [VesselPortRegMark]: "PSC",
    }
  },
  
  [Morant_Cays]: {
    [MorantCays]: {
      [VesselPortRegMark]: "MC",
    }
  }

};

export const RegionMapping = {
  [KingstonAndAndrew]: {
    [NFARegNumber]: "A"
  },
  [St_Cathrine]: {
    [NFARegNumber]: "B"
  },
  [Clarendon]: {
    [NFARegNumber]: "C"
  },
  [Manchester]: {
    [NFARegNumber]: "D"
  },
  [St_Elizabeth]: {
    [NFARegNumber]: "E"
  },
  [Westmoreland]: {
    [NFARegNumber]: "F"
  },
  [Hanover]: {
    [NFARegNumber]: "G"
  },
  [St_James]: {
    [NFARegNumber]: "H"
  },
  [Trelawny]: {
    [NFARegNumber]: "I"
  },
  [St_Ann]: {
    [NFARegNumber]: "J"
  },
  [St_Mary]: {
    [NFARegNumber]: "K"
  },
  [Portland]: {
    [NFARegNumber]: "L"
  },
  [St_Thomas]: {
    [NFARegNumber]: "M"
  }
}

export const VesselCategoryMapping = {
  [INDUSTRIAL_FISHING]: "I",
  [ARTISANAL_FISHING]: "A",
  [CARRIER_FISHING]: "C",
  [RECREATIONAL]: "R",
  [ACCESS_AGREEMENTS_FOREIGN_FISHING]: "F"
}


export const FisheriesWorker = "Fisheries Vendor";
export const NetMaker = "Net Maker";
export const EngineRepairer = "Engine Repairer";
export const BoatMaker = "Boat Maker";
export const VesselServicing = "Vessel Servicing";
export const AquacultureWorker = "Aquaculture Worker";

export const FishWorkerCategoryForFisherWorkerID = [
  FisheriesWorker,
  NetMaker,
  EngineRepairer,
  BoatMaker,
  VesselServicing
];

export const FishWorkerCategoryForAquaFisher = [
  AquacultureWorker
]

export const FishWorkerCategoryMapping = {
  [FisheriesWorker]: "F/V",
  [NetMaker]: "N/M",
  [EngineRepairer]: "E/R",
  [BoatMaker]: "B/M",
  [VesselServicing]: "V/S",
  [AquacultureWorker]: "F/AQ"
}


const ICMCaseNameMapping = {
  "FLIS_ApplicationforLicence": () => {
    return "Fisher Licence";
  },
  "FLIS_ApplicationforFishingVesselLicense": () => {
    return "Fishing Vessel Licence"
  },
  "FLIS_ApplicationforPermits": () => {
    return "Permits Licence"
  },
  "FLIS_ApplicationforQuotaandFishingRights": () => {
    return "Quota & Fishing Rights"
  },
  "FLIS_ApplicationforAuthorizations": () => {
    return "Authorizations"
  },
  "FLIS_ApplicationforAquacultureFacilityLicence": () => {
    return "Aquaculture Facility Licence"
  },
  "FLIS_ApplicationforMonitoringComplianceandSurveillance": () => {
    return "Notice Of Violation"
  },
  
}


export const getCaseRow = ({caseType , propertiesMap}) => {
  const map = {
    "FLIS_ApplicationforLicence": (prop = {}) => {
      return (
        <>
          <td>{concatValidData(prop.FLIS_NameofApplicant, prop.FLIS_NameofApplicantMiddle, prop.FLIS_NameofApplicantLast)}</td>
          <td>{ICMCaseNameMapping[caseType]?.()}</td>
          <td>{prop.FLIS_Licence}</td>
          <td>{prop.FLIS_TypeCategoryofLicenceFisher}</td>
          <td>{prop.FLIS_TypeofSpecies}</td>
          <td>{prop.FLIS_FishingPort || prop.FLIS_Inland}</td>
          <td>
            {prop.FLIS_DateofIssue ? new Date(prop.FLIS_DateofIssue).toLocaleString(
              "en-US",
              dateTimeOptions
            ) : null}
          </td>
          <td>
            {prop.FLIS_DateofExpiration ? new Date(prop.FLIS_DateofExpiration).toLocaleString(
              "en-US",
              dateTimeOptions
            ) : null}
          </td>

          <td>
            {prop.FLIS_IDCardDateofIssue ? new Date(prop.FLIS_IDCardDateofIssue).toLocaleString(
              "en-US",
              dateTimeOptions
            ) : null}
          </td>

          <td>
            {prop.FLIS_IDCardDateofExpiry ? new Date(prop.FLIS_IDCardDateofExpiry).toLocaleString(
              "en-US",
              dateTimeOptions
            ) : null}
          </td>



          {/* <td></td> */}
        </>
      );
    },

    "": (prop = {}) => {
      return <tr>
        <td>{prop.f}</td>
        <td>{prop.FLIS_TypeCategoryofLicenseVessel}</td>
      </tr>
    }
  }

  return map[caseType]?.(propertiesMap);
};