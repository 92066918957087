import React, { forwardRef } from "react";
import { useFieldArray } from "react-hook-form";
import { FormHookInput, FormHookSelect } from "../../components/FormHook/ReactFormHookFields";
import { yearFromTo } from "../../components/DataSource";

export const SeaCucumberHistoricalIndustryPerformance = ({formState,control, register, onSubmit,shouldRenderInForm=true, isDisabled=false, isAddEnabled=true, isDeleteEnabled=true , toShowHeading = true}) => {


    const {errors} = formState;

    const { fields, append, remove } = useFieldArray({
      control,
      name: "historical_industry_performance_sea_cucumber",
    });

    return (
      <div>
        <table className="table table-bordered">
          {toShowHeading && (
            <tr>
              <td style={{ textAlign: "center", fontSize: "20px" }} colSpan={6}>
                <b>Historical Industry Performance</b>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={3}>
              <FormHookInput
                register={register}
                error={
                  errors?.year_applicant_in_industry_sea_cucumber?.message
                }
                label="Number of Years in Industry"
                regFieldName={`year_applicant_in_industry_sea_cucumber`}
                placeholder="Number of Years in Industry"
                type="number"
                isDisabled={isDisabled}
              />
            </td>

            <td colSpan={3}>
              <FormHookInput
                register={register}
                error={
                  errors?.number_of_years_allocated_quota_sea_cucumber?.message
                }
                label="Number of Years Allocated Quota:"
                regFieldName={`number_of_years_allocated_quota_sea_cucumber`}
                placeholder="Number of Years Allocated Quota:"
                type="number"
                validations={{
                  maxLength: {
                    value: 4,
                    message: "Max length is 4",
                  },
                }}
                isDisabled={isDisabled}
              />
            </td>
          </tr>

          <tr style={{ fontWeight: 600 }}>
            <td>No.</td>
            <td style={{ maxWidth: "250px" }}>Sea Cucumber Fishing Season</td>
            <td style={{ maxWidth: "250px" }}>Sea Cucumber Quota Allocated</td>
            <td style={{ maxWidth: "250px" }}>
              Total 50% Sea Cucumber Landed or Caused to be Landed(Kg)
            </td>
            <td style={{ maxWidth: "250px" }}>
              Total 50% Clean Sea Cucumber exported or Sold Locally(Kg)
            </td>
            <td>

            </td>
          </tr>

          {fields?.map((group, groupIndex) => {
            return (
              <tr key={group.id}>
                <td>{groupIndex + 1}</td>
                <td style={{ maxWidth: "250px" }}>
                  {/* <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_sea_cucumber
                        ?.[groupIndex]?.sea_cucumber_fishing_season
                        ?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_sea_cucumber[${groupIndex}].sea_cucumber_fishing_season`}
                    placeholder="2000-2001"
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    validations={{
                      maxLength: {
                        value: 20,
                        message: "Max length is 20",
                      },
                    }}
                  /> */}

                  <FormHookSelect
                    label=""
                    hideLabel={true}
                    register={register}
                    parentClass="individual-field-small"
                    regFieldName={`historical_industry_performance_sea_cucumber[${groupIndex}].sea_cucumber_fishing_season`}
                    options={yearFromTo(1995)}
                    error={
                      errors?.historical_industry_performance_sea_cucumber?.[
                        groupIndex
                      ]?.sea_cucumber_fishing_season?.message
                    }
                    isDisabled={isDisabled}
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_sea_cucumber?.[
                        groupIndex
                      ]?.sea_cucumber_quota_allocated?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_sea_cucumber[${groupIndex}].sea_cucumber_quota_allocated`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_sea_cucumber?.[
                        groupIndex
                      ]?.sea_cucumber_to_be_landed?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_sea_cucumber[${groupIndex}].sea_cucumber_to_be_landed`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_sea_cucumber?.[
                        groupIndex
                      ]?.sea_cucumber_sold_locally?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_sea_cucumber[${groupIndex}].sea_cucumber_sold_locally`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  {!isDisabled && (
                    <button
                      type="button"
                      onClick={() => {
                        remove(groupIndex);
                      }}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </table>

        <div className="d-flex flex-row justify-content-between align-items-center">


        <FormHookInput
            register={register}
            error={errors?.total_investment_sea_cucumber?.message}
            label="Total Investment:"
            regFieldName={`total_investment_sea_cucumber`}
            placeholder="Total Investment"
            type="number"
            isDisabled={isDisabled}
          />


          {!isDisabled && (
            <button

              type="button"
              onClick={() => {
                append({id: Math.random()});
              }} className="btn btn-primary">
              Add Historical Performance
            </button>
          )}
        </div>
      </div>
    );

};