import React from 'react'

import '../../../assets/styles/division-selection.css';

import { useHistory } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';
import ChooseLicenseType from '../ChooseLicenceDetails';
import Form from '../../components/FormSteps/Form';
import SupportingDocument from '../../components/FormSteps/SupportingDocument';
import Preview from '../../components/FormSteps/Preview';
import TransactionDetails from '../../components/FormSteps/TransactionDetails';
import ApplicationHeading, { ApplicationHeadingWithActions } from '../../components/ApplicationHeading';
import ProgressStep from '../../components/Steps/ProgressStep';
import SupportingDocumentWithoutDefinedTypes from '../../components/FormSteps/SupportingDocumentWithoutDefinedTypes';
import Payment from '../../components/FormSteps/Payment';
import { useSelector } from 'react-redux';
import { APP_SUBMISSION_CONFIG } from '../../config/application-submission-config';

import { getCurrentTransaction } from '../../redux/actions/current-transaction';
import { appTypes } from '../../config/app-types';

export default function GeneralAdministrationApplicationSubmission(props) {

    const history = useHistory();
    
    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });
    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

    

    let stepCount = 0;
    for (const element of appDetails.steps) {
        if (element.currentStep === history.location.pathname)
            break;
        stepCount++;
    };

    const progress = (stepCount) * 100 / appDetails.totalSteps;

    return (
      <div>
        {/* <ApplicationHeading
          title={appDetails?.application?.applicationDesc}
        ></ApplicationHeading> */}

        <ApplicationHeadingWithActions
         title={appDetails?.application?.applicationDesc}
         actions={[
          {
            title: "Click here to Learn How to Apply",
            className: "btn btn-sm btn-custom-warning btn-no-padding btn-border",
            onClick: () => history.replace(appDetails?.detailsPage)
          }
         ]}/>

         
        <ProgressStep
          steps={[
            "Licence Type",
            "Fill Form",
            "Payment of Fees",
            "Preview",
            "Status",
          ]}
          percent={progress}
          stepSequence={['type_selection', 'form', 'payment', 'preview', 'transaction']}
        ></ProgressStep>
        {/* <img src={'./images/Background FTC.png'}></img> */}
        <div className="bg-image">
          {/* <BrowserRouter>if uncommented then will not work with some router*/}
          <Switch>
           

            <Route exact path="/application/general-administration/type">
              <ChooseLicenseType
                application={appDetails.application}
                appDetails={appDetails}
              ></ChooseLicenseType>
            </Route>

            <Route exact path="/application/general-administration/form">
              <Form
                application={appDetails.application}
                appDetails={appDetails}
              ></Form>
            </Route>


            <Route exact path="/application/general-administration/form-render">
              <Form
                application={appDetails.application}
                appDetails={appDetails}
              ></Form>
            </Route>
            <Route exact path="/application/general-administration/supporting-documents">
              <SupportingDocument application={appDetails.application} />
            </Route>

            <Route exact path="/application/general-administration/payment">
              <Payment application={appDetails.application} />
            </Route>

            <Route exact path="/application/general-administration/preview">
              <Preview
                appDetails={appDetails}
                application={appDetails.application}
              />
            </Route>
            <Route exact path="/application/general-administration/transaction-details">
              <TransactionDetails
                appDetails={appDetails}
                application={appDetails.application}
              />
            </Route>

            <Route path="*">
              <Redirect to="/application/general-administration/type"></Redirect>
            </Route>
          </Switch>
        </div>
        {/* </BrowserRouter> */}
      </div>
    );
}
