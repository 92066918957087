import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { FLISContext } from "../context/FLISContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HorizontalLine from "../components/HorLine";
import { FormHookInput, FormHookSelect, FormHookTextArea } from "../components/FormHook/ReactFormHookFields";
import { fishingAreaLocations } from "../components/DataSource";
import SupportingDocumentWithoutDefinedTypes from "../components/FormSteps/SupportingDocumentWithoutDefinedTypes";
import { alertService } from "../_services";
import { postData } from "../services/service-call";
import { ROUTES } from "../config";
import { useSelector } from "react-redux";

const ViolationNotice = () => {
  const { selectedMap, setSelectedMap } = useContext(FLISContext);


  let appType = useSelector((state) => {
    return state.currentTransaction.appType;
});

const temp_trans_id = useSelector((state) => state.currentTransaction.temp_trans_id);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    reset,
    control,
  } = useForm({
    defaultValues: selectedMap,
    mode: "all",
  });

  const {errors} = formState;

  const history = useHistory();

  return (
    <div className="container-fluid">
      <div className="shadow  bg-white rounded violation-border mb-3 pb-3">
        <div className="sub-form-header-registration sub-form-header-violation">Violation Details</div>
        <HorizontalLine></HorizontalLine>

        <div className="d-flex-wrap flex-row justify-content-around" style={{
          paddingLeft: '9%',
          paddingRight: '9%'
        }}>
          <FormHookInput
            register={register}
            error={errors?.date_of_violation?.message}
            label="Date of Violation"
            regFieldName="date_of_violation"
            type="date"
          />

          <FormHookSelect
            label="Location of Violation"
            register={register}
            regFieldName="location_of_violation"
            options={fishingAreaLocations}
            error={errors?.location_of_violation?.message}
          />
      
        </div>

        <div className="d-flex-wrap flex-column ">

        <FormHookTextArea
            register={register}
            parentClass="individual-field-text-area full-width-flex "
            error={errors?.address?.message}
            label="Address of Violation"
            regFieldName="address"
            placeholder="Address of Violation"
            style={{
              paddingLeft: '20%',
              paddingRight: '20%'
            }}
            cols={1}
            rows={2}
          />

          <FormHookTextArea
            register={register}
            parentClass="individual-field-text-area full-width-flex"
            error={errors?.description?.message}
            label="Description of Violation"
            regFieldName="description"
            placeholder="Description of Violation"
            style={{
              paddingLeft: '20%',
              paddingRight: '20%'
            }}
            cols={1}
            rows={6}
          />


        </div>


        <div>
      <div style={{marginLeft: '20%', marginRight: '20%'}}>
      <SupportingDocumentWithoutDefinedTypes shouldDisplayNavBar={false} shouldHaveNavigator={false} />

      <div className="d-flex ">
        <button
          onClick={async () => {
            const isValid = await trigger();
            if (isValid) {
              const data = getValues();
              console.log(data);
              console.log("data is valid");

              const result = await postData({
                url: `${ROUTES.saveApplication}?app_type=${appType}`,
                body: {
                  ...data,
                  temp_trans_id: temp_trans_id,
                  app_type: appType,
                  launchCase: true,
                },
              });

              if (result.success) {
                setSelectedMap({
                  ...selectedMap,
                  trans_number: result.data.transNumber,
                });
              }

              if (result.statusCode === 200) {
                alertService.success("violation Notice Submitted Successfully", {
                  autoClose: true,
                  keepAfterRouteChange: true,
                });
                history.push('/application-types');
              } else {
                alertService.error(
                  "Error occured while submitting application",
                  { autoClose: true, keepAfterRouteChange: true }
                );
              }
            } else {
              alertService.warn("Document already uploaded", {
                autoClose: true,
                keepAfterRouteChange: true,
              });
            }
          }}
          className="btn btn-success full-width-flex"
          style={{fontWeight: 700}}
        >
          Submit Notice of Violation
        </button>
      </div>
      </div>
        </div>
      </div>

{/* 
      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="sub-form-header">Violation Details</div>
        <HorizontalLine></HorizontalLine>

        <div className="d-flex-wrap flex-row justify-content-start">
          <FormHookInput
            register={register}
            error={errors?.date_of_violation?.message}
            label="Date of Violation"
            regFieldName="date_of_violation"
            type="date"
          />

          <FormHookSelect
            label="Location of Violation"
            register={register}
            regFieldName="location_of_violation"
            options={fishingAreaLocations}
            error={errors?.location_of_violation?.message}
          />
          <Break />

          <FormHookTextArea
            register={register}
            parentClass="individual-field-text-area full-width-flex"
            error={errors?.address?.message}
            label="Address of Violation"
            regFieldName="address"
            placeholder="Address of Violation"
          />

          <FormHookTextArea
            register={register}
            parentClass="individual-field-text-area full-width-flex"
            error={errors?.description?.message}
            label="Description of Violation"
            regFieldName="description"
            placeholder="Description of Violation"
          />

          <Break />
        </div>
      </div>
      <SupportingDocumentWithoutDefinedTypes shouldDisplayNavBar={false} shouldHaveNavigator={false} /> */}

      {/* <div className="d-flex flex-row-reverse">
        <button
          onClick={async () => {
            const isValid = await trigger();
            if (isValid) {
              const data = getValues();
              console.log(data);
              console.log("data is valid");

              const result = await postData({
                url: `${ROUTES.saveApplication}?app_type=${appType}`,
                body: {
                  ...data,
                  temp_trans_id: temp_trans_id,
                  app_type: appType,
                  launchCase: true,
                },
              });

              if (result.success) {
                setSelectedMap({
                  ...selectedMap,
                  trans_number: result.data.transNumber,
                });
              }

              if (result.statusCode === 200) {
                alertService.success("Application submitted successfully", {
                  autoClose: true,
                  keepAfterRouteChange: true,
                });
              } else {
                alertService.error(
                  "Error occured while submitting application",
                  { autoClose: true, keepAfterRouteChange: true }
                );
              }
            } else {
              alertService.warn("Document already uploaded", {
                autoClose: true,
                keepAfterRouteChange: true,
              });
            }
          }}
          className="btn btn-success"
        >
          Submit
        </button>
      </div> */}
    </div>
  );
};

export default ViolationNotice;
