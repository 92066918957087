import React, { forwardRef, useContext, useEffect, useState } from "react";
import { FormHookInput, FormHookInputCurrency, FormHookSelect, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { Break } from "../../components/DataSource";
import { FLISContext } from "../../context/FLISContext";
import { alertService } from "../../_services";
import CurrencyInput from "../../components/FormHook/CurrencyInput";

export const ParticularsofActivity = 
  ({ formState, control, register, onSubmit, shouldRenderInForm=true, isDisabled=false, watch, setValue}) => {
    const { errors, isDirty, isValid } = formState;

  //   function formatCurrency(value) {
  //     value = `${value}`;
  //     var numericValue = value.replace(/[^\d.]/g, "");
  //     return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  

  //   const [displayValue, setDisplayValue] = useState('');

  //   const handleChange = (e) => {
  //     let value = e.target.value;
  //     value = `${value}`;
  //     var parts = value.toString().split(".");

      
  //     e.target.value = parts.join(".");
  //     // onChange(formattedInput); // Send the numeric value to the form
  // };

    const {selectedMap} = useContext(FLISContext);

    const plannedStartDate = watch('particulars_of_activity.planned_start_date');
    const plannedEndDate = watch('particulars_of_activity.planned_end_date');

    useEffect(() => {

      if(plannedEndDate && plannedStartDate){
        // const diff = new Date(plannedEndDate) - new Date(plannedStartDate);
        // let month = diff / 1000 / 60 / 60 / 24 / 30;
        // if(month > 0){
        //   month = month.toFixed(2);
        //   setValue('particulars_of_activity.duration', month);
        // } else {

        // }

        const d2 = new Date(plannedEndDate);
        const d1 = new Date(plannedStartDate);
        if(d1 >= d2){
          alertService.warn("Planned End Date should be after Planned Start Date");
          return;
        }
        let years = d2.getFullYear() - d1.getFullYear();
        let months = d2.getMonth() - d1.getMonth();
        let days = d2.getDate() - d1.getDate();
    
        // Adjust months if day difference is negative
        if (days < 0) {
            months--;
        }

        let diff = years * 12 + months;
        setValue('particulars_of_activity.duration', diff);


        
      }
    }, [ plannedStartDate ,plannedEndDate])


    const fields = (
      <div className="d-flex-wrap flex-row justify-content-start">
        <FormHookInput
          register={register}
          error={errors?.particulars_of_activity?.planned_start_date?.message}
          label="Planned Start Date"
          regFieldName="particulars_of_activity.planned_start_date"
          type="date"
          isDisabled={isDisabled}
        />

        <FormHookInput
          register={register}
          error={errors?.particulars_of_activity?.planned_end_date?.message}
          label="Planned End Date"
          regFieldName="particulars_of_activity.planned_end_date"
          type="date"
          isDisabled={isDisabled}
        />

        <FormHookInput
          register={register}
          error={errors?.particulars_of_activity?.duration?.message}
          label={`Proposed Duration of ${selectedMap?.category} (In Months)`}
          regFieldName="particulars_of_activity.duration"
          placeholder="Duration (In Months)"
          type="number"
          isDisabled={isDisabled}
        />

        <FormHookInput
          register={register}
          error={errors?.particulars_of_activity?.no_of_participants?.message}
          label="No. of Participants"
          regFieldName="particulars_of_activity.no_of_participants"
          placeholder="No. of Participants"
          type="number"
          isDisabled={isDisabled}
        />

        <Break/>

        <FormHookInput
          register={register}
          error={errors?.particulars_of_activity?.estimated_cost?.message}
          label="Estimated Cost (JMD)"
          regFieldName="particulars_of_activity.estimated_cost"
          placeholder="Estimated Cost (JMD)"
          type="number"
          isDisabled={isDisabled}
        />

        <FormHookSelect
          register={register}
          error={errors?.particulars_of_activity?.goj_funded?.message}
          label="GOJ Funded or Sponsored"
          regFieldName="particulars_of_activity.goj_funded"
          options={["Yes", "No"]}
          isDisabled={isDisabled}
        />

        <Break />

        <FormHookTextArea
          register={register}
          error={errors?.particulars_of_activity?.description_of_activity_tobe_undertaken?.message}
          label="Description of activity to be undertaken and expected outcome:"
          regFieldName="particulars_of_activity.description_of_activity_tobe_undertaken"
          isDisabled={isDisabled}
        />

        <FormHookTextArea
          register={register}
          error={errors?.particulars_of_activity?.funding_partners?.message}
          label="Collaborating Institutions (If any):"
          regFieldName="particulars_of_activity.collaborating_institutions"
          isDisabled={isDisabled}
          isRequired={false}
        />

        <FormHookTextArea
          register={register}
          error={errors?.particulars_of_activity?.funding_partners?.message}
          label="Funding Partners (If any):"
          regFieldName="particulars_of_activity.funding_partners"
          isDisabled={isDisabled}
          isRequired={false}
        />
      </div>
    );

    return (
      <div className="form-container">
        {
          shouldRenderInForm && <fieldset>
          <form onSubmit={onSubmit}>
          <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="d-flex flex-row form-heading">
          <div
            style={{ marginRight: "10px" }}
          >{`${"Particulars of Activity"}`}</div>
        </div>
            {fields}
            </div>
          </form>
        </fieldset>
        }

        {
          !shouldRenderInForm && <div>{fields}</div>
        }
      </div>
    );
  };
