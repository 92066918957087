import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4'; // or 'react-ga' for Universal Analytics
// import reportWebVitals from './reportWebVitals';


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register("./service-worker.js").then(registration => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     }).catch(error => {
//       console.error('Service Worker registration failed:', error);
//     });
//   });
// }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
      navigator.serviceWorker
          .register('/service-worker.js?v=7')  // Path to your service worker file
          .then(registration => {
              console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch(err => {
              console.error('Service Worker registration failed:', err);
          });
  });
}

const isProdEnv = process.env.REACT_APP_ENV === 'prod';
console.log(process.env.REACT_APP_ENV);

// Conditionally load the chatbot script
if (isProdEnv) {
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/65ca5b8a0ff6374032cc2179/1hmf7b50i';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();


  ReactGA.initialize('G-4D5JSN26SV'); 

}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals((msg) => {
//   console.log('this is web vitals');
//   console.log(msg);
// });