import React, { useEffect } from "react";
import {
  FormHookInput,
  FormHookRadioInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { useFieldArray } from "react-hook-form";
export const LandingLogsAudit = ({
  formState,
  control,
  register,
  onSubmit,
  setValue,
  values,
  watch,
  disabled,
  showHeading = true,
}) => {
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "landing_logs_audit",
  });

  const landingLogsAudit = watch("landing_logs_audit");

  useEffect(() => {
    if (!landingLogsAudit?.length) {
      setValue("landing_logs_audit", [{ id: Math.random() }]);
    }
  }, [landingLogsAudit]);

  return (
    <div className={`p-3 mb-3 ${disabled ? "non-functional-element" : ""}`}>
      <div className="d-flex form-heading">
        {showHeading && <div style={{ marginRight: "10px" }}>{`${""}`}</div>}
      </div>

      <div className="d-flex-wrap justify-content-left align-items-center">
        <table
          className={`flex-grow-1 table-responsive table table-bordered table-sm`}
        >
          <thead>
            <tr>
              <th rowSpan={2}>Add</th>

              <th rowSpan={2}>Remove</th>

              <th rowSpan={2}>
                Date <br></br> DD/MM/YYY
              </th>

              <th rowSpan={2}>Number of Pots Hauled</th>

              <th rowSpan={2}>Average Soak Time (Hrs/days)</th>

              <th rowSpan={2}>Crew Size</th>

              <th rowSpan={2}>
                Area Fished <br></br> (Name / Description)
              </th>

              <th rowSpan={2}>Latitude</th>

              <th rowSpan={2}>Longitude</th>

              <th rowSpan={2}>Depth Fished (m, ft)</th>

              <th rowSpan={2}>Total Catch Weight (Kg/Lbs)</th>

              <th colSpan={3}>Process Level Landed(Kg/Lbs)</th>
            </tr>
            <tr>
              <th rowSpan={1}>Whole</th>

              <th rowSpan={1}>Tail</th>

              <th rowSpan={1}>Head Meat</th>
            </tr>
          </thead>

          <tbody>
            {fields.map((field, groupIndex) => {
              return (
                <tr key={field.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        append({ id: Math.random() });
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </button>
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        remove(groupIndex);
                      }}
                      className="btn btn-sm btn-danger"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.landing_log_date?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.landing_log_date`}
                      type="date"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.number_of_pots_hauled?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.number_of_pots_hauled`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.average_soak_time?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.average_soak_time`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]?.crew_size
                          ?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.crew_size`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]?.areas_fished
                          ?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.areas_fished`}
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]?.latitude
                          ?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.latitude`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]?.longitude
                          ?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.longitude`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]?.depth_fished
                          ?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.depth_fished`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.total_catch_weight?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.total_catch_weight`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.process_level_landed_whole?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.process_level_landed_whole`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.process_level_landed_tail?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.process_level_landed_tail`}
                      type="number"
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro"
                      register={register}
                      hideLabel={true}
                      error={
                        errors?.landing_logs_audit?.[groupIndex]
                          ?.process_level_landed_headmeat?.message
                      }
                      regFieldName={`landing_logs_audit.${groupIndex}.process_level_landed_headmeat`}
                      type="number"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
