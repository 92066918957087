import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { getUniqueTransNumber } from "../utils/utils";
import store from "../redux/store";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";
import ViewFisheriesAct from "./ViewFisheriesAct";
function AquacultureFisherDetails(props) {
  const history = useHistory();

  const { clearSelectedMap } = useContext(FLISContext);

  return (
    <div>
      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }}>
          Application for Aquaculture Fish Farmer Licence
        </div>

          <div className="d-flex flex-spacing-10">
            <button
              type="button"
              className="btn btn-custom-warning large-button"
              onClick={async () => {
                const details = {
                  appType: "APPLICATION_FOR_AQUACULTURE_FISHER",
                };

                const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                let transNumber = "";
                if (appDetails.isLoginRequired) {
                  if (!localStorage.getItem("token")) {
                    history.push("/login");
                    return;
                  }

                  transNumber = await getUniqueTransNumber(details.appType);
                } else {
                  transNumber = await getUniqueTransNumber(details.appType);
                }

                store.dispatch({
                  type: SET_CURRENT_TRANSACTION,
                  temp_trans_id: transNumber,
                  appType: details.appType,
                  transaction: {},
                  submitted_transaction: null,
                });
                clearSelectedMap();
                if (!!details)
                  history.push(`${appDetails.steps[0].currentStep}`);
              }}
            >
              Apply now for Aquaculture Fish Farmer Licence
            </button>

            <ViewFisheriesAct/>
          </div>
      </div>

      <div
        style={{
          padding: "40px 14%",
          color: "black",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <h5>
          To construct and operate an aquaculture fish farm, you must have a
          licence if you are the owner and/or operator. After submitting your
          application, an aquaculture extension officer will make contact with
          you to schedule a site visit and you will be guided on how to
          construct your facility.{" "}
        </h5>

        <br />

        <div className="d-flex flex-spacing-10">
          <button
            type="button"
            className="btn btn-custom-warning large-button"
            onClick={async () => {
              const details = {
                appType: "APPLICATION_FOR_AQUACULTURE_FISHER",
              };

              const appDetails = APP_SUBMISSION_CONFIG[details.appType];
              let transNumber = "";
              if (appDetails.isLoginRequired) {
                if (!localStorage.getItem("token")) {
                  history.push("/login");
                  return;
                }

                transNumber = await getUniqueTransNumber(details.appType);
              } else {
                transNumber = await getUniqueTransNumber(details.appType);
              }

              store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: details.appType,
                transaction: {},
                submitted_transaction: null,
              });
              clearSelectedMap();
              if (!!details) history.push(`${appDetails.steps[0].currentStep}`);
            }}
          >
            Apply now for Aquaculture Fish Farmer Licence
          </button>
        </div>
      </div>
    </div>
  );
}

export default AquacultureFisherDetails;
