
export const FISHER_LICENCE_AQUA = "Fisher Licence (Aqua)";
export const FISHING_VESSEL_LICENCE_AQUA = "Fishing Vessel Licence (Aqua)";
export const AQUACULTURE_FACILITY_LICENCE = "Aquaculture Facility Licence";
export const FRESH_WATER = "Fresh Water";
export const MARICULTURE = "Mariculture";
export const ORNAMENTAL_CULTURE = "Ornamental Culture";

export const typeOfLicence = [
    FRESH_WATER,
    MARICULTURE,
    ORNAMENTAL_CULTURE
];


const optionsForAll = [
    {
        value: "Hatchery",
        isInput: false
    },
    {
        value:   "Seedstock",
        isInput: false
    },
    {
        value: "Grow-out",
        isInput: false,
    },
    {
        value:   "Nursery",
        isInput: false
    },
    {
        value: "Breeding",
        isInput: false,
    },
    {
        value: "Holding",
        isInput: false,
    },
    {
        value: "Other",
        isInput: true
    }
];
export const typeofLicenceSubCategories = [
    {
        key: 'fresh_water_categories',
        title: FRESH_WATER,
        categories: optionsForAll
    },
    {
        key: 'mariculture_categories',
        title: MARICULTURE,
        categories: optionsForAll
    },
    {
        key: 'ornamental_categories',
        title: ORNAMENTAL_CULTURE,
        categories: optionsForAll
    }
]

// export const fisherAquaType = ["Fresh Water", "Mariculture", "Ornamental Culture"];

export const fisherAquaType = ["Commercial", "Recreational"];

export const fisherAquaTypeOfApplicant = ["Individual", "Company"];

export const fisherAquaApplicantRoleInFisheries = ["Owner", "Operator", "Owner/Operator", "Facility Worker"];


// export const typeOfSpecies = ["Aquaculture", "Mariculture", "Ornamental Culture"];
export const typeOfSpecies = [
    "Food Fish",
    "Crustacean",
    "Ornamental",
    "Oyster",
    "Aquatic Plant"
]


export const gearTypes = ["Scuba", "Hoocha", "Long Line", "Seine Net", "Dip Net", "Cast Net"];
// export const gearTypes = ["Scuba", "Hoocha", "Long Line", "Beach Seine", "Dip Net", "Cast Net"];


// export const applicantRoleInFisheries = [
//     "Aqua / Mariculture Facility Owner",
//     "Aqua/ Mariculture Facility Operator Manager",
//     "Aqua/ Mariculture Facility Worker",
//     "Ornamental Culture Operator"
//   ];

export const applicantRoleInFisheries =  ["Owner", "Operator", "Owner/Operator", "Facility Worker"];


