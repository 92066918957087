import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { closeIcon } from '../../../assets/icons/icons_svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  
  return (
    <div>
     
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={props.fullWidth}
        onClose={props.handleCloseSecondary}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={props.style ?? {}}
      >
        <DialogTitle id="alert-dialog-slide-title" style={props.titleStyle} >
          <div className='d-flex-wrap flex-row justify-content-between ml-4 mr-4'>
          <div>{props.title}</div>
          <div style={{cursor: 'pointer'}} onClick={() => {
            props?.handleCloseSecondary();
          }}>
            {closeIcon()}
          </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: 'black'}} id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} style={props.primaryActionStyle} color="primary">
            {props.primaryActionName}
          </Button>

          {
            props.secondaryActionName && <Button onClick={props.handleCloseSecondary} color="">
            {props.secondaryActionName}
          </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}
