import React from 'react';
import {useHistory} from 'react-router-dom';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { ProgressBar, Step } from "react-step-progress-bar";
import PaymentIcon from '@material-ui/icons/Payment';

function TransactionOverview(props) {

    const history = useHistory();
    return (
        <div>
         <div className='d-flex m-2  flex-spacing-10'>
            <div>   <img src="./images/single_fish.png" width={50} height={50} alt="fish"></img></div>

            <div  className='flex-grow-1 ml-2'  style={{color: '#00b7fd', fontWeight: '600', fontSize: '18px'}} >
                <div> Application for Licence </div>
                <div>TRN-102020</div>
            </div>

            <div>
                  
                </div>
                <div>
                    <button className='btn btn-warning'>
                        <span className="material-icons-outlined">
                            visibility
                        </span>
                    </button>
                </div>

                <div>
                    <button className='btn btn-warning'>
                        <span className="material-icons-outlined">
                            file_download
                        </span>
                    </button>
                </div>
         </div>


         <div>
            <img src='./images/overview.png' width={'100%'}></img>
         </div>


         {/* <ProgressBar
                percent={props.percent}
                filledBackground="linear-gradient(to right, #fefb72, #f0bb31)">
                <Step transition="scale">
                    {({ accomplished }) => (
                        <div><AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon></div>
                        // <div></div>
                        
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ListAltIcon className="svg_icons"></ListAltIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <InsertDriveFileIcon className="svg_icons"></InsertDriveFileIcon>
                    )}
                </Step>

                <Step transition="scale">
                    {({ accomplished }) => (
                       <PaymentIcon className="svg_icons"></PaymentIcon>
                    )}
                </Step>


                <Step transition="scale">
                    {({ accomplished }) => (
                       <VisibilityIcon className="svg_icons"></VisibilityIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ReceiptIcon className="svg_icons"></ReceiptIcon>
                    )}
                </Step>
            </ProgressBar> */}
        </div>
    );
}

export default TransactionOverview;