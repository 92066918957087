import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import SimpleNavigator from "../components/SimpleNavigator";

import { useHistory } from "react-router-dom";

import "../../assets/styles/common-styles.css";
import axios from "axios";
import { getData } from "../services/service-call";
import { getGuidelines } from "../redux/actions/guidelines";
import { useDispatch, useSelector } from "react-redux";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { alertService } from "../_services/alert.service";
import LicenseTypeSelection from "./Licence/LicenceTypeSelection";
import PermitTypeSelection from "./PermitLicence/PermitTypeSelection";
import AuthorizationTypeSelection from "./AuthorizationLicence/AuthorizationTypeSelection";
import QuotaFishingTypeSelection from "./QuotaFishing/QuotaFishingTypeSelection";
import DeclarationTypeSelection from "./Declaration/DeclarationTypeSelection";
import FishingVesselLicenceTypeSelection from "./FishingVesselLicence/FishingVesselLicenceTypeSelection";
import AquacultureTypeSelection from "./AquacultureFisher/AquaFisherTypeSelection";
import AquaFishingVesselTypeSelection from "./AquacultureFishingVessel/AquaFishingVesselTypeSelection";
import AquaFacilityTypeSelection from "./AquacultureFacility/AquaFacilityTypeSelection";
import LandingLogTypeSelection from "./LandingLogs/LandingLogTypeSelection";
import GeneralAdministrationTypeSelection from "./GeneralAdministration/GeneralAdminTypeSelection";
import FisherWorkerIdTypeSelection from "./FisherWorkerID/FisherWorkerIdTypeSelection";

function ChooseLicenseType(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  let appType = useSelector((state) => {
    return state.currentTransaction.appType;
  });

  let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

  let routingDetails = appDetails.steps.filter((step) => {
    if (step.currentStep === history.location.pathname) {
      return true;
    }
    return false;
  })[0];

  const nextAction = () => {
    history.push(routingDetails.next);
  };

  const previousAction = () => {
    history.push(routingDetails.previous);
  };

  useEffect(() => {
    // dispatch(getGuidelines(props.application.type));
    // alertService.info("Please choose license Category", {
    //   autoClose: true,
    //   keepAfterRouteChange: false,
    // });
  }, []);

  let container;

  if(appType === 'APPLICATION_FOR_LICENCE') {
    container =   <LicenseTypeSelection next={nextAction}></LicenseTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_PERMIT'){
    container = <PermitTypeSelection next={nextAction}></PermitTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_AUTHORIZATION'){
    container = <AuthorizationTypeSelection next={nextAction}></AuthorizationTypeSelection>;
  } else if(appType === 'APPLICATION_QUOTA_FISHING'){
    container = <QuotaFishingTypeSelection next={nextAction}></QuotaFishingTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_DECLARATION'){
    container = <DeclarationTypeSelection next={nextAction}></DeclarationTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_FISHING_VESSEL') {
    container = <FishingVesselLicenceTypeSelection next={nextAction}></FishingVesselLicenceTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHER') {
    container = <AquacultureTypeSelection next={nextAction}></AquacultureTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL') {
    container = <AquaFishingVesselTypeSelection next={nextAction}></AquaFishingVesselTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_AQUACULTURE_FACILITY') {
    container = <AquaFacilityTypeSelection next={nextAction}></AquaFacilityTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_LANDING_CATCH_DECLARATION') {
    container = <LandingLogTypeSelection next={nextAction}></LandingLogTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_GENERAL_ADMINISTRATION') {
    container = <GeneralAdministrationTypeSelection next={nextAction}></GeneralAdministrationTypeSelection>;
  } else if(appType === 'APPLICATION_FOR_FISHER_WORKER_ID') {
    container = <FisherWorkerIdTypeSelection next={nextAction}></FisherWorkerIdTypeSelection>;
  }

  return (
    <div>
      <div className="shadow-sm  m-3 bg-light rounded simple-border">
        {/* <div className="head-text">Choose License Category</div> */}

        <div>
          {container}
        </div>
      </div>
      {/* <SimpleNavigator prevValue="Cancel" next="/ftc/form" nextAction={nextAction} previousAction={previousAction} previous="/home"></SimpleNavigator> */}
    </div>
  );
}

export default ChooseLicenseType;
