import { ROUTES } from "../../config";

import { getData } from "../../services/service-call";

 export function requestGetTransactions(){
    return getData({
        url : `${ROUTES.getTransactions}`
    });
 }

 export function requestGetTransaction(trans_id){
    return getData({
        url : ROUTES.getTransaction + trans_id
    });
 }
