import React, { useEffect, useMemo } from "react";

    import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

export default function Map(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXVh0yXWSANaRjCTunS--vHf3mMQWToWU",
  });
  
  const center = useMemo(() => ({ lat: 18.52043, lng: -77.856743 }), []);

  const markers = [
    { lat: 18.2204, lng: -77.167 },
    { lat: 18.2204, lng: -77.3446 },
    { lat: 18.2204, lng: -77.7769 },
  ];

  const onLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  return (
    <div className="GoogleApp">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          {markers.map(({ lat, lng }) => (
            <Marker position={{ lat, lng }} />
          ))}
        </GoogleMap>
      )}
    </div>
  );
}
