import React, { useContext, useEffect , forwardRef, useState} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { FLISContext } from "../../context/FLISContext";
import { FormHookCheckbox, FormHookInput } from "../../components/FormHook/ReactFormHookFields";
import { divideArray } from "../../components/DataSource";


export const GearInformation = ({source, formState,control, register, onSubmit, setValue,values,watch, disabled, breakInto = 5, innerClass = "justify-content-between" }) => {


    const {errors} = formState;

    const {selectedMap} = useContext(FLISContext);

    useEffect(() => {
      if(selectedMap.gear_information){
        setValue('gear_information', selectedMap.gear_information);
      }
    }, [selectedMap.gear_information]);


    const gearInfo= watch('gear_information', {deep: true});

    const dividedSource = divideArray(source, breakInto);

    const singleColumnTable = (innerSource) =>   <table className="m-1 max-250 table  table-bordered table-sm">
    <thead>
      <tr>
        <th>Gear Type</th>
        <th>No. of Gear</th>
      </tr>
    </thead>
    <tbody>
      {innerSource.map((gear) => {
        return (
          <tr key={gear}>
            <td>
              <label key={gear} className=" checkbox-container">
                {gear}
                <input
                  disabled={false}
                  type="checkbox"
                  {...register(
                    `gear_information.${gear}.isChecked`,
                    {
                      required: false,
                    }
                  )}
                  // value={true}
                  // onChange={(event) => {
                  //   setValue(`gear_information.${gear}.isChecked`, event.target.checked ? gear: null )
                  // }}
                />
                <span className="checkbox-checkmark"></span>
              </label>
            </td>
            <td>
              <input
                disabled={!gearInfo?.[gear]?.isChecked}
                type={'number'}
                className={'form-control max-300'}
                {...register(`gear_information.${gear}.number_of_gears`, {
                  required: gearInfo?.[gear]?.isChecked ? "This field is required" : false,
                })}
              />

              <span className="error-message">{ errors?.gear_information?.[gear]
                    ?.number_of_gears?.message}</span>
            </td>
          </tr>
        );
      })}

    </tbody>
  </table>;

    return (
      <div className={`p-3 mb-3 ${disabled ? "non-functional-element" : ""} `}>

        {/* {JSON.stringify(gearInfo)} */}
        <div className={`d-flex-wrap flex-row ${innerClass}`}>
          {dividedSource?.map((chunckedSource) => {
            return singleColumnTable(chunckedSource);
          })}
        </div>
      </div>
    );
};
