import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SimpleNavigatorAnother from '../SimpleNavigatorAnother';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_SUBMISSION_CONFIG } from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
import { ROUTES } from "../../config/index";
import {  postData } from "../../services/service-call";
import ReactGA from 'react-ga4';

import { useReactToPrint } from 'react-to-print';
import { FLISContext } from '../../context/FLISContext';
import ApplicationForLicence from '../../containers/Licence/ApplicationForLicence';
import ApplicationForPermit from '../../containers/PermitLicence/ApplicationForPermit';
import ApplicationForAuthorization from '../../containers/AuthorizationLicence/ApplicationForAuthorization';
import ApplicationForQuotaFishing from '../../containers/QuotaFishing/ApplicationForQuotaFishing';
import ApplicationForFishingVessel from '../../containers/FishingVesselLicence/ApplicationForFishingVessel';
import ApplicationForDeclaration from '../../containers/Declaration/ApplicationForDeclaration';
import ApplicationForAquaFishingVessel from '../../containers/AquacultureFishingVessel/ApplicationForAquaFishingVessel';
import ApplicationForAquaculture from '../../containers/AquacultureFisher/ApplicationForAquaFisher';
import ApplicationForAquaFacility from '../../containers/AquacultureFacility/ApplicationForAquaFacility';
import ApplicationForLandingLogs from '../../containers/LandingLogs/ApplicationForLandingLogs';
import ApplicationForGeneralAdministration from '../../containers/GeneralAdministration/ApplicationForGeneralAdministration';
import ApplicationForFisherWorkerId from '../../containers/FisherWorkerID/ApplicationForFisherWorkerId';
import SupportingDocumentsPreview from '../SupportingDocumentsPreview';
import PreviewPaymentDetails from './PreviewPaymentDetails';
import { dataComplianceTerms, getNameOfApplicant, savedToDraft } from '../../utils/utils';
import AlertDialogSlide from '../Modal/Modal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { CREDIT_CARD } from '../../config/fees';
import { debounce } from 'lodash';

function Preview(props) {


    const componentRef = useRef();

    const formRef = useRef();

    const history = useHistory();
    const temp_trans_id = useSelector((state) => state.currentTransaction.temp_trans_id);


    let currentTransaction = useSelector((state) => {
        return state.currentTransaction;
    })

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    const {selectedMap, setSelectedMap} = useContext(FLISContext);
    

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};

    let routingDetails = appDetails.steps.filter((step) => {
        if (step.currentStep === history.location.pathname) {
            return true;
        }
        return false;
    })[0];

    const [showCompliance, setShowCompliance] = useState(false);

    const ComplianceForm = () => {

      
      
        const [success, setSuccess] = useState(false);
      
        return (
          <div style={{ fontSize: "14px" }}>
            {!success && (
              <>
                <div>
                  I have read and understood
                  this Customer Compliance Statement: Data Protection ACT – (DPA) 2020
                  provided by the National Fisheries Authority (NFA) of Jamaica. I
                  acknowledge and agree to the following:
                </div>
      
                <br></br>
      
                <ul>
                  {dataComplianceTerms?.map((term) => {
                    return <li style={{padding: '3px'}} key={term}>{term}</li>;
                  })}
                </ul>
      
               
                <div>
                  <br></br>
                  <ul>
                    <li>
                      Click{" "}
                      <Link
                        to="/forms/NFA Data Protection Policy.docx"
                        target="_blank"
                        download
                      >
                        {" "}
                        here{" "}
                      </Link>
                      for a link to our full Privacy Policy
                    </li>
                    <li>
                      Submit any data protection concerns to dataprotection@nfa.gov.jm
                    </li>
                  </ul>
                </div>
      
              </>
            )}
      
            
          </div>
        );
      }

    const nextAction = async () => {
      let result = {};

      console.log({
        ...selectedMap,
        temp_trans_id: temp_trans_id,
        app_type: appType,
        launchCase: true,
      });
      result = await postData({
        url: `${ROUTES.saveApplication}?app_type=${appType}`,
        body: {
          ...selectedMap,
          temp_trans_id: temp_trans_id,
          app_type: appType,
          launchCase: true,
        },
      });

      setSelectedMap({
        ...selectedMap,
        trans_number: result?.data?.transNumber,
        api_response: result?.success,
      });

      if (result.statusCode === 200) {
        alertService.success("Application submitted successfully", {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      } else {
        alertService.error("Error occured while submitting application", {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }

      // dispatch(saveCurrentTransaction(result));
      history.replace({ pathname: routingDetails.next });

      // Tracking a form submission event
      ReactGA.event({
        category: "Application Form",
        action: "Submit",
        label: "Application Submitted",
      });
    }

    const debouncedNextAction = useCallback(debounce(nextAction, 1000), []);

    const previousAction = () => {
        history.push(routingDetails.previous);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current   
    });

    let form;

    let properties = {
        next: routingDetails.next,
        previous: routingDetails.previous,
        isPreview: true,
        reference: formRef
    }


    const validateForm = async () => {
      const form = formRef.current;
      if (!form) return false;

      const isValid = await form.trigger();
      return isValid;

      // Check if all required fields are filled
      // const isFormValid = Array.from(form.elements).every(element => {
      //   return !element.required || !element.disabled || !!element.value;
      // });

      // // If all required fields are filled, submit the form
      // if (isFormValid) {
      //   form.dispatchEvent(new Event('submit', { cancelable: true }));
      //   return true;
      // } else {
      //   // Handle validation error
      //   console.log("Form is not valid");
      //   return false;
      // }
    };

    if (props.application.type === 'APPLICATION_FOR_LICENCE'){
        form = <ApplicationForLicence {...properties}></ApplicationForLicence>;
    } else if(props.application.type === 'APPLICATION_FOR_PERMIT') {
        form = <ApplicationForPermit {...properties}></ApplicationForPermit>;
    } else if(props.application.type === 'APPLICATION_FOR_AUTHORIZATION') {
        form = <ApplicationForAuthorization {...properties}></ApplicationForAuthorization>;
    } else if(props.application.type === 'APPLICATION_QUOTA_FISHING') {
        form = <ApplicationForQuotaFishing {...properties}></ApplicationForQuotaFishing>;
    } else if(props.application.type === 'APPLICATION_FOR_DECLARATION') {
        form = <ApplicationForDeclaration {...properties}></ApplicationForDeclaration>;
    } else if(props.application.type === 'APPLICATION_FOR_FISHING_VESSEL') {
        form = <ApplicationForFishingVessel {...properties}></ApplicationForFishingVessel>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FISHER') {
        form = <ApplicationForAquaculture {...properties}></ApplicationForAquaculture>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL') {
        form = <ApplicationForAquaFishingVessel {...properties}></ApplicationForAquaFishingVessel>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FACILITY') {
        form = <ApplicationForAquaFacility {...properties}></ApplicationForAquaFacility>;
    } else if(props.application.type === 'APPLICATION_FOR_LANDING_CATCH_DECLARATION') {
        form = <ApplicationForLandingLogs {...properties}></ApplicationForLandingLogs>;
    } else if(props.application.type === 'APPLICATION_FOR_GENERAL_ADMINISTRATION') {
        form = <ApplicationForGeneralAdministration {...properties}></ApplicationForGeneralAdministration>;
    } else if(props.application.type === 'APPLICATION_FOR_FISHER_WORKER_ID') {
        form = <ApplicationForFisherWorkerId {...properties}></ApplicationForFisherWorkerId>;
    }

    useEffect(() => {
        alertService.info('Please verify the details and submit the application', { autoClose:true, keepAfterRouteChange: false });

        if(selectedMap?.payment_details?.payment_method === CREDIT_CARD) {
          debouncedNextAction();
        }
    }, []);

    if(currentTransaction.submitted_transaction?.transaction_number){
        history.push('/home');
    }


    const saveAndContinueLater = async () => {
      const result = await postData({
        url: `${ROUTES.saveApplication}?app_type=${appType}`,
        body: {
          ...selectedMap,
          temp_trans_id: temp_trans_id,
          app_type: appType,
          launchCase: false,
        },
      });

      if (result.statusCode === 200) {
        alertService.success(savedToDraft, {
          autoClose: true,
          keepAfterRouteChange: true,
        });

        history.replace("/application-types");
      } else {
        alertService.error("Error occured while saving application", {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      }
    };
  
 
    return (
      <div>

        <AlertDialogSlide
          fullWidth={true}
          open={showCompliance}
          title={
            "Customer Acknowledgement Statement of Data Protection Standards"
          }
          titleStyle={{
            fontWeight: 600,
            fontSize: "24px",
            textAlign: "center",
          }}
          handleClose={() => {
            debouncedNextAction();
          }}
          primaryActionStyle={{backgroundColor: '#336699', color: 'white'}}
          primaryActionName="I Consent & Submit"
          secondaryActionName={"I don't Consent"}
          handleCloseSecondary={() => {
            saveAndContinueLater();
            setShowCompliance(false);
          }}
          style={{
            width: "800px",
            maxWidth: "100%",
            maxHeight: "50vh",
            margin: "auto",
          }}
          content={<ComplianceForm />}
        />

        <div>
          <div className="shadow-sm p-3 m-3 bg-light rounded">
            <SimpleNavigatorAnother
              parentClassName="pl-3 pr-3 mb-4"
              nextAction={useCallback(debounce(async () => {

                const isValid = await validateForm();

                if(isValid) {
                  setShowCompliance(true)
                }else {
                  console.log('trigger not validated');
                }
              }, 1000), [])}
              nextBtnClass="btn btn-success apply-border-button"
              prevBtnClass="btn btn-custom-warning apply-border-button"
              nextValue="Submit your Application to NFA"
              previousAction={previousAction}
              next
              previous
              middle
              middleAction={handlePrint}
              middleValue="Download a Copy of your Submission"
            ></SimpleNavigatorAnother>

            <div ref={componentRef} style={{ paddingTop: "2px" }}>
              <div>{form}</div>
              <SupportingDocumentsPreview />

              {/* add payment details here only so it gets included in print form */}
              <PreviewPaymentDetails />
            </div>

            <SimpleNavigatorAnother
              parentClassName="pl-3 pr-3 mb-4"
              nextAction={useCallback(debounce(async () => {

                const isValid = await validateForm();

                if(isValid) {
                  setShowCompliance(true)
                }else {
                  console.log('trigger not validated');
                }
                
              }, 1000), [])}
              nextBtnClass="btn btn-success apply-border-button"
              prevBtnClass="btn btn-custom-warning apply-border-button"
              nextValue="Submit your Application to NFA"
              previousAction={previousAction}
              next
              previous
              middle
              middleAction={handlePrint}
              middleValue="Download a Copy of your Submission"
            ></SimpleNavigatorAnother>
          </div>
        </div>
      </div>
    );
}

export default Preview;