import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { getUniqueTransNumber } from "../utils/utils";
import store from "../redux/store";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";
import ViewFisheriesAct from "./ViewFisheriesAct";
function AuthorizationDetails(props) {
  const history = useHistory();

  const { clearSelectedMap } = useContext(FLISContext);

  return (
    <div>
      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }}>
          Application for Authorizations
        </div>


        <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = { appType: 'APPLICATION_FOR_AUTHORIZATION' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }

                            transNumber = await getUniqueTransNumber(details.appType);

                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });
                        clearSelectedMap();
                        if (!!details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Apply now for Authorizations
                    </button>

                    <ViewFisheriesAct/>

                    
                </div> 
      </div>

      <div
        style={{
          padding: "40px 14%",
          color: "black",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div>
          Any person or entity that intends to engage in or use a vessel to
          engage in any educational or scientific research or survey operations
          relating to fisheries, aquaculture or any related activity, must get
          an authorization from the Authority.
        </div>

        <br />

        <div>
          For the research or survey to be done, the individuals who will be
          carrying out the activity along with any vessel that will be used will
          all require authorizations. In completing this application, you will
          be required to submit a detailed proposal describing the activity to
          be undertaken along with the requisite identification information.
        </div>

        <br />
        <div className="d-flex flex-spacing-10">
          <button
            type="button"
            className="btn btn-custom-warning large-button"
            onClick={async () => {
              const details = { appType: "APPLICATION_FOR_AUTHORIZATION" };

              const appDetails = APP_SUBMISSION_CONFIG[details.appType];
              let transNumber = "";
              if (appDetails.isLoginRequired) {
                if (!localStorage.getItem("token")) {
                  history.push("/login");
                  return;
                }

                transNumber = await getUniqueTransNumber(details.appType);
              } else {
                transNumber = await getUniqueTransNumber(details.appType);
              }

              store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: details.appType,
                transaction: {},
                submitted_transaction: null,
              });
              clearSelectedMap();
              if (!!details) history.push(`${appDetails.steps[0].currentStep}`);
            }}
          >
            Apply now for Authorizations
          </button>
        </div>
      </div>
    </div>
  );
}

export default AuthorizationDetails;
