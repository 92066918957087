import { SET_GUIDELINES } from '../actions/guidelines';


const intitialState = {
    guidelines : []
}

export default function(state = intitialState, action){
    switch(action.type){
        case SET_GUIDELINES:
            return {
                ...state,
                guidelines : action.guidelines
            };
        default:
            return state;
    }
}