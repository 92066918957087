import React from 'react';

import "react-step-progress-bar/styles.css";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { ProgressBar, Step } from "react-step-progress-bar";
import PaymentIcon from '@material-ui/icons/Payment';


const defaultSteps = [
    'License Type',
    'Fill Form',
    'Upload Documents',
    'Payment of Fees',
    'Preview',
    'Status'
]

const stepConfig = {
  type_selection: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <div>
            <AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon>
          </div>
        )}
      </Step>
    ),
  },
  form: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <ListAltIcon className="svg_icons"></ListAltIcon>
        )}
      </Step>
    ),
  },

  document: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <InsertDriveFileIcon className="svg_icons"></InsertDriveFileIcon>
        )}
      </Step>
    ),
  },
  payment: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <PaymentIcon className="svg_icons"></PaymentIcon>
        )}
      </Step>
    ),
  },
  preview: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <VisibilityIcon className="svg_icons"></VisibilityIcon>
        )}
      </Step>
    ),
  },
  transaction: {
    icon: (
      <Step transition="scale">
        {({ accomplished }) => (
          <ReceiptIcon className="svg_icons"></ReceiptIcon>
        )}
      </Step>
    ),
  },
};

function ProgressStep({steps = defaultSteps, stepSequence,  ...props }) {

    return (
      <div
        className="pt-4 pb-0 pl-5 pr-5"
        style={{ overflow: "hidden", textAlign: "center !important" }}
      >
        <ProgressBar
          percent={props.percent}
          filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        >
          {stepSequence?.map((seq) => stepConfig[seq].icon)}
          {/* <Step transition="scale">
                    {({ accomplished }) => (
                        <div><AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon></div>
                        
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ListAltIcon className="svg_icons"></ListAltIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <InsertDriveFileIcon className="svg_icons"></InsertDriveFileIcon>
                    )}
                </Step>

                <Step transition="scale">
                    {({ accomplished }) => (
                       <PaymentIcon className="svg_icons"></PaymentIcon>
                    )}
                </Step>


                <Step transition="scale">
                    {({ accomplished }) => (
                       <VisibilityIcon className="svg_icons"></VisibilityIcon>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <ReceiptIcon className="svg_icons"></ReceiptIcon>
                    )}
                </Step> */}
        </ProgressBar>

        <div className="mb-3"></div>

        <ProgressBar
          percent={100}
          filledBackground="linear-gradient(to right, #fff, #fff)">
          {steps?.map((step, index) => (
            <Step transition="scale">
              {({ accomplished }) => <span style={{
                paddingLeft: step.length > 15 && index === 0 ? (
                  step.length > 25 ? '60px' :  '30px'
                ) : 0
              }}>{step}</span>}
            </Step>
          ))}
          {/* <Step transition="scale">
                    {({ accomplished }) => (
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{steps[0]}</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>{steps[1]}</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>{steps[2]}</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>{steps[3]}</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span>{steps[4]}</span>
                    )}
                </Step>
                <Step transition="scale">
                    {({ accomplished }) => (
                       <span style={{textAlign:'center' }}>{steps[5]}</span>
                    )}
                </Step> */}
        </ProgressBar>
      </div>
    );
}

export default ProgressStep;