import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../redux/actions/transactions";
import { APP_SUBMISSION_CONFIG, desc } from "../config/application-submission-config";
import { getApplicationSpecificDetails } from "../utils/utils";
import { getData } from "../services/service-call";
import { ROUTES } from "../config";
import { FLISContext } from "../context/FLISContext";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AllDraftApplications = () => {

  const dispatch = useDispatch();

  const history = useHistory();

  const mapDataAccordingToType = (transaction) => {


    const map = {
      'APPLICATION_FOR_LICENCE': (transaction) => transaction?.fisher_application_detail,
      'APPLICATION_FOR_FISHING_VESSEL': (transaction) => transaction?.fishing_vessel_application_detail,
      'APPLICATION_FOR_PERMIT': (transaction) => transaction?.permit_application_detail,
      'APPLICATION_FOR_AQUACULTURE_FISHER': (transaction) => transaction?.aquaculture_fisher_application_detail,
      'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL': (transaction) => transaction?.aqua_fishing_vessel_application_detail,
      'APPLICATION_FOR_AQUACULTURE_FACILITY': (transaction) => transaction?.aquaculture_facility_application_detail,
      'APPLICATION_FOR_AUTHORIZATION': (transaction) => transaction?.authorization_application_detail,
      'APPLICATION_QUOTA_FISHING': (transaction) => transaction?.quota_fishing_application_detail,
      'APPLICATION_FOR_DECLARATION': (transaction) => transaction?.declaration_application_detail,
      'APPLICATION_FOR_LANDING_CATCH_DECLARATION': (transaction) => transaction?.landing_logs_application_detail,
      'APPLICATION_FOR_GENERAL_ADMINISTRATION': (transaction) => transaction?.general_administration_application_detail,
      'APPLICATION_FOR_FISHER_WORKER_ID': (transaction) => transaction?.fisher_worker_id_application_detail,
    }


    const data = map[transaction.app_type](transaction);
    const appDetails = APP_SUBMISSION_CONFIG[transaction.app_type];
    const ultimateData = {
      ...data,
      temp_trans_id: transaction.temp_trans_id,
      app_type: transaction.app_type,
      payment_details: transaction.payment_details
    };

    dispatch({
      type: SET_CURRENT_TRANSACTION,
      temp_trans_id: transaction.temp_trans_id,
      appType: transaction.app_type,
      transaction: {},
    });

    // setSelectedMap(ultimateData);
    clearSelectedMap(ultimateData);
    // TODO: should be redirected to where the save and continue button is clicked
    if (!!appDetails) history.push(`${appDetails.steps[0].currentStep}`);
  };

  const transactions = useSelector((state) => {
    return (
      state.transactions.transactions?.filter(
        (trans) => trans.status === "Draft"
      ) || []
    );
  });

  // setSelectedMap is not used as preset has to be used for the same 
  const {setSelectedMap, clearSelectedMap} = useContext(FLISContext);

  return (
    <div className="table-responsive">
      <table className="table table-sm table-bordered">
        <thead className="custom-table-header">
          <tr>
            <th>Date Submitted</th>
            <th>Type of Transaction</th>
            <th>Type of Licence</th>
            <th>Category of Licence</th>
            <th>Sub Category</th>
            <th>Permit/Licence Number</th>
            <th>Transaction Number</th>
            <th style={{ color: "lightgreen" }}>Resume/Continue</th>
          </tr>
        </thead>

        <tbody className="table-striped">
          {transactions?.map((trans) => {
            return (
              <tr key={trans.id}>
                <td>{new Date(trans.submitted_on).toDateString()}</td>
                <td>{desc[trans.app_type]}</td>
                <td>
                  {getApplicationSpecificDetails(
                    trans,
                    "type_of_transaction"
                  )}
                </td>
                <td>
                  {getApplicationSpecificDetails(
                    trans,
                    "licence_type"
                  )}
                </td>

                <td></td>
                <td></td>
                <td>{trans.id}</td>
                <td>
                  <button onClick={async () => {

                    const result = await getData({
                      url: `${ROUTES.getTransaction}${trans.id}`,
                    });
                    mapDataAccordingToType(result.data.transaction);
                  }} className="btn btn-sm btn-success">
                    Resume/Continue
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllDraftApplications;
