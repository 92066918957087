import React from "react";

function FooterContainer(props) {
  
  return (
    <div className="footer-container row">
      <div className="col-sm-12">
        <div className="mt-2 ">
          Follow our social feeds to be the first to know when something happens
        </div>

        <div>
          <img alt="socials" src="./images/socials.png" width={"120px"}></img>

          <button type="button" className="footer-feedback-button">
            Got feedback? Tell us what you think.
          </button>
        </div>
      </div>

      <div className="col-sm-12">
        <div className="footer-text "></div>
      </div>
    </div>
  );
}

export default FooterContainer;
