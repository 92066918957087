import React, { useState, useEffect } from 'react';

function Last12MonthButtons({onClick, className, heading}) {
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleClick = (monthIndex) => {
    onClick(monthIndex);
  };
  
  useEffect(() => {
    // Set the initial selected month to the current month
    setSelectedMonth(new Date().getMonth());
  }, []);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const getButtonLabel = (monthIndex, year) => {
    return `${months[monthIndex]}-${year}`;
  };

  return (
    <div className={className}>
      {heading && <b>{heading}</b>}
   
    <div className={`d-flex justify-content-between `}>


      
      {Array.from({ length: 12 }, (_, index) => {
        const currentMonth = new Date().getMonth() - index;
        const currentYear = new Date().getFullYear() - (currentMonth < 0 ? 1 : 0);
        const adjustedMonth = currentMonth < 0 ? currentMonth + 12 : currentMonth;
        return (
          <button
            key={index}
            onClick={() => handleClick(adjustedMonth)}
            className={`${selectedMonth === adjustedMonth ? 'selected' : ''} btn btn-sm btn-warning`}
          >
            {getButtonLabel(adjustedMonth, currentYear)}
          </button>
        );
      })}
    </div>
    </div>
  );
}

export default Last12MonthButtons;