export const CONCH_QUOTA = "Conch Quota and Fishing Rights";

export const SEA_CUCUMBER_QUOTA = "Sea Cucumber Quota and Fishing Rights";

export const SPINY_LOBSTER_QUOTA = "Spiny Lobster Industrial Fishing Rights";

export const categories = [
    CONCH_QUOTA,
    SEA_CUCUMBER_QUOTA,
    SPINY_LOBSTER_QUOTA
];

export const ARTISANAL = "Artisanal";
export const INDUSTRIAL = "Industrial";
export const subCategories = {
  [CONCH_QUOTA]: [ARTISANAL, INDUSTRIAL],
  [SEA_CUCUMBER_QUOTA]: [ARTISANAL, INDUSTRIAL],
};

export const typeofTransactions = ["New", "Renewal"];

export const ownershipStatus = ["Sole Owner", "Co-Owner", "Leased", "Rented", "Chartered"];


