// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getSVGImage,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
} from "../../components/DataSource";
import { CustomInputContainer, CustomSelectContainer, onChangeInput} from '../../components/CustomInputContainer';
import { FLISContext } from "../../context/FLISContext";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";

import { typeOfDeclarations, subCategories, typeofTransaction, intendToExport, typeofBusiness } from "./DeclarationDataSource";
import { validateKeys } from "../../utils/utils";
import { alertService } from "../../_services";
import { inputPreviousNumber, selectionNotFound } from "../../utils/messages";
import NFARegNumberSelection from "../../components/NFARegNumberQuestion";



export default function DeclarationTypeSelection(props) {

  const history = useHistory();

  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 0);


  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 0 && (
        <NFARegNumberSelection
          setSelectedMap={setSelectedMap}
          selectionCategory={selectionCategory}
          setSelectionCategory={setSelectionCategory}
          history={history}
          selectedMap={selectedMap}
        />
      )}

      {selectionCategory === 1 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Transaction"}
            listingOptions={typeofTransaction}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_transaction"}
            onClick={(value, i) => {
              if (value !== "Renewal")
                setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          />

          {["Renewal"].indexOf(selectedMap["type_of_transaction"]) !== -1 && (
            <CustomInputContainer
              heading={"Enter Licence Number"}
              prop_key={"renewal_licence_number"}
              placeholder={"Enter Licence number"}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
            ></CustomInputContainer>
          )}

          <InternalNavigator
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if (selectedMap.type_of_transaction === "Renewal") {
                if (!validateKeys(selectedMap, ["renewal_licence_number"])) {
                  alertService.warn(inputPreviousNumber("Licence Number"));
                  return;
                }
              }

              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              // history.goBack();
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 2 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Declarations"}
            listingOptions={typeOfDeclarations}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_declaration"}
            listingStyle="justify-content-center"
            isMultiSelect={true}
          />

          {/* {getGenericNavigator({ setSelectionCategory, selectionCategory, validations:  ['type_of_declaration'], selectedMap })} */}

          <InternalNavigator
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["type_of_declaration"])) {
                alertService.warn(selectionNotFound);
                return;
              }
              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              history.goBack();
            }}
          />
        </div>
      )}

      {selectionCategory === 3 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Sub Category"}
            listingOptions={subCategories}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            prop_key={"sub_category"}
            listingStyle="justify-content-center"
          />

          {getGenericNavigator({
            setSelectionCategory,
            selectionCategory,
            validations: ["sub_category"],
            selectedMap,
          })}
        </div>
      )}

      {selectionCategory === 4 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Do you Intend to Export"}
            listingOptions={intendToExport}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            prop_key={"intend_to_export"}
            listingStyle="justify-content-center"
          />

          {getGenericNavigator({
            setSelectionCategory,
            selectionCategory,
            validations: ["intend_to_export"],
            selectedMap,
          })}
        </div>
      )}

      {selectionCategory === 5 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Business"}
            listingOptions={typeofBusiness}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_business"}
            listingStyle="justify-content-around"
            isMultiSelect={true}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["type_of_business"])) {
                alertService.warn(selectionNotFound);
                return;
              }
              setSelectedMap((map) => {
                return {
                  ...map,
                  selectionCategory
                }
              })
              props?.next();
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}
    </div>
  );
}


const getGenericNavigator = ({setSelectionCategory, selectionCategory, validations = [], selectedMap = {}}) => {
    return <InternalNavigator
    nextValue={"Next"}
    nextAction={() => {

      if(!validateKeys(selectedMap, validations)){
        alertService.warn(selectionNotFound, {autoClose: true});
        return;
      }
      setSelectionCategory(selectionCategory + 1);
    }}
    prevValue={"Back"}
    previousAction={() => {
      setSelectionCategory(selectionCategory - 1);
    }}
  />;
}

