import React, { useContext, useEffect , forwardRef} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { emailPattern } from "../../components/DataSource";


export const TournamentDirectorInformation = forwardRef(({formState,control, register, onSubmit, ...props }, ref) => {



    const { fields, append, remove } = useFieldArray({
      control,
      name: 'tournament_director_information',
    });

  
    const {errors, isDirty, isValid} = formState;
   
    return (
      <div className="form-container">
        <fieldset>
          <form
            onSubmit={onSubmit}
          >
             {fields.map((group, groupIndex) => {
              return (
                <div
                  key={group.id}
                  className="shadow p-3 mb-3 bg-white rounded"
                >
                  <div className="d-flex flex-row form-heading">
                    <div
                      style={{ marginRight: "10px" }}
                    >{`${"Tournament Director Information"} #${
                      groupIndex + 1
                    }`}</div>

                    <div>
                      <button
                        className="btn btn-warning"
                        onClick={() => {
                          remove(groupIndex);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="d-flex-wrap flex-row justify-content-start">
                    <div className="individual-field">
                      <label>Name</label>

                      <input
                        type="text"
                        required
                        {...register(
                          `tournament_director_information[${groupIndex}].name`,
                          {
                            required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                          }
                        )}
                        className="form-control"
                        placeholder="Name"
                      />
                      {errors.tournament_director_information?.[groupIndex]
                        ?.name && <span>This field is required</span>}
                    </div>

                    <div className="individual-field">
                      <label>NFA Registration Number</label>

                      <input
                        type="text"
                        {...register(
                          `tournament_director_information[${groupIndex}].nfa_registration_number`,
                          {
                            required: false,
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                          }
                        )}
                        className="form-control"
                        placeholder="NFA Registration Number"
                      />
                      {errors.tournament_director_information?.[groupIndex]
                        ?.nfa_registration_number && (
                        <span>This field is required</span>
                      )}
                    </div>

                    <div className="individual-field">
                      <label>Cell - Phone Contact</label>

                      <input
                        type="text"
                        {...register(
                          `tournament_director_information[${groupIndex}].phone_contact`,
                          {
                            required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                          }
                        )}
                        className="form-control"
                        placeholder="Cell - Phone Contact"
                      />
                      {errors.tournament_director_information?.[groupIndex]
                        ?.phone_contact && <span>This field is required</span>}
                    </div>

                    <div className="individual-field">
                      <label>E-mail Address</label>

                      <input
                        type="email"
                        {...register(
                          `tournament_director_information[${groupIndex}].email_address`,
                          {
                            required: "This field is required",
                            maxLength: {
                              value: 100,
                              message: "Max length is 100",
                            },
                            pattern: {
                              value: emailPattern,
                              message: 'Not a valid email'
                            },
                          }
                        )}
                        className="form-control"
                        placeholder="E-mail Address"
                      />
                      {errors.tournament_director_information?.[groupIndex]
                        ?.email_address?.message}
                    </div>

                  </div>
                </div>
              );
             })}
         
          </form>
        </fieldset>


        <div className="d-flex flex-row-reverse" onClick={() => {
            append({id: Math.random()});
        }}>
            <button type="button" className="btn btn-primary">Add Tournament Director</button>
        </div>
      </div>
    );
});
