// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FISHER_LICENCE,
  licenceTypeMapping,
  typeOfTransactions,
  fishingArea,
  getSVGImage,
  getRectBoxStylingWithSingleValue,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  fisherCommercialLicenceCategory,
  COMMERCIAL_FISHING,
  FISHER_WORKER_ID,
  RenewalTypeOptions,
  baseLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  regionListForInland,
  regionInland,
  FishWorkerCategoryForFisherWorkerID,
  ID_CARD_RENEWAL,
  NEW_LICENCE,
  fisherWorkerIDTransactionsMapping,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { shouldAskPreviousAppNumber, validateKeys } from "../../utils/utils";
import { inputPreviousNumber, inputSelection, selectionNotFound } from "../../utils/messages";
import { alertService } from "../../_services";
import { ReactSelect } from "../../components/FormHook/ReactFormHookFields";

export default function FisherWorkerIdTypeSelection(props) {
  const history = useHistory();

  // const selected_map = localStorage.getItem('selection_map');

  // const [selectedMap, setSelectedMap] = useState(JSON.parse(selected_map ?? '{}'));

  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 2);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    fishingArea?.forEach(({ lat, lng }) => {
      if (lat !== 0) {
        bounds.extend({ lat, lng });
      }
    });
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };


  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 2 && (
        <div style={getAnimationStyle(selectionCategory === 2)}>
          <>
            {/* for fisher licence  */}
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                What Type of Licence do you want to apply for?
              </div>

              <div className="d-flex flex-wrap justify-content-center">
                {licenceTypeMapping[FISHER_LICENCE]?.map((licence_type, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            licence_type: licence_type,
                            applicant_type: selectedMap.applicant_type,
                          };
                        });
                        if (licence_type === COMMERCIAL_FISHING) {
                          setSelectionCategory(selectionCategory + 1);
                        } else {
                          setSelectionCategory(selectionCategory + 2);
                        }
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "licence_type",
                        licence_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["licence_type"] === licence_type
                            ? "#00b7fd"
                            : "##00b7fd"
                        )}
                        <div className="license-cat-container-text">
                          {licence_type}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  history.goBack();
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["licence_type"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (selectedMap.licence_type === COMMERCIAL_FISHING) {
                    setSelectionCategory(selectionCategory + 1);
                  } else {
                    setSelectionCategory(selectionCategory + 2);
                  }
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 3 && (
        <div style={getAnimationStyle(selectionCategory === 3)}>
          <>
            {/* for fisher licence worker id  */}
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                What Category of Licence do you want to apply for?
              </div>

              <div className="d-flex flex-wrap justify-content-center">
                {fisherCommercialLicenceCategory?.map((licence_type, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            commercial_category: licence_type,
                          };
                        });
                        setSelectionCategory(selectionCategory + 1);
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "commercial_category",
                        licence_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["commercial_category"] === licence_type
                            ? "#00b7fd"
                            : "##00b7fd"
                        )}
                        <div className="license-cat-container-text">
                          {licence_type}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["commercial_category"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select type of transaction
              </div>

              <div className="d-flex-wrap flex-row justify-content-center">
                {typeOfTransactions[FISHER_WORKER_ID]?.map((trans) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            type_of_transaction: trans,
                          };
                        });
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "type_of_transaction",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["type_of_transaction"] === trans
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small license-cat-container-text preserve-white-space">{fisherWorkerIDTransactionsMapping
                        [trans]}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {/* {
            <div className="d-flex flex-column">
              {["Renewal"].indexOf(selectedMap.type_of_transaction) >= 0 && (
                <>
                  <div className="rectangle-box-small-heading">
                    Select Type of Renewal
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <select
                      name="renewal_type"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.renewal_type}
                      className="col-sm-5 form-control form-control-sm"
                    >
                      <RenewalTypeOptions />
                    </select>
                  </div>
                </>
              )}
            </div>
          } */}

          {
            <div className="d-flex flex-column">
              {["Renewal"].indexOf(selectedMap.type_of_transaction) >= 0 && (
                <>
                  <div className="rectangle-box-small-heading">
                    Enter the Licence Number
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <input
                      type="text"
                      name="previous_licence_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.previous_licence_number}
                      className="col-sm-5 form-control form-control-sm"
                      placeholder={"Enter Licence Number"}
                    />
                  </div>
                </>
              )}
            </div>
          }

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }


                // if (
                //   ["Renewal"].indexOf(selectedMap.type_of_transaction) !== -1 &&
                //   !validateKeys(selectedMap, ["renewal_type"])
                // ) {
                //   alertService.warn(inputSelection("Renewal Type"));
                //   return;
                // }

                if (shouldAskPreviousAppNumber(selectedMap) &&
                  !validateKeys(selectedMap, ["previous_licence_number"])
                ) {
                  alertService.warn(inputPreviousNumber("Licence Number"));
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                if (selectedMap.licence_type === COMMERCIAL_FISHING) {
                  setSelectionCategory(selectionCategory - 1);
                } else {
                  setSelectionCategory(selectionCategory - 2);
                }
              }}
            />
          </div>
        </div>
      )}

      {/* {selectionCategory === 5 && (
        <div style={getAnimationStyle(selectionCategory === 5)}>
          <div className="d-flex flex-row justify-content-between">
            <div>
              {isLoaded && (
                <div className="flex-grow-1 GoogleApp-small mt-4 ">
                  <GoogleMap
                    mapContainerClassName="map-container"
                    onLoad={onMapLoad}
                    onClick={() => setIsOpen(false)}
                  >
                    {fishingArea.map(({ location, lat, lng, id }, ind) => (
                      <Marker
                        key={id}
                        position={{ lat, lng }}
                        onClick={() => {
                          handleMarkerClick(id, lat, lng, location);
                          onChangeMultiSelect(
                            {
                              target: {
                                value: location,
                                name: "fishing_area",
                              },
                            },
                            selectedMap,
                            setSelectedMap
                          );
                        }}
                      >
                        {isOpen && infoWindowData?.id === id && (
                          <InfoWindow
                            onCloseClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <span>{infoWindowData.address}</span>
                          </InfoWindow>
                        )}
                      </Marker>
                    ))}
                  </GoogleMap>
                </div>
              )}
            </div>

            <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
              <div className="rectangle-box-small-heading">
                Select Fishing Area(s)
              </div>

              <div
                className="d-flex-wrap flex-row justify-content-start"
                style={{ margin: "10px 30px 26px 0px" }}
              >
                {fishingArea?.map((fishing_area, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        onChangeMultiSelect(
                          {
                            target: {
                              value: fishing_area.location,
                              name: "fishing_area",
                            },
                          },
                          selectedMap,
                          setSelectedMap
                        );
                      }}
                      className="p-2 location-cat-container"
                      style={{
                        ...getRectBoxStylingWithMultiTypeValues(
                          selectedMap,
                          "fishing_area",
                          fishing_area.location
                        ),
                        maxWidth: "130px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          32,
                          32,
                          selectedMap["fishing_area"] === fishing_area.location
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small-text">
                          {fishing_area.location}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div></div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["fishing_area"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });
                props?.next();
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )} */}

      {selectionCategory === 5 && (
        <div style={getAnimationStyle(selectionCategory === 5)}>
          <BoxChoiceContainer
            listingHeading={"Select Port or Inland"}
            listingOptions={baseLocations}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"port_or_inland"}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            listingStyle="justify-content-center"
            listingDescriptions={[null, "(Rivers and Ponds)"]}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["port_or_inland"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 6 &&
        selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
          <div style={getAnimationStyle(selectionCategory === 6)}>
            {/* <BoxChoiceContainer
              listingHeading={"Select Inland"}
              listingOptions={InlandList}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              prop_key={"inland"}
              listingStyle="justify-content-center"
              boxStyle="sub-cat-container"
              textStyle="rectangle-box-small-text"
            />

            {selectedMap.inland === "Other" && (
              <CustomInputContainer
                heading={"Other"}
                prop_key={"other_inland"}
                placeholder={"Other"}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              ></CustomInputContainer>
            )} */}

            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionListForInland}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      inland: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionInland?.[selectedMap.region]}
                  value={selectedMap.inland}
                  label="Select Inland"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        inland: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["inland"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (
                    selectedMap.inland === "Other" &&
                    !validateKeys(selectedMap, ["other_inland"])
                  ) {
                    alertService.warn(inputPreviousNumber("Other Inland"), {
                      autoClose: true,
                    });
                    return;
                  }

                  // setSelectionCategory(selectionCategory + 2);
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory,
                    };
                  });

                  props?.next();
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 6 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 6)}>
            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionParishList}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      port: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionParishPort?.[selectedMap.region]}
                  value={selectedMap.port}
                  label="Select Port"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        port: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["region", "port"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 7 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 7)}>
            <div className="d-flex flex-row justify-content-between">
              <div>
                {isLoaded && (
                  <div className="flex-grow-1 GoogleApp-small mt-4 ">
                    <GoogleMap
                      mapContainerClassName="map-container"
                      onLoad={onMapLoad}
                      onClick={() => setIsOpen(false)}
                    >
                      {fishingArea.map(({ location, lat, lng, id }, ind) => (
                        <Marker
                          key={id}
                          position={{ lat, lng }}
                          onClick={() => {
                            handleMarkerClick(id, lat, lng, location);
                            onChangeMultiSelect(
                              {
                                target: {
                                  value: location,
                                  name: "fishing_area",
                                },
                              },
                              selectedMap,
                              setSelectedMap
                            );
                          }}
                        >
                          {isOpen && infoWindowData?.id === id && (
                            <InfoWindow
                              onCloseClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              <span>{infoWindowData.address}</span>
                            </InfoWindow>
                          )}
                        </Marker>
                      ))}
                    </GoogleMap>
                  </div>
                )}
              </div>

              <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
                <div className="rectangle-box-small-heading">
                  Select Fishing Area(s)
                </div>

                <div
                  className="d-flex-wrap flex-row justify-content-start"
                  style={{ margin: "10px 30px 26px 0px" }}
                >
                  {fishingArea?.map((fishing_area, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          onChangeMultiSelect(
                            {
                              target: {
                                value: fishing_area.location,
                                name: "fishing_area",
                              },
                            },
                            selectedMap,
                            setSelectedMap
                          );
                        }}
                        className="p-2 location-cat-container"
                        style={{
                          ...getRectBoxStylingWithMultiTypeValues(
                            selectedMap,
                            "fishing_area",
                            fishing_area.location
                          ),
                          maxWidth: "130px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            32,
                            32,
                            selectedMap["fishing_area"] ===
                              fishing_area.location
                              ? "black"
                              : "#cbf1ff"
                          )}

                          <div className="rectangle-box-small-text">
                            {fishing_area.location}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div></div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["fishing_area"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 8 && (
        <div style={getAnimationStyle(selectionCategory === 8)}>
          <BoxChoiceContainer
            listingHeading={"Select Fish Worker Category"}
            listingOptions={FishWorkerCategoryForFisherWorkerID}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"fish_worker_category"}
            listingStyle="justify-content-center"
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["fish_worker_category"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });
                props?.next();
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
