import React, { useContext, useEffect , forwardRef} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormHookTextArea, FormHookInput, FormHookCheckbox, FormHookSelect } from "../../components/FormHook/ReactFormHookFields";



// used in both
export const LocationDetails = ({formState,control, register, onSubmit,shouldRenderInForm=true, isDisabled=false, isAddEnabled=true, isDeleteEnabled=true, typeOfSpecies, zoneBeachAreas }) => {



    const { fields, append, remove } = useFieldArray({
      control,
      name: 'authorization_location_species_details',
    });


    const holdReleaseType = ['Catch and Keep', 'Catch- Hold - Release', 'Catch - Immediate Release'];
  
    const {errors, isDirty, isValid} = formState;

    const flds = <>  {fields.map((group, groupIndex) => {
      return (
        <div key={group.id} className="shadow p-3 mb-3 bg-white rounded">
          <div className="d-flex flex-row form-heading">
            <div
              style={{ marginRight: "10px" }}
            >{`${"Location & Species Details"} #${groupIndex + 1}`}</div>

            <div>
              {isDeleteEnabled && (
                <button
                  className="btn btn-warning"
                  hidden={fields?.length === 1}
                  onClick={() => {
                    remove(groupIndex);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
          <div className="d-flex-wrap flex-row justify-content-start">
            {/* {
              zoneBeachAreas && <FormHookSelect
              label="Name of Zone, Beach, or Areas"
              register={register}
              regFieldName={`authorization_location_species_details[${groupIndex}].name`}
              options={zoneBeachAreas}
              error= {
                errors.authorization_location_species_details?.[groupIndex]?.name?.message
              }
            />
            } */}

            {zoneBeachAreas && (
              <FormHookTextArea
                label="Name of Zone, Beach, or Areas"
                register={register}
                regFieldName={`authorization_location_species_details[${groupIndex}].name`}
                error={
                  errors.authorization_location_species_details?.[groupIndex]
                    ?.name?.message
                }
                validations={{
                  maxLength: {
                    value: 500,
                    message: "Max length should be 500",
                  },
                  minLength: {
                    value: 3,
                    message: "Min length should be 3",
                  },
                }}
                isDisabled={isDisabled}
              />
            )}

            {!zoneBeachAreas && (
              <FormHookTextArea
                register={register}
                error={
                  errors.authorization_location_species_details?.[groupIndex]
                    ?.name?.message
                }
                label="Name of Zone, Beach, or Areas"
                regFieldName={`authorization_location_species_details[${groupIndex}].name`}
                placeholder="Name of Zone, Beach, or Areas"
                validations={{
                  maxLength: {
                    value: 500,
                    message: "Max length should be 500",
                  },
                  minLength: {
                    value: 3,
                    message: "Min length should be 3",
                  },
                }}
                isDisabled={isDisabled}
              />
            )}

            {typeOfSpecies && (
              <FormHookSelect
                label="Main Species to be Caught"
                register={register}
                regFieldName={`authorization_location_species_details[${groupIndex}].main_species_to_be_caught`}
                options={typeOfSpecies}
                error={
                  errors.authorization_location_species_details?.[groupIndex]
                    ?.main_species_to_be_caught?.message
                }
                isDisabled={isDisabled}
              />
            )}

            {!typeOfSpecies && (
              <FormHookTextArea
                register={register}
                error={
                  errors.authorization_location_species_details?.[groupIndex]
                    ?.main_species_to_be_caught?.message
                }
                label="Main Species to be Caught"
                regFieldName={`authorization_location_species_details[${groupIndex}].main_species_to_be_caught`}
                placeholder="Main Species to be Caught"
                validations={{
                  maxLength: {
                    value: 500,
                    message: "Max length should be 500",
                  },
                  minLength: {
                    value: 3,
                    message: "Min length should be 3",
                  },
                }}
                isDisabled={isDisabled}
              />
            )}

            <FormHookInput
              register={register}
              error={
                errors.authorization_location_species_details?.[groupIndex]
                  ?.expected_qty?.message
              }
              label="Expected Qty. (Kg)"
              placeholder="Expected Qty. (Kg)"
              regFieldName={`authorization_location_species_details[${groupIndex}].expected_qty`}
              type="number"
              isDisabled={isDisabled}
            />

            <FormHookCheckbox
              label="Catch - Hold or Release Type"
              register={register}
              regFieldName={`authorization_location_species_details[${groupIndex}].hold_release`}
              options={holdReleaseType}
              error={
                errors.authorization_location_species_details?.[groupIndex]
                  ?.hold_release?.message
              }
              isDisabled={isDisabled}
              isMulti={false}
            />
          </div>
        </div>
      );
     })}</>;
    
    return (
      <div className="form-container">
        {shouldRenderInForm && (
          <fieldset>
            <form onSubmit={onSubmit}>{flds}</form>
          </fieldset>
        )}

        {!shouldRenderInForm && <>{flds}</>}

        {isAddEnabled && (
          <div
            className="d-flex flex-row-reverse"
            onClick={() => {
              append({ id: Math.random() });
            }}>
            <button type="button" className="btn btn-primary">Add Location Details</button>
          </div>
        )}
      </div>
    );
};
