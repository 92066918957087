import React, { useContext } from "react";
import {
  FormHookCheckbox,
  FormHookInput,
  FormHookSelect,
  FormHookTextArea,
} from "../components/FormHook/ReactFormHookFields";
import HorizontalLine from "../components/HorLine";
import { useFieldArray, useForm } from "react-hook-form";
import { countries } from "./AuthorizationLicence/AuthorizationDataSource";
import { educationTypes, emailPattern, formatPhoneNumber, formatTRN, getMaxDateForAtleastYear, getMinDateForAtleastYear, parishList, telephonePattern, trnPattern } from "../components/DataSource";
import { emailNotValid, telephoneNotValid, trnNotValid } from "../utils/messages";
import { postData, putData } from "../services/service-call";
import { ROUTES } from "../config";
import { FLISContext } from "../context/FLISContext";
import { alertService } from "../_services";

const UpdateEntityInformation = ({ entity = {} }) => {


  const {setCurrentEntity} = useContext(FLISContext);

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    defaultValues: entity,
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contact_details",
  });

  const { errors } = formState;

  const onSubmit = async () => {
    const isValid = await trigger();
    console.log(isValid);

    if (isValid) {
      const data = getValues();
      const result = await putData({
        url: `${ROUTES.updateEntityInformation}/${entity.id}`,
        body: data,
      });

      if (result.success) {
        alertService.success(result.data.msg);

        setCurrentEntity(result.data.entity);
      } else {
        alertService.warn(result.data.msg);
      }
    }
  };

  const soType = watch('supporting_officer_role');
  const SupportingOfficer = (
    <div className="shadow bg-white rounded registration-border mb-3 pb-3">
      <div className="sub-form-header-registration">Supporting Officer</div>
      <HorizontalLine></HorizontalLine>

      <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
        <FormHookSelect
          register={register}
          error={errors?.supporting_officer_role?.message}
          label="Supporting Officer"
          regFieldName="supporting_officer_role"
          options={[
            "Not Applicable",
            "Clerical Officer",
            "Records Officer",
            "Cashier",
            "Field Officer",
            
          ]}
          fieldClassName="form-control form-control-sm"
        />

        <FormHookInput
          register={register}
          error={errors?.supporting_officer_name?.message}
          label="Supporting Officer Name"
          regFieldName="supporting_officer_name"
          placeholder="Supporting Officer Name"
          type="text"
          fieldClassName="form-control form-control-sm"
          isRequired={soType !== "Not Applicable" ? "This field is required": false}
        />

        <FormHookInput
          register={register}
          error={errors?.supporting_officer_email?.message}
          label="Supporting Officer Email"
          regFieldName="supporting_officer_email"
          placeholder="Supporting Officer Email"
          validations={{
            pattern: {
              value: emailPattern,
              message: emailNotValid,
            },
          }}
          fieldClassName="form-control form-control-sm"
          isRequired={soType !== "Not Applicable" ? "This field is required": false}
        />
      </div>
    </div>
  );

  const educationType = watch("education") || [];

  return (
    <div>
      {entity.applicant_type === "Individual" && (
        <>
          <div className="shadow rounded bg-white registration-border mb-3 pb-3">
            <div className="sub-form-header-registration">
              Individual Details
            </div>
            <HorizontalLine></HorizontalLine>

            <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
              <FormHookInput
                register={register}
                error={errors?.first_name_individual?.message}
                label="First Name"
                regFieldName="first_name_individual"
                placeholder="First"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.middle_name_individual?.message}
                label="Middle Name"
                regFieldName="middle_name_individual"
                placeholder="Middle"
                isRequired={false}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.last_name_individual?.message}
                label="Last Name"
                regFieldName="last_name_individual"
                placeholder="Last"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.alias_individual?.message}
                label="Alias"
                regFieldName="alias_individual"
                placeholder="Alias"
                fieldClassName="form-control form-control-sm"
                isRequired={false}
              />

              <FormHookSelect
                label="Country of Origin"
                register={register}
                error={errors?.country_of_origin?.message}
                regFieldName="country_of_origin"
                options={countries}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                register={register}
                error={errors?.gender?.message}
                label="Gender"
                regFieldName="gender"
                options={["Male", "Female"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.date_of_birth_individual?.message}
                label="Date of Birth"
                regFieldName="date_of_birth_individual"
                type="date"
                fieldClassName="form-control form-control-sm"
                maxDate={getMaxDateForAtleastYear(16)}
                minDate={getMinDateForAtleastYear(200)}
              />

              <FormHookInput
                register={register}
                error={errors?.cell_phone?.message}
                label="Work Cell Phone"
                regFieldName={`cell_phone`}
                placeholder="Work Cell Phone"
                type="text"
                fieldClassName="form-control form-control-sm"
                validations={{
                  pattern: {
                    value: telephonePattern,
                    message: telephoneNotValid,
                  },
                }}
                onChange={formatPhoneNumber}
              />

              <FormHookSelect
                label="Parish"
                register={register}
                error={errors?.parish?.message}
                regFieldName="parish"
                options={parishList}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                register={register}
                error={errors?.domicile?.message}
                label="Nationality"
                regFieldName="domicile"
                options={["Local", "Foreign"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                register={register}
                error={errors?.category_of_applicant?.message}
                label="Category of Applicant"
                regFieldName="category_of_applicant"
                options={["Commercial", "Recreational", "Temporary"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.taxation_registration_number?.message}
                label="TRN"
                regFieldName="taxation_registration_number"
                placeholder="TRN"
                isRequired={false}
                fieldClassName="form-control form-control-sm"
                validations={{
                  pattern: {
                    value: trnPattern,
                    message: trnNotValid,
                  },
                }}
                onChange={formatTRN}
              />

              <FormHookTextArea
                register={register}
                error={errors?.address?.message}
                label="Address"
                regFieldName="address"
                placeholder="Address"
                parentClass="individual-field-text-area flex-grow-1"
                style={{ marginRight: "40px" }}
                rows={1}
              />

              <FormHookCheckbox
                label="Education"
                options={educationTypes}
                register={register}
                error={errors?.education?.message}
                regFieldName={`education`}
              />

              {educationType?.includes("Other") && (
                <FormHookInput
                  register={register}
                  error={errors?.education_other?.message}
                  label="Other (Education)"
                  regFieldName="education_other"
                  placeholder="Other Education"
                  fieldClassName="form-control form-control-sm"
                  parentClass={"individual-field-margin min-200 max-200"}
                  isRequired={
                    educationType?.includes("Other")
                      ? "This field is required"
                      : false
                  }
                />
              )}
            </div>
          </div>

          {SupportingOfficer}

          <div className="d-flex mb-3 justify-content-end align-item-center">
            <button
              onClick={onSubmit}
              className="btn btn-primary min-200"
              type="submit"
            >
              Submit
            </button>
          </div>
        </>
      )}

      {entity.applicant_type === "Company" && (
        <>
          <div className="shadow  bg-white rounded registration-border mb-3 pb-3">
            <div className="sub-form-header-registration">Company Details</div>
            <HorizontalLine></HorizontalLine>

            <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
              <FormHookInput
                register={register}
                error={errors?.company_name?.message}
                label="Company Name"
                regFieldName="company_name"
                placeholder="Company Name"
                type="text"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                label="Country of Origin"
                register={register}
                error={errors?.country_of_origin?.message}
                regFieldName="country_of_origin"
                options={countries}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.date_of_registration?.message}
                label="Date of Registration/Incorporation"
                regFieldName="date_of_registration"
                type="date"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.email?.message}
                label="Email Address"
                regFieldName="company_email_address"
                placeholder="Email"
                type="text"
                validations={{
                  pattern: {
                    value: emailPattern,
                    message: emailNotValid,
                  },
                }}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.cell_phone?.message}
                label="Work Cell Phone"
                regFieldName={`cell_phone`}
                placeholder="Work Cell Phone"
                type="text"
                fieldClassName="form-control form-control-sm"
                validations={{
                  pattern: {
                    value: telephonePattern,
                    message: telephoneNotValid,
                  },
                }}
                onChange={formatPhoneNumber}
              />

              <FormHookInput
                register={register}
                error={errors?.website?.message}
                label="Website"
                regFieldName={`website`}
                placeholder="Website"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                label="Parish"
                register={register}
                error={errors?.parish?.message}
                regFieldName="parish"
                options={parishList}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                register={register}
                error={errors?.domicile?.message}
                label="Nationality"
                regFieldName="domicile"
                options={["Local", "Foreign"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookSelect
                register={register}
                error={errors?.category_of_applicant?.message}
                label="Category of Applicant"
                regFieldName="category_of_applicant"
                options={["Commercial", "Recreational", "Temporary"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={register}
                error={errors?.taxation_registration_number?.message}
                label="TRN"
                regFieldName="taxation_registration_number"
                placeholder="TRN"
                isRequired={false}
                fieldClassName="form-control form-control-sm"
                validations={{
                  pattern: {
                    value: trnPattern,
                    message: trnNotValid,
                  },
                }}
                onChange={formatTRN}
              />

           
              <FormHookTextArea
                register={register}
                error={errors?.address?.message}
                label="Address"
                regFieldName="address"
                placeholder="Address"
                cols={61}
                rows={1}
              />
            </div>
          </div>

          <div className="shadow rounded bg-white registration-border mb-3 pb-3">
            <div className="sub-form-header-registration">
              Contact Details (Company Contact Details)
            </div>
            <HorizontalLine></HorizontalLine>

            {fields?.map((group, groupIndex) => {
              return (
                <div key={group.id}>
                  <div className="d-flex-wrap gap-20-10 flex-row justify-content-start registration-margin-left">
                    <FormHookInput
                      register={register}
                      error={
                        errors?.contact_details?.[groupIndex]?.name_of_contact
                          ?.message
                      }
                      label="Name of Contact"
                      regFieldName={`contact_details[${groupIndex}].name_of_contact`}
                      placeholder="Name of Contact"
                      type="text"
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.contact_details?.[groupIndex]?.cell_phone
                          ?.message
                      }
                      label="Cell Phone"
                      regFieldName={`contact_details[${groupIndex}].cell_phone`}
                      placeholder="Cell Phone"
                      type="text"
                      fieldClassName="form-control form-control-sm"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.contact_details?.[groupIndex]?.work_home_phone
                          ?.message
                      }
                      label="Work / Home Phone"
                      regFieldName={`contact_details[${groupIndex}].work_home_phone`}
                      placeholder="Work / Home Phone"
                      type="text"
                      isRequired={false}
                      fieldClassName="form-control form-control-sm"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <div>
                      <button
                        style={{ marginTop: "1.8rem" }}
                        className=" btn btn-warning"
                        onClick={() => {
                          remove(groupIndex);
                        }}
                        disabled={fields?.length === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="d-flex-wrap mt-3 justify-content-end">
              <button
                type="button"
                onClick={() => {
                  append({ id: Math.random() });
                }}
                className="ml-2 btn btn-sm btn-success mr-4"
              >
                Add Another Contact
              </button>
            </div>
          </div>

          {SupportingOfficer}

          <div className="d-flex mb-3 justify-content-end align-item-center">
            <button
              onClick={onSubmit}
              className="btn btn-primary min-200"
              type="button"
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UpdateEntityInformation;
