import React from 'react';
import { useHistory } from 'react-router-dom';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';



function CommunityHeadlines(props) {

    const headline = [
        'Best practices to adopt while Fishing',
        'Why you should always have a License?',
        'Fishing without harming environment'
    ]

    const replies = [
        '12 replies',
        '2 replies',
        '6 replies',
    ]

    const activity = [
       'Last activity 3 hours ago',
       'Last activity 12 hours ago',
       'Last activity 1 day ago',
        
    ]

    // const history = useHistory();
    return (
       <div className='p-1'>
             <div style={{textDecoration: 'underline', fontSize: '18px', color: 'green'}}>
                            {headline[props.sequence ?? 0]}
                        </div>

                        <div className='d-flex justify-content-start flex-spacing-10'>
                            <div><b> {replies[props.sequence ?? 0]}</b></div>
                            <div> {activity[props.sequence ?? 0]}</div>
                        </div>
       </div>
    );
}

export default CommunityHeadlines;