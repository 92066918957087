import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { FLISContext } from '../context/FLISContext';
import ViewFisheriesAct from './ViewFisheriesAct';
function DeclarationDetails(props) {

    const history = useHistory();

    const {clearSelectedMap} = useContext(FLISContext);

    return (
        <div>

            <div className='custom-banner-bg'>
                <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                    Closed Season Declarations
                </div>


              <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = { appType: 'APPLICATION_FOR_DECLARATION' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }

                            transNumber = await getUniqueTransNumber(details.appType);

                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });
                        clearSelectedMap();
                        if (!!details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Click here to submit Close Season Declaration
                    </button>

                    <ViewFisheriesAct/>

                </div> 
            </div>


         
            <div
        style={{
          padding: "40px 14%",
          color: "black",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div>
        At the start of the close season, fishers and establishments must declare the amount of lobster, conch or sea cucumber they have in their possession based on the fishery that is closed.
        </div>

        <br />
        <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = { appType: 'APPLICATION_FOR_DECLARATION' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }

                            transNumber = await getUniqueTransNumber(details.appType);

                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });
                        clearSelectedMap();
                        if (!!details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Click here to submit Close Season Declaration
                    </button>

                </div>
      </div>

        </div>
    );
}

export default DeclarationDetails;