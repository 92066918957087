import React from 'react';
import styled from 'styled-components';

import Circle from '../../components/Circle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { createGlobalStyle } from 'styled-components';
import { reportHeader } from '../../utils/utils';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background: #f4f4f9;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
`;


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px; /* Space between circles */
  background: #f4f4f9;
  padding: 20px;
  margin-top: 40px;
  box-sizing: border-box;
`;

const circlesData = [
  { title: 'Manage Closed Season Species', link: '/admin/species-seasons' },
  { title: 'Transaction Report', link: '/admin/transactions' },
  { title: 'Licences Report', link: '/admin/licences' },
  // Add more circles as needed
];

const AdminHome = () => {
  const history = useHistory();

  return (
    <div>
      {reportHeader("IrieFINS Admin Portal", {fontSize: '36px', padding: '20px'})}

      <Container>
        <GlobalStyle />

        {circlesData.map((circle, index) => (
          <Circle
            key={index}
            title={circle.title}
            onClick={() => history.push(circle.link)}
          />
        ))}
      </Container>
    </div>
  );
};

export default AdminHome;
