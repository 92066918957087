import React from "react";

import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const FAQ = (props) => {


   
    return (
        <div>
            <iframe title='uniqueTitle' width={'100%'} height={'700px'} src='./forms/FAQ.pdf' id="documentPreviewer" className="preview-document"></iframe>;
        </div>
    )
}