// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getSVGImage,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  specieal_species_allow,
  CONCH,
  SEA_CUCUMBER,
  SPINY_LOBSTER,
} from "../../components/DataSource";
import { CustomInputContainer, CustomSelectContainer, onChangeInput} from '../../components/CustomInputContainer';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";
import { useForm } from "react-hook-form";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { CONCH_QUOTA, SEA_CUCUMBER_QUOTA, SPINY_LOBSTER_QUOTA, categories, subCategories, typeofTransactions } from "./QuotaFishingDataSource";
import { MarineVesselDetailsSpiny } from "./MarineVesselDetailsSpiny";
import { MarineVesselDetails } from "./MarineVesselDetails";
import { ProposedFishingDetails } from "./ProposedFishingDetails";
import { ConchHistoricalIndustryPerformance } from "./ConchHistoricalPerformance";
import { SpinyHistoricalIndustryPerformance } from "./SpinyLobsterHistoricalPerformance";
import { isDateInRange, validateKeys } from "../../utils/utils";
import { alertService } from "../../_services";
import { inputPreviousNumber, selectionNotFound } from "../../utils/messages";
import NFARegNumberSelection from "../../components/NFARegNumberQuestion";
import { SeaCucumberHistoricalIndustryPerformance } from "./SeaCucumberHistoricalPerformance";
import { InvestmentAndProductionCapacity } from "./InvestmentAndProductionCapacity";
import { SpinyInvestment } from "./SpinyInvestment";
import HorizontalLine from "../../components/HorLine";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";


export default function QuotaFishingTypeSelection(props) {

  const history = useHistory();

  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 0);


  const onSubmit = (data) => {
    console.log(data);
  };

  const mvdForm = useForm({
    defaultValues: {
      marine_vessel_details: selectedMap.marine_vessel_details ?? [{id: Math.random()}],
    },
    mode: "all"
  });

  const mvdFormSpiny = useForm({
    defaultValues: {
      marine_vessel_details_spiny: selectedMap.marine_vessel_details_spiny ?? [{id: Math.random()}],
    },
    mode: "all"
  });


  const pfdForm = useForm({
    defaultValues: {
      proposed_fishing_details: selectedMap.proposed_fishing_details ?? [{id: Math.random()}],
      percentage_intended_for_export: selectedMap.percentage_intended_for_export,
      percentage_intended_for_local_sales: selectedMap.percentage_intended_for_local_sales
    },
    mode: "all"
  });

  const hipConchForm = useForm({
    defaultValues: {
      historical_industry_performance_conch:
        selectedMap.historical_industry_performance_conch ?? [
          { id: Math.random() },
        ],
      year_applicant_in_industry_conch:
        selectedMap.year_applicant_in_industry_conch,
      number_of_years_allocated_quota_conch:
        selectedMap.number_of_years_allocated_quota_conch,
      total_investment_conch: selectedMap.total_investment_conch,
    },
    mode: "all",
  });


  const hipSeacucumberForm = useForm({
    defaultValues: {
      historical_industry_performance_sea_cucumber: selectedMap.historical_industry_performance_sea_cucumber ?? [
        {id: Math.random()}],
      year_applicant_in_industry_sea_cucumber: selectedMap.year_applicant_in_industry_sea_cucumber,
      number_of_years_allocated_quota_sea_cucumber: selectedMap.number_of_years_allocated_quota_sea_cucumber,
      total_investment_sea_cucumber: selectedMap.total_investment_sea_cucumber,
    },
    mode: 'all'
  });



  const hipSpinyForm = useForm({
    defaultValues: {
      historical_industry_performance_spiny:
        selectedMap.historical_industry_performance_spiny ?? [
          { id: Math.random() },
        ],
        year_applicant_entered_in_industry_spiny: selectedMap.year_applicant_entered_in_industry_spiny,
      number_of_years_allocated_quota_spiny:
        selectedMap.number_of_years_allocated_quota_spiny,
    },
    mode: "all",
  });

  const investmentForm = useForm({
    defaultValues: {
      investment_production_capacity: selectedMap.investment_production_capacity
    },
    mode: "all",
  });

  const investmentFormSpiny = useForm({
    defaultValues: {
      investment_production_capacity_spiny: selectedMap.investment_production_capacity_spiny
    },
    mode: "all",
  });

  const { handleSubmit : handleTDISubmit, control: mvdFormControl, trigger: triggerValidateMVD, getValues: getMVDValues, register: registerMVD, formState: mvdFormState} = mvdForm;

  const { handleSubmit : handleMVDSpinySubmit, control: mvdSpinyFormControl, trigger: triggerValidateMVDSpiny, getValues: getMVDSpinyValues, register: registerMVDSpiny, formState: mvdSpinyFormState} = mvdFormSpiny;

  const { handleSubmit : handlePFDSubmit, control: pfdFormControl, trigger: triggerValidatePFD, getValues: getPFDValues, register: registerPFD, formState: pfdFormState} = pfdForm;

  const { handleSubmit : handleHIPConchSubmit, control: hipConchFormControl, trigger: triggerValidateHIPConch, getValues: getHIPConchValues, register: registerHIPConch, formState: hipConchFormState} = hipConchForm;

  const { handleSubmit : handleHIPSeaCucumberSubmit, control: hipSeaCucumberFormControl, trigger: triggerValidateHIPSeaCucumber, getValues: getHIPSeaCucumberValues, register: registerHIPSeaCucumber, formState: hipSeaCucumberFormState} = hipSeacucumberForm;

  const { handleSubmit : handleHIPSpinySubmit, control: hipSpinyFormControl, trigger: triggerValidateHIPSpiny, getValues: getHIPSpinyValues, register: registerHIPSpiny, formState: hipSpinyFormState} = hipSpinyForm;

  const { handleSubmit : handleInvestmentSubmit, control: investmentFormControl, trigger: triggerValidateInvestment, getValues: getInvestmentValues, register: registerInvestment, formState: investmentFormState} = investmentForm;

  const { handleSubmit : handleInvestmentSpinySubmit, control: investmentSpinyFormControl, trigger: triggerValidateInvestmentSpiny, getValues: getInvestmentSpinyValues, register: registerInvestmentSpiny, formState: investmentSpinyFormState} = investmentFormSpiny;

  const validateMVDForm = async () => {
    try {

      const isFormValid = await triggerValidateMVD();
      const data = await getMVDValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            marine_vessel_details: data.marine_vessel_details
          }
        });
        handleTDISubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateMVDSpinyForm = async () => {
    try {

      const isFormValid = await triggerValidateMVD();
      const data = await getMVDValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            marine_vessel_details_spiny: data.marine_vessel_details_spiny
          }
        });
        handleTDISubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };


  const validatePFDForm = async () => {
    try {

      const isFormValid = await triggerValidatePFD();
      const data = await getPFDValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            proposed_fishing_details: data.proposed_fishing_details,
            percentage_intended_for_export: data.percentage_intended_for_export,
            percentage_intended_for_local_sales: data.percentage_intended_for_local_sales
          }
        });

        handlePFDSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateHIPConchForm = async () => {
    try {
      const isFormValid = await triggerValidateHIPConch();
      const data = await getHIPConchValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            historical_industry_performance_conch: data.historical_industry_performance_conch,
            year_applicant_in_industry_conch: data.year_applicant_in_industry_conch,
            number_of_years_allocated_quota_conch: data.number_of_years_allocated_quota_conch,
            total_investment_conch: data.total_investment_conch
          }
        });

        handleHIPConchSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateHIPSeaCucumberForm = async () => {
    try {
      const isFormValid = await triggerValidateHIPSeaCucumber();
      const data = await getHIPSeaCucumberValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            historical_industry_performance_sea_cucumber: data.historical_industry_performance_sea_cucumber,
            year_applicant_in_industry_sea_cucumber: data.year_applicant_in_industry_sea_cucumber,
            number_of_years_allocated_quota_sea_cucumber: data.number_of_years_allocated_quota_sea_cucumber,
            total_investment_sea_cucumber: data.total_investment_sea_cucumber
          }
        });

        handleHIPSeaCucumberSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateHIPSpinyForm = async () => {
    try {

      const isFormValid = await triggerValidateHIPSpiny();
      const data = await getHIPSpinyValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            historical_industry_performance_spiny: data.historical_industry_performance_spiny,
            year_applicant_entered_in_industry_spiny: data.year_applicant_entered_in_industry_spiny,
            number_of_years_allocated_quota_spiny: data.number_of_years_allocated_quota_spiny
          }
        });
        handleHIPSpinySubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateInvestment = async () => {
    try {

      const isFormValid = await triggerValidateInvestment();
      const data = await getInvestmentValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            investment_production_capacity: data.investment_production_capacity,
          }
        });
        handleInvestmentSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateInvestmentSpiny = async () => {
    try {

      const isFormValid = await triggerValidateInvestmentSpiny();
      const data = await getInvestmentSpinyValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map, 
            investment_production_capacity_spiny: data.investment_production_capacity_spiny,
          }
        });
        handleInvestmentSpinySubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 0 && (
        <NFARegNumberSelection
          setSelectedMap={setSelectedMap}
          selectionCategory={selectionCategory}
          setSelectionCategory={setSelectionCategory}
          history={history}
          selectedMap={selectedMap}
        />
      )}

      {selectionCategory === 1 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select type of Transaction"}
            listingOptions={typeofTransactions}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_transaction"}
            onClick={(value, i) => {
              if (value !== "Renewal")
                setSelectionCategory(selectionCategory + 1);
            }}
            listingStyle="justify-content-center"
          />

          {["Renewal"].indexOf(selectedMap["type_of_transaction"]) !== -1 && (
            <CustomInputContainer
              heading={"Enter Licence Number"}
              prop_key={"renewal_licence_number"}
              placeholder={"Enter Licence number"}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
            ></CustomInputContainer>
          )}

          <InternalNavigator
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if (selectedMap.type_of_transaction === "Renewal") {
                if (!validateKeys(selectedMap, ["renewal_licence_number"])) {
                  alertService.warn(inputPreviousNumber("Licence Number"));
                  return;
                }
              }

              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              // history.goBack();
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 2 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Category of Quota & Fishing Rights"}
            listingOptions={categories}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={(trans) => {
              if (trans === SPINY_LOBSTER_QUOTA) {
                setSelectionCategory(selectionCategory + 2);
              } else {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prop_key={"category"}
            listingStyle="justify-content-center"
            listingDescriptions={[
              specieal_species_allow[CONCH].description,
              specieal_species_allow[SEA_CUCUMBER].description,
              specieal_species_allow[SPINY_LOBSTER].description,
             
             
            ]}
            // listingDisabled={[
              
            //   !isDateInRange(
            //     specieal_species_allow[CONCH].start_date,
            //     specieal_species_allow[CONCH].end_date
            //   ),
            //   !isDateInRange(
            //     specieal_species_allow[SEA_CUCUMBER].start_date,
            //     specieal_species_allow[SEA_CUCUMBER].end_date
            //   ),
            //   !isDateInRange(
            //     specieal_species_allow[SPINY_LOBSTER].start_date,
            //     specieal_species_allow[SPINY_LOBSTER].end_date
            //   ),
            // ]}

          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (!validateKeys(selectedMap, ["category"])) {
                alertService.warn(selectionNotFound);
                return;
              }
              if (selectedMap.category === SPINY_LOBSTER_QUOTA) {
                setSelectionCategory(selectionCategory + 2);
              } else {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 3 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Sub Category of Quota & Fishing Rights"}
            listingOptions={subCategories[selectedMap.category]}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            prop_key={"sub_category"}
            listingStyle="justify-content-center"
          />

          {getGenericNavigator({
            setSelectionCategory,
            selectionCategory,
            selectedMap,
            validations: ["sub_category"],
          })}
        </div>
      )}

      {selectionCategory === 4 && (
        <>
          {(selectedMap.category === CONCH_QUOTA ||
            selectedMap.category === SEA_CUCUMBER_QUOTA) && (
            <div>
              <MarineVesselDetails
                formState={mvdFormState}
                register={registerMVD}
                control={mvdFormControl}
                onSubmit={onSubmit}
              />

              <InternalNavigator
                nextValue={"Next"}
                nextAction={async () => {
                  if (await validateMVDForm()) {
                    setSelectionCategory(selectionCategory + 1);
                  }
                }}
                prevValue={"Back"}
                previousAction={() => {
                  if (selectedMap.category === SPINY_LOBSTER_QUOTA) {
                    setSelectionCategory(selectionCategory - 2);
                  } else {
                    setSelectionCategory(selectionCategory - 1);
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      {selectionCategory === 4 && (
        <>
          {(selectedMap.category === SPINY_LOBSTER_QUOTA) && (
            <div>
              <MarineVesselDetailsSpiny
                formState={mvdSpinyFormState}
                register={registerMVDSpiny}
                control={mvdSpinyFormControl}
                onSubmit={onSubmit}
              />

              <InternalNavigator
                nextValue={"Next"}
                nextAction={async () => {
                  if (await validateMVDSpinyForm()) {
                    setSelectionCategory(selectionCategory + 1);
                  }
                }}
                prevValue={"Back"}
                previousAction={() => {
                  if (selectedMap.category === SPINY_LOBSTER_QUOTA) {
                    setSelectionCategory(selectionCategory - 2);
                  } else {
                    setSelectionCategory(selectionCategory - 1);
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      {selectionCategory === 5 && (
        <div>
          <ProposedFishingDetails
            formState={pfdFormState}
            register={registerPFD}
            control={pfdFormControl}
            onSubmit={onSubmit}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validatePFDForm()) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}


      {selectionCategory === 6 && selectedMap.category === CONCH_QUOTA && (
        <div>
          <ConchHistoricalIndustryPerformance
            formState={hipConchFormState}
            register={registerHIPConch}
            control={hipConchFormControl}
            onSubmit={onSubmit}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validateHIPConchForm()) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 6 &&
        selectedMap.category === SEA_CUCUMBER_QUOTA && (
          <div>
            <SeaCucumberHistoricalIndustryPerformance
              formState={hipSeaCucumberFormState}
              register={registerHIPSeaCucumber}
              control={hipSeaCucumberFormControl}
              onSubmit={onSubmit}
            />

            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateHIPSeaCucumberForm()) {
                  setSelectionCategory(selectionCategory + 1);
                }
              }}
              prevValue={"Back"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        )}

      {selectionCategory === 6 && selectedMap.category === SPINY_LOBSTER_QUOTA && (
        <div>
          <SpinyHistoricalIndustryPerformance
            formState={hipSpinyFormState}
            register={registerHIPSpiny}
            control={hipSpinyFormControl}
            onSubmit={onSubmit}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validateHIPSpinyForm()) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 7 &&
        (selectedMap.category === CONCH_QUOTA ||
          selectedMap.category === SEA_CUCUMBER_QUOTA) && (
          <div>
            <InvestmentAndProductionCapacity
              formState={investmentFormState}
              register={registerInvestment}
              control={investmentFormControl}
              onSubmit={onSubmit}
            />

            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateInvestment()) {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory,
                    };
                  });

                  props?.next();
                }
              }}
              prevValue={"Back"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        )}

      {selectionCategory === 7 && selectedMap.category === SPINY_LOBSTER_QUOTA && (
        <div>
          <SpinyInvestment
            formState={investmentSpinyFormState}
            register={registerInvestmentSpiny}
            control={investmentSpinyFormControl}
            onSubmit={onSubmit}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validateInvestmentSpiny()) {
                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });

                props?.next();
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}



    
    </div>
  );
}


const getGenericNavigator = ({setSelectionCategory, selectionCategory, validations = [], selectedMap = {}}) => {
  return <InternalNavigator
  nextValue={"Next"}
  nextAction={() => {

    if(!validateKeys(selectedMap, validations)){
      alertService.warn(selectionNotFound, {autoClose: true});
      return;
    }
    setSelectionCategory(selectionCategory + 1);
  }}
  prevValue={"Back"}
  previousAction={() => {
    setSelectionCategory(selectionCategory - 1);
  }}
/>;
}

