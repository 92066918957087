import React, { useEffect } from "react";
import {
  FormHookCheckbox,
  FormHookInput,
  FormHookRadioInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { plannedHistoricalProductionHeadings, productionUnits } from "./AquaFacilityDataSource";
import { useFieldArray } from "react-hook-form";
import { years, yearsInAdvance } from "../../components/DataSource";
import { typeOfSpecies } from "../AquacultureFishingVessel/AquaFishingVesselDataSource";

const HISTORICAL = "Historical";
const PLANNED = "Planned";

export const HistoricalPlannedProduction = ({
  formState,
  control,
  register,
  onSubmit,
  setValue,
  values,
  watch,
  disabled,
  showHeading = true,
}) => {
  const { errors } = formState;
  const productionType = watch("production_type");

  const isHistorical = productionType && productionType?.indexOf(HISTORICAL) >= 0;  
  const isPlanned = productionType && productionType?.indexOf(PLANNED) >= 0;


  const histProd = watch("historical_production");

  const plannedProd = watch("planned_production");


  useEffect(() => {
    let count = 0;
    let output = 0;
    histProd?.forEach(hist => {
      if(hist?.output_kg) {
        count++;
        output += parseFloat(hist?.output_kg);
      }
    })

    setValue('historical_production_unit_total',count);
    setValue('historical_output_total', output);
  }, [JSON.stringify(histProd)])


  useEffect(() => {

    let count = 0;
    let output = 0;
    plannedProd?.forEach(hist => {
      if(hist?.output_kg) {
        count++;
        output += parseFloat(hist?.output_kg);
      }
    })

    setValue('planned_production_unit_total',count);
    setValue('planned_output_total', output);

  }, [JSON.stringify(plannedProd)])


  const {
    fields: historical_fields,
    append: append_historical,
    remove: remove_historical,
  } = useFieldArray({
    control,
    name: "historical_production",
  });

  const {
    fields: planned_fields,
    append: append_planned,
    remove: remove_planned,
  } = useFieldArray({
    control,
    name: "planned_production",
  });

  return (
    // <fieldset disabled={disabled}>
    //   <form onSubmit={onSubmit}>
    <div className={`p-3 mb-3 ${disabled ? "non-functional-element" : ""}`}>
      <div className="d-flex form-heading">
        {showHeading && (
          <div
            style={{ marginRight: "10px" }}
          >{`${"Historical or Planned Production"}`}</div>
        )}
      </div>

      {/* for historical */}
      <div className="d-flex-wrap justify-content-left align-items-center">
        <FormHookCheckbox
          fieldClassName="d-flex-wrap flex-row"
          options={["Historical"]}
          register={register}
          error={errors?.production?.message}
          regFieldName={`production_type`}
          hideLabel={true}
        />

        <table
          className={`flex-grow-1 table-responsive table table-bordered table-sm`}
        >
          <thead>
            <tr>
            
              {plannedHistoricalProductionHeadings.map((head) => (
                <th key={head.key}>{head.heading}</th>
              ))}
                <th>Add</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {historical_fields.map((field, groupIndex) => {
              return (
                <tr key={field.id}>
                  <td>
                    <FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      parentClass="individual-field-micro"
                      regFieldName={`historical_production[${groupIndex}].year`}
                      options={years}
                      error={
                        errors?.historical_production?.[groupIndex]?.year
                          ?.message
                      }
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    />
                  </td>
                  <td>
                    {/* <FormHookInput
                      parentClass="individual-field-micro max-200"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.historical_production?.[groupIndex]?.species
                          ?.message
                      }
                      regFieldName={`historical_production.${groupIndex}.species`}
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    /> */}

                    <FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      parentClass="individual-field-micro max-200"
                      regFieldName={`historical_production[${groupIndex}].species`}
                      options={typeOfSpecies}
                      error={
                        errors?.historical_production?.[groupIndex]?.species
                          ?.message
                      }
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    />
                  </td>
                  <td>
                    {/* <FormHookInput
                      parentClass="individual-field-micro max-200"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.historical_production?.[groupIndex]
                          ?.production_unit?.message
                      }
                      regFieldName={`historical_production.${groupIndex}.production_unit`}
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    /> */}

                    <FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      parentClass="individual-field-micro max-200"
                      regFieldName={`historical_production[${groupIndex}].production_unit`}
                      options={productionUnits}
                      error={
                        errors?.historical_production?.[groupIndex]
                          ?.production_unit?.message
                      }
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro max-100"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.historical_production?.[groupIndex]?.output_kg
                          ?.message
                      }
                      type="number"
                      regFieldName={`historical_production.${groupIndex}.output_kg`}
                      isDisabled={!isHistorical}
                      isRequired={
                        isHistorical ? "This field is required" : false
                      }
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.historical_production?.[groupIndex]?.comments
                          ?.message
                      }
                      regFieldName={`historical_production.${groupIndex}.comments`}
                      isDisabled={!isHistorical}
                      isRequired={false}
                    />
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        append_historical({ id: Math.random() });
                      }}
                      className="btn btn-success pl-4 pr-4"
                      disabled={!isHistorical}
                    >
                      Add
                    </button>
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        remove_historical(groupIndex);
                      }}
                      className="btn btn-danger"
                      disabled={
                        !isHistorical || historical_fields?.length === 1
                      }
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}

            <tr>
              <td colSpan={2}>
                <b>Total:</b>
              </td>

              <td>
              <FormHookInput
                  parentClass="individual-field-micro"
                  label="Total"
                  hideLabel={true}
                  register={register}
                  type="number"
                  error={errors?.historical_production_unit_total?.message}
                  regFieldName={`historical_production_unit_total`}
                  isRequired={
                    isHistorical ? "This field is required" : false
                  }
                  isDisabled={!isHistorical || true}
                />

              </td>

              <td>
              <FormHookInput
                  parentClass="individual-field-micro max-100"
                  label="Total"
                  hideLabel={true}
                  register={register}
                  type="number"
                  error={errors?.historical_output_total?.message}
                  regFieldName={`historical_output_total`}
                  isRequired={
                    isHistorical ? "This field is required" : false
                  }
                  isDisabled={!isHistorical || true}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* for planned */}

      <div className="d-flex-wrap justify-content-left align-items-center">
        <FormHookCheckbox
          fieldClassName="d-flex-wrap flex-row"
          options={["Planned"]}
          register={register}
          error={errors?.production?.message}
          regFieldName={`production_type`}
          hideLabel={true}
        />

        <table className="flex-grow-1 table-responsive table table-bordered table-sm">
          <thead>
            <tr>
             
              {plannedHistoricalProductionHeadings.map((head) => (
                <th key={head.key}>{head.heading}</th>
              ))}
               <th>Add</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {planned_fields.map((field, groupIndex) => {
              return (
                <tr key={field.id}>
                 

                  <td>
                    <FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      regFieldName={`planned_production[${groupIndex}].year`}
                      options={yearsInAdvance(10)}
                      error={
                        errors?.planned_production?.[groupIndex]?.year?.message
                      }
                      parentClass="individual-field-micro"
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    />
                  </td>
                  <td>
                    {/* <FormHookInput
                      parentClass="individual-field-micro max-200"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.planned_production?.[groupIndex]?.species
                          ?.message
                      }
                      regFieldName={`planned_production.${groupIndex}.species`}
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    /> */}
                    <FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      regFieldName={`planned_production[${groupIndex}].species`}
                      options={typeOfSpecies}
                      error={
                        errors?.planned_production?.[groupIndex]?.species?.message
                      }
                      parentClass="individual-field-micro max-200"
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    />
                  </td>
                  <td>
                    {/* <FormHookInput
                      parentClass="individual-field-micro max-200"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.planned_production?.[groupIndex]
                          ?.production_unit?.message
                      }
                      regFieldName={`planned_production.${groupIndex}.production_unit`}
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    /> */}

<FormHookSelect
                      label=""
                      hideLabel={true}
                      register={register}
                      regFieldName={`planned_production[${groupIndex}].production_unit`}
                      options={productionUnits}
                      error={
                        errors?.planned_production?.[groupIndex]?.production_unit?.message
                      }
                      parentClass="individual-field-micro max-200"
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    />


                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field-micro max-100"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.planned_production?.[groupIndex]?.output_kg
                          ?.message
                      }
                      type="number"
                      regFieldName={`planned_production.${groupIndex}.output_kg`}
                      isDisabled={!isPlanned}
                      isRequired={
                        isPlanned ? "This field is required" : false
                      }
                    />
                  </td>

                  <td>
                    <FormHookInput
                      parentClass="individual-field"
                      hideLabel={true}
                      register={register}
                      error={
                        errors?.planned_production?.[groupIndex]?.comments
                          ?.message
                      }
                      regFieldName={`planned_production.${groupIndex}.comments`}
                      isDisabled={!isPlanned}
                      isRequired={false}
                    />
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        append_planned({ id: Math.random() });
                      }}
                      className="btn btn-success pl-4 pr-4"
                      disabled={!isPlanned}
                    >
                      Add
                    </button>
                  </td>

                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        remove_planned(groupIndex);
                      }}
                      className="btn btn-danger"
                      disabled={
                        !isPlanned ||
                        planned_fields?.length === 1
                      }
                    >
                      Remove
                    </button>
                  </td>


                </tr>
              );
            })}

            <tr>
              <td colSpan={2}>
                <b>Total:</b>
              </td>

              <td>
              <FormHookInput
                  parentClass="individual-field-micro "
                  label="Total"
                  hideLabel={true}
                  register={register}
                  type="number"
                  error={errors?.planned_production_unit_total?.message}
                  regFieldName={`planned_production_unit_total`}
                  isRequired={
                    isPlanned ? "This field is required" : false
                  }
                  isDisabled={!isPlanned || true}
                />
              </td>

             
              <td>
              <FormHookInput
                  parentClass="individual-field-micro max-100"
                  label="Total"
                  hideLabel={true}
                  register={register}
                  type="number"
                  error={errors?.planned_output_total?.message}
                  regFieldName={`planned_output_total`}
                  isRequired={
                    isPlanned ? "This field is required" : false
                  }
                  isDisabled={!isPlanned || true}
                />
              </td>


            </tr>
          </tbody>
        </table>
      </div>
    </div>
    //   </form>
    // </fieldset>
  );
};
