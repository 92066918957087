import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeOptions, getApplicationSpecificDetails } from "../utils/utils";
import { desc } from "../config/application-submission-config";
import AlertDialogSlide from "../components/Modal/Modal";
import { FormHookTextArea } from "../components/FormHook/ReactFormHookFields";
import SupportingDocumentWithoutDefinedTypes from "../components/FormSteps/SupportingDocumentWithoutDefinedTypes";
import ReturnedForCorrectionsSupportingDoc from "./ReturnedForCorrectionsSupportingDoc";
import { alertService } from "../_services";
import { postData } from "../services/service-call";
import { ROUTES } from "../config";
import { getTransactions } from "../redux/actions/transactions";

const RETURNED_FOR_CORRECTIONS = "Returned For Correction";


const ReplyToGeneralCorrection = ({transaction, setComment}) => {


    return (<>

    <h4 style={{color: 'black'}}>Respond to Correction Request</h4>
    <pre><b>Comments</b> - {transaction?.comments}</pre>
    

    <div
      className={``}
    >
      {
       <label>Reply Comments</label>
      }

      <textarea
        disabled={false}
        rows={4}
        cols={50}
        className={"form-control"}
        onChange={(event) => setComment(event.target.value)}
      />

    </div>


    <ReturnedForCorrectionsSupportingDoc
          classes={null}
          trans_number={transaction.id}
          temp_trams_id={transaction.temp_trans_id}
        />
    </>);
}


const ReturnedForCorrections = () => {

    const [comment , setComment] = useState('');


  const transactions = useSelector((state) => {
    return (
      state.transactions.transactions?.filter(
        (trans) => trans.status === RETURNED_FOR_CORRECTIONS
      ) || []
    );
  });

  const [dialog, showDialog] = useState(false);

  const [currentTransaction, setCurrentTransaction] = useState({});

  const dispatch = useDispatch();


  return (
    <>
      <AlertDialogSlide
        fullWidth={false}
        open={dialog}
        title={""}
        handleClose={async () => {
          if (!comment) {
            alertService.warn("Comment is Mandatory", {
              autoClose: true,
              keepAfterRouteChange: true,
            });
            return;
          }
          const result = await postData({
            url: `${ROUTES.revertGeneralCorrection}${currentTransaction.case_folder_id}`,
            body: {
              comment,
            },
          });
          if (result.statusCode === 200) {
            alertService.success(result.data.msg, {
              autoClose: true,
              keepAfterRouteChange: true,
            });
            setComment("");
            dispatch(getTransactions());
          } else {
            alertService.error(
              "Error occured while responding to correction, please try again",
              { autoClose: true, keepAfterRouteChange: true }
            );
          }

          showDialog(false);
        }}
        handleCloseSecondary={() => {
          showDialog(false);
        }}
        primaryActionName={"Submit"}
        secondaryActionName="Close"
        content={
          <ReplyToGeneralCorrection
            setComment={setComment}
            transaction={currentTransaction}
          />
        }
      ></AlertDialogSlide>

      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="custom-table-header">
            <tr>
              <th>Date</th>
              <th>Type of Application</th>
              <th>Type of Transaction</th>
              <th>Transaction Number</th>
              <th>Category of Licence</th>
              <th>Comments</th>
              <th>Reply</th>
            </tr>
          </thead>

          <tbody className="table-striped">
            {transactions?.map((trans) => {
              return (
                <tr key={trans.id}>
                  <td>
                    {new Date(trans.updated_at).toLocaleString(
                      "en-US",
                      dateTimeOptions
                    )}
                  </td>
                  <td>{desc[trans.app_type]}</td>
                  <td>
                    {getApplicationSpecificDetails(
                      trans,
                      "type_of_transaction"
                    )}
                  </td>
                  <td>{trans.id}</td>
                  <td>
                    {getApplicationSpecificDetails(
                      trans,
                      "licence_type"
                    )}
                  </td>

                  <td> {trans.comments}</td>
                  <td>
                    <button
                      onClick={() => {
                        setCurrentTransaction(trans);
                        showDialog(true);
                      }}
                      className="btn btn-sm btn-warning"
                    >
                      Reply
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReturnedForCorrections;
