import React, { useState, useEffect } from 'react';

const DocumentPreview = ({ blob }) => {
  const [fileType, setFileType] = useState('');
  const [objectURL, setObjectURL] = useState('');

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setObjectURL(url);
      
      const extension = blob.type.split('/').pop().toLowerCase();
      setFileType(extension);

      // Cleanup the object URL when the component unmounts
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [blob]);

  const renderDocument = () => {
    if (fileType === 'pdf') {
      return <embed src={objectURL} type="application/pdf" width="100%" height="600px" />;
    } else if (['png', 'jpg', 'jpeg', 'gif'].includes(fileType)) {
      return <img src={objectURL} alt="Document Preview" width="100%" height="auto" />;
    } else if (['plain', 'txt'].includes(fileType)) {
      return <iframe src={objectURL} width="100%" height="600px" />;
    } else if (['msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document',
                'vnd.ms-excel', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'vnd.ms-powerpoint', 'vnd.openxmlformats-officedocument.presentationml.presentation'].includes(blob.type)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(objectURL)}`}
          width="100%"
          height="600px"
          frameBorder="0"
        />
      );
    } else {
      return <p>Unsupported file type: {fileType}</p>;
    }
  };

  return (
    <div>
      {blob ? renderDocument() : <p>No document to preview</p>}
    </div>
  );
};

export default DocumentPreview;