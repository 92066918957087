import React, { useState, useEffect } from "react";
import axios from "axios";
import { getData, postData, putData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { useForm } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
  FormHookTextArea,
} from "../../components/FormHook/ReactFormHookFields";
import { getReadableDateFromMonthAndDate, reportHeader } from "../../utils/utils";
import { alertService } from "../../_services";

const parentStyle = {margin: '10px'};
const ManageSpeciesSeasons = () => {
  const [species, setSpecies] = useState([]);

  const [currentSpecie, setCurrentSpecie] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await getData({
        url: ROUTES.getSpeciesSeasons,
      });

      setSpecies(result.data);
    })();
  }, [isEditing]);

  const UpdateForm = ({ specie }) => {
    const {
      register,
      handleSubmit,
      watch,
      formState,
      setValue,
      getValues,
      trigger,
    } = useForm({
      defaultValues: specie,
      mode: "all",
    });

    const { errors } = formState;


    useEffect(() => {

        if(specie.start_date) setValue('start_date', `${new Date().getFullYear()}-${specie.start_date}`);
        if(specie.end_date) setValue('end_date', `${new Date().getFullYear()}-${specie.end_date}`);


    }, [specie]);

    return (
      <div style={parentStyle}>
        <div className="d-flex-wrap flex-row justify-content-start">
          {/* <FormHookSelect
            label="Application Name"
            register={register}
            regFieldName={`application_name`}
            options={[
             
              {
                key: "APPLICATION_FOR_LICENCE",
                value: "Application for Fisher Licence",
              },
              {
                key: "APPLICATION_FOR_FISHING_VESSEL",
                value: "Application For Fishing Vessel Licence",
              },
              {
                key: "APPLICATION_FOR_PERMIT",
                value: "Application For Permit",
              },
              {
                key: "APPLICATION_QUOTA_FISHING",
                value: "Application for Quota and Fishing Rights",
              },
            ]}
            error={errors?.application_name?.message}
          /> */}

          <FormHookInput
            register={register}
            error={errors?.specie_name?.message}
            label="Specie Name"
            regFieldName="specie_name"
            isDisabled={true}
          />

          <FormHookInput
            register={register}
            error={errors?.start_date?.message}
            label="Closed Season Start Date"
            regFieldName="start_date"
            type="date"
          />

          <FormHookInput
            register={register}
            error={errors?.end_date?.message}
            label="Closed Season End Date"
            regFieldName="end_date"
            type="date"
          />

          <FormHookTextArea
            register={register}
            error={errors?.description?.message}
            label="Description"
            regFieldName="description"
            placeholder="Description"
            type="textarea"
            parentClass="flex-grow-1"
          />
        </div>

        <div className="m-2 d-flex-wrap flex-row justify-content-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={async () => {
              if (await trigger()) {
                const data = getValues();

                const result = await putData({
                  url: `${ROUTES.updateSpeciesSeasons}/${specie.id}`,
                  body: data,
                });
                if (result.statusCode === 200) {
                  alertService.success(result.data.msg);
                  setIsEditing(false);
                }
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  };

  const SpeciesList = () => {
    return (
      <div style={parentStyle}>
        <ResponsiveTable
          headers={[
            "",
            // "Application Name",
            "Specie Name",
            "Closed Season Start Date",
            "Closed Season End Date",
            "Description",
            "Edit",
          ]}
          headerClass="blue-gradient-bg"
        >
          <tbody style={{fontWeight: 600}}>
            {species.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.specie_name}</td>
                  <td>{getReadableDateFromMonthAndDate(item.start_date)}</td>

                  <td>{getReadableDateFromMonthAndDate(item.end_date)}</td>

                  <td>{item.description}</td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        setIsEditing(true);
                        setCurrentSpecie(item);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      </div>
    );
  };

  return (
    <div className="simple-border-2" >
      <div className="simple-border" style={{margin: '10px'}}>
        {reportHeader("Manage Species Closed Season")}

        {isEditing && <UpdateForm specie={currentSpecie} />}
      </div>

      <SpeciesList></SpeciesList>
    </div>
  );
};

export default ManageSpeciesSeasons;
