import React, { useContext } from "react";
import { useFieldArray } from "react-hook-form";
import {
  FormHookInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { fishingAreaLocations } from "../../components/DataSource";
import { FLISContext } from "../../context/FLISContext";
import {
  CONCH_QUOTA,
  SEA_CUCUMBER_QUOTA,
  SPINY_LOBSTER_QUOTA,
} from "./QuotaFishingDataSource";
import HorizontalLine from "../../components/HorLine";

export const ProposedFishingDetails = ({
  formState,
  control,
  register,
  onSubmit,
  shouldRenderInForm = true,
  isDisabled = false,
}) => {
  const { selectedMap } = useContext(FLISContext);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "proposed_fishing_details",
  });

  const { errors } = formState;

  const flds = (
    <>
      {fields.map((group, groupIndex) => {
        return (
          <div key={group.id} className="shadow p-3 mb-3 bg-white rounded">
            <div className="d-flex flex-row form-heading">
              <div style={{ marginRight: "10px" }}>
                {`${"Proposed Fishing Detail"} #${groupIndex + 1}`}
              </div>

              <div>
                {!isDisabled && (
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      remove(groupIndex);
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex-wrap flex-row justify-content-start">
              {/* <FormHookInput
                      register={register}
                      error={
                        errors?.proposed_fishing_details?.[groupIndex]
                          ?.fishing_area_zone?.message
                      }
                      label="Fishing Area / Zone"
                      placeholder="Fishing Area / Zone"
                      regFieldName={`proposed_fishing_details[${groupIndex}].fishing_area_zone`}
                      isDisabled={isDisabled}
                    /> */}

              <FormHookSelect
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]
                    ?.fishing_area_zone?.message
                }
                label="Fishing Area / Zone"
                regFieldName={`proposed_fishing_details[${groupIndex}].fishing_area_zone`}
                isDisabled={isDisabled}
                options={fishingAreaLocations}
              />

              {/* <FormHookInput
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]?.proposed_dates
                    ?.message
                }
                label="Dates"
                regFieldName={`proposed_fishing_details[${groupIndex}].proposed_dates`}
                type="date"
                isDisabled={isDisabled}
              /> */}

              <FormHookInput
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]
                    ?.proposed_date_from?.message
                }
                label="Date From"
                regFieldName={`proposed_fishing_details[${groupIndex}].proposed_date_from`}
                type="date"
                isDisabled={isDisabled}
              />

              <FormHookInput
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]
                    ?.proposed_date_to?.message
                }
                label="Date To"
                regFieldName={`proposed_fishing_details[${groupIndex}].proposed_date_to`}
                type="date"
                isDisabled={isDisabled}
              />

              <FormHookInput
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]
                    ?.est_catch_quota?.message
                }
                label="Est. Catch / Quota (Kg @ 50%)"
                placeholder="Est. Catch / Quota (Kg @ 50%)"
                regFieldName={`proposed_fishing_details[${groupIndex}].est_catch_quota`}
                isDisabled={isDisabled}
              />

              {selectedMap.category === SPINY_LOBSTER_QUOTA && (
                <FormHookInput
                  register={register}
                  error={
                    errors?.proposed_fishing_details?.[groupIndex]
                      ?.number_of_pots?.message
                  }
                  label="Number of Pots"
                  placeholder="Number of Pots"
                  regFieldName={`proposed_fishing_details[${groupIndex}].number_of_pots`}
                  type="number"
                  validations={{
                    maxLength: { value: 7, message: "Max length is 7" },
                  }}
                  isDisabled={isDisabled}
                />
              )}
              <FormHookInput
                register={register}
                error={
                  errors?.proposed_fishing_details?.[groupIndex]
                    ?.number_of_divers?.message
                }
                label="Number of Divers"
                placeholder="Number of Divers"
                regFieldName={`proposed_fishing_details[${groupIndex}].number_of_divers`}
                type="number"
                validations={{
                  maxLength: { value: 7, message: "Max length is 7" },
                }}
                isDisabled={isDisabled}
              />

              {(selectedMap.category === CONCH_QUOTA ||
                selectedMap.category === SEA_CUCUMBER_QUOTA) && (
                <FormHookInput
                  register={register}
                  error={
                    errors?.proposed_fishing_details?.[groupIndex]?.comments
                      ?.message
                  }
                  label="Comments"
                  placeholder="Comments"
                  regFieldName={`proposed_fishing_details[${groupIndex}].comments`}
                  isDisabled={isDisabled}
                  isRequired={false}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
  return (
    <div className="form-container">
      {shouldRenderInForm && (
        <fieldset>
          <form onSubmit={onSubmit}>
            <>{flds}</>
          </form>
        </fieldset>
      )}

      {!shouldRenderInForm && <>{flds}</>}
      
      <div className="mb-3 d-flex flex-row-reverse">
        {!isDisabled && (
          <button
            onClick={() => {
              append({ id: Math.random() });
            }}
            type="button"
            className="btn btn-primary"
          >
            Add Proposed Fishing Details
          </button>
        )}
      </div>



      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="form-heading">Intented Use of Quota</div>
        <HorizontalLine></HorizontalLine>
        <div className="d-flex-wrap flex-row justify-content-start">
          <FormHookInput
            register={register}
            error={errors?.percentage_intended_for_export?.message}
            label="Percentage Intended for Export 1"
            regFieldName="percentage_intended_for_export"
            placeholder="Percentage Intended for Export"
            type="number"
            isDisabled={isDisabled}
          />

          <FormHookInput
            register={register}
            error={errors?.percentage_intended_for_local_sales?.message}
            label="Percentage Intended for Local Sales"
            regFieldName="percentage_intended_for_local_sales"
            placeholder="Percentage Intended for Local Sales"
            type="number"
            isDisabled={isDisabled}
          />
        </div>
      </div>
      
    </div>
  );
};
