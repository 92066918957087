import React from 'react'



export default function HorizontalLine() {

    return (
       <div style={{height:'0.5px !important', padding:'1px', backgroundColor:'#ddd'}}></div>
    )
}
