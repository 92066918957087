import React, { useEffect, useState } from 'react';
import { ROUTES } from '../config';
import { getData } from '../services/service-call';
import ApplicationHeading from '../../js//components/ApplicationHeading';

function EntityPortal(props) {

  

    return (
      <div className="text-center">
        <div className='text-bold little-big-text'>Entity Portal</div>
       


        <div>   
            
        </div>


      </div>
    );
}

export default EntityPortal;