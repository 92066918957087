import { useEffect, useState } from "react";
import { crossIcon } from "../../../assets/icons/icons_svg";
import { getBase64 } from "../../utils/utils";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";

export const FormHookFileUploadHandler = ({
    parentClass = "individual-field",
    label = "",
    fieldClassName = "",
    register,
    isRequired = "This field is required",
    regFieldName = "",
    placeholder = "",
    hideLabel = false,
    labelClass = "",
    error = "",
    isPreviewable=false,
    watch,
    removeFile,
    accept,
    docInfo,
    setValue,
    toShowLink=true,
    toShowInstructionLink=false,
    isDisabled=false
  }) => {

    // if(isRequired && typeof isRequired === "boolean") {
    //   isRequired = "This field is required";
    // }

    const location = useLocation();
    const isPDFGenerator = location.pathname === '/form-render-for-pdf';
    // const queryParams = new URLSearchParams(location.search);


    function resizeIframe() {
      const iframe = document.getElementById("doc-preview-iframe");
      if(!iframe) return;
      // const contentWidth = iframe.contentWindow.document.body.scrollWidth;
      // const contentHeight = iframe.contentWindow.document.body.scrollHeight;

      // iframe.style.width = contentWidth + "px";
      // iframe.style.height = contentHeight + "px";
      // iframe.style.width =  "100%";
      // iframe.style.height = "100%";
    }


    useEffect(() => {
      console.log(location);
      if(isPDFGenerator) {
        setTimeout(() => {
          resizeIframe();
        }, 1000);
      }
    }, []);
  

  
  
    // this works like onchange for react form hook
    const selectedFile = watch(regFieldName); 
    const [url, setUrl] = useState();

    // const [files, setFiles] = useState();

    const onChange = async (files) => {

        if(files && files.length){
            const blob = URL.createObjectURL(files[0]);
            const name = files[0].name;
            setUrl(blob);
            const { base64: content, fullData, contentType } = await getBase64(files[0]);


            setValue(regFieldName, {
                name, content, contentType
            });
            if(isPDFGenerator) {
              resizeIframe();
            }
            console.log(location);
            
        }
    }


    useEffect(() => {

       async function processDocument() {

        if(selectedFile && selectedFile.name){
          // Decode the Base64 string
        //   const {contentType, base64} = getBase64Component(selectedFile);
          const byteCharacters = atob(selectedFile.content);

          const byteNumbers = new Array(byteCharacters.length);
    
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          
          const byteArray = new Uint8Array(byteNumbers);
    
          const blob = new Blob([byteArray], { type: selectedFile.contentType})
          setUrl(URL.createObjectURL(blob));

          if(isPDFGenerator) {
            resizeIframe();
          }
        };
        
       }

       processDocument();
    }, [selectedFile])


    return (
      <div className={parentClass}>
        {!hideLabel && <label>{label}</label>}

        {/* shifted here */}
        {toShowLink && selectedFile && selectedFile.name && (
          <span>
            {" "}
            <a href={url} rel="noreferrer" target="_blank">
              (View File)
            </a>
          </span>
        )}

        {toShowInstructionLink && (
          <>
            {" "}
            <a
              className="failure-text"
              href={"./forms/PHOTO_REQUIREMENTS_IRIEFINS.pdf"}
              rel="noreferrer"
              target="_blank"
            >
              (View Instructions!)
            </a>
          </>
        )}

        {/* to download document from id */}
        {typeof selectedFile === "number" && (
          <span>
            {" "}
            <a
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => {
                console.log(selectedFile);
              }}
              rel="noreferrer"
              target="_blank"
            >
              (View File)
            </a>
          </span>
        )}

        {isPreviewable && selectedFile && (
          <div className={isPDFGenerator ? '' : 'image-preview-form'}>
            {/* {getBase64Component(selectedFile)?.contentType?.startsWith('image/') && (
                <img className="image-preview-form" src={document} alt="File Preview" />
              )} */}
            {/* {selectedFile?.[0]?.type?.startsWith('video/') && (
                <video controls>
                  <source src={URL.createObjectURL(selectedFile[0])} type={selectedFile.type} />
                  Your browser does not support the video tag.
                </video>
              )} */}
            <img title="" id="doc-preview-iframe" style={{maxWidth: '100', width: '100%', objectFit: 'contain'}} height={'100%'} src={url}></img>
          </div>
        )}

        {/* ealier it was here */}
        {/* {
                    toShowLink && selectedFile && selectedFile.name && (
                      <div> <a href={url} target="_blank" >Uploaded File</a></div>
                    )
                } */}

        <div style={{ position: "relative" }}>
          <input
            id={`${regFieldName}.fileHandler`}
            type="file"
            className={fieldClassName}
            //   {...register(regFieldName, {
            //     required: isRequired,
            //   })}
            onChange={(e) => onChange(e.target.files)}
            accept={accept}
            placeholder={placeholder}
            disabled={isDisabled}
          />

          <span className="error-message">{error}</span>

          <input
            style={{ visibility: "hidden", position: "fixed", left: 0, top: 0 }}
            type="text"
            className={fieldClassName}
            {...register(regFieldName, {
              required: isRequired,
            })}
            accept={accept}
            placeholder={placeholder}
            disabled={isDisabled}
          />

          <span
            onClick={() => {
              removeFile(regFieldName);
              const fileInput = window.document.getElementById(
                `${regFieldName}.fileHandler`
              );
              fileInput.value = "";
            }}
            style={{
              position: "absolute",
              right: "1px",
              top: "4px",
              cursor: "pointer",
              backgroundColor: "white",
            }}
          >
            {crossIcon(32, 32)}
          </span>
        </div>
        <div></div>
      </div>
    );
  };

  const getBase64Component = (data) => {
    if(!data){
        return {
            base64: '',
            contentType: ''
        }
    }
    return {
        base64: data.slice(data.indexOf('base64,') + 7),
        contentType: data.slice(data.indexOf(':') + 1, data.indexOf(';'))
    }
  }