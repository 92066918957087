import React, { useRef } from 'react';

const IframeComponent = ({iFrameHTML}) => {
  // Reference to the iframe element
  const iframeRef = useRef(null);
  
  return (
    <div>
      <iframe
        // src='http://localhost:3000'
        ref={iframeRef}
        srcDoc={iFrameHTML}
        title="External Content"
        width="600"
        height="400"
      />
    </div>
  );
};

export default IframeComponent;