
export const CONCH = 'Conch';

export const SPINY_LOBSTER = 'Spiny Lobster';

export const SEA_CUCUMBER = 'Sea Cucumber';

export const typeOfDeclarations = [
    CONCH,
    SPINY_LOBSTER,
    SEA_CUCUMBER
];

export const subCategories = [
    'Artisanal', 'Industrial'
];

export const typeofTransaction = [
    "New", "Renewal"
]

export const intendToExport = [
    "Yes", "No"
];

export const typeofBusiness = [
    "Hotel", "Restaurant", "Supermarket", "Wholesaler", "Meat Shop", "Cold Storage Facility", "Retailer", "Fisher", "Other"
];

const SPINY_WHOLE_LOBSTER = "Spiny Whole Lobster";
const SPINY_LOBSTER_TAIL = "Spiny Lobster Tails";
const SPINY_LOBSTER_HEAD_MEAT = "Spiny Lobster Head Meat";

export const spinyLobsterDeclaration = [
  {
    id: 1,
    description: SPINY_WHOLE_LOBSTER,
    key: SPINY_WHOLE_LOBSTER.toLowerCase().replace(/ /g, "_"),
  },
  {
    id: 2,
    description: SPINY_LOBSTER_TAIL,
    key: SPINY_LOBSTER_TAIL.toLowerCase().replace(/ /g, "_"),
  },
  {
    id: 3,
    description: SPINY_LOBSTER_HEAD_MEAT,
    key: SPINY_LOBSTER_HEAD_MEAT.toLowerCase().replace(/ /g, "_"),
  },
];

const makeKey = (text) => text.toLowerCase().replace(/ /g, '_');


const UNPROCESSED_CONCH = "Unprocessed Conch";
const PERCENT_50_PROCESSED = "50 % Processsed";
const PERCENT_65_PROCESSED = "65 % Processsed";
const PERCENT_85_PROCESSED = "85 % Processsed";
const PERCENT_100_FILLED = "100 % Filled";
const CONCH_OPERCULA = "Conch Opercula";

export const conchDeclaration = [
    {
        id: 1,
        level_processed: UNPROCESSED_CONCH,
        key: makeKey(UNPROCESSED_CONCH)
    },
    {
        id: 2,
        level_processed: PERCENT_50_PROCESSED,
        key: "processed_50"
    },
    {
        id: 3,
        level_processed: PERCENT_65_PROCESSED,
        key: "processed_65"
    },
    {
        id: 4,
        level_processed: PERCENT_85_PROCESSED,
        key: "processed_85"
    },
    {
        id: 5,
        level_processed: PERCENT_100_FILLED,
        key: "filled_100"
    },
    {
        id: 6,
        level_processed: CONCH_OPERCULA,
        key: makeKey(CONCH_OPERCULA)
    },

]

export const SEA_CUCUMBER_1 = "Sea Cucumber";

export const seaCucumberDeclaration = [
    {
        id: 1,
        description: SEA_CUCUMBER_1,
        key: "sea_cucumber"
    }
]

