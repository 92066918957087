
export const FISHER_LICENCE_AQUA = "Fisher Licence (Aqua)";
export const FISHING_VESSEL_LICENCE_AQUA = "Fishing Vessel Licence (Aqua)";
export const AQUACULTURE_FACILITY_LICENCE = "Aquaculture Facility Licence";

export const typeOfLicence = [
    FISHER_LICENCE_AQUA,
    AQUACULTURE_FACILITY_LICENCE,
    AQUACULTURE_FACILITY_LICENCE
];

export const FOOD_FISH = "Food Fish";
export const CRUSTACEAN = "Crustacean";
export const ORNAMENTAL = "Ornamental";
export const OYSTER = "Oyster";
export const AQUATIC_PLANT = "Aquatic Plant";

export const typeOfSpecies = [
    FOOD_FISH,
    CRUSTACEAN,
    ORNAMENTAL,
    OYSTER,
    AQUATIC_PLANT
]


export const fisherAquaType = ["Fresh Water", "Mariculture", "Ornamental Culture"];

export const vesselClasses = ["Decked Vessel", "Non-Decked (Motorized)", "Non-Decked (Non -motorized)"];


export const gearTypes = ["Scuba", "Hoocha", "Long Line", "Seine Net", "Dip Net", "Cast Net"];
// export const gearTypes = ["Scuba", "Hoocha", "Long Line", "Beach Seine", "Dip Net", "Cast Net"];

// export const typeOfFish = {
//     [FOOD_FISH]: [
//         {
//             value: "Tilapia",
//             isInput: false
//         },
//         {
//             value:   "Pangassius",
//             isInput: false
//         },
//         {
//             value: "Carp",
//             isInput: false,
//         },
//         {
//             value: "Other",
//             isInput: true
//         }
//     ],
//     [CRUSTACEAN]: [
//         {
//             value: "Marine Shrimp",
//             isInput: false
//         },
//         {
//             value: "Freshwater Prawn",
//             isInput: false,
//         },
//         {
//             value: "Crab",
//             isInput: false
//         },
//         {
//             value: "Crayfish",
//             isInput: false
//         },
//         {
//             value: "Other",
//             isInput: true
//         }
//     ],
//     [ORNAMENTAL]: [
//        {
//         value:  "Egg -layers",
//         isInput: false
//        },
//         {
//             value: "Live-Bearers",
//             isInput: false
//         },
//         {
//             value: "Mouth-Breeders",
//             isInput: false
//         },
//         {
//             value: "Species",
//             isInput: true
//         },
//         {
//             value: "Aquatic Plant",
//             isInput: false
//         }
//     ],
//     [OYSTER]: [
//        {
//         value:  "Mangrove",
//         isInput: false
//        },
//        {
//         value: "Rhizophore",
//         isInput: false
//        },
//        {
//         value: "Other",
//         isInput: true
//        }
//     ],
//     [AQUATIC_PLANT]: [
//         {
//             value: "Algae",
//             isInput: false
//         },
//         {
//             value: "Sea Moss",
//             isInput: false
//         },
//         {
//             value: "Other",
//             isInput: true
//         }
//     ]
// };

export const typeOfFish = [
    {
        title: FOOD_FISH,
        key: 'food_fish', 
        categories: [
            {
                value: "Tilapia",
                isInput: false
            },
            {
                value:   "Pangassius",
                isInput: false
            },
            {
                value: "Carp",
                isInput: false,
            },
            {
                value: "Other",
                isInput: true
            }
        ]
    },
    {
        title: CRUSTACEAN,
        key: 'crustacean', 
        categories: [
            {
                value: "Marine Shrimp",
                isInput: false
            },
            {
                value: "Freshwater Prawn",
                isInput: false,
            },
            {
                value: "Crab",
                isInput: false
            },
            {
                value: "Crayfish",
                isInput: false
            },
            {
                value: "Other",
                isInput: true
            }
        ]
    },
    {
        title: ORNAMENTAL,
        key: 'ornamental', 
        categories: [
            {
             value:  "Egg-layers",
             isInput: false
            },
             {
                 value: "Live-Bearers",
                 isInput: false
             },
             {
                 value: "Mouth-Breeders",
                 isInput: false
             },
             {
                 value: "Species",
                 isInput: true
             },
             {
                 value: "Aquatic Plant",
                 isInput: false
             }
         ]
    },
    {
        title: OYSTER,
        key: 'oyster', 
        categories: [
            {
             value:  "Mangrove",
             isInput: false
            },
            {
             value: "Rhizophore",
             isInput: false
            },
            {
             value: "Other",
             isInput: true
            }
         ]
    },
    {
        title: AQUATIC_PLANT,
        key: 'aquatic_plant', 
        categories: [
            {
                value: "Algae",
                isInput: false
            },
            {
                value: "Sea Moss",
                isInput: false
            },
            {
                value: "Other",
                isInput: true
            }
        ]
    }
];
