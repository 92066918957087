import { cloneDeep } from "lodash";
import {
  Break,
  getAnimationStyle,
  getRectBoxStylingWithMultiTypeValues,
  getRectBoxStylingWithMultiValue,
  getRectBoxStylingWithSingleValue,
  getSVGImage,
} from "./DataSource";

const BoxChoiceContainer = ({
  listingHeading,
  listingOptions,
  selectedMap,
  setSelectedMap,
  onClick = () => {},
  prop_key,
  listingStyle = "justify-content-between",
  boxStyle = "license-cat-container",
  textStyle = "license-cat-container-text",
  imageWidth = 48,
  imageHeight = 84,
  isMultiSelect = false,
  icons = [],
  breakAfter= null,
  listingDescriptions=[],
  listingDisabled=[]
}) => {
  return (
    <div className={`d-flex flex-column`} style={getAnimationStyle(true)}>
      <div className="rectangle-box-small-heading">{listingHeading}</div>

      <div className={`d-flex flex-wrap ${listingStyle}`}>
        
        {listingOptions.map((trans, i) => {

          const content = (
            <div
              key={i}
              onClick={() => {
                if (isMultiSelect) {
                  let values = cloneDeep(selectedMap[prop_key] || []);
                  const index = values.indexOf(trans);
                  if (typeof values === "string") values = [values];

                  if (index === -1) values.push(trans);
                  else values.splice(index, 1);

                  setSelectedMap((map) => {
                    return {
                      ...map,
                      [prop_key]: values,
                    };
                  });

                  onClick(values, i);
                } else {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      [prop_key]: trans,
                    };
                  });

                  onClick(trans, i);
                }
              }}
              className={`p-2 ${boxStyle} ${listingDisabled?.[i] ? 'non-functional-element' : ''}`}
              style={
                isMultiSelect
                  ? getRectBoxStylingWithMultiValue(
                      selectedMap,
                      prop_key,
                      trans
                    )
                  : getRectBoxStylingWithSingleValue(
                      selectedMap,
                      prop_key,
                      trans
                    )
              }
            >
              <div style={{ textAlign: "center" }}>
                {icons[i] && <>{icons[i]}</>}

                {!icons[i] && (
                  <>
                    {getSVGImage(
                      imageWidth,
                      imageHeight,
                      selectedMap[prop_key] === trans ? "#00b7fd" : "#00b7fd"
                    )}
                  </>
                )}

                <div className={textStyle}>{trans}</div>
                {listingDescriptions?.[i] && <span>{listingDescriptions[i]}</span>}
              </div>
            </div>
          );
          return breakAfter && ((i+1) % breakAfter === 0) ? 
          <>{content} <Break></Break> </> : content;
        })}
      </div>
    </div>
  );
};

export default BoxChoiceContainer;