import React from "react";
import { Link } from "react-router-dom";

function SimpleNavigatorAnother({parentClassName = 'pl-2 pr-2 mb-4', ...props}) {

  return (
    <div className={parentClassName}>
      <div className="d-flex-wrap justify-content-between align-items-center">
        <div>
          {!!props.previous && (
            <Link
              onClick={() => {
                props.previousAction && props.previousAction();
              }}
            >
              <button
                type="button"
                className={
                  props.prevBtnClass
                    ? props.prevBtnClass
                    : `btn btn-custom-warning`
                }
              >
                {props.prevValue || "Previous"}
              </button>
            </Link>
          )}
        </div>

        <div>
          {!!props.middle && (
            <Link
              onClick={() => {
                props.middleAction && props.middleAction();
              }}
            >
              <button type="button" className="btn btn-custom-success">
                {props.middleValue || "Middle"}
              </button>
            </Link>
          )}
        </div>

        <div>
          {!!props.next && (
            <Link
              onClick={() => {
                props.nextAction && props.nextAction();
              }}
            >
              <button
                style={{ fontSize: "18px", fontWeight: 600 }}
                type="button"
                className={
                  props.nextBtnClass
                    ? props.nextBtnClass
                    : `btn btn-custom-success`
                }
              >
                {props.nextValue || "Next"}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default SimpleNavigatorAnother;
