// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getSVGImage,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  fishingAreaLocations,
} from "../../components/DataSource";
import { CustomInputContainer, CustomSelectContainer, onChangeInput} from '../../components/CustomInputContainer';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";
import { useForm } from "react-hook-form";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";

import { AMENDMENT, NEW_LICENCE, RENEWAL, RESEARCH, categoryOfAuthorization, countries, domicileType, subcategoryofResearch, typeOfAuthorization, typeOfSpecies, typeofTransaction } from "./AuthorizationDataSource";
import { LocalVesselDetails } from "./LocalVesselDetails";
import { ForeignVesselDetails } from "./ForeignVesselDetails";
import { ParticularsofActivity } from "./ParticularsofActivity";
import { LocationDetails } from "../AuthorizationLicence/AuthorizationLocationSpeciesDetails";
import { validateKeys } from "../../utils/utils";
import { inputPreviousNumber, selectionNotFound } from "../../utils/messages";
import { alertService } from "../../_services";
import NFARegNumberSelection from "../../components/NFARegNumberQuestion";


export default function AuthorizationTypeSelection(props) {

  const history = useHistory();


  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(selectedMap.selectionCategory ?? 1);


  const localVesselGearDetails = useForm({
    defaultValues: {
      local_vessel_gear_details: selectedMap.local_vessel_gear_details ?? [{id: Math.random()}]
    },
    mode: "all"
  });

  const foreignVesselGearDetails = useForm({
    defaultValues: {
      foreign_vessel_gear_details:  selectedMap.foreign_vessel_gear_details ?? [{id: Math.random()}]
    },
    mode: 'all'
  })

  const particularsOfActivity = useForm({
    defaultValues: {
      particulars_of_activity: selectedMap.particulars_of_activity ?? {}
    },
    mode: "all"
  });

  const locationForm = useForm({
    defaultValues: {
      authorization_location_species_details: selectedMap.authorization_location_species_details ?? [{id: Math.random()}]
    },
    mode: "all"
  });

  const { handleSubmit : handleLVGSubmit, control: lvgFormControl, trigger: triggerValidateLVG, getValues: getLVGValues, register: registerLVG, formState: lvgFormState, watch: lvgWatch, setValue: setLVGValue} = localVesselGearDetails;

  const { handleSubmit : handleFVGSubmit, control: fvgFormControl, trigger: triggerValidateFVG, getValues: getFVGValues, register: registerFVG, formState: fvgFormState, watch: fvgWatch, setValue: setFVGValue} = foreignVesselGearDetails;


  const { handleSubmit : handlePOASubmit, control: poaFormControl, trigger: triggerValidatePOA, getValues: getPOAValues, register: registerPOA, formState: poaFormState, watch: watchPoa, setValue: setValuePoa} = particularsOfActivity;


  const { handleSubmit : handleLocationSubmit, control: locationFormControl, trigger: triggerValidateLocation, getValues: getLocationValues, register: registerLocation, formState: locationFormState} = locationForm;

  const onSubmit = (data) => {
    console.log(data);
  };

  const validateFVGForm = async () => {
    try {

      const isFormValid = await triggerValidateFVG();

      // can get values from this
      const data = await getFVGValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            foreign_vessel_gear_details: data.foreign_vessel_gear_details
          }
        })
        handleFVGSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };


  const validateLFGForm = async () => {
    try {

      const isFormValid = await triggerValidateLVG();
      // can get values from this
      const data = await getLVGValues();

      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            local_vessel_gear_details: data.local_vessel_gear_details
          }
        })
        handleLVGSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validatePOAForm = async () => {
    try {

      const isFormValid = await triggerValidatePOA();

      // can get values from this
      const data = await getPOAValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            particulars_of_activity: data.particulars_of_activity
          }
        })
        handlePOASubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateLocationForm = async () => {
    try {
      const isFormValid = await triggerValidateLocation();
      const data = await getLocationValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            authorization_location_species_details: data.authorization_location_species_details
          }
        })
        handleLocationSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {/* {selectionCategory === 0 && (
        <NFARegNumberSelection
          setSelectedMap={setSelectedMap}
          selectionCategory={selectionCategory}
          setSelectionCategory={setSelectionCategory}
          history={history}
          selectedMap={selectedMap}
        />
      )} */}

      {selectionCategory === 1 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Type of Transaction"}
            listingOptions={typeofTransaction}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={(value, i) => {
              if (value === NEW_LICENCE)
                setSelectionCategory(selectionCategory + 1);
            }}
            prop_key={"type_of_transaction"}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          {[AMENDMENT, RENEWAL].indexOf(selectedMap["type_of_transaction"]) >= 0 && (
            <CustomInputContainer
              heading={"Enter the Authorization Number"}
              prop_key={"previous_authorization_number"}
              placeholder={"Enter the Authorization number"}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
            ></CustomInputContainer>
          )}

          <InternalNavigator
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if ([AMENDMENT, RENEWAL].indexOf(selectedMap.type_of_transaction) !== -1 ) {
                if (
                  !validateKeys(selectedMap, ["previous_authorization_number"])
                ) {
                  alertService.warn(
                    inputPreviousNumber("Authorization Number"),
                    { autoClose: true }
                  );
                  return;
                }
              }

              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              history.push("/");
            }}
          />
        </div>
      )}

      {selectionCategory === 2 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select Category of Authorization"}
            listingOptions={categoryOfAuthorization}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"category"}
            listingStyle="justify-content-center"
            onClick={(value, i) => {
              if (value === RESEARCH) {
                setSelectionCategory(selectionCategory + 1);
              } else {
                setSelectionCategory(selectionCategory + 2);
              }
            }}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (!validateKeys(selectedMap, ["category"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if (selectedMap.category === RESEARCH) {
                setSelectionCategory(selectionCategory + 1);
              } else {
                setSelectionCategory(selectionCategory + 2);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 3 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select sub category for Research"}
            listingOptions={subcategoryofResearch[selectedMap.category]}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"sub_category"}
            listingStyle="justify-content-center"
            onClick={(value, i) => {
              setSelectionCategory(selectionCategory + 1);
            }}
          ></BoxChoiceContainer>

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (selectedMap.category === RESEARCH) {
                if (!validateKeys(selectedMap, ["sub_category"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }
              }

              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 4 && (
        <div>
          <BoxChoiceContainer
            listingHeading={"Select the Type of Authorization"}
            listingOptions={typeOfAuthorization}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_authorization"}
            listingStyle="justify-content-center"
            onClick={(value, i) => {
              if (value === "Vessel") {
                setSelectionCategory(selectionCategory + 1);
              } else {
                setSelectionCategory(selectionCategory + 2);
              }
            }}
          ></BoxChoiceContainer>

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (!validateKeys(selectedMap, ["type_of_authorization"])) {
                alertService.warn(selectionNotFound);
                return;
              }
              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Back"}
            previousAction={() => {
              if (selectedMap.category === RESEARCH) {
                setSelectionCategory(selectionCategory - 1);
              } else {
                setSelectionCategory(selectionCategory - 2);
              }
            }}
          />
        </div>
      )}

     

      {selectionCategory === 5 && (
        <div>
          {selectedMap.nationality === "Local" && (
            <div>
              <LocalVesselDetails
                formState={lvgFormState}
                register={registerLVG}
                control={lvgFormControl}
                onSubmit={onSubmit}
                watch={lvgWatch}
                setValue={setLVGValue}
              ></LocalVesselDetails>

              <InternalNavigator
                nextValue={"Next"}
                nextAction={async () => {
                  if (await validateLFGForm()) {
                    setSelectionCategory(selectionCategory + 1);
                  }
                }}
                prevValue={"Back"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          )}

          {selectedMap.nationality === "Foreign" && (
            <div>
              <ForeignVesselDetails
                formState={fvgFormState}
                register={registerFVG}
                control={fvgFormControl}
                onSubmit={onSubmit}
                watch={fvgWatch}
                setValue={setFVGValue}
              ></ForeignVesselDetails>

              <InternalNavigator
                nextValue={"Next"}
                nextAction={async () => {
                  if (await validateFVGForm()) {
                    setSelectionCategory(selectionCategory + 1);
                  }
                }}
                prevValue={"Back"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          )}
        </div>
      )}

      {selectionCategory === 6 && (
        <div>
          <ParticularsofActivity
            formState={poaFormState}
            register={registerPOA}
            control={poaFormControl}
            onSubmit={onSubmit}
            watch={watchPoa}
            setValue={setValuePoa}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validatePOAForm()) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
            prevValue={"Back"}
            previousAction={() => {
              if (selectedMap.type_of_authorization === "Vessel") {
                setSelectionCategory(selectionCategory - 1);
              } else {
                setSelectionCategory(selectionCategory - 2);
              }
            }}
          />
        </div>
      )}

      {selectionCategory === 7 && (
        <div>
          <LocationDetails
            formState={locationFormState}
            register={registerLocation}
            control={locationFormControl}
            onSubmit={onSubmit}
            typeOfSpecies={typeOfSpecies}
            zoneBeachAreas={fishingAreaLocations}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateLocationForm()) {
                  // setSelectionCategory(selectionCategory + 1);
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory,
                    };
                  });
                  props?.next();
                }
              }}
              prevValue="Go Back"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}
      
    </div>
  );
}


const getGenericNavigator = ({setSelectionCategory, selectionCategory, validations = [], selectedMap = {}}) => {
  return <InternalNavigator
  nextValue={"Next"}
  nextAction={() => {

    if(!validateKeys(selectedMap, validations)){
      alertService.warn(selectionNotFound, {autoClose: true});
      return;
    }
    setSelectionCategory(selectionCategory + 1);
  }}
  prevValue={"Back"}
  previousAction={() => {
    setSelectionCategory(selectionCategory - 1);
  }}
/>;
}



