export const CONCH = "Conch";
export const SEA_CUCUMBER = "Sea Cucumber";
export const SPINY_LOBSTER = "Spiny Lobster";

export const landingLogsFor = [
    CONCH,
    SEA_CUCUMBER,
    SPINY_LOBSTER
    
]

export const typeOfApplicant = [
    "Artisanal", "Industrial"
]

export const storageType = [
    "Freezer", "No Ice", "Ice", "Other"
]
