import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";

import {
  Break,
  Declarations,
  emailPattern,
  years,
} from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FLISContext } from "../../context/FLISContext";
import {
  FormHookInput,
  FormHookSelect,
  FormHookTextArea,
} from "../../components/FormHook/ReactFormHookFields";
import { emailNotValid } from "../../utils/messages";
import {
  CONCH_QUOTA,
  SEA_CUCUMBER_QUOTA,
  SPINY_LOBSTER_QUOTA,
} from "./QuotaFishingDataSource";
import { ConchHistoricalIndustryPerformance } from "./ConchHistoricalPerformance";
import { SpinyHistoricalIndustryPerformance } from "./SpinyLobsterHistoricalPerformance";
import { MarineVesselDetailsSpiny } from "./MarineVesselDetailsSpiny";
import { MarineVesselDetails } from "./MarineVesselDetails";
import { ProposedFishingDetails } from "./ProposedFishingDetails";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { SignaturePad } from "../../components/SignatureCanvas";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import GeneralDeclarations from "../GeneralDeclaration";
import { SeaCucumberHistoricalIndustryPerformance } from "./SeaCucumberHistoricalPerformance";
import { InvestmentAndProductionCapacity } from "./InvestmentAndProductionCapacity";
import { SpinyInvestment } from "./SpinyInvestment";
import { ROUTES } from "../../config";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForQuotaFishing = (props) => {
  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const history = useHistory();
  const buttonSubmitRef = useRef();

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  const inlineFormClassProps = {
    parentClass: "d-flex-wrap flex-row m-2",
    fieldClassName: "form-control",
    fieldWrapperClassName: "individual-field",
    labelClassName: "horizontal-field-label-min-250",
  };

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const submitAllDetails = () => {
    history.push("/ftc/transaction-details");
  };

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    trigger,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: selectedMap,
    mode: "all",
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const { errors } = formState;

  const onSubmit = async (data) => {
    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();
    
    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }

    if (isValid) {
      setSelectedMap(data);
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data);
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
  };

  const [selectedForm, setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <FormHookSelect
                      label="Licence Year"
                      register={register}
                      regFieldName={`licence_year`}
                      options={years}
                      error={errors?.licence_year?.message}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.name_of_applicant?.message}
                      label="Name of Applicant"
                      regFieldName="name_of_applicant"
                      placeholder="Name of Applicant"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact Name"
                      regFieldName="contact_name"
                      placeholder="Contact Name"
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_phone?.message}
                      label="Contact Phone"
                      regFieldName="contact_phone"
                      placeholder="Contact Phone"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_email?.message}
                      label="Contact Email"
                      regFieldName="contact_email"
                      placeholder="Contact Email"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      isRequired={false}
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.processing_facility_address?.message}
                      label="Processing Facility Address (If any):"
                      regFieldName="processing_facility_address"
                      placeholder="Processing Facility Address"
                      rows={1}
                      cols={72}
                      isRequired={false}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Historical Industry Performance
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    {selectedMap.category === CONCH_QUOTA && (
                      <>
                        <ConchHistoricalIndustryPerformance
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                          isDisabled={true}
                          toShowHeading={false}
                        />
                      </>
                    )}

                    {selectedMap.category === SEA_CUCUMBER_QUOTA && (
                      <>
                        <SeaCucumberHistoricalIndustryPerformance
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                          isDisabled={true}
                          toShowHeading={false}
                        />
                      </>
                    )}

                    {selectedMap.category === SPINY_LOBSTER_QUOTA && (
                      <>
                        <SpinyHistoricalIndustryPerformance
                          formState={formState}
                          register={register}
                          control={control}
                          onSubmit={onSubmit}
                          isDisabled={true}
                          toShowHeading={false}
                        />
                      </>
                    )}
                  </div>
                </div>

                {(selectedMap.category === CONCH_QUOTA ||
                  selectedMap.category === SEA_CUCUMBER_QUOTA) && (
                  <InvestmentAndProductionCapacity
                    formState={formState}
                    register={register}
                    control={control}
                    onSubmit={onSubmit}
                    isDisabled={true}
                  />
                )}

                {selectedMap.category === SPINY_LOBSTER_QUOTA && (
                  <SpinyInvestment
                    formState={formState}
                    register={register}
                    control={control}
                    onSubmit={onSubmit}
                    isDisabled={true}
                  />
                )}

                <div className="shadow p-3 mb-3 bg-white rounded">
                  {/* <div className="sub-form-header">Marine Vessel Details</div> */}
                  {/* <HorizontalLine></HorizontalLine> */}

                  {selectedMap.category === SPINY_LOBSTER_QUOTA && (
                    <MarineVesselDetailsSpiny
                      formState={formState}
                      register={register}
                      control={control}
                      shouldRenderInForm={false}
                      isDisabled={true}
                    />
                  )}

                  {(selectedMap.category === CONCH_QUOTA ||
                    selectedMap.category === SEA_CUCUMBER_QUOTA) && (
                    <MarineVesselDetails
                      formState={formState}
                      register={register}
                      control={control}
                      shouldRenderInForm={false}
                      isDisabled={true}
                    />
                  )}
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  {/* <div className="sub-form-header">
                    Proposed Fishing Details
                  </div> */}
                  <HorizontalLine></HorizontalLine>

                  <ProposedFishingDetails
                    formState={formState}
                    register={register}
                    control={control}
                    shouldRenderInForm={false}
                    isDisabled={true}
                  />
                </div>

                <SimpleDeclaration register={register} errors={errors}/>

                {/* moved to proposed fishing details */}
                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Intented Use of Quota</div>
                  <HorizontalLine></HorizontalLine>
                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.percentage_intended_for_export?.message}
                      label="Percentage Intended for Export"
                      regFieldName="percentage_intended_for_export"
                      placeholder="Percentage Intended for Export"
                      type="number"
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors?.percentage_intended_for_local_sales?.message
                      }
                      label="Percentage Intended for Local Sales"
                      regFieldName="percentage_intended_for_local_sales"
                      placeholder="Percentage Intended for Local Sales"
                      type="number"
                      isDisabled={true}
                    />
                  </div>
                </div> */}

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>

        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForQuotaFishing);
