import React from 'react';
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const ViewFisheriesAct = () => {

    return (
      <button type="button" className="btn btn-custom-warning large-button">
        <Link
          to="/forms/FisheriesAct.pdf"
          target="_blank"
          download>
          To View Fisheries Act - Click Here
        </Link>
      </button>
    );

}

export default ViewFisheriesAct;