import React from "react";

import { Link } from "react-router-dom";

function SimpleNavigator({ shouldAddPadMar = true, className = "", ...props }) {
  return (
    <div
      className={`${shouldAddPadMar ? "pl-2 pr-2 mb-2 mt-2" : ""} ${className}`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {!!props.previous && (
            <Link
              onClick={() => {
                props.previousAction && props.previousAction();
              }}
            >
              <button
                type="button"
                className="btn btn-custom-warning btn-custom-previous"
                style={{ fontSize: "20px", fontWeight: 600, minWidth: "100px" }}
              >
                {props.prevValue || "Previous"}
              </button>
            </Link>
          )}
        </div>

        <div>
          {!!props.middle && (
            <Link
              onClick={() => {
                props.middleAction && props.middleAction();
              }}
            >
              <button
                type="button"
                className="btn btn-custom-success"
                style={{ fontSize: "20px", fontWeight: 600, minWidth: "100px" }}
              >
                {props.middleValue || "Middle"}
              </button>
            </Link>
          )}
        </div>

        <div>
          {!!props.next && (
            <Link
              onClick={() => {
                props.nextAction && props.nextAction();
              }}
            >
              <button
                type="button"
                className={
                  props.nextBtnClass
                    ? props.nextBtnClass
                    : `btn btn-custom-success`
                }
                style={{ fontSize: "20px", fontWeight: 600, minWidth: "200px" }}
              >
                {props.nextValue || "Next"}
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default SimpleNavigator;
