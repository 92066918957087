import React from "react";

export const SubFormContainer = ({children, title, style}) => {


    return (<div style={style}>
        <div style={{backgroundColor: '#61dafb', color: 'black', fontWeight: 700, paddingLeft: '10px'}}>{title}</div>
        <div>
            {children}
        </div>
    </div>)


}