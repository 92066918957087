


export const VERIFICATION_LETTERS = 'Verification Letter for Fishers (eg. Embassies, FLA, etc)';
export const LETTER_TO_JFCU = `Letter to JFCU re Purchase of Bait Net by Fisher`;
export const LETTER_TO_MINISTRY_LABOUR = 'Letter to Ministry of Labour to facilitate work permits for foreigners';
export const LETTER_TO_PICA = 'Letter to PICA to facilitate landing visa';
export const LETTER_TO_JAMAICA_CUSTOMS = 'Letter to Jamaica Customs Agency to facilitate GCT waiver for import';
export const VERIFICATION_LETTER_SEIZURE_OF_BOAT = 'Verification Letter re Seizure of Boat';
export const LETTER_OF_NO_OBJECTION = 'Letter of No Objection for fish import';
export const LETTER_TO_CONSULATE_EMBASSY = 'Letter to  Consulate/Embassy';
export const VERIFICATION_LETTER_FISH_FARMERS = 'Verification Letter for Fish Farmers';
export const LETTER_TO_MINISTRY_NATIONAL_SECURITY = 'Letter to Ministry of National Security re Importation of Spear Gun';


export const SUBSTITUTE_ID_CARD = "Substitute ID Card";

export const typeOfLetterRequested = [
    // "Embassy Letters",
    // "Firearms Letter",
    // "AGD Letters",
    // "Other Letters",
    VERIFICATION_LETTERS,
    LETTER_TO_JFCU,
    LETTER_TO_MINISTRY_LABOUR,
    LETTER_TO_PICA,
    LETTER_TO_JAMAICA_CUSTOMS,
    VERIFICATION_LETTER_SEIZURE_OF_BOAT,
    LETTER_OF_NO_OBJECTION,
    LETTER_TO_CONSULATE_EMBASSY,
    VERIFICATION_LETTER_FISH_FARMERS,
    LETTER_TO_MINISTRY_NATIONAL_SECURITY,
    SUBSTITUTE_ID_CARD
    
];




