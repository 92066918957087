import React, { useContext, useEffect } from 'react'
import { appTypes } from '../config/app-types';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import ApplicationType from '../components/ApplicationType'
import FooterContainer from './FooterContainer';
import _ from 'lodash';
import SimpleImageSlider from "react-simple-image-slider";
import { Fade, Slide } from 'react-slideshow-image';
import { getUniqueTransNumber } from '../utils/utils';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { FLISContext } from '../context/FLISContext';


export default function Home(props) {


//   useEffect(() => {

//     localStorage.setItem('selection_map', '{}');
// }, [])


const {clearSelectedMap} = useContext(FLISContext);


    const history = useHistory();

    const params = useParams();
    let data = appTypes;
    let queryParams = queryString.parse(props?.location?.search || {})

    console.log(queryParams)
    const images = [
        // { url: "images/1.jpg", },
        // { url: "images/2.jpg" ,},
       
        { url: "images/4.jpg" },
        { url: "images/5.jpg" },
        { url: "images/6.jpg" },
        { url: "images/7.jpg" },
        { url: "images/8.jpg" },
        { url: "images/9.jpg" },
        { url: "images/10.jpg" },
        { url: "images/3.jpg" },
      ];

     
      const divStyle = {
        backgroundSize: 'contain',
        height: '500px',
        width: '100%',
      }

      const buttonStyle = {
        width: "30px",
        background: 'none',
        border: '0px'
    };

      const properties = {
        autoPlay: true,
        transitionDuration: 2000,
        duration: 2000,
        prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
        nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
    }

    if (data.length === 0) {
        data = appTypes;
    }
    const dispatch = useDispatch();


    const submitApplication = async (app) => {
      if(app.isLoginRequired){
        if (!localStorage.getItem("token")) {
          history.push("/login");
          return;
        }
      }
      let transNumber = '';
      if (app.initiateState) {
        transNumber = await getUniqueTransNumber(app.appType);

        dispatch({
          type: SET_CURRENT_TRANSACTION,
          temp_trans_id: transNumber,
          appType: app.appType,
          transaction: {},
          submitted_transaction: null,
        });
        
      }
      clearSelectedMap();
      history.push(app.redirectTo);
    }

    return (
      <div className="no-gutters">
        {/* <div className="col-sm-2">

                <SideBar links={sidebarLinks}></SideBar>
            </div> */}

        {/* <div className='custom-banner-bg'>
                <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                    Fisheries Licensing & Information System
                </div>

                <h5>Ensure your business licenses are approved and valid</h5>


                <div className='d-flex flex-spacing-10'>
                   

                    <button onClick={() => history.push('/how-to-apply')} type="button" className="btn btn-custom-warning">
                        How to Apply
                    </button>


                    <button onClick={() => history.push('/community')} type="button" className="btn btn-custom-warning">
                        Community
                    </button>

                   

                    <button  onClick={() => history.push('/faq')} type="button" className="btn btn-custom-warning">
                        FAQ's
                    </button>


                    <button type="button" className="btn btn-custom-warning">
                        Manuals
                    </button>

                    <button type="button" className="btn btn-custom-warning">
                        Videos
                    </button>

                </div>
            </div> */}

        <div
          onClick={() => {
            // history.push('/overview')
          }}
        >
          {/* <SimpleImageSlider
         autoPlay={true}
        width={'100%'}
        height={'70vh'}
        images={images}
        showBullets={true}
        showNavs={true}
        slideDuration={10}
      /> */}

          <div className="slide-container">
            <Fade {...properties}>
              {images.map((slideImage, index) => (
                <div key={index}>
                  <div style={{ position: "relative" }}>
                    <img
                      alt="fisheries"
                      src={`${slideImage.url}`}
                      style={{ ...divStyle }}
                    />
                    <div className='central-show-container'>
                      <div
                        style={{
                          borderLeft: "10px solid #00b7fd",
                          borderRight: "10px solid #00b7fd",
                          padding: "5px 10px",
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontSize: "24px",
                            fontWeight: "800",
                            textAlign: "center",
                            maxHeight: "100px",
                          }}
                        >
                          {/* <div>{"Fisheries Licensing & Information System"}</div> */}
                          {/* <div
                            style={{
                              fontStyle: "italic",
                              fontSize: "20px",
                              color: "yellow",
                            }}
                          >
                            Empowering Success with Fisheries
                          </div> */}
                          <div>
                            {" "}
                            <button
                              type="button"
                              style={{ textAlign: "center" }}
                              className="btn btn-custom-warning large-button"
                              onClick={async () => {
                                history.push(`/application-types`);
                              }}
                            >
                              Apply Now for your Licence{" "}
                              <img
                                width={20}
                                height={20}
                                src="./images/maximize.png"
                              />
                            </button>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Fade>
          </div>
        </div>


        <div>
          <div
            className="d-flex-wrap justify-content-between flex-spacing-10"
            style={{
              padding: "40px 14%",
              color: "black",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            <div>
              <h2 style={{ fontWeight: 800 }}>
              About the National Fisheries Authority
              </h2>
              The National Fisheries Authority (NFA) was established by The Fisheries Act, 2018, and is mandated to sustainably manage and develop capture fisheries and aquaculture in Jamaica. The Authority is the sole body with the responsibility of ensuring that there is conservation of Jamaica’s fisheries; collection, compilation, and analysis of statistics for the sector; monitoring, control and enforcement of activities related to fisheries and aquaculture; as well as, granting of licences, authorizations and permits and allocation of fishing rights and quotas for all who intend to fish in Jamaica’s waters.
            </div>


            <div>
              <br></br>
              <br></br>
              <h2 style={{ fontWeight: 800 }}>
              Aquaculture
              </h2>
              <div>
              The Aquaculture Division focuses on Tilapia, Ornamental and Mariculture production, across various parishes in Jamaica. Aquaculture has a rich in Jamaica, with the first species of tilapia introduced to the country from St Lucia in 1949-1950. Its main aim is to ensure food and nutrition security, gainful employment, and utilization of untapped mariculture resources.
              </div>
              <br></br>

              <div>
              The Aquaculture Division fulfills its mandate through: Research and Development, Extension Services, Training and other Support Services. The Division assists fish farmers with obtaining quality seedstock for production, obtaining licensing and registration and . The Extension Unit provides expert advice on production methodologies through training, on farm consultation and monitoring. 
              </div>

              <br></br>

             

              <div>Persons who are interested in fish farming should contact the Aquaculture Division for the necessary guidance and technical support required from assessment, implementation, and completion of production cycle.</div>

              <br></br>

              <div>
              <button className="btn btn-sm btn-custom-warning" onClick={() => {

                  const app = _.filter(appTypes, app => app.appType === 'APPLICATION_FOR_AQUACULTURE_FISHER');
                  submitApplication(app[0]);
                }}>{"Apply Here for an Aquaculture Licence"}</button>
              </div>

              <br></br>

            </div>


            <div>
              <br></br>
              <br></br>
              <h2 style={{ fontWeight: 800 }}>
              Capture Fisheries 
              </h2>
              <div>
              Capture Fisheries in Jamaica consists of the extensive marine space which includes the Exclusive Economic Zone, as well as inland waters such as rivers and ponds. This sub-sector consists primarily of artisanal fishers operating from open type canoes over inshore and offshore areas; the inshore fishery takes place in the coastal waters of the Island Shelf with its nine proximal banks. A smaller but economically significant sub-sector is comprised of industrial fishers who fish for lobster and conch, the latter being a highly significant part of the industry from the 1960s.
              </div>
              <br></br>

              <div>
              The Capture Fisheries Division of the NFA is tasked with the management of capture fisheries in Jamaica through research and development, sound fishery management planning and extension services. The research which informs how fisheries are managed and which influences the conditions under which fishers are licensed, is conducted by this Division. 
              </div>

              <br></br>


              <div>
              <button className="btn btn-sm btn-custom-warning" onClick={() => {
                  
                  const app = _.filter(appTypes, app => app.appType === 'APPLICATION_FOR_LICENCE');
                  submitApplication(app[0]);

                }}>{"Apply Here for your Fishing Licence"}</button>
              </div>

             
            </div>



            {/* <img
              className='flex-grow-1'
              height={"250px"}
              alt="11"
              src="./images/11.jpeg"
            /> */}
          </div>

       
         
        </div>

        <FooterContainer></FooterContainer>
      </div>
    );
}
