import { createContext, useEffect, useState } from "react";
import { COMMERCIAL, COMMERCIAL_FISHING, INDUSTRIAL_FISHING, RECREATIONAL_FISHING, resetSpeciesSeasons } from "../components/DataSource";
import { getData } from "../services/service-call";
import { ROUTES } from "../config";
import { getEmailAddress, getNameOfApplicant } from "../utils/utils";
// import { FISHING_VESSEL_LICENCE } from "../components/DataSource";

export const FLISContext = createContext();

export const FLISContextComponent = ({children}) => {


    let selected_map = JSON.parse(localStorage.getItem('selection_map') || '{}');

    const [selectedMap, setSelectedMap] = useState(selected_map);

    const [currentTransDetails, setCurrentTransDetails] = useState();

    const [currentEntity, setCurrentEntity] = useState({});

    const [associatedEntities, setAssociatedEntities] = useState([]);

    const [profileData, setProfileData] = useState({});
    const [speciesSeasons, setSpeciesSeasons] = useState();
    const token = localStorage.getItem('token');

    useEffect(() => {

        if(!token) {
            setCurrentEntity(null);
            setProfileData(null);
            return;
        }
        (async () => {
            const result = await getData({
                url: ROUTES.getProfileInfo,
              });
              setProfileData(result.data);
              setCurrentEntity(result.data?.entities?.[0] ?? {});
              setAssociatedEntities(result?.associated_entities);
        })();

        (async () => {
          const result = await getData({
            url: ROUTES.getSpeciesSeasons,
          });
          setSpeciesSeasons(result.data);
          resetSpeciesSeasons(result.data);
        })();

        
      }, [token]);



    const clearSelectedMap = (preset = {}) => {
        let obj = {};
        obj.temp_trans_id = selectedMap.temp_trans_id;
        let declaration_details = {};
        if(currentEntity.applicant_type === 'Individual'){
            declaration_details = {
                name: `${currentEntity.first_name_individual} ${currentEntity.middle_name_individual ? currentEntity.middle_name_individual + ' ' : ''}${currentEntity.last_name_individual}`,
                address: currentEntity.address
            }
        }else {
            declaration_details = {
                name: currentEntity.company_name,
                address: currentEntity.address
            }
        }

        obj =  {
            last_name_individual: currentEntity.last_name_individual,
            first_name_individual: currentEntity.first_name_individual,
            middle_name_individual: currentEntity.middle_name_individual,
            alias_individual:  currentEntity.alias_individual,
            company_name: currentEntity.company_name,
            country_of_origin: currentEntity.country_of_origin,
            // national_registration_id: 'ID 774433', // to be checked with Elena
            national_registration_id: currentEntity?.entity_picture_identification?.picture_identification_number,
            taxation_registration_number: currentEntity.taxation_registration_number,
            nfa_registration_number: currentEntity.nfa_registration_number,
            is_nfa_reg_present: !!currentEntity.nfa_registration_number,
            country_address: currentEntity.country_of_origin,
            address: currentEntity.address,
            cell_phone: currentEntity.cell_phone,
            work_home_phone: currentEntity.work_home_phone,
            email: getEmailAddress(currentEntity),
            contact: currentEntity.cell_phone,
            gender_type_of_business: currentEntity.gender || "Business",
            date_of_birth_individual: `${currentEntity?.date_of_birth_individual?.substring(0, 10)}`,
            domicile: currentEntity.domicile,
            nationality: currentEntity.domicile,
            applicant_type: currentEntity.applicant_type,
            name_of_applicant: getNameOfApplicant(currentEntity),
            contact_phone: currentEntity.cell_phone,
            contact_name: getNameOfApplicant(currentEntity),
            contact_email: getEmailAddress(currentEntity),

            // to be due diligence
            picture_identification: currentEntity?.entity_picture_identification?.picture_identification,

            picture_identification_type_for_no: currentEntity?.entity_picture_identification?.picture_identification_type_for_no,

            type_of_picture_identification: currentEntity?.entity_picture_identification?.type_of_picture_identification,
            picture_identification_number: currentEntity?.entity_picture_identification?.picture_identification_number,
            picture_expiry_date: currentEntity?.entity_picture_identification?.picture_expiry_date,
            // declaration details
            declaration_details: declaration_details

        };

        setSelectedMap({
            ...obj,
            ...preset
        });
    }

    const value = {
        selectedMap,
        setSelectedMap,
        clearSelectedMap,
        currentTransDetails,
        setCurrentTransDetails,
        profileData,
        currentEntity,
        setCurrentEntity,
        associatedEntities,
        speciesSeasons
    };

    return <FLISContext.Provider value={value}>
        {children}
    </FLISContext.Provider>
};