import React from "react";
import { FormHookCheckbox, FormHookInput } from "../../components/FormHook/ReactFormHookFields";
import ReactJson from "react-json-view";


export const TargetFish = ({source, formState,control, register, onSubmit, setValue,values,watch, disabled, showHeading=true, ...props }) => {


    const {errors} = formState;


    const targetFish= watch('target_fish', {deep: true});
    return (
      // <fieldset disabled={disabled}>
        // <form onSubmit={onSubmit}>
          <div className=" p-3 mb-3 ">
            <div className="d-flex  form-heading">
              {
                showHeading && <div
                style={{ marginRight: "10px" }}
              >{`${"Type of Fish"}`}</div>
              }
            </div>
            {/* <ReactJson src={targetFish} ></ReactJson> */}
            <div className="d-flex-wrap justify-content-center ">
              {source.map(({key,title,  categories}) => (
                <div key={key} className="d-flex-wrap flex-column sub-form-container m-2 flex-grow-1 max-500">

                  <FormHookCheckbox
                  parentClass=""
                    options={[title]}
                    register={register}
                    regFieldName={`target_fish.${key}.isEnabled`}
                    isDisabled={disabled}
                    hideLabel={true}
                    isRequired={false}
                  />

                  <div className="d-flex-wrap flex-column ml-3">
                  <div><b>Categories</b></div>
                    {categories.map((entry, i) => (
                      <div key={entry.value} className="d-flex-wrap flex-row ">
                        <FormHookCheckbox
                        parentClass=""
                        fieldClassName=""
                          options={[entry.value]}
                          register={register}
                          error={errors?.target_fish?.[key]?.sub_categories?.message}
                          regFieldName={`target_fish.${key}.sub_categories`}
                          hideLabel={true}
                          isRequired={targetFish?.[key]?.isEnabled ? ("This field is required") : false}
                          isDisabled={(!targetFish?.[key]?.isEnabled || disabled) ? ("This field is required") : false}

                        />

                        {
                            entry.isInput && targetFish?.[key]?.sub_categories && targetFish?.[key]?.sub_categories?.indexOf(entry.value) >= 0 && 
                            <FormHookInput
                            register={register}
                            error={
                              errors?.target_fish?.[key]?.[`${entry.value}_input`]?.message
                            }
                            fieldClassName="form-control form-control-sm max-100"
                            hideLabel={true}
                            label={entry.value}
                            regFieldName={`target_fish.${key}.${entry.value}_input`}
                            type="text"
                            isDisabled={(!targetFish?.[key]?.isEnabled || disabled) ? ("This field is required") : false}
                          />
                        }
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        // </form>
      // </fieldset>
    );
};

