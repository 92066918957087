import  { useEffect, useState } from "react";
import { getData } from "../services/service-call";
import { ROUTES } from "../config";
import { useSelector } from "react-redux";


const SupportingDocumentsPreview = () => {

    const temp_trans_id = useSelector((state) => state.currentTransaction.temp_trans_id);

    const [documents, setAllDocuments] = useState([]);

    useEffect(() => {

        if(!temp_trans_id) return;
        
        const getDocuments = async () => {
            const result = await getData({
                url: `${ROUTES.getUploadedDocuments}${temp_trans_id}`
            })
            setAllDocuments(result);

        };
        getDocuments();
    }, [temp_trans_id]);

    return <div className="shadow p-3 m-3 bg-white rounded left-align">

    <div className="head-text">Supporting Documents</div>
    <table className="table table-bordered">
        <thead>
            <tr>
                <th className="w-50">File</th>
                <th className="w-50">Upload Status</th>
            </tr>
        </thead>
        <tbody>
            {
                documents?.map((document, index) => {
                    return <tr key={index}>
                        <td className="w-50">{document.file_name}</td>
                        <td className="w-50">Uploaded</td>
                    </tr>
                })
            }
        </tbody>
    </table>
</div>;
        
    



};

export default SupportingDocumentsPreview;