import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FLISContext } from "../context/FLISContext";
import { deleteData, getData, postData, putData } from "../services/service-call";
import { ROUTES } from "../config";
import HorizontalLine from "../components/HorLine";
import { FormHookInput, FormHookSelect } from "../components/FormHook/ReactFormHookFields";
import { emailPattern, formatPhoneNumber, telephonePattern } from "../components/DataSource";
import { emailNotValid, telephoneNotValid } from "../utils/messages";
import { alertService } from "../_services";
import { passwordAndReenterShouldBeSame } from "../utils/utils";

const UpdateUserInformation = () => {
  const { currentEntity, profileData } = useContext(FLISContext);
  const [updatingUser, setUpdatingUser] = useState(false);

  const [primaryUser, setPrimaryUser] = useState({});
  const [secondaryUsers, setSecondaryUsers] = useState([]);

  const [currentUser, setCurrentUser] = useState({});
  const {
    register,
    formState,
    getValues,
    trigger,
    reset,
  } = useForm({
    defaultValues: currentUser,
    mode: "all",
  });


  const {
    register : registerNewUser,
    formState: formStateNewUser,
    getValues: getValuesNewUser,
    trigger: triggerNewUser,
    reset: resetNewUser,
  } = useForm({
    defaultValues: {},
    mode: "all",
  });

  const { errors: errorsNewUser } = formStateNewUser;
  const { errors } = formState;


  useEffect(() => {
    reset(currentUser);
  }, [currentUser]);

  const getEntityUsers = async () => {
    if (!currentEntity?.id) return;
    const result = await getData({
      url: `${ROUTES.getAllUsers}${currentEntity.id}`,
    }) || [];

    const primaryUser = result?.find(
      (u) => currentEntity.primary_user_id === u.id
    );

    if (primaryUser) setPrimaryUser(primaryUser);
    else setPrimaryUser(result?.[0] ?? {});

    const secondaryUsers =
      result?.filter((u) => currentEntity.primary_user_id !== u.id) || [];
    if (secondaryUsers?.length === result?.length)
      setSecondaryUsers(result?.slice(1));
    else setSecondaryUsers(secondaryUsers);


    const currentUser = result?.find(u => u.id === profileData.id);
    setCurrentUser(currentUser);

  };

  useEffect(() => {
    getEntityUsers();
  }, [currentEntity?.id]);

  return (
    <div>

      {updatingUser && (
        <div>
          <div>
            <div className="mt-2 shadow bg-white rounded registration-border mb-3 pb-3">
              <div
                className="sub-form-header-registration"
                style={{ backgroundColor: "#00b7fd" }}
              >
                Update Current User
              </div>
              <HorizontalLine></HorizontalLine>

              <div className="d-flex-wrap flex-row justify-content-start align-items-start registration-margin">
                {currentUser?.login_as === "Applicant" && (
                  <>
                    <FormHookSelect
                      register={register}
                      error={errors?.applying_as?.message}
                      label="Applying As"
                      regFieldName="applying_as"
                      options={[
                        "Applicant",
                        "Company on Behalf of Crew Member",
                      ]}
                      fieldClassName="form-control form-control-sm"
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.name_of_applicant?.message}
                      label="Name"
                      regFieldName="name_of_applicant"
                      placeholder="Name"
                      type="text"
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.email?.message}
                      label="Email Address"
                      regFieldName="email"
                      placeholder="Email"
                      type="text"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      fieldClassName="form-control form-control-sm"
                      isDisabled={true}
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.cell_phone_user?.message}
                      label="Cell Phone"
                      regFieldName="cell_phone_user"
                      placeholder="Cell Phone"
                      type="text"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                      fieldClassName="form-control form-control-sm"
                      isDisabled={true}
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.password?.message}
                      label="Password"
                      regFieldName="password"
                      placeholder="Password"
                      type="password"
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.reenterpassword?.message}
                      label="Reenter Password"
                      regFieldName="reenterpassword"
                      placeholder="Reenter Password"
                      type="password"
                      fieldClassName="form-control form-control-sm"
                    />
                  </>
                )}

                {currentUser?.login_as === "Supporting Officer" && (
                  <>
                    <FormHookInput
                      register={register}
                      error={errors?.supporting_officer_name?.message}
                      label="Supporting Officer Name"
                      regFieldName="supporting_officer_name"
                      placeholder="Supporting Officer Name"
                      type="text"
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.email?.message}
                      label="Supporting Officer Email"
                      regFieldName="email"
                      placeholder="Supporting Officer Email"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.password?.message}
                      label="Password"
                      regFieldName="password"
                      placeholder="Password"
                      type="password"
                      fieldClassName="form-control form-control-sm"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.reenterpassword?.message}
                      label="Reenter Password"
                      regFieldName="reenterpassword"
                      placeholder="Reenter Password"
                      type="password"
                      fieldClassName="form-control form-control-sm"
                    />
                  </>
                )}

                <button
                  onClick={async () => {
                    const isValid = await trigger();
                    const data = getValues();
                    if (data.password !== data.reenterpassword) {
                      alertService.error(passwordAndReenterShouldBeSame);
                      return;
                    }
                    if (isValid) {
                      const result = await putData({
                        url: `${ROUTES.users}/${currentUser.id}`,
                        body: data,
                      });
                      if (result.success) {
                        alertService.success(result.data.msg, {
                          autoClose: true,
                          keepAfterRouteChange: true,
                        });

                        getEntityUsers();
                        setUpdatingUser(false);
                      } else {
                        alertService.warn(result.data.msg, {
                          autoClose: true,
                          keepAfterRouteChange: true,
                        });
                      }
                    }
                  }}
                  className="mt-30 ml-3 btn  btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="entity-users-head">Primary Agent</div>
        <div>
          <div className="table-responsive left-align">
            <table className="table table-sm table-bordered">
              <thead className="custom-table-header">
                <tr>
                  <th className="w-25">Name</th>
                  <th className="w-25">Email</th>
                  <th className="w-25">Phone #</th>
                  <th className="w-25">User Type</th>
                  <th className="w-25">Update User</th>
                </tr>
              </thead>

              <tbody className="table-striped">
                <tr>
                  <td className="w-25">{primaryUser.name_of_applicant}</td>
                  <td className="w-25">{primaryUser.email}</td>
                  <td className="w-25">{primaryUser.cell_phone_user}</td>
                  <td className="w-25">
                    {primaryUser.login_as ?? "Applicant"}
                  </td>
                  <td className="w-25">
                    <button
                      onClick={() => {
                        reset(primaryUser);
                        setUpdatingUser(true);
                      }}
                      disabled={profileData.id != primaryUser.id}
                      className="btn btn-success"
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <div className="entity-users-head">Secondary Agent</div>

        <div>
          <div className="table-responsive left-align">
            <table className="table table-sm table-bordered">
              <thead className="custom-table-header">
                <tr>
                  <th className="w-20">Name</th>
                  <th className="w-20">Email</th>
                  <th className="w-20">Phone #</th>
                  <th className="w-20">User Type</th>
                  <th className="w-10">Delete</th>
                  <th className="w-10">Promote</th>
                  <th className="w-10">Update User</th>
                </tr>
              </thead>

              <tbody className="table-striped">
                {secondaryUsers?.map((u) => {
                  return (
                    <tr>
                      <td className="w-20">
                        {u.login_as === "Supporting Officer"
                          ? u.supporting_officer_name
                          : u.name_of_applicant}
                      </td>
                      <td className="w-20">{u.email}</td>
                      <td className="w-20">{u.cell_phone_user}</td>
                      <td className="w-20">{u.login_as ?? "Applicant"}</td>
                      <td className="w-10">
                        <button
                          className="btn btn-danger"
                          onClick={async () => {
                            const result = await deleteData({
                              url: `${ROUTES.deleteUserFromEntity}/${u.id}`,
                            });

                            if (result.success) {
                              alertService.success(result.data.msg, {
                                autoClose: true,
                                keepAfterRouteChange: true,
                              });
                              getEntityUsers();
                            } else {
                              alertService.warn(result.data.msg, {
                                autoClose: true,
                                keepAfterRouteChange: true,
                              });
                            }
                          }}
                        >
                          Delete
                        </button>
                      </td>

                      <td className="w-10">
                        <button className="btn btn-success">Promote</button>
                      </td>

                      <td className="w-25">
                        <button
                          onClick={() => {
                            reset(u);
                            setUpdatingUser(true);
                          }}
                          disabled={profileData.id != u.id}
                          className="btn btn-success"
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div className="mt-2 shadow bg-white rounded registration-border mb-3 pb-3">
            <div
              className="sub-form-header-registration"
              style={{ backgroundColor: "#00b7fd" }}
            >
              Add New user
            </div>
            <HorizontalLine></HorizontalLine>

            <div className="d-flex-wrap flex-row justify-content-start align-items-start registration-margin">
              <FormHookSelect
                register={registerNewUser}
                error={errorsNewUser?.applying_as?.message}
                label="Applying As"
                regFieldName="applying_as"
                options={["Applicant", "Company on Behalf of Crew Member"]}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={registerNewUser}
                error={errorsNewUser?.name_of_applicant?.message}
                label="Name"
                regFieldName="name_of_applicant"
                placeholder="Name"
                type="text"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={registerNewUser}
                error={errorsNewUser?.email?.message}
                label="Email Address"
                regFieldName="email"
                placeholder="Email"
                type="text"
                validations={{
                  pattern: {
                    value: emailPattern,
                    message: emailNotValid,
                  },
                }}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={registerNewUser}
                error={errorsNewUser?.cell_phone_user?.message}
                label="Cell Phone"
                regFieldName="cell_phone_user"
                placeholder="Cell Phone"
                type="text"
                validations={{
                  pattern: {
                    value: telephonePattern,
                    message: telephoneNotValid,
                  },
                }}
                onChange={formatPhoneNumber}
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={registerNewUser}
                error={errorsNewUser?.password?.message}
                label="Password"
                regFieldName="password"
                placeholder="Password"
                type="password"
                fieldClassName="form-control form-control-sm"
              />

              <FormHookInput
                register={registerNewUser}
                error={errorsNewUser?.reenterpassword?.message}
                label="Reenter Password"
                regFieldName="reenterpassword"
                placeholder="Reenter Password"
                type="password"
                fieldClassName="form-control form-control-sm"
              />

              <button
                onClick={async () => {
                  if (secondaryUsers?.length >= 3) {
                    alertService.warn(
                      `Only ${secondaryUsers?.length} secondary agents can be added`,
                      {
                        autoClose: true,
                        keepAfterRouteChange: true,
                      }
                    );
                    return;
                  }
                  const isValid = await triggerNewUser();
                  const data = getValuesNewUser();
                  if (data.password !== data.reenterpassword) {
                    alertService.error(passwordAndReenterShouldBeSame);
                    return;
                  }
                  if (isValid) {
                    const result = await postData({
                      url: `${ROUTES.addUserToEntity}${currentEntity.id}`,
                      body: data,
                    });
                    if (result.success) {
                      alertService.success(result.data.msg, {
                        autoClose: true,
                        keepAfterRouteChange: true,
                      });
                      resetNewUser({
                        email: null,
                        applying_as: null,
                        password: null,
                        reenterpassword: null,
                        cell_phone_user: null,
                        name_of_applicant: null,
                      });
                      getEntityUsers();
                    } else {
                      alertService.warn(result.data.msg, {
                        autoClose: true,
                        keepAfterRouteChange: true,
                      });
                    }
                  }
                }}
                className="mt-30 ml-3 btn  btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserInformation;
