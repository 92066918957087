import React, { useState } from "react";
import BoxChoiceContainer from "./BoxChoiceContainer";
import { CustomInputContainer } from "./CustomInputContainer";
import { InternalNavigator } from "./DataSource";
import { validateKeys } from "../utils/utils";
import { alertService } from "../_services";
import { inputPreviousNumber, selectionNotFound } from "../utils/messages";
import AlertDialogSlide from "./Modal/Modal";




const NFARegNumberSelection = ({selectedMap, selectionCategory, setSelectedMap, history, setSelectionCategory, allowIfNot = false, notAllowedTitle = "You are not a NFA Registered Fisher and therefore not allowed to proceed", previousTransNumberText = "Enter your Fisher Licence Transaction Number", proceedText="Proceed to Submit an Application for a Fisher Licence.", warningModalTitle = "Submit Fisher Licence"}) => {

    const [dialog, showDialog] = useState(false);
    return (
      <div>
        <AlertDialogSlide
          fullWidth={false}
          open={dialog}
          title={""}
          handleClose={() => {
            showDialog(false);
          }}
          primaryActionName={"Close"}
          content={
            <>
              {notAllowedTitle}
              <br></br>

              <div className="d-flex-wrap justify-content-center ">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    history.push("/application-details");
                  }}
                >
                  Apply for Fisher Licence
                </button>
              </div>
            </>
          }
        ></AlertDialogSlide>

        {/* Do you have an NFA Registration # */}
        {/* Have NFA Registration # */}
        {/* Dont have NFA Registration but have Transaction Number of Fisher Licence  */}
        {/*  */}
        <BoxChoiceContainer
          listingHeading={"Do you have an NFA Registration #"}
          listingOptions={["Yes", "No"]}
          selectedMap={selectedMap}
          setSelectedMap={setSelectedMap}
          selectionCategory={selectionCategory}
          prop_key={"have_nfa_reg_number"}
          listingStyle="justify-content-center"
        ></BoxChoiceContainer>

        {selectedMap["have_nfa_reg_number"] === "Yes" && (
          <CustomInputContainer
            heading={"Enter NFA Registration Number"}
            prop_key={"nfa_registration_number"}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
          ></CustomInputContainer>
        )}

        {selectedMap["have_nfa_reg_number"] === "No" && (
          <div className="d-flex-wrap flex-column justify-content-center">
            <CustomInputContainer
              heading={previousTransNumberText}
              prop_key={"parent_transaction_number"}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
            ></CustomInputContainer>
            <div className="rectangle-box-small-heading"> OR</div>
            <button onClick={() => {
               history.push("/application-details");
            }} className="btn btn-warning full-width max-400 center-it text-bold"
            >{proceedText}</button>
          </div>
        )}

        <div className="d-flex flex-column">
          <InternalNavigator
            prevValue={"Previous"}
            previousAction={() => {
              history.push("/");
            }}
            nextValue={"Next"}
            nextAction={() => {
              if (!validateKeys(selectedMap, ["have_nfa_reg_number"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if (
                selectedMap.have_nfa_reg_number === "Yes" &&
                !validateKeys(selectedMap, ["nfa_registration_number"])
              ) {
                alertService.warn(inputPreviousNumber("NFA Registration #"), {
                  autoClose: true,
                });
                return;
              }

              if (
                selectedMap.have_nfa_reg_number === "No" &&
                !validateKeys(selectedMap, ["parent_transaction_number"])
              ) {
                alertService.warn("Input the transaction number to proceed", {
                  autoClose: true,
                });
                return;
              }

              if (allowIfNot) {
                setSelectionCategory(selectionCategory + 1);
                return;
              } else if (selectedMap.have_nfa_reg_number === "No") {
                // showDialog(true);
                // return;
              }

              setSelectionCategory(selectionCategory + 1);
            }}
          />
        </div>
      </div>
    );
  }
  

  export default NFARegNumberSelection