export const appTypes = [

   
    {
        color: "rgb(234,130,97)",
        title: "Application for Fisher Licence",
        value: 10,
        appType: 'APPLICATION_FOR_LICENCE',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0004.jpg',
        detailsPage: '/application-details',
        initiateState: true,
        redirectTo: '/application/licence',
        isLoginRequired: true
    },

    {
        color: "rgb(148,194,200)",
        title: "Application for Fishing Vessel Licence",
        value: 10,
        appType: 'APPLICATION_FOR_FISHING_VESSEL',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0006.jpg',
        detailsPage: '/fishing-vessel-details',
        initiateState: true,
        redirectTo: '/application/fishing-vessel',
        isLoginRequired: true
    },

    {
        color: "rgb(148,194,200)",
        title: "Application for Permits",
        value: 10,
        appType: 'APPLICATION_FOR_PERMIT',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0010.jpg',
        detailsPage: '/permit-details',
        initiateState: true,
        redirectTo: '/application/permit',
        isLoginRequired: true
    },


    {
        color: "rgb(148,194,200)",
        title: "Application for Aquaculture Fish Farmer Licence",
        appType: 'APPLICATION_FOR_AQUACULTURE_FISHER',
        value: 10,
        appBelongsTo: 'CAC',
        dispImage: './images/aqua/aqua4.jpeg',
        detailsPage: '/aquaculture-fisher-details',
        initiateState: true,
        redirectTo: '/application/aquaculture-fisher',
        isLoginRequired: true
    },

    {
        color: "rgb(148,194,200)",
        title: "Application for Aquaculture Fishing Vessel Licence",
        appType: 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL',
        value: 10,
        appBelongsTo: 'CAC',
        dispImage: './images/aqua/aqua5.jpeg',
        detailsPage: '/aquaculture-fishing-vessel-details',
        initiateState: true,
        redirectTo: '/application/aquaculture-fishing-vessel',
        isLoginRequired: true
    },

    {
        color: "rgb(148,194,200)",
        title: "Application for Aquaculture Facility Licence",
        appType: 'APPLICATION_FOR_AQUACULTURE_FACILITY',
        value: 10,
        appBelongsTo: 'CAC',
        dispImage: './images/aqua/aqua6.jpeg',
        detailsPage: '/aquaculture-facility-details',
        initiateState: true,
        redirectTo: '/application/aquaculture-facility',
        isLoginRequired: true
    },

    {
        color: "rgb(233,219,162)",
        title: "Application for Authorizations",
        value: 12,
        appType: 'APPLICATION_FOR_AUTHORIZATION',
        appBelongsTo: 'CAC',
        dispImage: './images/attachments/IMG-20230608-WA0014.jpg',
        detailsPage: '/authorization-details',
        initiateState: true,
        redirectTo: '/application/authorization',
        isLoginRequired: true
    },

    {
        color: "rgb(234,130,97)",
        title: "Application for Quota and Fishing Rights",
        value: 12,
        appType: 'APPLICATION_QUOTA_FISHING',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0015.jpg',
        detailsPage: '/quota-fishing-details',
        initiateState: true,
        redirectTo: '/application/quota-fishing',
        isLoginRequired: true
    },

    {
        color: "rgb(233,219,162)",
        title: "Closed Season Declarations",
        value: 10,
        appType: 'APPLICATION_FOR_DECLARATION',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0016.jpg',
        detailsPage: '/declaration-details',
        initiateState: true,
        redirectTo: '/application/declaration',
        isLoginRequired: true
    },

  

    {
        color: "rgb(148,194,200)",
        title: "Landing Catch Declaration",
        appType: 'APPLICATION_FOR_LANDING_CATCH_DECLARATION',
        value: 10,
        appBelongsTo: 'CAC',
        dispImage: './images/attachments/IMG-20230608-WA0017.jpg',
        detailsPage: '/landing-logs-details',
        initiateState: true,
        redirectTo: '/application/landing-logs',
        isLoginRequired: true
    },

    {
        color: "rgb(234,130,97)",
        title: "Other Services",
        value: 10,
        appType: 'APPLICATION_FOR_GENERAL_ADMINISTRATION',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0018.jpg',
        detailsPage: '/general-administration-details',
        initiateState: true,
        redirectTo: '/application/general-administration',
        isLoginRequired: true
    },

    {
        color: "rgb(234,130,97)",
        title: "Application for Fisher Worker ID",
        value: 10,
        appType: 'APPLICATION_FOR_FISHER_WORKER_ID',
        appBelongsTo: 'FTC',
        dispImage: './images/attachments/IMG-20230608-WA0019.jpg',
        detailsPage: '/fisher-worker-details',
        initiateState: true,
        redirectTo: '/application/fisher-worker-id',
        isLoginRequired: true

    },
    {
        color: "rgb(234,130,97)",
        title: "Submit Notice of Violation",
        value: 10,
        appType: 'NOTICE_OF_VIOLATION',
        dispImage: './images/attachments/IMG-20230608-WA0019.jpg',
        redirectTo: '/application/submit-violation-notice',
        initiateState: true,
        isLoginRequired: false
    },

];