export const CustomInputContainer = ({
  heading,
  setSelectedMap,
  prop_key,
  placeholder,
  selectedMap,
  type = "text",
}) => {
  return (
    <>
      <div className="rectangle-box-small-heading">{heading}</div>

      <div className="d-flex flex-row justify-content-center">
        <input
          type={type}
          name={prop_key}
          onChange={(e) => onChangeInput(e, setSelectedMap)}
          value={selectedMap[prop_key]}
          className="col-sm-5 form-control form-control-sm"
          placeholder={placeholder}
        />
      </div>
    </>
  );
};


export const CustomTextAreaContainer = ({
  heading,
  setSelectedMap,
  prop_key,
  placeholder,
  selectedMap,
  rows=10,
  cols=50,
  isDisabled=false,
  fieldClassName="d-flex flex-row justify-content-center ",
  labelClassName="rectangle-box-small-heading",
  parentClassName="individual-field"
}) => {
  return (
    <div className={`${isDisabled ? 'non-functional-element' : ''} ${parentClassName}`}>
      <div className={labelClassName}>{heading}</div>

      <div className={fieldClassName}>
        <textarea
          name={prop_key}
          onChange={(e) => onChangeInput(e, setSelectedMap)}
          value={selectedMap[prop_key]}
          className="col-sm-5 form-control form-control-sm"
          placeholder={placeholder}
          rows={rows}
          cols={cols}
        />
      </div>
    </div>
  );
};



export const onChangeInput = (e, setSelectedMap) => {
  if (e.target.type === "checkbox" && !e.target.checked) {
    e.target.value = null;
  }
  setSelectedMap((map) => {
    return {
      ...map,
      [e.target.name]: e.target.value,
    };
  });
};


export const CustomSelectContainer = ({
  heading,
  setSelectedMap,
  prop_key,
  selectedMap,
  options
}) => {
  return (
    <>
      <div className="rectangle-box-small-heading">{heading}</div>

      <div className="d-flex flex-row justify-content-center">
        <select
        style={{position: 'top'}}
          name={prop_key}
          onChange={(e) => onChangeInput(e, setSelectedMap)}
          value={selectedMap[prop_key]}
          className="col-sm-5 form-control form-control-sm">


          <option value={""}>---Select---</option>
          {
            options?.map((option, index) => <option value={option}>{option}</option>)
          }
      </select>
      </div>
    </>
  );
};
