import React from "react";
import UpdateEntityInformation from "./UpdateEntityInformation";
import UpdateUserInformation from "./UpdateUserInformation";


const UpdateProfileInformation = ({entity = {}}) => {


    return <div class=" mt-2 accordion" id="accordionExample">
    <div class="mb-2 card">
      <div class="card-header custom-card-header" id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseUserInformation" aria-expanded="true" aria-controls="collapseUserInformation">
            Update User Information
          </button>
        </h2>
      </div>
  
      <div id="collapseUserInformation" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body">
          <UpdateUserInformation />
        </div>
      </div>
    </div>
    <div class="mb-2 card">
      <div class="card-header custom-card-header" id="headingTwo">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEntityInformation" aria-expanded="false" aria-controls="collapseEntityInformation">
            Update Applicant Information
          </button>
        </h2>
      </div>
      <div id="collapseEntityInformation" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <UpdateEntityInformation entity={entity}/>
      </div>
    </div>
    
  </div>
}

export default UpdateProfileInformation;