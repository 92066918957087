import React, { useContext } from "react";
import HorizontalLine from "../components/HorLine";
import {
  FormHookInput,
  FormHookSelect,
} from "../components/FormHook/ReactFormHookFields";
import { FormHookFileUploadHandler } from "../components/FormHook/FormHookDocument";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import {  individualYesDocList } from "../components/DataSource";
import { alertService } from "../_services";
import { postData } from "../services/service-call";
import { ROUTES } from "../config";
import { FLISContext } from "../context/FLISContext";

const PictureIdentification = () => {

  const {currentEntity} = useContext(FLISContext);

  const {
    register,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      entity_picture_identification: currentEntity?.entity_picture_identification ?? {}
    },
    mode: "all",
  });

  const { errors } = formState;

  const havePictureIdentification = watch(
    "entity_picture_identification.picture_identification"
  );

  const pictureIdentificationForNo = watch(
    "entity_picture_identification.picture_identification_type_for_no"
  );

  const updatePictureIdentification = async () => {
    const isValid = await trigger();

    if(isValid){
      const data = getValues();
      const result = await postData({
        url: `${ROUTES.updatePictureIdentification}${currentEntity.id}`,
        body: data,
      });

      if(result.success){
        alertService.success(result.data.msg, { autoClose:true, keepAfterRouteChange: true });
    } else {
        alertService.warn(result.data.msg, { autoClose:true, keepAfterRouteChange: true });
    }
    
    }

    
  }

  return (
    <div>
       <div className="shadow rounded bg-white registration-border mb-3 pb-3">
                <div className="sub-form-header-registration"
                 style={{ backgroundColor: "#00b7fd" }}>
                  Picture Identification (Optional) -
                  <span style={{ fontSize: "12px" }}>
                    If you do not have a Picture ID - please select either Birth
                    Certificate + Picture ID or Statutory Declaration
                  </span>
                </div>
                <HorizontalLine></HorizontalLine>
                <div className="d-flex-wrap gap-40-10 flex-row justify-content-start registration-margin">
                  <FormHookSelect
                    label="Do You have a Picture Identification"
                    register={register}
                    error={
                      errors?.entity_picture_identification
                        ?.picture_identification?.message
                    }
                    regFieldName="entity_picture_identification.picture_identification"
                    options={["Yes", "No"]}
                    isRequired={false}
                    fieldClassName="form-control form-control-sm"
                  />

                  {havePictureIdentification === "Yes" && (
                    <FormHookSelect
                      label="Select Picture Identification"
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.type_of_picture_identification?.message
                      }
                      regFieldName="entity_picture_identification.type_of_picture_identification"
                      options={individualYesDocList}
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {havePictureIdentification === "Yes" && (
                    <FormHookInput
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.picture_identification_number?.message
                      }
                      label="Picture Identification Number"
                      regFieldName="entity_picture_identification.picture_identification_number"
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {havePictureIdentification === "Yes" && (
                    <FormHookInput
                      register={register}
                      error={
                        errors?.entity_picture_identification
                          ?.picture_expiry_date?.message
                      }
                      label="Expiry Date"
                      regFieldName="entity_picture_identification.picture_expiry_date"
                      type="date"
                      fieldClassName="form-control form-control-sm"
                    />
                  )}

                  {
                    havePictureIdentification === "Yes" && (
                      <FormHookFileUploadHandler
                    error={errors?.entity_picture_identification?.identification_document?.message}
                    register={register}
                    watch={watch}
                    label="Upload Document"
                    accept="*"
                    regFieldName="entity_picture_identification.identification_document"
                    fieldClassName="field-block-control max-250"
                    parentClass="individual-field max-250"
                    isPreviewable={false}
                    removeFile={(id) => setValue(id, null)}
                    setValue={setValue}
                    toShowInstructionLink={true}
                  /> 
                    )
                  }

                  {havePictureIdentification === "No" && (
                    <>
                      <FormHookSelect
                        label="Identification Doc to be Uploaded"
                        register={register}
                        error={
                          errors?.entity_picture_identification
                            ?.picture_identification_type_for_no?.message
                        }
                        fieldClassName="form-control form-control-sm"
                        regFieldName="entity_picture_identification.picture_identification_type_for_no"
                        options={[
                          "Birth Certificate + Picture Identification",
                          "Statutory Certificate",
                        ]}
                      />


                      {pictureIdentificationForNo ===
                        "Birth Certificate + Picture Identification" && (
                        <>
                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.birth_certificate_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Birth Certificate"
                            accept="*"
                            regFieldName="entity_picture_identification.birth_certificate_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          ></FormHookFileUploadHandler>

                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.photo_identification_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Picture"
                            accept="*"
                            regFieldName="entity_picture_identification.photo_identification_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          ></FormHookFileUploadHandler>
                        </>
                      )}

                      {pictureIdentificationForNo ===
                        "Statutory Certificate" && (
                        <>
                          <FormHookFileUploadHandler
                            error={
                              errors?.entity_picture_identification
                                ?.statutory_certificate_file?.message
                            }
                            register={register}
                            watch={watch}
                            label="Upload Statutory Certificate"
                            accept="*"
                            regFieldName="entity_picture_identification.statutory_certificate_file"
                            fieldClassName="field-block-control max-250"
                            parentClass="individual-field max-250"
                            isPreviewable={false}
                            removeFile={(id) => setValue(id, null)}
                            setValue={setValue}
                          />

                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                            class="alert alert-primary"
                            role="alert"
                          >
                            If No - Download and fill out the attached
                            <Link
                              to="/forms/Statutory_Declaration_of_Date_of_Birth.pdf"
                              target="_blank"
                              download
                            >
                              {" "}
                              Statutory Declaration of Date of Birth{" "}
                            </Link>
                            (Upload the completed form at the “Upload Statutory
                            Certificate")
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

      <div className="d-flex mb-3 justify-content-end align-item-center">
        <button
          onClick={updatePictureIdentification}
          className="btn btn-primary min-200"
          type="button"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default PictureIdentification;
