import React, { useContext, useEffect, forwardRef } from "react";
import { useFieldArray } from "react-hook-form";
import { FormHookInput, FormHookSelect } from "../../components/FormHook/ReactFormHookFields";
import { years } from "../../components/DataSource";
import { FLISContext } from "../../context/FLISContext";
import { ARTISANAL, ownershipStatus } from "./QuotaFishingDataSource";

export const MarineVesselDetails =  ({ formState, control, register, onSubmit, shouldRenderInForm=true, isDisabled=false }) => {


  const {selectedMap} = useContext(FLISContext);
    const { fields, append, remove } = useFieldArray({
      control,
      name: "marine_vessel_details",
    });

    const { errors, isDirty, isValid } = formState;

    const flds = <> {fields.map((group, groupIndex) => {
      return (
        <div key={group.id} className="shadow p-3 mb-3 bg-white rounded">
          <div className="d-flex flex-row form-heading">
            <div
              style={{ marginRight: "10px" }}
            >{`${"Marine Vessel Details"} #${groupIndex + 1}`}</div>

            <div>
              {!isDisabled && (
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    remove(groupIndex);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
          <div className="d-flex-wrap flex-row justify-content-start">
            <FormHookInput
              register={register}
              error={
                errors?.marine_vessel_details?.[groupIndex]?.name_of_vessel
                  ?.message
              }
              label="Name of Vessel"
              placeholder="Name of Vessel"
              regFieldName={`marine_vessel_details[${groupIndex}].name_of_vessel`}
              isDisabled={isDisabled}
            />

            <FormHookInput
              register={register}
              error={
                errors?.marine_vessel_details?.[groupIndex]
                  ?.nfa_vessel_registration_number?.message
              }
              label="NFA Vessel Registration #"
              placeholder="NFA Vessel Registration #"
              regFieldName={`marine_vessel_details[${groupIndex}].nfa_vessel_registration_number`}
              isDisabled={isDisabled}
            />

            <FormHookInput
              register={register}
              error={
                errors?.marine_vessel_details?.[groupIndex]
                  ?.maritime_authority_number?.message
              }
              label="Maritime Authority Cert #"
              placeholder="Maritime Authority Cert #"
              regFieldName={`marine_vessel_details[${groupIndex}].maritime_authority_number`}
              isDisabled={isDisabled}
              isRequired={
                selectedMap.sub_category === ARTISANAL
                  ? false
                  : "This field is required"
              }
            />

            {/* <FormHookInput
              register={register}
              error={
                errors?.marine_vessel_details?.[groupIndex]?.ownership_percent
                  ?.message
              }
              label="% Ownership"
              placeholder="% Ownership"
              regFieldName={`marine_vessel_details[${groupIndex}].ownership_percent`}
              type="number"
              isDisabled={isDisabled}
            /> */}

            <FormHookSelect
              label="Ownership Status"
              register={register}
              regFieldName={`marine_vessel_details[${groupIndex}].ownership_status`}
              options={ownershipStatus}
              error={
                errors?.marine_vessel_details?.[groupIndex]?.ownership_status?.message
              }
              isDisabled={isDisabled}
            />

            <FormHookSelect
              label="Year Acquired"
              register={register}
              regFieldName={`marine_vessel_details[${groupIndex}].year_acquired`}
              options={years}
              error={
                errors?.marine_vessel_details?.[groupIndex]?.year_acquired
                  ?.message
              }
              isDisabled={isDisabled}
            />

            <FormHookInput
              register={register}
              error={
                errors?.marine_vessel_details?.[groupIndex]?.vsd_certificate
                  ?.message
              }
              label="VSD Certificate"
              placeholder="VSD Certificate"
              regFieldName={`marine_vessel_details[${groupIndex}].vsd_certificate`}
              isDisabled={isDisabled}
            />

            {/* <FormHookSelect
              label="Charter"
              register={register}
              regFieldName={`marine_vessel_details[${groupIndex}].is_charter`}
              options={["Yes", "No"]}
              error={
                errors?.marine_vessel_details?.[groupIndex]
                  ?.is_charter?.message
              }
              isDisabled={isDisabled}
            /> */}
          </div>
        </div>
      );
    })}</>;


    return (
      <div className="form-container">
       {
        shouldRenderInForm && 
        <fieldset>
        <form onSubmit={onSubmit}>
         <> {flds}</>
        </form>
      </fieldset>
       }

       {
        !shouldRenderInForm && <>{flds}</>
       }

        <div className="d-flex flex-row-reverse">
          {
            !isDisabled && <button onClick={() => {
              append({ id: Math.random() });
            }} type="button" className="btn btn-primary">Add Marine Vessel Details</button>
          }
        </div>
      </div>
    );
  };
