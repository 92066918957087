export const RESEARCH = "Research";
export const SURVEY_OPERATIONS = "Survey Operations";

export const categoryOfAuthorization = [RESEARCH, SURVEY_OPERATIONS];
export const NEW_LICENCE = "New Licence";
export const AMENDMENT = "Amendment";
export const RENEWAL = "Renewal";

export const subcategoryofResearch = {
  [RESEARCH]: ["Educational", "Scientific"],
};

export const typeofTransaction = [
  NEW_LICENCE,
  AMENDMENT,
  RENEWAL,
  // "Substitute (Replacement)",
];

const allCountries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

// Sort the countries alphabetically
export const countries = allCountries.sort();

export const domicileType = ["Local", "Foreign"];

export const typeOfSpecies = [
  "Reef Fish",
  // "Reef Fishery",
  "Spiny Lobster",
  // "Octopus/Squid",
  "Octopus / Squid",
  // "Ornamentals",
  "Coastal Pelagics",
  // "Conch",
  "Conch (Other conch)",
  "Conch (Queen conch)",
  "Crabs",
  "Bait",
  // "Sea Moss / Weed",
  "Irish Moss / Sea Weeds",
  "Inland FinFish",
  // "Shrimp",
  "Shrimp Freshwater",
  "Shrimp Marine",
  // "Sea Cucumber",
  // "Deep / Slope Snapper",
  "Deep Slope / Snapper",
  // "Offshore Tunas / Pelagics",
  "Offshore Pelagics",
  // "Glass Eel",
  "Glass eels",
  // "Other",
  "Other Aquatic Plants"
];

export const INDIVIDUAL_AUTHORIZATION = "Individual";
export const COMPANY_AUTHORIZATION = "Company";
export const VESSEL_AUTHORIZATION = "Vessel";

export const typeOfAuthorization = [INDIVIDUAL_AUTHORIZATION,COMPANY_AUTHORIZATION , VESSEL_AUTHORIZATION];

export const authorizationCodeMapping = {
  [INDIVIDUAL_AUTHORIZATION]: "RI",
  [COMPANY_AUTHORIZATION]: "RC",
  [VESSEL_AUTHORIZATION]: "RV"
}
