import React, { forwardRef} from "react";


export const TournamentInformation = forwardRef(({showHeading = true, formState,control, register, onSubmit }, ref) => {


    const {errors} = formState;

    return (
      <div className="form-container">
        {/* <fieldset> */}
          {/* <form onSubmit={onSubmit}> */}
            <div className="shadow p-3 mb-3 bg-white rounded">
              <div className="d-flex flex-row form-heading">
               {
                showHeading &&  <div
                style={{ marginRight: "10px" }}
              >{`${"Tournament Information"}`}</div>
               }
              </div>
              <div className="d-flex-wrap flex-row justify-content-start">
                <div className="individual-field">
                  <label>Port of Departure</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.port_of_departure`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Port of Departure"
                  />
                  {errors?.tournament_information?.port_of_departure && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="individual-field">
                  <label>Start Date & Time</label>

                  <input
                    type="datetime-local"
                    {...register(`tournament_information.start_date_time`, {
                      required: "This field is required",
                      maxLength: { value: 100, message: "Max length is 100" },
                    })}
                    className="form-control"
                  />
                  {errors?.tournament_information?.start_date_time && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="individual-field">
                  <label>End Date & Time</label>

                  <input
                    type="datetime-local"
                    {...register(`tournament_information.end_date_time`, {
                      required: "This field is required",
                      maxLength: { value: 100, message: "Max length is 100" },
                    })}
                    className="form-control"
                  />
                  {errors?.tournament_information?.end_date_time && (
                    <span>This field is required</span>
                  )}
                </div>



                <div className="individual-field">
                  <label>Main Sponsor</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.main_sponsor`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Main Sponsor"
                  />
                  {errors?.tournament_information?.main_sponsor && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Total Prize Money</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.total_prize_money`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Total Prize Money"
                  />
                  {errors?.tournament_information?.total_prize_money && (
                    <span>This field is required</span>
                  )}
                </div>

                <div className="individual-field">
                  <label>Maximum Prize Money</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.maximum_prize_money`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Maximum Prize Money"
                  />
                  {errors?.tournament_information?.maximum_prize_money && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Maximum Number Boats</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.maximum_boats`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Maximum Number Boats"
                  />
                  {errors?.tournament_information?.maximum_boats && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Maximum Fishers</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.maximum_fishers`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Maximum Fishers"
                  />
                  {errors?.tournament_information?.maximum_fishers && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Entry Fee (JMD)</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.entry_fee`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Entry Fee"
                  />
                  {errors?.tournament_information?.entry_fee && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Weigh in Port / Location</label>

                  <input
                    type="text"
                    required
                    {...register(
                      `tournament_information.weigh_in_port_location`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Weigh in Port / Location"
                  />
                  {errors?.tournament_information?.weigh_in_port_location && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Weigh in Start Time</label>

                  <input
                    type="datetime-local"
                    required
                    {...register(
                      `tournament_information.weigh_in_start_time`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Weigh in Start Time"
                  />
                  {errors?.tournament_information?.weigh_in_start_time && (
                    <span>This field is required</span>
                  )}
                </div>


                <div className="individual-field">
                  <label>Weigh in End Time</label>

                  <input
                    type="datetime-local"
                    required
                    {...register(
                      `tournament_information.weigh_in_end_time`,
                      {
                        required: "This field is required",
                        maxLength: { value: 100, message: "Max length is 100" },
                      }
                    )}
                    className="form-control"
                    placeholder="Weigh in End Time"
                  />
                  {errors?.tournament_information?.weigh_in_end_time && (
                    <span>This field is required</span>
                  )}
                </div>


                {/* <button type="submit" disabled={!isValid}>
                  Submit
                </button> */}
              </div>{" "}
            </div>
          {/* </form> */}
        {/* </fieldset> */}
      </div>
    );
});
