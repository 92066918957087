// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FISHER_LICENCE,
  typeOfTransactions,
  getSVGImage,
  getRectBoxStylingWithMultiValue,
  getRectBoxStylingWithSingleValue,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  getAnimationStyle,
  onChangeMultiSelect,
  NEW_LICENCE,
  RenewalTypeOptions,
  baseLocations,
  BASE_LOCATION_INLAND,
  regionListForInland,
  regionInland,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  ID_CARD_RENEWAL,
  RENEWAL,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";

import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import { shouldAskForNFARegNumber, shouldAskPreviousAppNumber, validateKeys } from "../../utils/utils";
import {
  formInvalidMessage,
  gearSelectionNotFound,
  inputPreviousNumber,
  inputSelection,
  selectionNotFound,
} from "../../utils/messages";
import { alertService } from "../../_services";
import {
  fisherAquaType,
  fisherAquaTypeOfApplicant,
  typeOfSpecies,
  fisherAquaApplicantRoleInFisheries,
  typeOfLicence,
  gearTypes,
  typeofLicenceSubCategories,
} from "./AquaFisherDataSource";
import { useForm } from "react-hook-form";
import { GearInformation } from "../FishingVesselLicence/GearInformation";
import { SubCategorizedChoices } from "../../components/SubCategorisedChoicelist";
import {
  FormHookCheckbox,
  ReactSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { includes } from "lodash";
import { TargetFish } from "../AquacultureFishingVessel/TargetFish";
import { typeOfFish } from "../AquacultureFishingVessel/AquaFishingVesselDataSource";

export default function LicenseTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap } = useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(
    selectedMap.selectionCategory ?? 3
  );

  const gearInformationForm = useForm({
    defaultValues: {
      gear_information: selectedMap.gear_information ?? {},
    },
    mode: "all",
  });

  const {
    handleSubmit: handleGISubmit,
    control: giFormControl,
    trigger: triggerValidateGI,
    getValues: getGearInfoValues,
    register: registerGI,
    formState: giFormState,
    setValue: setGearInfoValues,
    watch: watchGearInfo,
  } = gearInformationForm;

  const licenceCategoryForm = useForm({
    defaultValues: {
      fresh_water_categories: selectedMap.fresh_water_categories ?? [],
      mariculture_categories: selectedMap.mariculture_categories ?? [],
      ornamental_categories: selectedMap.ornamental_categories ?? [],
    },
    mode: "all",
  });

  const {
    handleSubmit: handleLSCSubmit,
    control: lscFormControl,
    trigger: triggerValidateLSC,
    getValues: getLicenceSubcategoriesValues,
    register: registerLSC,
    formState: lscFormState,
    setValue: setLicenceSubCategoriesValues,
    watch: watchLSC,
  } = licenceCategoryForm;
  const { errors: errorsLSC } = lscFormState;

  const targetFishForm = useForm({
    defaultValues: {
      target_fish: selectedMap.target_fish ?? {},
    },
    mode: "all",
  });

  const {
    handleSubmit: handleTFSubmit,
    control: tfFormControl,
    trigger: triggerValidateTF,
    getValues: getTargetFishValues,
    register: registerTF,
    formState: tfFormState,
    setValue: setTFValue,
    reset: resetTf,
    watch: watchTF,
  } = targetFishForm;

  const onSubmit = (data) => {
    console.log(data);
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  const validateTFForm = async () => {
    try {
      const isFormValid = await triggerValidateTF();

      // can get values from this
      const data = await getTargetFishValues();

      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            target_fish: data.target_fish,
          };
        });
        handleTFSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 3 && (
        <div style={getAnimationStyle(selectionCategory === 3)}>
          <>
            <BoxChoiceContainer
              listingHeading={"Select Fish Farmer Category"}
              listingOptions={fisherAquaType}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              onClick={() => {
                setSelectionCategory(selectionCategory + 1);
              }}
              prop_key={"licence_type"}
              listingStyle="justify-content-center"
            ></BoxChoiceContainer>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  history.goBack();
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["licence_type"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                  setSelectionCategory(selectionCategory + 1);
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          <>
            <BoxChoiceContainer
              listingHeading={"Select Licence Category"}
              listingOptions={typeOfLicence}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              prop_key={"licence_category"}
              onClick={() => {
                // setSelectionCategory(selectionCategory + 1);
              }}
              listingStyle="justify-content-center"
              isMultiSelect={true}
            ></BoxChoiceContainer>

            <div className="d-flex-wrap justify-content-center ">
              {typeofLicenceSubCategories.map(({ key, title, categories }) => (
                <div
                  key={key}
                  className="d-flex-wrap flex-column sub-form-container m-2 flex-grow-1"
                >
                  <div className="d-flex-wrap flex-column ml-3">
                    <div>
                      <b>{title} - Sub Categories</b>
                    </div>
                    {categories.map((entry, i) => (
                      <div key={entry.value} className="d-flex-wrap flex-row ">
                        <FormHookCheckbox
                          parentClass=""
                          fieldClassName=""
                          options={[entry.value]}
                          register={registerLSC}
                          error={errorsLSC?.[key]?.message}
                          regFieldName={`${key}`}
                          hideLabel={true}
                          isRequired={
                            selectedMap?.licence_category?.includes(title)
                              ? "This field is required"
                              : false
                          }
                          isDisabled={
                            !selectedMap?.licence_category?.includes(title)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
                nextValue={"Next"}
                nextAction={async () => {
                  if (!validateKeys(selectedMap, ["licence_category"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (await triggerValidateLSC()) {
                    const data = getLicenceSubcategoriesValues();
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        ...data,
                      };
                    });
                    setSelectionCategory(selectionCategory + 1);
                  }
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 5 && (
        <div style={getAnimationStyle(selectionCategory === 5)}>
          {
            <div className="d-flex flex-column">
              <div className="rectangle-box-small-heading">
                Select type of transaction
              </div>

              <div className="d-flex-wrap flex-row justify-content-center">
                {typeOfTransactions[FISHER_LICENCE]?.map((trans) => {
                  return (
                    <div
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            type_of_transaction: trans,
                          };
                        });
                        if (trans === NEW_LICENCE) {
                          setSelectionCategory(selectionCategory + 1);
                        }
                      }}
                      className="p-2 sub-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "type_of_transaction",
                        trans
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          32,
                          32,
                          selectedMap["type_of_transaction"] === trans
                            ? "black"
                            : "#cbf1ff"
                        )}

                        <div className="rectangle-box-small">{trans}</div>

                        {trans === NEW_LICENCE && (
                          <div>(Licence + ID Card)</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }

          {
            <div className="d-flex flex-column">
              {["Renewal"].indexOf(selectedMap.type_of_transaction) >= 0 && (
                <>
                  <div className="rectangle-box-small-heading">
                    Select Type of Renewal
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <select
                      name="renewal_type"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.renewal_type}
                      className="col-sm-5 form-control form-control-sm"
                    >
                      <RenewalTypeOptions />
                    </select>
                  </div>
                </>
              )}
            </div>
          }

          {
            <div className="d-flex flex-column">
              {shouldAskPreviousAppNumber(selectedMap) && (
                <>
                  <div className="rectangle-box-small-heading">
                    Enter the Licence Number
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <input
                      type="text"
                      name="previous_licence_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.previous_licence_number}
                      className="col-sm-5 form-control form-control-sm"
                      placeholder={"Enter Licence Number"}
                    />
                  </div>
                </>
              )}
            </div>
          }

          {
            <div className="d-flex flex-column">
              {shouldAskForNFARegNumber(selectedMap) && (
                <>
                  <div className="rectangle-box-small-heading">
                    Enter your Fish Farmer Registration #
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <input
                      type="text"
                      name="nfa_registration_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.nfa_registration_number}
                      className="col-sm-5 form-control form-control-sm"
                      placeholder={"Enter your Fish Farmer Registration #"}
                    />
                  </div>
                </>
              )}
            </div>
          }

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                if (
                  ["Renewal"].indexOf(selectedMap.type_of_transaction) !== -1 &&
                  !validateKeys(selectedMap, ["renewal_type"])
                ) {
                  alertService.warn(inputSelection("Renewal Type"));
                  return;
                }

                if (
                  shouldAskPreviousAppNumber(selectedMap) &&
                  !validateKeys(selectedMap, ["previous_licence_number"])
                ) {
                  alertService.warn(inputPreviousNumber("Licence Number"));
                  return;
                } else if (
                  shouldAskForNFARegNumber(selectedMap) &&
                  !validateKeys(selectedMap, ["nfa_registration_number"])
                ) {
                  alertService.warn(
                    inputPreviousNumber("Fish Farmer Registration #")
                  );
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 6 && (
        <div style={getAnimationStyle(selectionCategory === 6)}>
          <BoxChoiceContainer
            listingHeading={"Select Applicant's Role In Fisheries"}
            listingOptions={fisherAquaApplicantRoleInFisheries}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            onClick={() => {
              setSelectionCategory(selectionCategory + 1);
            }}
            prop_key={"applicant_role_in_fisheries"}
            listingStyle="justify-content-center"
          ></BoxChoiceContainer>

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (
                  !validateKeys(selectedMap, ["applicant_role_in_fisheries"])
                ) {
                  alertService.warn(selectionNotFound);
                  return;
                }
                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 7 && (
        <div style={getAnimationStyle(selectionCategory === 7)}>
          <BoxChoiceContainer
            listingHeading={"Select Port or Inland"}
            listingOptions={baseLocations}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"port_or_inland"}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            listingStyle="justify-content-center"
            listingDescriptions={[null, "(Rivers and Ponds)"]}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["port_or_inland"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 8 &&
        selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
          <div style={getAnimationStyle(selectionCategory === 8)}>
            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionListForInland}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      inland: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionInland?.[selectedMap.region]}
                  value={selectedMap.inland}
                  label="Select Inland"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        inland: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["inland"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (
                    selectedMap.inland === "Other" &&
                    !validateKeys(selectedMap, ["other_inland"])
                  ) {
                    alertService.warn(inputPreviousNumber("Other Inland"), {
                      autoClose: true,
                    });
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 8 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 8)}>
            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionParishList}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      port: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionParishPort?.[selectedMap.region]}
                  value={selectedMap.port}
                  label="Select Port"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        port: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["region", "port"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 9 && (
        <div style={getAnimationStyle(selectionCategory === 9)}>
          {
            <>
              <TargetFish
                formState={tfFormState}
                register={registerTF}
                control={tfFormControl}
                onSubmit={onSubmit}
                setValue={setTFValue}
                reset={resetTf}
                values={getTargetFishValues}
                watch={watchTF}
                source={typeOfFish}
              />

              {/* <div className="d-flex-wrap flex-column full-width align-items-center justify-content-center">
                <div className="rectangle-box-small-heading">
                  Gear Information
                </div>

                <GearInformation
                  formState={giFormState}
                  register={registerGI}
                  control={giFormControl}
                  onSubmit={onSubmit}
                  setValue={setGearInfoValues}
                  values={getGearInfoValues}
                  watch={watchGearInfo}
                  disabled={false}
                  source={gearTypes}
                  breakInto={1}
                />
              </div> */}
            </>
          }

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                if (await validateTFForm()) {
                  setSelectionCategory(selectionCategory + 1);
                } else {
                  alertService.warn(formInvalidMessage);
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 10 && (
        <div style={getAnimationStyle(selectionCategory === 10)}>
          <div className="d-flex-wrap flex-column full-width align-items-center justify-content-center">
            <div className="rectangle-box-small-heading">Gear Information</div>

            <GearInformation
              formState={giFormState}
              register={registerGI}
              control={giFormControl}
              onSubmit={onSubmit}
              setValue={setGearInfoValues}
              values={getGearInfoValues}
              watch={watchGearInfo}
              disabled={false}
              source={gearTypes}
              breakInto={1}
            />
          </div>

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={async () => {
                const gearInfo = await getGearInfoValues().gear_information;
                const isFormValid = await triggerValidateGI();
                if (!isFormValid) {
                  alertService.warn("Number of Gear is required");
                  return;
                }
                const gearKeys = Object.keys(gearInfo);
                const isChecked = gearKeys.some((key) => {
                  return gearInfo[key].isChecked;
                });
                if (isChecked) {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      gear_information: gearInfo,
                    };
                  });
                } else {
                  alertService.warn(gearSelectionNotFound);
                  return;
                }

                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });

                props?.next();
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
