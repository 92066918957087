import React from "react";
import { FormHookCheckbox } from "../components/FormHook/ReactFormHookFields";

const SimpleDeclaration = ({register, errors}) => {
    // return <div style={{paddingLeft: '20px', fontSize: '18px', paddingBottom: '10px', fontWeight: 600}}>{" "} * I hereby declare that I have read and fully understand the above</div>

    return (
        <FormHookCheckbox
        label="I hereby declare that I have read and fully understand the above"
        options={["I hereby declare that I have read and fully understand the above"]}
        register={register}
        error={errors?.accept_understand_above?.message}
        regFieldName={`accept_understand_above`}
        isMulti={true}
        hideLabel={true}
      />
    );
}

export default SimpleDeclaration;