import React from "react";
import { appTypes } from "../config/app-types";
import { useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import ApplicationType from "../components/ApplicationType";
import FooterContainer from "./FooterContainer";
import { getUniqueTransNumber } from "../utils/utils";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import SimpleImageSlider from "react-simple-image-slider";

export default function TypeOfApplications(props) {
  const history = useHistory();

  const params = useParams();

  const dispatch = useDispatch();
  let data = appTypes;
  let queryParams = queryString.parse(props?.location?.search || {});

  if (data.length === 0) {
    data = appTypes;
  }

  return (
    <div className="no-gutters">
      {/* <div className="col-sm-2">

                <SideBar links={sidebarLinks}></SideBar>
            </div> */}

      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }} className="d-flex-wrap align-items-center justify-content-between ">
        


        <div>
        <div>Jamaica’s Fisheries and Aquaculture Information System</div>
          <h5>Ensure your licences are approved and valid</h5>
        </div>



          <div className="d-flex-wrap flex-column flex-spacing-10 bottom-spacing-10">
          <button
            onClick={async () => {

             const transNumber = await getUniqueTransNumber();

              dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: 'NOTICE_OF_VIOLATION',
                transaction: {},
                submitted_transaction: null,
              });
          
            
              history.push("/application/submit-violation-notice")


            }}
            type="button"
            className="btn btn-custom-warning"
          >
            Notice of Violation
          </button>

          {/* <button
            onClick={async () => {

              window.open("https://iriefins.tawk.help/", '_blank');

            }}
            type="button"
            className="btn btn-custom-warning"
          >
         Help Center
          </button> */}
         
        </div> 

        </div>

      

      </div>

      <div className="row">
        {
          <div
            className={`d-flex-wrap m-4 flex-row justify-content-around bottom-spacing-10 `}
            style={{ width: "100%" }}>
            <ApplicationType app={data[0]} />
            <ApplicationType app={data[1]} />
            <ApplicationType app={data[2]} />

            <ApplicationType app={data[3]} />
            <ApplicationType app={data[4]} />
            <ApplicationType app={data[5]} />

            <ApplicationType app={data[6]} />
            <ApplicationType app={data[7]} />
            <ApplicationType app={data[8]} />
            <ApplicationType app={data[9]} />
            <ApplicationType app={data[10]} />
            <ApplicationType app={data[11]} />
            {/* <ApplicationType app={data[12]} /> */}
          </div>
        }
      </div>

      <FooterContainer />
    </div>
  );
}
