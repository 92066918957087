import React from 'react';
import { useHistory } from 'react-router-dom';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';



function DashboardUpdates(props) {

    const status = [
        'Draft',
        'On Hold',
        'Submitted'
    ]

    const transNumber = [
        '27408/22',
        '26708/22',
        '25608/21'
    ]

    const title = [
        'Fisheries License (Renewal)',
        'Fisheries License (New)',
        'Registration',
        
    ]

    const history = useHistory();
    return (
        <div className='mt-2 mb-3 pl-2 pt-3 pb-2  pr-2' style={{border: '1px solid #aaa', background: 'white'}}>

            <div className='mt-2 d-flex justify-content-start flex-spacing-10'>

                <img src="./images/single_fish.png" width={40} height={40} alt="fish"></img>
                <div className='flex-grow-1' style={{color: '#00b7fd', fontWeight: '600', fontSize: '18px'}}>
                    <div> {title[props.sequence]}</div>
                    <div>{transNumber[props.sequence]}</div>
                </div>

                <div>
                    <div><b>Status</b></div>
                    <span className="badge badge-secondary">{status[props.sequence ?? 0]}</span>
                </div>
                <div>
                    <button className='btn btn-warning'>
                        <span className="material-icons-outlined">
                            visibility
                        </span>
                    </button>
                </div>

                <div>
                    <button className='btn btn-warning'>
                        <span className="material-icons-outlined">
                            file_download
                        </span>
                    </button>
                </div>


            </div>

            <div>
                <hr className='ml-3'></hr>
            </div>

            <div className='d-flex justify-content-end flex-spacing-10'>

                <button onClick={() => {
                    history.push('/view-transaction');
                }} className='btn btn-custom-plain-yellow-black-text'>
                    Overview
                </button>

                <button className='btn btn-custom-plain-yellow-black-text'>
                    Transaction History
                </button>


                <button onClick={() =>{
                    document.getElementById('downloadLicense').click();
                }}  className='btn btn-custom-plain-yellow-black-text'>
                    License Certificate
                </button>


                <a id='downloadLicense' download={true} href='./forms/LicenseCertificate.pdf' hidden={true}></a>

                <button className='btn btn-secondary'>
                    Discussion and Support
                </button>
            </div>
        </div>
    );
}

export default DashboardUpdates;