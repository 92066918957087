import React, { useContext } from 'react';
import SimpleNavigator from '../SimpleNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {APP_SUBMISSION_CONFIG, desc} from '../../config/application-submission-config';
import { SUCCESS_SVG } from '../ImageDataSource';
import { FLISContext } from '../../context/FLISContext';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CREDIT_CARD } from '../../config/fees';
import { useEffect } from 'react';


export const subCategoryMap =  (map, appType) =>  {
  
  const lookup = {
    APPLICATION_FOR_DECLARATION: "",
    APPLICATION_QUOTA_FISHING: "",
    APPLICATION_FOR_AUTHORIZATION: map?.['category'],
    APPLICATION_FOR_PERMIT: map?.['type_of_permit'],
    APPLICATION_FOR_LICENCE: map?.['licence_type'],
    APPLICATION_FOR_FISHING_VESSEL: map?.['licence_type'],
    APPLICATION_FOR_AQUACULTURE_FISHER: "",
    APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL: "",
    APPLICATION_FOR_AQUACULTURE_FACILITY: "",
    APPLICATION_FOR_LANDING_CATCH_DECLARATION: "",
    APPLICATION_FOR_GENERAL_ADMINISTRATION: "",
    APPLICATION_FOR_FISHER_WORKER_ID: "",
  };

  return lookup?.[appType] ?? '';
}

function TransactionDetails(props) {
    const history = useHistory();



    useEffect(() => {
      // Push the current path to the history stack again to prevent going back
      history.push(history.location.pathname);

      // Listen to the history block
      const unblock = history.block((location, action) => {
        if (action === "POP") {
          // Prevent navigation
          history.push(history.location.pathname);
          return false;
        }
      });

      // Clean up the block when the component is unmounted
      return () => {
        unblock();
      };
    }, [history]);


    const {selectedMap, currentEntity} = useContext(FLISContext);

    const {api_response = '', trans_number} = selectedMap;

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    

    let routingDetails = appDetails.steps.filter((step) => {
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    const nextAction = () => {
        history.push(routingDetails.next);
    }

    const previousAction = () => {
        history.push(routingDetails.previous);
    }


    return (
      <div className='submitted-app-transaction-details'>

       {
        api_response &&  <div className='little-big-text d-flex flex-column justify-content-center application-status-container'>
        <div className='pt-3'>
          <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', top: '-40px', left: '-20px'}}>
          {SUCCESS_SVG(212,217)}
          </div>
          <h3 style={{paddingTop: '100px'}}><b>Application Submitted</b></h3>

          </div>
          <div style={{fontWeight: 600}}>
              Congratulations! Your {desc?.[appType]}  {subCategoryMap(selectedMap, appType) ? ` for ${subCategoryMap(selectedMap, appType)}` : ''} {`${selectedMap?.payment_details?.payment_method === CREDIT_CARD ? ' and Payment ' : ''}`} has been submitted successfully.
          </div>


          <div  className='mt-4'>To view your transaction click <Link to="/profile?active=transactions-tab">here</Link></div>

          <div className='mt-2'>
          An email will be sent to the email address below notifying you of your submission.
          <br/>
          <a style={{cursor: 'pointer'}} href={`mailto:${currentEntity.email}`}>{currentEntity.email}</a>
          </div>
          <div className='mt-2'>
          In the coming days you may need to collaborate with the NFA in order to move this application forward. You may be asked to provide additional or updated documentation.
          </div>



          <div style={{background: '#f1f1f1'}} className='mt-4 mb-4 p-2 d-flex flex-row flex-wrap justify-content-around'>

              <div>
                  <div><b>Transaction Number</b></div>
                  <div>{trans_number}</div>
              </div>

              <div>
                  <div><b>Status</b></div>
                  <div className='badge badge-success'>Successful</div>
              </div>



          </div>

        </div>
      </div>
       }


       {
        !api_response &&  <div className='little-big-text d-flex flex-column justify-content-center application-status-container'>
        <div className='pt-3'>
          <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', top: '-40px', left: '-20px'}}>
          {/* {FAILED_SVG(212,217)} */}
          <img src='/images/failed-popup.png' alt='failed transaction'></img>
          </div>
          <h3 style={{paddingTop: '100px'}}><b>Application Failed to be Submitted</b></h3>

          </div>
          <div style={{fontWeight: 600}}>
              Your application is saved in the Drafts Application. Please click on this <Link to="/profile?active=draft-applications-tab">link</Link> to resubmit your application.
          </div>

          <div style={{background: '#f1f1f1'}} className='mt-4 mb-4 p-2 d-flex flex-row flex-wrap justify-content-around'>

              <div>
                  <div><b>Transaction Number</b></div>
                  <div>-</div>
              </div>

              <div>
                  <div><b>Status</b></div>
                  <div className='badge badge-secondary'>Draft</div>
              </div>



          </div>

        </div>
      </div>
       }

      </div>
    );
}

export default TransactionDetails;