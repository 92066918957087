import React, { useContext, useState } from "react";
import { FLISContext } from "../../context/FLISContext";
import { useSelector } from "react-redux";
import { applicationConstants } from "../../config/application-submission-config";


const PreviewPaymentDetails = () => {

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    const {selectedMap} = useContext(FLISContext);

    const paymentDetails = selectedMap.payment_details;
    const paymentList = paymentDetails?.payment_list ?? [];

    return <div className="shadow p-3 m-3 bg-white rounded left-align">

    <div className="head-text">Payment Details</div>
    <table className="table table-bordered ">
        <thead>
            <tr>
                <th className="w-50">{applicationConstants?.[appType]?.feesTypeHeader ?? 'Fishing Category'}</th>
                <th className="w-50">Value</th>
            </tr>
        </thead>
        <tbody>
            {
                paymentList?.map((payment, index) => {
                    return <tr key={index}>
                        <td className="w-50">{payment.name}</td>
                        <td className="w-50">{payment.value}</td>
                    </tr>
                })
            }
        </tbody>
    </table>

    <div className="d-flex-wrap flex-row justify-content-between">
        <div><b>Payment Method</b> - {paymentDetails?.payment_method}</div>
        <div><b>Payment Amount</b> - {paymentDetails?.amount_due}</div>
    </div>
</div>


}

export default PreviewPaymentDetails;