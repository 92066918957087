import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";
import { SignaturePad } from "../../components/SignatureCanvas";

import {
  Break,
  Declarations,
  emailPattern,
  fishingAreaLocations,
  formatPhoneNumber,
  getMaxDateForAtleastYear,
  isRequiredCompany,
  isRequiredIndividual,
  telephonePattern
} from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FormHookCheckbox, FormHookInput, FormHookRadioInput, FormHookSelect, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { countries, typeOfSpecies } from "./AuthorizationDataSource";
import { emailNotValid, telephoneNotValid } from "../../utils/messages";
import { ParticularsofActivity } from "./ParticularsofActivity";
import { LocationDetails } from "../AuthorizationLicence/AuthorizationLocationSpeciesDetails";
import { FLISContext } from "../../context/FLISContext";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import GeneralDeclarations from "../GeneralDeclaration";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import { ROUTES } from "../../config";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForAuthorization = (props) => {
  
  
  const {selectedMap, setSelectedMap} = useContext(FLISContext);

  const history = useHistory();
  const buttonSubmitRef = useRef();

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  useEffect(() => {

      const value = getValues();
      if(!value?.authorization_location_species_details?.length){
        setValue('authorization_location_species_details', [{id: Math.random()}]);
      }

  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const submitAllDetails = () => {
    history.push("/ftc/transaction-details");
  };
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    trigger,
    setValue
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const {errors} = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });

  const onSubmit = async (data) => {

    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();
    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn(fillInAllTheFields);
      return; 
    }

    // if(selectedForm === 2){
    //   if(!data?.declaration_details?.applicant_signature_gen_dec  && !data?.declaration_details?.signature_draw_path_gen_dec){
    //     alertService.warn(fillInAllTheFields);
    //     return; 
    //   }
    // }

    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
    // localStorage.setItem("ftc_complaints", JSON.stringify(data));
  };

  

  const [selectedForm , setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Application Form"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Applicant Information</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isRequired={false}
                      type="text"
                      isDisabled={true}
                    />

                    <Break />

                    <div className="individual-field-margin1">
                      <label className="individual-field-margin mt-2">
                        Name of Applicant(Individuals):
                      </label>

                      <div className="d-flex-wrap sub-form-container-no-border">
                        <FormHookInput
                          register={register}
                          error={errors?.last_name_individual?.message}
                          label="Last"
                          regFieldName="last_name_individual"
                          placeholder="Last"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.first_name_individual?.message}
                          label="First"
                          regFieldName="first_name_individual"
                          placeholder="First"
                          isRequired={isRequiredIndividual(selectedMap)}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.middle_name_individual?.message}
                          label="Middle"
                          regFieldName="middle_name_individual"
                          placeholder="Middle"
                          isRequired={false}
                        />

                        <FormHookInput
                          register={register}
                          error={errors?.alias_individual?.message}
                          label="Alias"
                          regFieldName="alias_individual"
                          placeholder="Alias"
                          isRequired={false}
                        />
                      </div>
                    </div>

                    <Break />
                    <FormHookInput
                      register={register}
                      error={errors?.company_name?.message}
                      label="Name of Applicant(If Company)"
                      regFieldName="company_name"
                      placeholder="Name of Applicant"
                      type="text"
                      isRequired={isRequiredCompany(selectedMap)}
                      toAddOptionalText={false}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_of_origin?.message}
                      label="Nationality / Country of Origin"
                      regFieldName="country_of_origin"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.national_registration_id?.message}
                      label="Nationality Registration ID"
                      regFieldName="national_registration_id"
                      placeholder="Nationality Registration ID"
                      type="text"
                      // isRequired={isRequiredIndividual(selectedMap)}
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.taxation_registration_number?.message}
                      label="Taxation Registration Number"
                      regFieldName="taxation_registration_number"
                      placeholder="Taxation Registration Number"
                      type="text"
                      isRequired={false}
                      toAddOptionalText={false}
                    />

                    <Break />

                    <FormHookTextArea
                      register={register}
                      error={errors?.address?.message}
                      label="Address"
                      regFieldName="address"
                      placeholder="Address"
                      cols={73}
                      rows={1}
                    />

                    <FormHookSelect
                      register={register}
                      error={errors?.country_address?.message}
                      label="Country"
                      regFieldName="country_address"
                      options={countries}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.cell_phone?.message}
                      label="Cell Phone"
                      regFieldName="cell_phone"
                      placeholder="Cell Phone"
                      type="text"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.work_home_phone?.message}
                      label="Work / Home Phone"
                      regFieldName="work_home_phone"
                      placeholder="Work / Home Phone"
                      type="text"
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.email?.message}
                      label="Email"
                      regFieldName="email"
                      placeholder="Email"
                      type="text"
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact"
                      regFieldName="contact_name"
                      placeholder="Contact"
                      type="text"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.date_of_birth_individual?.message}
                      label="Date of Birth (Individual)"
                      regFieldName="date_of_birth_individual"
                      type="date"
                      isRequired={isRequiredIndividual(selectedMap)}
                      maxDate={getMaxDateForAtleastYear(16)}
                    />

                    <Break/>

                    <FormHookRadioInput
                      label="Gender / Type of Application:"
                      fieldClassName="d-flex-wrap flex-row"
                      isKeyValue={true}
                      options={[
                        {
                          label: "Male",
                          value: "Male",
                        },
                        {
                          label: "Female",
                          value: "Female",
                        },
                        {
                          label: "Business",
                          value: "Business",
                        },
                      ]}
                      register={register}
                      error={errors?.gender_type_of_business?.message}
                      regFieldName={`gender_type_of_business`}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Particulars of Activity</div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <ParticularsofActivity
                      formState={formState}
                      register={register}
                      shouldRenderInForm={false}
                      isDisabled={true}
                      watch={watch}
                      setValue={setValue}
                    ></ParticularsofActivity>

                    {/* added to the type selection form */}
                    {/* <FormHookTextArea
                      register={register}
                      error={
                        errors?.description_of_activity_tobe_undertaken?.message
                      }
                      label="Description of activity to be undertaken and expected outcome:"
                      regFieldName="description_of_activity_tobe_undertaken"
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.funding_partners?.message}
                      label="Collaborating Institutions (If any):"
                      regFieldName="collaborating_institutions"
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.funding_partners?.message}
                      label="Funding Partners (If any):"
                      regFieldName="funding_partners"
                    /> */}
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <LocationDetails
                    formState={formState}
                    control={control}
                    register={register}
                    shouldRenderInForm={false}
                    isDisabled={true}
                    isAddEnabled={false}
                    isDeleteEnabled={false}
                    typeOfSpecies={typeOfSpecies}
                    zoneBeachAreas={fishingAreaLocations}
                  />
                </div>

               { selectedMap.type_of_authorization === 
                  "Vessel" && <div className="shadow p-3 mb-3 bg-white rounded">
                  {selectedMap.nationality === "Local" && selectedMap.type_of_authorization === 
                  "Vessel" && (
                    <ResponsiveTable
                      headers={[
                        "Vessel Name",
                        "NFA Registration Number",
                        "Name of Captain",
                        "Gear Information",
                      ]}
                    >
                      <tbody>
                        {getValues()?.local_vessel_gear_details?.map(
                          (info, groupIndex) => {
                            return (
                              <tr key={groupIndex}>
                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.local_vessel_gear_details?.[
                                        groupIndex
                                      ]?.vessel_name?.message
                                    }
                                    label="Vessel Name"
                                    placeholder="Vessel Name"
                                    regFieldName={`local_vessel_gear_details[${groupIndex}].vessel_name`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.local_vessel_gear_details?.[
                                        groupIndex
                                      ]?.nfa_vessel_registration_number?.message
                                    }
                                    label="NFA Vessel Registration #"
                                    placeholder="NFA Vessel Registration #"
                                    regFieldName={`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.local_vessel_gear_details?.[
                                        groupIndex
                                      ]?.name_of_captain?.message
                                    }
                                    label="Name of Captain"
                                    placeholder="Name of Captain"
                                    regFieldName={`local_vessel_gear_details[${groupIndex}].name_of_captain`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookCheckbox
                                    label="Gear Information"
                                    register={register}
                                    regFieldName={`local_vessel_gear_details[${groupIndex}].gear_type`}
                                    options={[
                                      "Traps",
                                      "Nets",
                                      "Lines",
                                      "Free Lung",
                                      "Other",
                                    ]}
                                    error={
                                      errors.local_vessel_gear_details?.[
                                        groupIndex
                                      ]?.gear_type?.message
                                    }
                                    isDisabled={true}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </ResponsiveTable>
                  )}

                  {selectedMap.nationality === "Foreign" && selectedMap.type_of_authorization === 
                  "Vessel" && (
                    <ResponsiveTable
                      headers={[
                        "Vessel Name",
                        "NFA Registration Number",
                        "Name of Captain",
                        "Gear Information",
                      ]}
                    >
                      <tbody>
                        {getValues()?.foreign_vessel_gear_details?.map(
                          (info, groupIndex) => {
                            return (
                              <tr key={groupIndex}>
                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.foreign_vessel_gear_details?.[
                                        groupIndex
                                      ]?.vessel_name?.message
                                    }
                                    label="Vessel Name"
                                    placeholder="Vessel Name"
                                    regFieldName={`foreign_vessel_gear_details[${groupIndex}].vessel_name`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.foreign_vessel_gear_details?.[
                                        groupIndex
                                      ]?.foreign_vessel_gear_details?.message
                                    }
                                    label="NFA Vessel Registration #"
                                    placeholder="NFA Vessel Registration #"
                                    regFieldName={`foreign_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookInput
                                    register={register}
                                    error={
                                      errors.foreign_vessel_gear_details?.[
                                        groupIndex
                                      ]?.name_of_captain?.message
                                    }
                                    label="Name of Captain"
                                    placeholder="Name of Captain"
                                    regFieldName={`foreign_vessel_gear_details[${groupIndex}].name_of_captain`}
                                    isDisabled={true}
                                  />
                                </td>

                                <td>
                                  <FormHookCheckbox
                                    label="Gear Information"
                                    register={register}
                                    regFieldName={`foreign_vessel_gear_details[${groupIndex}].gear_type`}
                                    options={[
                                      "Traps",
                                      "Nets",
                                      "Lines",
                                      "Free Lung",
                                      "Other",
                                    ]}
                                    error={
                                      errors.foreign_vessel_gear_details?.[
                                        groupIndex
                                      ]?.gear_type?.message
                                    }
                                    isDisabled={true}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </ResponsiveTable>
                  )}
                </div>}

                <SimpleDeclaration register={register} errors={errors}/>

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>
        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForAuthorization);
