export const selectionNotFound = "You must make a selection before proceeding to the next step";

export const gearSelectionNotFound = "You must select atleast one Gear Information";

export const inputPreviousNumber  = (text) => {
    return `You must input ${text} before proceeding to the next step`;
}

export const inputSelection  = (text) => {
    return `You must select ${text} before proceeding to the next step`;
}

export const formInvalidMessage = "You must fill in all the madatory details before proceeding to the next step";

export const emailNotValid = "Email Not Valid";

export const telephoneNotValid = 'Format should be 123-456-7890';

export const trnNotValid = 'Format should be 123-456-789';