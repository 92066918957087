import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

export const SignaturePad = ({
  id = "",
  label = "",
  setSignatureValue,
  currentValue = "",
  isDisabled = false,
}) => {
  const sigCanvasRef = useRef(null);

  const clearSignature = () => {
    sigCanvasRef.current.clear(); // Clear the signature canvas
    setSignatureValue(id, undefined);
  };

  const loadSignature = () => {
    // Example URL, replace with your own image data URL

    sigCanvasRef.current.fromDataURL(currentValue);
  };

  const [signatureDrawn, setSignatureDrawn] = useState(false);

  // const saveSignature = async () => {
  //     const signatureData = sigCanvasRef.current.toDataURL('image/png');

  //     try {
  //         await axios.post('/saveSignature', { signatureData });
  //         console.log('Signature saved successfully');
  //     } catch (error) {
  //         console.error('Error saving signature:', error);
  //     }
  // };

  useEffect(() => {
    loadSignature();
  }, []);

  const onEndDrawing = () => {
    // Called when the user stops drawing
    if (!sigCanvasRef.current.isEmpty()) {
      setSignatureDrawn(true);
      const base64Image = sigCanvasRef.current.toDataURL("image/png");
      setSignatureValue(id, base64Image);
    } else {
      setSignatureDrawn(false);
      setSignatureValue(id, undefined);
    }
  };

  return (
    <div className={`${isDisabled ? "non-functional-element" : ""}`}>
      <label>{label}</label>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "0px",
          position: "relative",
          maxWidth: 600
        }}>
        <SignatureCanvas
          ref={sigCanvasRef}
          canvasProps={{
            width: 500,
            height: 200,
            onMouseUp: onEndDrawing,
            onTouchEnd: onEndDrawing,
          }}
        />

        <button
          style={{ position: "absolute", right: 0, top: 0 }}
          type="button"
          className="btn btn-sm btn-warning"
          onClick={() => {
            clearSignature();
          }}
        >
          Clear Signature
        </button>
      </div>
    </div>
  );
};
