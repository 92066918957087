import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { useState } from "react";

export const GoogleMapClickable = () => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDXVh0yXWSANaRjCTunS--vHf3mMQWToWU",
      });



  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const markers = [
    { lat: 17.8, lng: -77.30, address: "North Shelf" },
    { lat: 17.9, lng: -77.30, address: "South Shelf" },
    { lat: 18, lng: -77.30, address: "New Bank" },
    { lat: 18.1, lng: -77.30, address: "Blossom Bank" },
    { lat: 18.2, lng: -77.30, address: "Walton Bank" },
  ];

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  return (<>
  <div className="GoogleApp-small">
    {
      isLoaded &&   <GoogleMap
      mapContainerClassName="map-container"
      onLoad={onMapLoad}
      onClick={() => setIsOpen(false)}
    >
      {markers.map(({ address, lat, lng }, ind) => (
        <Marker
          key={ind}
          position={{ lat, lng }}
          onClick={() => {
            
            handleMarkerClick(ind, lat, lng, address);
          }}
        >
          {isOpen && infoWindowData?.id === ind && (
            <InfoWindow
              onCloseClick={() => {
                setIsOpen(false);
              }}
            >
              <span>{infoWindowData.address}</span>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
    }


{markers?.map((trans) => {
                              return (
                                <label className="checkbox-container">
                                  {trans.address}
                                  <input
                                  disabled
                                    type="checkbox"
                                    value={trans}
                                    // checked={
                                    //   selectedMap?.type_of_species &&
                                    //   selectedMap?.type_of_species?.indexOf(
                                    //     trans
                                    //   ) != -1
                                    // }
                                    name="type_of_species"
                                  />
                                  <span className="checkbox-checkmark"></span>
                                </label>
                              );
                            })}
    </div>
   </>
  );
};