import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";

import { InternalNavigator, Declarations } from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FLISContext } from "../../context/FLISContext";
import {
  CONCH,
  SEA_CUCUMBER,
  SPINY_LOBSTER,
  conchDeclaration,
  seaCucumberDeclaration,
  spinyLobsterDeclaration,
  typeOfDeclarations,
  typeofBusiness,
} from "./DeclarationDataSource";
import {
  FormHookCheckbox,
  FormHookFileUpload,
  FormHookInput,
  FormHookRadioInput,
  FormHookTextArea,
} from "../../components/FormHook/ReactFormHookFields";
import { SignaturePad } from "../../components/SignatureCanvas";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import GeneralDeclarations from "../GeneralDeclaration";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import { ROUTES } from "../../config";
import SimpleDeclaration from "../SimpleDeclaration";

export const ApplicationForDeclaration = (props) => {
  const { selectedMap, setSelectedMap } = useContext(FLISContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonSubmitRef = useRef();


  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: selectedMap,
    mode: "all",
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const { errors } = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });

  const onSubmit = async (data) => {
    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };


  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();
    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }


    if (isValid) {
      setSelectedMap(data);
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
  };

  const setSignatureValue = (id, base64) => {
    setValue(id, base64);
  };

  const [selectedForm, setSelectedForm] = useState(1);

  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "CLOSE SEASON SINGLE SPECIES (LOBSTER & CONCH) DECLARATION"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Close Season Single Species Declaration
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookCheckbox
                      label="Declaration for:"
                      register={register}
                      regFieldName={`type_of_declaration`}
                      options={typeOfDeclarations}
                      error={errors.type_of_declaration?.message}
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.declaration_date?.message}
                      label="Declaration Date"
                      regFieldName="declaration_date"
                      placeholder="Business / Individual Name"
                      isRequired="This field is required"
                      type="date"
                    />

                    <FormHookCheckbox
                      label="Type of Business"
                      register={register}
                      regFieldName={`type_of_business`}
                      options={typeofBusiness}
                      error={errors.type_of_business?.message}
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.name_of_business?.message}
                      label="Business / Individual Name"
                      regFieldName="name_of_business"
                      placeholder="Business / Individual Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.trn?.message}
                      label="TRN"
                      regFieldName="trn"
                      placeholder="TRN"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.nfa_registration_number?.message}
                      label="NFA Registration Number"
                      regFieldName="nfa_registration_number"
                      placeholder="NFA Registration Number"
                      isDisabled={true}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact_name?.message}
                      label="Contact"
                      regFieldName="contact_name"
                      placeholder="Contact"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.contact2?.message}
                      label="Contact 2"
                      regFieldName="contact2"
                      placeholder="Contact 2"
                      isRequired={false}
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.declaration_location_address?.message}
                      label="Facility / Declaration Location address:"
                      regFieldName="declaration_location_address"
                      placeholder="Facility / Declaration Location address:"
                      type="textarea"
                      cols={72}
                      rows={1}
                    />
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Declaration Of Product By Customer Ownership
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={errors?.customer_owner_name?.message}
                      label="Customer / Owner Name:"
                      regFieldName="customer_owner_name"
                      placeholder="Customer / Owner Name:"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.co_trn?.message}
                      label="TRN:"
                      regFieldName="co_trn"
                      placeholder="TRN:"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.co_nfa_registration_number?.message}
                      label="NFA Registration Number:"
                      regFieldName="co_nfa_registration_number"
                      placeholder="NFA Registration Number:"
                    />
                  </div>
                </div>

                {selectedMap.type_of_declaration?.indexOf(SPINY_LOBSTER) !==
                  -1 && (
                  <div className="shadow p-3 mb-3 bg-white rounded">
                    <div className="sub-form-header">Lobster Declaration</div>

                    <ResponsiveTable
                      headers={[
                        "",
                        "Description",
                        "Storage Days",
                        "Weight (Kg)",
                      ]}
                    >
                      <tbody>
                        {spinyLobsterDeclaration.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.description}</td>
                              <td>
                                <FormHookRadioInput
                                  hideLabel={true}
                                  isKeyValue={true}
                                  options={[
                                    {
                                      label: "< 21 Days",
                                      value: "< 21 Days",
                                    },
                                    {
                                      label: "> 21 Days",
                                      value: "> 21 Days",
                                    },
                                  ]}
                                  register={register}
                                  error={
                                    errors?.lobster_declaration?.[item.key]
                                      ?.storage_days?.message
                                  }
                                  regFieldName={`lobster_declaration.${item.key}.storage_days`}
                                />
                              </td>

                              <td>
                                <FormHookInput
                                  type="number"
                                  hideLabel={true}
                                  register={register}
                                  error={
                                    errors?.lobster_declaration?.[item.key]
                                      ?.weight_kg?.message
                                  }
                                  regFieldName={`lobster_declaration.${item.key}.weight_kg`}
                                  placeholder="Weight"
                                  validations={{
                                    min: {
                                      value: 0.001,
                                      message: "Weight must be greater than 0",
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ResponsiveTable>
                  </div>
                )}

                {selectedMap.type_of_declaration?.indexOf(CONCH) !== -1 && (
                  <div className="shadow p-3 mb-3 bg-white rounded">
                    <div className="sub-form-header">Conch Declaration</div>

                    <ResponsiveTable
                      headers={[
                        "",
                        "Level Processed",
                        "Storage Days",
                        "Weight (Kg)",
                      ]}
                    >
                      <tbody>
                        {conchDeclaration.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.level_processed}</td>
                              <td>
                                <FormHookRadioInput
                                  hideLabel={true}
                                  isKeyValue={true}
                                  options={[
                                    {
                                      label: "< 21 Days",
                                      value: "< 21 Days",
                                    },
                                    {
                                      label: "> 21 Days",
                                      value: "> 21 Days",
                                    },
                                  ]}
                                  register={register}
                                  error={
                                    errors?.conch_declaration?.[item.key]
                                      ?.storage_days?.message
                                  }
                                  regFieldName={`conch_declaration.${item.key}.storage_days`}
                                />
                              </td>

                              <td>
                                <FormHookInput
                                  type="number"
                                  hideLabel={true}
                                  register={register}
                                  error={
                                    errors?.conch_declaration?.[item.key]
                                      ?.weight_kg?.message
                                  }
                                  regFieldName={`conch_declaration.${item.key}.weight_kg`}
                                  placeholder="Weight"
                                  validations={{
                                    min: {
                                      value: 0.001,
                                      message: "Weight must be greater than 0",
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ResponsiveTable>
                  </div>
                )}

                {selectedMap.type_of_declaration?.indexOf(SEA_CUCUMBER) !==
                  -1 && (
                  <div className="shadow p-3 mb-3 bg-white rounded">
                    <div className="sub-form-header">
                      Sea Cucumber Declaration
                    </div>

                    <ResponsiveTable
                      headers={[
                        "",
                        "Description",
                        "Storage Days",
                        "Weight (Kg)",
                      ]}
                    >
                      <tbody>
                        {seaCucumberDeclaration.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.description}</td>
                              <td>
                                <FormHookRadioInput
                                  hideLabel={true}
                                  isKeyValue={true}
                                  options={[
                                    {
                                      label: "< 21 Days",
                                      value: "< 21 Days",
                                    },
                                    {
                                      label: "> 21 Days",
                                      value: "> 21 Days",
                                    },
                                  ]}
                                  register={register}
                                  error={
                                    errors?.sea_cucumber_declaration?.[item.key]
                                      ?.storage_days?.message
                                  }
                                  regFieldName={`sea_cucumber_declaration.${item.key}.storage_days`}
                                />
                              </td>

                              <td>
                                <FormHookInput
                                  type="number"
                                  hideLabel={true}
                                  register={register}
                                  error={
                                    errors?.sea_cucumber_declaration?.[item.key]
                                      ?.weight_kg?.message
                                  }
                                  regFieldName={`sea_cucumber_declaration.${item.key}.weight_kg`}
                                  placeholder="Weight"
                                  validations={{
                                    min: {
                                      value: 0.001,
                                      message: "Weight must be greater than 0",
                                    },
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ResponsiveTable>
                  </div>
                )}

                <SimpleDeclaration register={register} errors={errors}/>

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUpload
                      error={
                        errors?.applicant_signature
                          ?.message
                      }
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                    ></FormHookFileUpload>

                    <SignaturePad
                      setSignatureValue={setSignatureValue}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}

                {/* <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">Declaration</div>
                  <HorizontalLine></HorizontalLine>

                  <div>
                    I / We declare that the information given is true and
                    correct to the best of my / our knowledge and belief.
                  </div>

                  <div className="error-message">
                    {!watch("applicant_signature") &&
                      !watch("signature_draw_path") && (
                        <span>Atleast one field is required from below</span>
                      )}
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookFileUploadHandler
                      error={errors?.applicant_signature?.message}
                      register={register}
                      watch={watch}
                      label="Upload Applicant's Signature"
                      accept=".jpg, .jpeg, .png"
                      regFieldName="applicant_signature"
                      fieldClassName="field-block-control"
                      isPreviewable={true}
                      isRequired={false}
                      removeFile={(id) => setValue(id, null)}
                      setValue={setValue}
                    ></FormHookFileUploadHandler>

                    <SignaturePad
                      isDisabled={props.isDisabled || props.isPreview}
                      currentValue={watch("signature_draw_path")}
                      setSignatureValue={(id, base64) => setValue(id, base64)}
                      id="signature_draw_path"
                      label="Or Draw Your Signature Below"
                    />
                  </div>
                </div> */}
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>

        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForDeclaration);
