import React, { useContext } from "react";
import DocumentViewer from "../components/DocumentViewer";
import { FLISContext } from "../context/FLISContext";

export const DataProtection = () => {

    const {currentEntity} = useContext(FLISContext);

    return <div style={{paddingTop: '20px', fontSize: '22px', textAlign:'center', fontWeight: 600}}>
        <div >Click <DocumentViewer id={currentEntity?.consent_document_id} text="here" /> to view Data Protection Consent Statement </div>


        <div>Submit any data protection concerns to <a href="mailto:dataprotection@nfa.gov.jm">dataprotection@nfa.gov.jm</a></div>
    </div>
}