import React, { useContext, useEffect, forwardRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { SubFormContainer } from "../SubFormContainer";

import {
  FormHookCheckbox,
  FormHookInput,
  FormHookSelect,
} from "../../components/FormHook/ReactFormHookFields";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { getRequiredTextField } from "../../utils/utils";


export const LocalVesselDetails = forwardRef(
  ({ formState, control, register, onSubmit, watch, setValue, ...props }, ref) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: "local_vessel_gear_details",
    });

    const { errors, isDirty, isValid } = formState;

    const watchFields = watch("local_vessel_gear_details", {deep: true});

    return (
      <div className="form-container">
        <fieldset>
          <form onSubmit={onSubmit}>
            {fields.map((group, groupIndex) => {
              return (
                <div
                  key={group.id}
                  className="shadow p-3 mb-3 bg-white rounded"
                >
                  <div className="d-flex flex-row form-heading">
                    <div
                      style={{ marginRight: "10px" }}
                    >{`${"Local Vessel & Gears Details"} #${
                      groupIndex + 1
                    }`}</div>

                    <div>
                      <button
                        className="btn btn-warning"
                        onClick={() => {
                          remove(groupIndex);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>

                  <div className="d-flex-wrap flex-column">
                    <SubFormContainer
                      title={"Registration of Vessel Details (MAJ)"}
                      style={{
                        border: "1px solid #ccc",
                        paddingBottom: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex-wrap flex-row justify-content-start">
                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.maj_vessel_registration_mark?.message
                          }
                          label="MAJ (Vessel Registration/Mark #)"
                          placeholder="MAJ (Vessel Registration/Mark #)"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`}
                          type="text"
                          isRequired={!watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`)}
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.maj_date_of_issue?.message
                          }
                          label="Date of Issue"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].maj_date_of_issue`}
                          type="date"
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`)}
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`))}
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.maj_date_of_expiration?.message
                          }
                          label="Date of Expiration"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].maj_date_of_expiration`}
                          type="date"
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`)}
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`))}
                        />

                        <FormHookFileUploadHandler
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.maj_registration_certificate?.message
                          }
                          register={register}
                          watch={watch}
                          label="Upload Copy of Registration Certificate"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].maj_registration_certificate`}
                          fieldClassName=""
                          isPreviewable={false}
                          setValue={setValue}
                          removeFile={(id) => setValue(id, null)}
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`)}
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`))}
                        ></FormHookFileUploadHandler>
                      </div>
                    </SubFormContainer>

                    <SubFormContainer
                      title={"Registration of Vessel Details (NFA)"}
                      style={{
                        border: "1px solid #ccc",
                        paddingBottom: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex-wrap flex-row justify-content-start">
                        {/* <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.maj_vessel_registration_mark?.message
                          }
                          label="MAJ (Vessel Registration/Mark #)"
                          placeholder="MAJ (Vessel Registration/Mark #)"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`}
                        /> */}

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.nfa_vessel_registration_number?.message
                          }
                          label="NFA Vessel Registration #"
                          placeholder="NFA Vessel Registration #"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`}
                          isRequired={!watch(`local_vessel_gear_details[${groupIndex}].maj_vessel_registration_mark`)}
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.nfa_date_of_issue?.message
                          }
                          label="Date of Issue"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].nfa_date_of_issue`}
                          type="date"
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`))}
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`)}
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.nfa_date_of_expiration?.message
                          }
                          label="Date of Expiration"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].nfa_date_of_expiration`}
                          type="date"
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`))}
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`)}
                        />

                        <FormHookFileUploadHandler
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.nfa_registration_certificate?.message
                          }
                          register={register}
                          watch={watch}
                          label="Upload Copy of Registration Certificate"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].nfa_registration_certificate`}
                          fieldClassName=""
                          isPreviewable={false}
                          setValue={setValue}
                          removeFile={(id) => setValue(id, null)}
                          isDisabled={!watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`)}
                          isRequired={getRequiredTextField(watch(`local_vessel_gear_details[${groupIndex}].nfa_vessel_registration_number`))}
                        ></FormHookFileUploadHandler>
                      </div>
                    </SubFormContainer>

                    <SubFormContainer
                      title={"Vessel & Gear Information"}
                      style={{
                        border: "1px solid #ccc",
                        paddingBottom: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex-wrap flex-row justify-content-start">
                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.vessel_name?.message
                          }
                          label="Vessel Name"
                          placeholder="Vessel Name"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].vessel_name`}
                        />

                        <FormHookInput
                          register={register}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.name_of_captain?.message
                          }
                          label="Name of Captain"
                          placeholder="Name of Captain"
                          regFieldName={`local_vessel_gear_details[${groupIndex}].name_of_captain`}
                        />

                        <FormHookCheckbox
                          label="Gear Information"
                          register={register}
                          regFieldName={`local_vessel_gear_details[${groupIndex}].gear_type`}
                          options={[
                            "Traps",
                            "Nets",
                            "Lines",
                            "Free Lung",
                            "Other",
                          ]}
                          error={
                            errors.local_vessel_gear_details?.[groupIndex]
                              ?.gear_type?.message
                          }
                        />

                        {(watchFields?.[groupIndex]?.gear_type
                          ? watchFields?.[groupIndex]?.gear_type
                          : []
                        )?.indexOf("Other") >= 0 && (
                          <FormHookInput
                            register={register}
                            error={
                              errors?.local_vessel_gear_details?.[groupIndex]
                                ?.other_types_gear?.message
                            }
                            label="Other"
                            placeholder="Other"
                            regFieldName={`local_vessel_gear_details[${groupIndex}].other_types_gear`}
                          />
                        )}
                      </div>
                    </SubFormContainer>
                  </div>
                </div>
              );
            })}
          </form>
        </fieldset>

        <div
          className="d-flex flex-row-reverse"
          onClick={() => {
            append({ id: Math.random() });
          }}
        >
          <button type="button" className="btn btn-primary">Add Vessel Details</button>
        </div>
      </div>
    );
  }
);
