import React from 'react';
import { useHistory } from 'react-router-dom';
import { getUniqueTransNumber } from '../utils/utils';
import store from '../redux/store';
import { SET_CURRENT_TRANSACTION } from '../redux/actions/current-transaction';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';



function Community(props) {

    const headline = [
        'Best practices to adopt while Fishing',
        'Why you should always have a License?',
        'Fishing without harming environment'
    ]

    const replies = [
        '12 replies',
        '2 replies',
        '6 replies',
    ]

    const activity = [
       'Last activity 3 hours ago',
       'Last activity 12 hours ago',
       'Last activity 1 day ago',
        
    ]

    const history = useHistory();
    return (
       <div>
           
           <div className='custom-banner-bg'>
                <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                    Welcome to NFA community
                </div>

                <h5>Where investors find their answers!</h5>

               
            </div>


            <div style={{padding: '20px 6%'}} className="d-flex ">
                <div>
                <div style={{fontSize: '24px', fontWeight: '600'}}>Latest News</div>
                <div style={{fontSize: '20px'}}>Discover new incentives and new opportunities for your business!</div>
                <div className='mt-4'>
                    <button className='btn btn-custom-warning'>Read all News and Articles</button>
                </div>

                <div className='mt-2 '>Follow our social feeds to be the first to know when something happens</div>

                <div>
                    <img alt='socials' src='./images/socials.png' width={'120px'}></img>
                </div>
                </div>

                <div>
                    <img alt="commmunity" className='ml-4' width={"600px"} src='./images/community.png'></img>
                </div>
            </div>

       </div>
    );
}

export default Community;