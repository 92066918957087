import React, { useContext, useEffect, forwardRef } from "react";
import HorizontalLine from "../../components/HorLine";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";

const inlineFormClassProps = {
  parentClass: "d-flex-wrap flex-row m-2",
  fieldClassName: "form-control form-control-sm max-200",
  // fieldWrapperClassName: "individual-field",
  labelClassName: "horizontal-field-label-min-250",
};

export const SpinyInvestment = forwardRef(
  ({ formState, control, register, onSubmit, setValue, isDisabled= false, ...props }, ref) => {
    const { errors, isDirty, isValid } = formState;

    return (
      <div className="shadow p-3 mb-3 bg-white rounded">
        <div className="sub-form-header">
          Investment and Production Capacity
        </div>
        <HorizontalLine></HorizontalLine>
        <div className="d-flex-wrap flex-row mt-1 ">
          <div className="d-flex-wrap sub-form-container flex-column mr-2 mt-2">
            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.lobster_trap?.message
              }
              label="Lobster Trap"
              regFieldName="investment_production_capacity_spiny.lobster_trap"
              type="number"
              isDisabled={isDisabled}
              fieldClassName="form-control form-control-sm max-100"
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.processing_plant
                  ?.message
              }
              label="Processing Plant"
              regFieldName="investment_production_capacity_spiny.processing_plant"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.post_harvest_facility
                  ?.message
              }
              label="Post Harvest Facility"
              regFieldName="investment_production_capacity_spiny.post_harvest_facility"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.lobster_casitas?.message
              }
              label="Lobster Casitas/Condominiums"
              regFieldName="investment_production_capacity_spiny.lobster_casitas"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.total_investments_jmd
                  ?.message
              }
              label="Total Investments (JMD)"
              regFieldName="investment_production_capacity_spiny.total_investments_jmd"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />

            <HorizontalLine />

            <FormHookInput
              register={register}
              error={
                errors?.investment_production_capacity_spiny?.total_jamaicans_employed
                  ?.message
              }
              label="Total Jamaicans Employed"
              regFieldName="investment_production_capacity_spiny.total_jamaicans_employed"
              type="number"
              isDisabled={isDisabled}
              {...inlineFormClassProps}
            />
          </div>
        </div>
      </div>
    );
  }
);
