import React from "react";
import { FormHookCheckbox, FormHookInput } from "../../components/FormHook/ReactFormHookFields";



export const ProductionUnitInformation = ({source, formState,control, register, onSubmit, setValue,values,watch, disabled, showHeading=true }) => {


    const {errors} = formState;
    const productionInput= watch('production_unit', {deep: true});


    return (
      <div className=" p-3 mb-3 ">
        <div className="d-flex  form-heading">
          {showHeading && (
            <div style={{ marginRight: "10px" }}>{`${"Production Unit"}`}</div>
          )}
        </div>
        <div className="d-flex-wrap justify-content-center align-items-center left-align">
          <table className="flex-grow-1 table table-bordered table-sm">
            <thead>
              <tr>
                <th>Type</th>
                <th>Quantity</th>
                <th>Total Size (Square Metres (m²))</th>
              </tr>
            </thead>
            <tbody>
              {source.map((unit) => {
                return (
                  <tr key={unit}>
                    <td>
                      <FormHookCheckbox
                        hideLabel={true}
                        parentClass=""
                        fieldClassName=""
                        options={[unit]}
                        register={register}
                        regFieldName={`production_unit.${unit}.isChecked`}
                        isDisabled={disabled}
                        isRequired={false}
                      />

                      {/* <label key={unit} className="checkbox-container">
                        {unit}
                        <input
                          disabled={disabled}
                          type="checkbox"
                          {...register(`production_unit.${unit}.isChecked`, {
                            required: false,
                          })}
                        />
                        <span className="checkbox-checkmark"></span>
                      </label> */}
                    </td>
                    <td>
                      <FormHookInput
                        parentClass="individual-field max-300"
                        hideLabel={true}
                        register={register}
                        error={
                          errors?.production_unit?.[unit]?.quantity?.message
                        }
                        type="number"
                        regFieldName={`production_unit.${unit}.quantity`}
                        isDisabled={
                          !productionInput?.[unit]?.isChecked || disabled
                        }
                        isRequired={
                          productionInput?.[unit]?.isChecked
                            ? "This field is required"
                            : false
                        }
                      />
                    </td>

                    <td>
                      <FormHookInput
                        parentClass="individual-field-small max-300"
                        hideLabel={true}
                        register={register}
                        error={
                          errors?.production_unit?.[unit]?.total_size?.message
                        }
                        type="number"
                        regFieldName={`production_unit.${unit}.total_size`}
                        isDisabled={
                          !productionInput?.[unit]?.isChecked || disabled
                        }
                        isRequired={
                          productionInput?.[unit]?.isChecked
                            ? "This field is required"
                            : false
                        }
                      />
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td>
                  <FormHookCheckbox
                    hideLabel={true}
                    parentClass="mt-4"
                    fieldClassName=""
                    options={["Long Line (Metres)"]}
                    register={register}
                    regFieldName={`production_unit.Long Line.isChecked`}
                    isDisabled={disabled}
                    isRequired={false}
                  />
                </td>

                <td>
                  <FormHookInput
                    parentClass="individual-field max-300"
                    label="Length:"
                    register={register}
                    error={errors?.production_unit?.["Long Line"]?.length?.message}
                    type="number"
                    regFieldName={`production_unit.Long Line.length`}
                    isDisabled={!productionInput?.["Long Line"]?.isChecked || disabled}
                    isRequired={
                      productionInput?.["Long Line"]?.isChecked
                        ? "This field is required"
                        : false
                    }
                  />
                </td>


                <td>
                  <FormHookInput
                    parentClass="individual-field max-300"
                    label="# of Culches:"
                    register={register}
                    error={errors?.production_unit?.["Long Line"]?.number_of_culches?.message}
                    type="number"
                    regFieldName={`production_unit.Long Line.number_of_culches`}
                    isDisabled={!productionInput?.["Long Line"]?.isChecked || disabled}
                    isRequired={
                      productionInput?.["Long Line"]?.isChecked
                        ? "This field is required"
                        : false
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
};
