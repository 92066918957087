import React, { useEffect, useState } from 'react';
import { ROUTES } from '../config';
import { getData } from '../services/service-call';
import ApplicationHeading from '../../js//components/ApplicationHeading';

function Profile360(props) {

    const [profileData, setProfileDate] = useState({});

   
    return (
      <div className="text-center">
        <div className='text-bold little-big-text'>Fisher 360 Profile (D4c)</div>

        <div>
        <table className="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Type of Licence</th>
                      <th>Name of Licence Holder</th>
                      <th>License #</th>
                      <th>Licence Conditions</th>
                      <th>Category of Fisher/Vessel</th>
                      <th>Date of Issue</th>
                      <th>Date of Expiration</th>
                      <th>License Status</th>
                    </tr>
                  </thead>
                  <tbody className='little-big-text'>
                    <tr>
                     <td>Fisher</td>
                     <td>John Doe</td>
                     <td>NFA7733</td>
                     <td></td>
                     <td>Industrial</td>
                     <td>1st Jan -23</td>
                     <td>31st Dec- 23</td>
                     <td>Current</td>


                      </tr>

                      <tr>
                        <td>Fisher Vessel</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                      </tr>


                      <tr>
                        <td>Authorization</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td>Spiny Lobster Permit</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                     
                    
                  </tbody>
                </table>
        </div>
      </div>
    );
}

export default Profile360;