import React from "react";
import {
  FormHookCheckbox,
  FormHookInput,
} from "../../components/FormHook/ReactFormHookFields";
import ReactJson from "react-json-view";

export const MarketingStrategy = ({
  source,
  formState,
  control,
  register,
  onSubmit,
  setValue,
  values,
  watch,
  disabled,
  showHeading = true,
}) => {
  const { errors } = formState;
  const marketingStrategy = watch("marketing_strategy", { deep: true });

  return (

        <div className={`p-3 mb-3 ${disabled ? 'non-functional-element' : ''}`}>
          <div className="d-flex  form-heading">
            {showHeading && (
              <div
                style={{ marginRight: "10px" }}
              >{`${"Marketing Strategy"}`}</div>
            )}
          </div>
          {/* <ReactJson src={marketingStrategy}></ReactJson> */}
          <div className="d-flex-wrap justify-content-center align-items-center">
            <table className="flex-grow-1 table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Quantity (kg)</th>
                  <th>% Total of Production</th>
                </tr>
              </thead>
              <tbody>
                {source.map((unit) => {
                  return (
                    <tr key={unit.key}>
                      <td>
                       

                       <div className="d-flex-wrap flex-row">
                       <FormHookCheckbox
                          parentClass=""
                          fieldClassName=""
                          options={[unit.title]}
                          register={register}
                          regFieldName={`marketing_strategy.${unit.key}.isEnabled`}
                          hideLabel={true}
                          error={errors?.marketing_strategy?.[unit.key]?.isEnabled?.message}
                          // isKeyValue={true}
                          isDisabled={disabled}
                          isRequired={false}
                          isMulti={true}
                        />

                        {
                          unit.inputField && <FormHookInput
                          hideLabel={true}
                          register={register}
                          error={
                            errors?.marketing_strategy?.[unit.key]?.other
                              ?.message
                          }
                          regFieldName={`marketing_strategy.${unit.key}.other`}
                          isDisabled={!marketingStrategy?.[unit.key]?.isEnabled}
                          isRequired={
                            marketingStrategy?.[unit.key]?.isEnabled
                              ? "This field is required"
                              : false
                          }
                        />
                        }
                       </div>
                      </td>
                      <td>
                        <FormHookInput
                          hideLabel={true}
                          register={register}
                          error={
                            errors?.marketing_strategy?.[unit.key]?.quantity
                              ?.message
                          }
                          type="number"
                          regFieldName={`marketing_strategy.${unit.key}.quantity`}
                          isDisabled={!marketingStrategy?.[unit.key]?.isEnabled}
                          isRequired={
                            marketingStrategy?.[unit.key]?.isEnabled
                              ? "This field is required"
                              : false
                          }
                        />
                      </td>

                      <td>
                        <FormHookInput
                          hideLabel={true}
                          register={register}
                          error={
                            errors?.marketing_strategy?.[unit.key]
                              ?.percent_total_production?.message
                          }
                          type="number"
                          regFieldName={`marketing_strategy.${unit.key}.percent_total_production`}
                          isDisabled={!marketingStrategy?.[unit.key]?.isEnabled}
                          isRequired={
                            marketingStrategy?.[unit.key]?.isEnabled
                              ? "This field is required"
                              : false
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
  );
};
