import React, { forwardRef } from "react";
import { useFieldArray } from "react-hook-form";
import { FormHookInput } from "../../components/FormHook/ReactFormHookFields";

export const ConchHistoricalIndustryPerformance = ({formState,control, register, onSubmit,shouldRenderInForm=true, isDisabled=false, isAddEnabled=true, isDeleteEnabled=true , toShowHeading = true}) => {


    const {errors, isDirty, isValid} = formState;

    const { fields, append, remove } = useFieldArray({
      control,
      name: "historical_industry_performance_conch",
    });

    return (
      <div>
        <table className="table table-bordered">
          {toShowHeading && (
            <tr>
              <td style={{ textAlign: "center", fontSize: "20px" }} colSpan={6}>
                <b>Historical Industry Performance</b>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={3}>
              <FormHookInput
                register={register}
                error={errors?.year_applicant_in_industry_conch?.message}
                label="Number of Years in Industry"
                regFieldName={`year_applicant_in_industry_conch`}
                placeholder="Number of Years in Industry"
                type="number"
                isDisabled={isDisabled}
              />
            </td>

            <td colSpan={3}>
              <FormHookInput
                register={register}
                error={errors?.number_of_years_allocated_quota_conch?.message}
                label="Number of Years Allocated Quota"
                regFieldName={`number_of_years_allocated_quota_conch`}
                placeholder="Number of Years Allocated Quota:"
                type="number"
                validations={{
                  maxLength: {
                    value: 4,
                    message: "Max length is 4",
                  },
                }}
                isDisabled={isDisabled}
              />
            </td>
          </tr>

          <tr style={{ fontWeight: 600 }}>
            <td>No.</td>
            <td style={{ maxWidth: "250px" }}>Conch Fishing Season</td>
            <td style={{ maxWidth: "250px" }}>Conch Quota Allocated</td>
            <td style={{ maxWidth: "250px" }}>
              Total 50% Conch Landed or Caused to be Landed(Kg)
            </td>
            <td style={{ maxWidth: "250px" }}>
              Total 50% Clean Conch exported or Sold Locally(Kg)
            </td>
            <td></td>
          </tr>

          {fields?.map((group, groupIndex) => {
            return (
              <tr key={group.id}>
                <td>{groupIndex + 1}</td>
                <td style={{ maxWidth: "250px" }}>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_conch?.[
                        groupIndex
                      ]?.conch_fishing_season?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_conch[${groupIndex}].conch_fishing_season`}
                    placeholder="2000-2001"
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    validations={{
                      maxLength: {
                        value: 20,
                        message: "Max length is 20",
                      },
                    }}
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_conch?.[
                        groupIndex
                      ]?.conch_quota_allocated?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_conch[${groupIndex}].conch_quota_allocated`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_conch?.[
                        groupIndex
                      ]?.conch_to_be_landed?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_conch[${groupIndex}].conch_to_be_landed`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_conch?.[
                        groupIndex
                      ]?.conch_sold_locally?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_conch[${groupIndex}].conch_sold_locally`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    type="number"
                  />
                </td>

                <td>
                  {!isDisabled && (
                    <button
                      type="button"
                      onClick={() => {
                        remove(groupIndex);
                      }}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </table>

        <div className="d-flex flex-row justify-content-between align-items-center">
          <FormHookInput
            register={register}
            error={errors?.total_investment_conch?.message}
            label="Total Investment:"
            regFieldName={`total_investment_conch`}
            placeholder="Total Investment"
            type="number"
            isDisabled={isDisabled}
          />

          {!isDisabled && (
            <button
              type="button"
              onClick={() => {
                append({ id: Math.random() });
              }}
              className="btn btn-primary"
            >
              Add Historical Performance
            </button>
          )}
        </div>
      </div>
    );

};