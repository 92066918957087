import React, { useEffect, useRef, useState } from "react";
import { postData, postDataWithoutLoader } from "../services/service-call";
import { ROUTES } from "../config";
import { alertService } from "../_services";

const DocumentViewer = ({type="link", id, toPreview = false, style={}, toDownload = true, text="View File", w=100, h=100}) => {

    
    // const [href, setHref] = useState('');

    // const downloadLinkRef = useRef();

    const [img, setImg] = useState();


    useEffect(() => {
      if (type === "image" && toPreview) {
        if (!id) {
          return;
        }

        (async () => {
          const result = await postDataWithoutLoader({
            url: `${ROUTES.downloadDocumentByID}/${id}`,
            body: {},
          });

          if (result.statusCode === 200) {
            const data = result.data;
            var FileContent =
              "data:" + data.ContentType + ";base64," + data.base64;
              setImg(FileContent);
          }
        })();
      }
    }, [id, toPreview, type]);

    if(type === "link") {
        return (
            <span
            style={style}
              onClick={async () => {
                  if (!id) {
                    alertService.warn("Document Not Found");
                    return;
                  }
                const result = await postData({
                  url: `${ROUTES.downloadDocumentByID}/${id}`,
                  body: {},
                });
                if(result.statusCode !== 200){
                  alertService.error(result.data.msg);
                    return;
                }
                const data = result.data;
                var FileContent =
                  "data:" + data.ContentType + ";base64," + data.base64;
      
                //   setHref(result.data.base64);
                //   downloadLinkRef.current.click();
      
                // Create a temporary link element and trigger the download
                const link = document.createElement("a");
                link.href = FileContent;
                link.download = data.downloadFileName;
                document.body.appendChild(link);
                link.click();
                //TODO: Clean up by revoking the Object URL and removing the temporary link first have to check how to do blob first - https://chat.openai.com/c/2156f7a5-c2b5-4958-8072-61f8bdf6475e
                // URL.revokeObjectURL(blobUrl);
                document.body.removeChild(link);
              }}>
      
              {/* <a
                ref={downloadLinkRef}
                href={href}
                id="documentDownload"
                style={{ visibility: "hidden" }}
                download={toDownload}
              /> */}
      
              <span className="mocked-link">{text}</span>
            </span>
          );
    } else if (type === 'image' && toPreview){
        return <div style={style}>
            <img width={w} height={h} src={img} alt=""/>
        </div>
    }
    

    
}

export default DocumentViewer;