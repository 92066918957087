import React, { useRef, useState } from "react";
import { crossIcon, uploadIcon } from "../../../assets/icons/icons_svg";

import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from a suitable icon library

const requiredMsg = "This field is required";

export const handleKeyDown = (event) => {
  event.preventDefault(); // Prevent default keyboard behavior
};

export const FormHookInput = ({
  parentClass = "individual-field",
  label = "",
  type = "text",
  fieldClassName = "form-control",
  register,
  isRequired = "This field is required",
  regFieldName = "",
  placeholder = "",
  hideLabel = false,
  error = "",
  validations = {},
  isDisabled = false,
  fieldWrapperClassName = "",
  labelClassName = "",
  toAddOptionalText=true,
  maxDate=null,
  minDate=null,
  onChange=() => {}
}) => {


  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  if(!isRequired && toAddOptionalText){
    if(label?.indexOf("(Optional)") === -1){
      label = `${label} (Optional)`
    }
  };
  
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label className={labelClassName}>{label}</label>}

      <div className={`${fieldWrapperClassName} ${type ===  'password' ? 'password-toggle-parent' : ''}`}>
        <input
          type={type === 'password' ? inputType : type}
          className={fieldClassName}
          {...register(regFieldName, {
            required: isRequired,
            ...validations,
            onChange: onChange,
          })}
          max={maxDate}
          min={minDate}
          placeholder={placeholder}
        />

        {type === "password" && (
          <span className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash size={'20'} /> : <FaEye  size={'20'} />}
          </span>
        )}

       
      </div>
      <span className="error-message">{error}</span>
    </div>
  );
};

export const ReactInput = ({
  parentClass = "individual-field",
  label = "",
  type = "text",
  fieldClassName = "form-control",
  isRequired = "This field is required",
  regFieldName = "",
  placeholder = "",
  hideLabel = false,
  error = "",
  validations = {},
  isDisabled = false,
  fieldWrapperClassName = "",
  labelClassName = "",
  toAddOptionalText=true,
  onChange=(e) => {},
  // value
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [innerValue, setInnerValue] = useState('');

  const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  if(!isRequired && toAddOptionalText){
    if(label?.indexOf("(Optional)") === -1){
      label = `${label} (Optional)`
    }
  };
  
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label className={labelClassName}>{label}</label>}

      <div className={`${fieldWrapperClassName} ${type ===  'password' ? 'password-toggle-parent' : ''}`}>
        <input
          value={innerValue}
          type={type === 'password' ? inputType : type}
          className={fieldClassName}
          placeholder={placeholder}
          onChange={(e) => {
            setInnerValue(e.target.value);
            onChange(e);
          }}
        />

        {type === "password" && (
          <span className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash size={'20'} /> : <FaEye  size={'20'} />}
          </span>
        )}

       
      </div>
      <span className="error-message">{error}</span>
    </div>
  );
};

export const ReactSelect = ({
  parentClass = "individual-field max-250",
  label = "",
  fieldClassName = "form-control ",
  isRequired = requiredMsg,
  options = [],
  isKeyValue = false,
  error = "",
  isDisabled = false,
  hideLabel=false,
  onChange=() => {},
  value=""
}) => {
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label>{label}</label>}
      <select
        value={value}
        disabled={isDisabled}
        className={fieldClassName}
        onChange={(e) => {
          onChange(e);
        }}
      >
        <option value="">---Select---</option>

        {options?.map((item) => {
          return (
            <option
              key={isKeyValue ? item.value : item}
              value={isKeyValue ? item.value : item}
            >
              {isKeyValue ? item.label : item}
            </option>
          );
        })}
      </select>
      <span className="error-message">{error}</span>
    </div>
  );
};

export const FormHookTextArea = ({
  parentClass = "individual-field-text-area",
  label = "",
  fieldClassName = "form-control",
  register,
  isRequired = "This field is required",
  regFieldName = "",
  placeholder = "",
  rows = undefined,
  cols = undefined,
  error = "",
  isDisabled = false,
  validations = {},
  hideLabel=false,
  style={}
}) => {
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      style={style}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {
        !hideLabel && <label>{label}</label>
      }

      <textarea
        disabled={isDisabled}
        rows={rows}
        cols={cols}
        className={fieldClassName}
        {...register(regFieldName, {
          required: isRequired,
          ...validations,
        })}
        placeholder={placeholder}
      />
      <span className="error-message">{error}</span>
    </div>
  );
};

export const FormHookRadioInput = ({
  label = "",
  hideLabel = false,
  options = [],
  parentClass = "individual-field",
  fieldClassName = "d-flex-wrap flex-column",
  register,
  isRequired = "This field is required",
  regFieldName = "",
  placementStyle = "inline",
  error = "",
  isDisabled = false,
  isKeyValue = false,
  setValue = undefined
}) => {

  const [selectedValue, setSelectedValue] = useState(null);
  const handleRadioChange = (event) => {
    if (!setValue) return;

    const value = event.target.value;
    if (selectedValue === value) {
      setSelectedValue(null);
      setValue(regFieldName, null);
    } else {
      setSelectedValue(value);
    }
  };


  
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      <div> {!hideLabel && <label>{label}</label>}</div>

      <div className={fieldClassName}>
        {options.map((item) => (
          <label key={item.value} className="radio-container">
            {isKeyValue ? item.label : item}
            <input
              disabled={isDisabled}
              type="radio"
              value={isKeyValue ? item.value : item}
              {...register(regFieldName, {
                required: isRequired,
              })}
              onClick={handleRadioChange}
            />
            <span className="checkmark"></span>
          </label>
        ))}
      </div>

      <span className="error-message">{error}</span>
    </div>
  );
};

export const FormHookSelect = ({
  parentClass = "individual-field max-250",
  label = "",
  fieldClassName = "form-control ",
  register,
  isRequired = requiredMsg,
  regFieldName = "",
  options = [],
  isKeyValue = false,
  error = "",
  isDisabled = false,
  hideLabel=false,
  isMultiple=false
}) => {
  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label>{label}</label>}
      <select
        multiple={isMultiple}
        disabled={isDisabled}
        className={fieldClassName}
        {...register(regFieldName, {
          required: isRequired,
        })}>
        {
          !isMultiple && <option value="">---Select---</option>
        }

        {options?.map((item) => {
          return (
            <option
              key={isKeyValue ? item.value : item}
              value={isKeyValue ? item.value : item}
            >
              {isKeyValue ? item.label : item}
            </option>
          );
        })}
      </select>
      <span className="error-message">{error}</span>
    </div>
  );
};

export const FormHookFileUpload = ({
  parentClass = "individual-field",
  label = "",
  fieldClassName = "",
  register,
  isRequired = "This field is required",
  regFieldName = "",
  placeholder = "",
  hideLabel = false,
  labelClass = "",
  error = "",
  isPreviewable = false,
  watch,
  removeFile,
  accept,
  docID,
  isDisabled = false,
}) => {
  // this works like onchange for react form hook
  const selectedFile = watch(regFieldName);

  const [documentID, setDocumentID] = useState(docID);

  return (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label>{label}</label>}

      {isPreviewable && selectedFile && (
        <div className="file-preview">
          {selectedFile?.[0]?.type?.startsWith("image/") && (
            <img
              className="image-preview-form"
              src={URL.createObjectURL(selectedFile[0])}
              alt="File Preview"
            />
          )}
          {selectedFile?.[0]?.type?.startsWith("video/") && (
            <video controls>
              <source
                src={URL.createObjectURL(selectedFile[0])}
                type={selectedFile.type}
              />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
      <div style={{ position: "relative" }}>
        <input
          type="file"
          className={fieldClassName}
          {...register(regFieldName, {
            required: isRequired,
          })}
          accept={accept}
          placeholder={placeholder}
        />

        <span
          onClick={() => removeFile(regFieldName)}
          style={{
            position: "absolute",
            right: 0,
            top: "4px",
            cursor: "pointer",
          }}
        >
          {crossIcon(32, 32)}
        </span>
      </div>
      <div>
        <span className="error-message">{error}</span>
      </div>
    </div>
  );
};

export const FormHookCheckbox = ({
  parentClass = "individual-field",
  label = "",
  fieldClassName = "d-flex-wrap flex-row field-control",
  register,
  isRequired = requiredMsg,
  regFieldName = "",
  hideLabel = false,
  error = "",
  isDisabled = false,
  isKeyValue = false,
  options = [],
  toOpenOtherField = false,
  otherFieldRegName = "",
  otherFieldErr = "",
  isMulti=true,
  fullWidth=false
}) => {
  parentClass += ' max-1000';
  if(fullWidth) {
    parentClass += ' full-width';
  }
  if (!options?.length) return null;

  const otherField = toOpenOtherField && (
    <FormHookInput
      register={register}
      error={otherFieldErr}
      label={label}
      placeholder={label}
      regFieldName={otherFieldRegName}
      type="text"
    />
  );

  const selectField = (
    <div
      className={`${parentClass} ${isDisabled ? "non-functional-element" : ""}`}
      onKeyDown={isDisabled ? handleKeyDown : () => {}}
    >
      {!hideLabel && <label>{label}</label>}

      <div className={fieldClassName}>
        {options?.map((item) => {
          return (
            <label key={item} className="checkbox-container">
              {isKeyValue ? item.label : item}
              <input
                // disabled={isDisabled}
                type={isMulti ? "checkbox" : "radio"}
                {...register(regFieldName, {
                  required: isRequired,
                })}
                value={isKeyValue ? item.value : item}
              />
              <span className="checkbox-checkmark"></span>
            </label>
          );
        })}
      </div>
      <div>
        {" "}
        <span className="error-message">{error}</span>
      </div>
    </div>
  );

  if (!toOpenOtherField) return selectField;
  else {
    return <>
      {selectField}
      {otherField}
    </>
  }
};
