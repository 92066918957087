import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { getUniqueTransNumber } from "../utils/utils";
import store from "../redux/store";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";
import ViewFisheriesAct from "./ViewFisheriesAct";


function ApplicationDetails(props) {
  const history = useHistory();
  const { clearSelectedMap } = useContext(FLISContext);
  return (
    <div>
      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }}>
          Application for a Fisher Licence
        </div>

        {/* <h5>Ensure your business licenses are approved and valid</h5> */}

        <div className="d-flex justify-content-start align-items-start flex-spacing-10">
          <button
            type="button"
            className="btn btn-custom-warning large-button"
            onClick={async () => {
              const details = props.app ?? {
                appType: "APPLICATION_FOR_LICENCE",
              };

              const appDetails = APP_SUBMISSION_CONFIG[details.appType];
              let transNumber = "";
              if (appDetails.isLoginRequired) {
                if (!localStorage.getItem("token")) {
                  history.push("/login");
                  return;
                }

                transNumber = await getUniqueTransNumber(details.appType);
              } else {
                transNumber = await getUniqueTransNumber(details.appType);
              }

              store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: details.appType,
                transaction: {},
                submitted_transaction: null,
              });

              clearSelectedMap();
              if (!!details) history.push(`${appDetails.steps[0].currentStep}`);
            }}
          >
            Apply now for a Fisher Licence
          </button>


         <ViewFisheriesAct/>
        </div>

      </div>

      <div>
        <div
          className="d-flex-wrap justify-content-between flex-spacing-10"
          style={{
            padding: "40px 14%",
            color: "black",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          <div>
            <h2 style={{ fontWeight: 800 }}>Apply for a Fisher Licence</h2>
            <div>
              To fish in Jamaica, you must have a licence and you must always
              carry your licence with you while fishing in Jamaican waters. The
              only exception by law is if you are fishing with a single rod from
              a seashore or river bank, or if you are fishing from a pond that
              is entirely on a private property.
            </div>

            <br />

            <div>
              To apply for a general fishing licence, you must have at least the
              following:
            </div>
            <br />
            <ul>
              <li>Valid Picture Identification</li>
              <li>Passport Sized Picture</li>
              <li>Application Fee</li>
            </ul>
          </div>

          <div>
            <button
              type="button"
              className="btn btn-custom-warning large-button"
              onClick={async () => {
                const details = props.app ?? {
                  appType: "APPLICATION_FOR_LICENCE",
                };

                const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                let transNumber = "";
                if (appDetails.isLoginRequired) {
                  if (!localStorage.getItem("token")) {
                    history.push("/login");
                    return;
                  }

                  transNumber = await getUniqueTransNumber(details.appType);
                } else {
                  transNumber = await getUniqueTransNumber(details.appType);
                }

                store.dispatch({
                  type: SET_CURRENT_TRANSACTION,
                  temp_trans_id: transNumber,
                  appType: details.appType,
                  transaction: {},
                  submitted_transaction: null,
                });

                clearSelectedMap();
                if (!!details)
                  history.push(`${appDetails.steps[0].currentStep}`);
              }}
            >
              Apply now for a Fisher Licence
            </button>
          </div>

        
        </div>
      </div>
    </div>
  );
}

export default ApplicationDetails;
