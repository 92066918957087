import {SET_TRANSACTIONS, } from '../actions/transactions';

const initialState = {
    transactions: []
}

export default function transaction(state = initialState, action){
    
    switch(action.type){
        
        case SET_TRANSACTIONS:
            return{
                ...state,
                transactions : action.transactions
            }
        default:
            return state;
    }
}