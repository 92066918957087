import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { APP_SUBMISSION_CONFIG } from "../config/application-submission-config";
import { getUniqueTransNumber } from "../utils/utils";
import store from "../redux/store";
import { SET_CURRENT_TRANSACTION } from "../redux/actions/current-transaction";
import { FLISContext } from "../context/FLISContext";
import ViewFisheriesAct from "./ViewFisheriesAct";
function AquacultureFishingVesselDetails(props) {
  const history = useHistory();

  const { clearSelectedMap } = useContext(FLISContext);

  return (
    <div>
      <div className="custom-banner-bg">
        <div style={{ fontSize: "40px", fontWeight: "bold" }}>
          Application for Aquaculture Fishing Vessel Licence
        </div>


        <div className='d-flex flex-spacing-10'>
                    <button type="button" className="btn btn-custom-warning large-button" onClick={async () => {
                        const details = { appType: 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL' };

                        const appDetails = APP_SUBMISSION_CONFIG[details.appType];
                        let transNumber = '';
                        if (appDetails.isLoginRequired) {
                            if (!localStorage.getItem('token')) {
                                history.push('/login');
                                return;
                            }
                            transNumber = await getUniqueTransNumber(details.appType);
                        } else {
                            transNumber = await getUniqueTransNumber(details.appType);
                        }

                        store.dispatch({
                            type: SET_CURRENT_TRANSACTION,
                            temp_trans_id: transNumber,
                            appType: details.appType,
                            transaction: {},
                            submitted_transaction: null
                        });

                        clearSelectedMap();

                        if (details)
                            history.push(`${appDetails.steps[0].currentStep}`);
                    }}>
                        Apply now for Aquaculture Fishing Vessel Licence
                    </button>


                    <ViewFisheriesAct/>

                </div>
      </div>

      <div
        style={{
          padding: "40px 14%",
          color: "black",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <h5>
          The use of a fishing vessel for the purpose of any type of aquaculture
          activity requires that the vessel be licensed. This includes using a
          vessel for mariculture activities such a oyster farming.
        </h5>

        <br />

        <div className="d-flex flex-spacing-10">
          <button
            type="button"
            className="btn btn-custom-warning large-button"
            onClick={async () => {
              const details = {
                appType: "APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL",
              };

              const appDetails = APP_SUBMISSION_CONFIG[details.appType];
              let transNumber = "";
              if (appDetails.isLoginRequired) {
                if (!localStorage.getItem("token")) {
                  history.push("/login");
                  return;
                }
                transNumber = await getUniqueTransNumber(details.appType);
              } else {
                transNumber = await getUniqueTransNumber(details.appType);
              }

              store.dispatch({
                type: SET_CURRENT_TRANSACTION,
                temp_trans_id: transNumber,
                appType: details.appType,
                transaction: {},
                submitted_transaction: null,
              });

              clearSelectedMap();

              if (details) history.push(`${appDetails.steps[0].currentStep}`);
            }}
          >
            Apply now for Aquaculture Fishing Vessel Licence
          </button>
        </div>
      </div>
    </div>
  );
}

export default AquacultureFishingVesselDetails;
