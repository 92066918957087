import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../../assets/styles/form.css";
import HorizontalLine from "../../components/HorLine";
import SimpleNavigator from "../../components/SimpleNavigator";
import { postData } from "../../services/service-call";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { alertService } from "../../_services";
import {
  InternalNavigator,
  Declarations,
  Break
} from "../../components/DataSource";
import ImageUpload from "../../components/ImageUpload";
import { FLISContext } from "../../context/FLISContext";
import { FormHookCheckbox, FormHookFileUpload, FormHookInput, FormHookRadioInput, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { SignaturePad } from "../../components/SignatureCanvas";
import { ResponsiveTable } from "../../components/ResponsiveTable";
import { FormHookFileUploadHandler } from "../../components/FormHook/FormHookDocument";
import { landingLogsFor, storageType } from "./LandingLogsDataSource";
import { LandingLogsAudit } from "./LandingLogsAudit";
import { fillInAllTheFields, getPreviewHeading, saveAndContinueLaterText, scrollToTop } from "../../utils/utils";
import GeneralDeclarations from "../GeneralDeclaration";
import { ROUTES } from "../../config";

export const ApplicationForLandingLogs = (props) => {

  const {selectedMap, setSelectedMap} = useContext(FLISContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonSubmitRef = useRef();
  

  const transactionDetails = useSelector((state) => {
    return state.currentTransaction;
  });

  useEffect(() => {
    if (transactionDetails.temp_trans_id) {
      // dispatch(getCurrentTransaction(transactionDetails.temp_trans_id));
    }
  }, [transactionDetails.temp_trans_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    getValues,
    trigger,
    reset,
    control,
  } = useForm({
    defaultValues: selectedMap,
    mode: 'all'
  });

  React.useImperativeHandle(props.reference, () => {
    return {
      ...(props?.reference?.current || {}),
      trigger: trigger,
    };
  });

  const {errors} = formState;

  let appType = useSelector((state) => {
    return state?.currentTransaction?.appType;
  });


  const onSubmit = async (data) => {
    if (selectedForm === 1) {
      scrollToTop();
      setSelectedForm(2);
      return;
    }

    data.temp_trans_id = props.temp_trans_id;
    history.push(props.next);
  };

  const nextAction = async () => {
    const isValid = await trigger();
    const data = getValues();
    
    if(selectedForm === 2 && !data?.applicant_signature  && !data?.signature_draw_path){
      alertService.warn('Fill all the mandatory fields');
      return; 
    }



    if(isValid){
      setSelectedMap(data)
    } else {
      alertService.warn(fillInAllTheFields);
    }
    buttonSubmitRef.current.click();
  };

  const previousAction = () => {
    const data = getValues();
    setSelectedMap(data)
    if (selectedForm === 1) {
      history.push(props.previous);
      return;
    } else if (selectedForm === 2) {
      setSelectedForm(1);
    }
    scrollToTop();
  };

  const saveAndContinueLater = async () => {
    const currentData = getValues();
    currentData.temp_trans_id = props.temp_trans_id;
    
    const result = await postData({
      url: `${ROUTES.saveApplication}?app_type=${appType}`,
      body: {
        ...selectedMap,
        temp_trans_id: props.temp_trans_id,
        app_type: appType,
        launchCase: false,
      },
    });

    if (result.statusCode === 200) {
      alertService.success("Application saved successfully", {
        autoClose: true,
        keepAfterRouteChange: true,
      });

      history.replace("/application-types");
    } else {
      alertService.error("Error occured while saving application", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    }
  };

  const saveYourStore = () => {
    const data = getValues();
    data.temp_trans_id = props.temp_trans_id;
  };

  const setSignatureValue = (id, base64) => {
    setValue(id, base64);
  }

  const [selectedForm , setSelectedForm] = useState(1);

  useEffect(() => {
    trigger();
  }, []);

  const storageTypeWatch = watch('storage_type');
  return (
    <div className="container-fluid " ref={componentRef}>
      <div className="head-text">
        {props.isPreview
          ? getPreviewHeading(appType, props.isFormPDF)
          : selectedForm === 1
          ? "Landing Catch Declaration Sheet"
          : "Declaration of Knowledge - Form"}
      </div>

      <div className="form-container">
        <fieldset disabled={props.isDisabled || props.isPreview}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            // onChange={() => saveYourStore()}
            ref={props.reference}
          >
            {selectedForm === 1 && (
              <>
                <div className="shadow p-3 mb-3 bg-white rounded">
                  <div className="sub-form-header">
                    Landing Catch Declaration
                  </div>
                  <HorizontalLine></HorizontalLine>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookCheckbox
                      label="Landing Catch for"
                      options={landingLogsFor}
                      register={register}
                      error={errors?.landing_logs_for?.message}
                      regFieldName={`landing_logs_for`}
                      isMulti={false}
                      fullWidth={true}
                    />

                    <FormHookTextArea
                      register={register}
                      error={errors?.landing_site?.message}
                      label="Landing Site:"
                      regFieldName="landing_site"
                      placeholder="Landing Site"
                      type="textarea"
                      cols={72}
                      rows={1}
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.vessel_name?.message}
                      label="Vessel Name:"
                      regFieldName="vessel_name"
                      placeholder="Vessel Name"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.vessel_registration_number?.message}
                      label="Vessel Registration Number:"
                      regFieldName="vessel_registration_number"
                      placeholder="Vessel Registration Number"
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.depart_time_date?.message}
                      label="Time/Date Depart:"
                      regFieldName="depart_time_date"
                      type="datetime-local"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.return_time_date?.message}
                      label="Time/Date Return:"
                      regFieldName="return_time_date"
                      type="datetime-local"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.captain_name?.message}
                      label="Captain Name:"
                      regFieldName="captain_name"
                      placeholder="Captain Name:"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.licence_number?.message}
                      label="Licence #:"
                      regFieldName="licence_number"
                      placeholder="Licence #:"
                    />

                    <Break />

                    <FormHookInput
                      register={register}
                      error={errors?.gear_type?.message}
                      label="Gear Type:"
                      regFieldName="gear_type"
                      placeholder="Gear Type:"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.total_gears?.message}
                      label="Total Number of Gear"
                      regFieldName="total_gears"
                      placeholder="Total Number of Gear"
                      type="number"
                    />

                    <FormHookInput
                      register={register}
                      error={errors?.total_crew_size?.message}
                      label="Total Crew Size"
                      regFieldName="total_crew_size"
                      placeholder="Total Crew Size"
                      type="number"
                    />

                    <Break />

                    <FormHookCheckbox
                      label="Storage"
                      options={storageType}
                      register={register}
                      error={errors?.storage_type?.message}
                      regFieldName={`storage_type`}
                      parentClass="individual-field min-520"
                    />

                    {storageTypeWatch &&
                      storageTypeWatch?.indexOf("Other") >= 0 && (
                        <FormHookInput
                          register={register}
                          error={errors?.storage_other?.message}
                          label="Other"
                          regFieldName="storage_other"
                        />
                      )}
                  </div>
                </div>

                <div className="shadow p-3 mb-3 bg-white-rounded">
                  <LandingLogsAudit
                    formState={formState}
                    register={register}
                    control={control}
                    onSubmit={onSubmit}
                    setValue={setValue}
                    reset={reset}
                    values={getValues}
                    watch={watch}
                    showHeading={false}
                  />
                </div>

                <div className="shadow p-3 mb-3 bg-white-rounded">
                  <FormHookTextArea
                    register={register}
                    error={errors?.comments?.message}
                    label="Comments"
                    regFieldName="comments"
                    placeholder="Comments"
                    type="textarea"
                    isRequired={false}
                  />
                </div>
              </>
            )}

            {(selectedForm === 2 || props.isPreview) && (
              <GeneralDeclarations
                register={register}
                errors={errors}
                watch={watch}
                isDisabled={props.isDisabled || props.isPreview}
                setValue={setValue}
              ></GeneralDeclarations>
            )}

            <div className="d-flex flex-row-reverse">
              <div className="ml-2">
                <button
                  hidden={true}
                  ref={buttonSubmitRef}
                  className="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </fieldset>

        <div hidden={props.isDisabled || props.isPreview}>
          <SimpleNavigator
            next
            middle
            middleValue={saveAndContinueLaterText}
            middleAction={saveAndContinueLater}
            nextAction={nextAction}
            previousAction={previousAction}
            previous
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    temp_trans_id: state.currentTransaction.temp_trans_id,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationForLandingLogs);
