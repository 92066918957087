import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactions } from "../redux/actions/transactions";
import { desc, shortDesc, statusShortMap } from "../config/application-submission-config";
import { dateTimeOptions, getApplicationSpecificDetails } from "../utils/utils";
import AlertDialogSlide from "../components/Modal/Modal";
import { getData, postData } from "../services/service-call";
import { ROUTES } from "../config";
import { alertService } from "../_services";
import { FLISContext } from "../context/FLISContext";



const TransactionAuditLog = ({transaction}) => {

  const [audits, setAudits] = useState();

  useEffect(() => {
    
    (async () => {
      const result = await getData({
        url: `${ROUTES.getTransactionAudit}${transaction?.id}`,
      });

      setAudits(result?.data);

    })();

  }, [transaction])

  return (<>

<div className="table-responsive">
      <table className="table table-sm table-bordered">
        <thead className="custom-table-header">
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Comments</th>
            <th>Date of Inspection</th>
            <th>Document</th>
          </tr>
        </thead>

        <tbody className="table-striped">
          {audits?.map((trans) => {
            return (
              <tr key={trans.id}>
                <td>{new Date(trans.created_at).toLocaleString('en-US', dateTimeOptions)}</td>
                <td>{trans.status}</td>
                <td>  
                  {trans.comments}
                </td>
                <td>{trans.inspection_date ? new Date(trans.inspection_date).toISOString().substring(0,10) : null}</td>
                <td>{}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

  </>);
}




const AllTransactions = () => {
  const dispatch = useDispatch();

  const [dialog, showDialog] = useState(false);

  const { currentEntity } = useContext(FLISContext);


  const [currentTransaction, setCurrentTransaction] = useState({});

  const transactions = useSelector((state) => {
    return state.transactions.transactions?.filter(trans => trans.status !== 'Draft') || [];
  });
  const linkRef = useRef();
  const [downloadHref, setDownloadHref] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');


  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  const manageDocumentInClient = (result) => {
    console.log(result);
    let fileContent = "data:" + result.data.contentType + ';base64,' + result.data.base64;
    if (result.statusCode === 200) {
      setDownloadHref(fileContent);
      setDownloadFileName(result.data.downloadFileName);

      setTimeout(() => {
        linkRef.current.click();
        alertService.success('File downloaded successfully', { autoClose: true, keepAfterRouteChange: false });
      }, 2000);

    } else {
      alertService.error(result.data.msg, { autoClose: true, keepAfterRouteChange: false });
    }
  }

  return (
    <>
      <a
        ref={linkRef}
        download
        href={downloadHref}
        download={downloadFileName}
      ></a>
      {/* <AlertDialogSlide
        fullWidth={true}
        open={dialog}
        title={`Transaction ${currentTransaction?.id} Details`}
        handleClose={async () => {
          showDialog(false);
        }}
        handleCloseSecondary={() => {
          showDialog(false);
        }}
        // primaryActionName={"Submit"}
        secondaryActionName="Close"
        content={<TransactionAuditLog transaction={currentTransaction} />}
      ></AlertDialogSlide> */}

      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="custom-table-header">
            <tr>
              <th>Date of Application</th>
              <th>NFA Reg. #</th>
              {/* <th>Date Submitted</th> */}
              <th>Type of Application</th>
              <th>Type of Transaction</th>
              <th>Licence Number</th>
              <th>Transaction Number</th>
              <th>Category of Licence</th>
              {/* <th>Sub Category</th> */}
              {/* <th>Renewal Permit/Licence Number</th> */}

              <th>Status</th>
              <th>Transcript</th>
              {/* <th>Application Progress</th> */}
              {/* <th>View Details</th> */}
            </tr>
          </thead>

          <tbody className="table-striped">
            {transactions?.map((trans) => {
              return (
                <tr key={trans.id}>
                  <td>{new Date(trans.created_at).toLocaleString('en-US', dateTimeOptions)}</td>
                  <td>{currentEntity.nfa_registration_number}</td>
                  {/* <td>
                  {new Date(trans.submitted_on).toLocaleString(
                    "en-US",
                    dateTimeOptions
                  )}
                </td> */}
                  <td>{shortDesc[trans.app_type]}</td>

                  <td>
                    {getApplicationSpecificDetails(
                      trans,
                      "type_of_transaction"
                    )}
                  </td>
                  <td>
                    {getApplicationSpecificDetails(
                      trans,
                      "previous_licence_number"
                    )}
                  </td>
                  <td>{trans.id}</td>
                  <td>
                    {getApplicationSpecificDetails(trans, "licence_type")}
                  </td>

                  <td> {statusShortMap[trans.status] || trans.status}</td>
                  <td>
                    <button
                      className="d-flex justify-content-center flex-column btn btn-warning"
                      onClick={async (e) => {
                        const result = await postData({
                          url: `${ROUTES.getTransactionTranscript}${trans.temp_trans_id}?doc_type=APPLICATION_TRANSCRIPT`,
                        });
                        manageDocumentInClient(result);
                      }}
                    >
                      <span className="material-icons-outlined">
                        file_download
                      </span>
                      {/* <span className="small-button-download-text">Licence</span> */}
                    </button>
                  </td>
                  {/* <th>
                <button
                      onClick={() => {
                        setCurrentTransaction(trans);
                          showDialog(true)
                      }}
                      className="btn btn-sm btn-warning"
                    >
                      View
                    </button>
                </th> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllTransactions;
