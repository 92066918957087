import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


import ApplicationForLicence from '../../containers/Licence/ApplicationForLicence';

import SimpleNavigator from '../SimpleNavigator';
import { useDispatch, useSelector } from 'react-redux';
import {APP_SUBMISSION_CONFIG} from '../../config/application-submission-config';
import { alertService } from '../../_services/alert.service';
import ApplicationForPermit from '../../containers/PermitLicence/ApplicationForPermit';
import ApplicationForAuthorization from '../../containers/AuthorizationLicence/ApplicationForAuthorization';
import ApplicationForQuotaFishing from '../../containers/QuotaFishing/ApplicationForQuotaFishing';
import ApplicationForDeclaration from '../../containers/Declaration/ApplicationForDeclaration';
import ApplicationForFishingVessel from '../../containers/FishingVesselLicence/ApplicationForFishingVessel';
import ApplicationForAquaculture from '../../containers/AquacultureFisher/ApplicationForAquaFisher';
import ApplicationForAquaFishingVessel from '../../containers/AquacultureFishingVessel/ApplicationForAquaFishingVessel';
import ApplicationForAquaFacility from '../../containers/AquacultureFacility/ApplicationForAquaFacility';
import ApplicationForLandingLogs from '../../containers/LandingLogs/ApplicationForLandingLogs';
import ApplicationForGeneralAdministration from '../../containers/GeneralAdministration/ApplicationForGeneralAdministration';
import ApplicationForFisherWorkerId from '../../containers/FisherWorkerID/ApplicationForFisherWorkerId';

function Form(props) {

    const history = useHistory();
    let form;

    let appType = useSelector((state) => {
        return state.currentTransaction.appType;
    });

    
    let appDetails = APP_SUBMISSION_CONFIG[appType] || {};
    
    let routingDetails = appDetails.steps.filter((step) => {    
        if(step.currentStep === history.location.pathname){
            return true;
        }
        return false;
    })[0];

    useEffect(() => {
        alertService.info('Please fill form with relevant details', { autoClose:true, keepAfterRouteChange: false });
    }, []);

    let properties = {
        next: routingDetails.next,
        previous: routingDetails.previous,
        isPreview: false
    }
    if (props.application.type === 'APPLICATION_FOR_LICENCE'){
        form = <ApplicationForLicence {...properties}></ApplicationForLicence>;
    } else if(props.application.type === 'APPLICATION_FOR_PERMIT') {
        form = <ApplicationForPermit {...properties}></ApplicationForPermit>;
    } else if(props.application.type === 'APPLICATION_FOR_AUTHORIZATION') {
        form = <ApplicationForAuthorization {...properties}></ApplicationForAuthorization>;
    } else if(props.application.type === 'APPLICATION_QUOTA_FISHING') {
        form = <ApplicationForQuotaFishing {...properties}></ApplicationForQuotaFishing>;
    } else if(props.application.type === 'APPLICATION_FOR_DECLARATION') {
        form = <ApplicationForDeclaration {...properties}></ApplicationForDeclaration>;
    } else if(props.application.type === 'APPLICATION_FOR_FISHING_VESSEL') {
        form = <ApplicationForFishingVessel {...properties}></ApplicationForFishingVessel>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FISHER') {
        form = <ApplicationForAquaculture {...properties}></ApplicationForAquaculture>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FISHING_VESSEL') {
        form = <ApplicationForAquaFishingVessel {...properties}></ApplicationForAquaFishingVessel>;
    } else if(props.application.type === 'APPLICATION_FOR_AQUACULTURE_FACILITY') {
        form = <ApplicationForAquaFacility {...properties}></ApplicationForAquaFacility>;
    } else if(props.application.type === 'APPLICATION_FOR_LANDING_CATCH_DECLARATION') {
        form = <ApplicationForLandingLogs {...properties}></ApplicationForLandingLogs>;
    } else if(props.application.type === 'APPLICATION_FOR_GENERAL_ADMINISTRATION') {
        form = <ApplicationForGeneralAdministration {...properties}></ApplicationForGeneralAdministration>;
    } else if(props.application.type === 'APPLICATION_FOR_FISHER_WORKER_ID') {
        form = <ApplicationForFisherWorkerId {...properties}></ApplicationForFisherWorkerId>;
    }

    return (
        <div>
            <div>
                <div className="shadow-sm  mt-2 bg-light rounded ">
                    {form}
                </div>

                {/* <div></div> */}
            </div>
        </div>
    );
}

export default Form;