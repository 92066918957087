import React from "react";

import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const HowToApply = (props) => {


    const allSteps = [
        {
            title: 'Guidelines',
            image: <AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon>
        },
        {
            title: 'License Type',
            image: <AssignmentTurnedInIcon className="svg_icons"></AssignmentTurnedInIcon>
        },
        {
            title: 'Fill Form',
            image: <ListAltIcon className="svg_icons"></ListAltIcon>
        },
        {
            title: 'Upload Documents',
            image: <InsertDriveFileIcon className="svg_icons"></InsertDriveFileIcon>
        },
        {
            title: 'Payment of Fees',
            image: <PaymentIcon className="svg_icons"></PaymentIcon>
        },
        {
            title: 'Preview & Submit',
            image: <VisibilityIcon className="svg_icons"></VisibilityIcon>
        },

    ]
    return (
        <div style={{background: '#f0ef8a', height: '70vh', padding: '20px 6%'}}>

            <nav style={{textAlign: 'center'}}>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">

                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Process Overview</a>

                    <a className="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-guideline" role="tab" aria-controls="nav-home" aria-selected="true">Guidelines</a>
                    
                    <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-license" role="tab" aria-controls="nav-profile" aria-selected="false">License Type</a>

                    <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-fill" role="tab" aria-controls="nav-contact" aria-selected="false">Fill Form</a>

                    <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-upload" role="tab" aria-controls="nav-contact" aria-selected="false">Upload Documents</a>

                    <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-fees" role="tab" aria-controls="nav-contact" aria-selected="false">Payment of Fees</a>

                    <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-preview" role="tab" aria-controls="nav-contact" aria-selected="false">Preview & Submit</a>

                </div>
            </nav>
            <div style={{backgroundColor: 'white', height: '60vh', padding: '40px 20px'}} className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                   
                    <div className="d-flex flex-wrap justify-content-around">

                        {
                            allSteps.map((step) => {
                                return (<div onClick={() => {
                                    // setSelectedMap((map) => {
                                    //     return {
                                    //         ...map,
                                    //         [trans]: !map[trans]
                                    //     }
                                    // })
                                }} style={{minWidth: '350px'}} className="p-3 license-cat-container">

                                    <div style={{textAlign: 'center'}}>
                                        {step.image}
                                    </div>
                                    <div style={{ textAlign: 'center', paddingTop: '10px'}}>
                                        <div style={{ fontSize: '24px', fontWeight: '600' }}>
                                            {step.title}
                                        </div>
                                    </div>
                                    
                                </div>);
                            })
                        }
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-guideline" role="tabpanel" aria-labelledby="nav-profile-tab">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div className="tab-pane fade" id="nav-license" role="tabpanel" aria-labelledby="nav-profile-tab">Choose License Category</div>
                <div className="tab-pane fade" id="nav-fill" role="tabpanel" aria-labelledby="nav-profile-tab">Fill Form</div>
                <div className="tab-pane fade" id="nav-upload" role="tabpanel" aria-labelledby="nav-profile-tab">Upload Documents</div>
                <div className="tab-pane fade" id="nav-fees" role="tabpanel" aria-labelledby="nav-profile-tab">Fees</div>
                <div className="tab-pane fade" id="nav-preview" role="tabpanel" aria-labelledby="nav-profile-tab">Preview & Submit</div>

            </div>
        </div>
    )
}