import React from 'react';
import styled from 'styled-components';

const CircleWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(145deg, #6ec1e4, #3a95e4);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const CircleTitle = styled.div`
  font-size: 18px;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const Circle = ({ title, onClick }) => (
  <CircleWrapper onClick={onClick}>
    <CircleTitle>{title}</CircleTitle>
  </CircleWrapper>
);

export default Circle;
