import React, { useState, useEffect } from 'react';

import { getBase64 } from '../utils/utils'
import { useHistory } from 'react-router-dom';
import { getData, postData } from '../services/service-call'
import { ROUTES } from '../config/index'
import '../../assets/styles/common-styles.css';
import store from '../redux/store';
import { alertService } from '../_services/alert.service';
//todo more actions and disable ness depending upon diff use case

function ReturnedForCorrectionsSupportingDoc({classes = "shadow-sm p-3 m-3 bg-light rounded",
trans_number, temp_trams_id, documentType = 'CORRECTION_SUPPORTING_DOCUMENTS',  ...props}) {

    let inputFileUpload;
    const history = useHistory();
    let initialDocument = [{ isUploaded: false }];
    let [documents, setDocuments] = useState(initialDocument);
    let state = store.getState();
    const [selectedFiles, setSelectedFiles] = useState(undefined);


    const selectFile = async (event, index=0) => {
        // single document code below
        // const file = event.target.files[0];
        // if(!file)
        //     return
        // documents[index].file = file;
        // setSelectedFiles(file);



        // multi document code below
        let files = event.target.files;
        if (!files)
            return

        files = Array.from(files).map((file) => file);

        setDocuments((documents) => {
            let updatedDocuments = [];
            documents.map((document, i) => {
                if (index === i) {
                    files.map((file) => {
                        updatedDocuments.push(Object.assign({}, document, {
                            isUploaded: false,
                            file
                        }))
                    });
                }
                updatedDocuments.push(document);
            });
            return updatedDocuments;//.concat(initialDocument);
        });
    };

    useEffect(async () => {
        let toUpdate = false;
        let localDocuments = Object.assign([], documents);
        for(let i=0;i<localDocuments.length;i++){
            if(!localDocuments[i].isUploaded && localDocuments[i].file){
                toUpdate = true;
                const result = await uploadFile(i)
                localDocuments[i] = {
                    ...localDocuments[i],
                    ...result
                }
            }
        }

        if(toUpdate){
            setDocuments(localDocuments);
        }
    }, [documents]);

    const uploadFile = async (index) => {
        if(documents[index].isUploaded){
            alertService.warn('Document already uploaded', { autoClose:true, keepAfterRouteChange: true });
            return;
        }
        const file = documents[index].file;
        if (!file)
            return;
        const { base64: content, contentType } = await getBase64(documents[index].file);
        try {
            const result = await postData({
                url: ROUTES.documentUpload, body: {
                    document: {
                        content,
                        contentType,
                        name: documents[index].file.name,
                        documentType: documentType,
                    },
                    temp_trans_id: temp_trams_id
                }
            });
            alertService.success('Document uploaded successfully', { autoClose:true, keepAfterRouteChange: true });
            return {
                isUploaded: true,
                id: result.data.id
            }
        } catch (err) {
            alertService.error('Document could not uploaded successfully', { autoClose:true, keepAfterRouteChange: true });
            return {
                isUploaded: false
            }
        }
    }

    const removeDocument =  async (index) => {

        const {id, isUploaded} = documents[index];
        if(!isUploaded){
            setDocuments((documents) => {
                return documents.splice(index, 1);
            })
            return
        }
        const result = await postData({
            url: `${ROUTES.removeDocument}${id}`
        });
        alertService.success('Document removed successfully', { autoClose:true, keepAfterRouteChange: true });
    }


    return (
      <div>
        <div className={classes}>

         
          <div className="d-flex flex-column justify-content-center align-items-center mb-1 mt-1">

         
           <div>
           <button
              className="btn btn-sm btn-info"
              type="button"
              id="clickImage"
              onClick={() => inputFileUpload.click()}
              value="Upload"
            >
              Select files...
            </button>

            <input
              type="file"
              hidden
              ref={(input) => (inputFileUpload = input)}
              multiple
              onChange={(event) => selectFile(event)}
            ></input>

            <button
              className="btn btn-sm btn-success ml-2"
              type="button"
              id="uploadAll"
              value="Upload"
            >
              Upload All
            </button>
           </div>
          </div>

          <table className="table table-sm left-align table-bordered">
            <thead>
              <tr>
                <th>File</th>
                <th>Action</th>
                <th>Upload Status</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document, index) => {
                if (documents.length == index + 1) return null;

                return (
                  <tr key={index}>
                    <td>{document.file?.name}</td>

                    <td>
                    {/* className="d-flex flex-row justify-content-start" */}
                      <button
                        onClick={() => uploadFile(index)}
                        className="btn btn-sm btn-warning"
                      >
                        Upload
                      </button>

                      <button
                        onClick={() => {
                          removeDocument(index);
                        }}
                        className="btn btn-sm btn-danger ml-1"
                      >
                        Remove
                      </button>
                    </td>
                    <td
                      className={
                        document.isUploaded ? "success-text" : "failure-text"
                      }
                    >
                      <b>{document.isUploaded ? "Uploaded" : "Not Uploaded"}</b>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      
      </div>
    );
}

export default ReturnedForCorrectionsSupportingDoc;