import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router'; 
import { useHistory, useParams, Switch } from 'react-router-dom';
import { APP_SUBMISSION_CONFIG } from '../config/application-submission-config';

import ApplicationHeading from './ApplicationHeading';

function FormSubmission(props) {

    const params = useParams();

    let appDetails = useSelector((state) => {
        return APP_SUBMISSION_CONFIG[params.appType];
    });

 
    return (
        <div>
            <ApplicationHeading title={appDetails?.application?.applicationDesc}></ApplicationHeading>
            <Switch>
                <Route path="/form-submission/:appType">
                 {/* generic application submission was here which is now removed */}
                </Route>
            </Switch>
        </div>
    );
}

export default FormSubmission;