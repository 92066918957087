import React from "react";
import { FormHookCheckbox, FormHookInput, FormHookRadioInput, FormHookTextArea } from "../../components/FormHook/ReactFormHookFields";
import { regulatoryApprovalHeadings, regulatoryApprovalTypes } from "./AquaFacilityDataSource";



export const RegulatoryApproval = ({ formState,control, register, onSubmit, setValue,values,watch, disabled, showHeading=true }) => {


    const {errors} = formState;
    const regulatoryApproval = watch('regulatory_approval', {deep: true});

    return (

        // <fieldset disabled={disabled}>
          // <form onSubmit={onSubmit}>
            <div className={`p-3 mb-3 ${disabled ? "non-functional-element" : ""}`}>
              <div className="d-flex  form-heading">
               {
                showHeading &&  <div
                style={{ marginRight: "10px" }}
              >{`${"Regulatory Approval"}`}</div>
               }
              </div>
              <div className="d-flex-wrap justify-content-center align-items-center">

                <table className="flex-grow-1 table-responsive table table-bordered table-sm">
                  <thead>
                    <tr>
                    {
                        regulatoryApprovalHeadings.map(head => <th style={{color: 'green'}} key={head.key}>{head.heading}</th>)
                    }
                    </tr>
                  </thead>
                  <tbody>
                    {regulatoryApprovalTypes.map((entry) => {
                      return (
                        <tr key={entry.key}>
                            <td><b>{entry.title}</b></td>
                            <td>
                            <FormHookInput
                            parentClass="individual-field-small"
                              hideLabel={true}
                              register={register}
                              error={
                                errors?.regulatory_approval?.[entry.key]
                                  ?.permit_licence_type?.message
                              }
                              regFieldName={`regulatory_approval.${entry.key}.permit_licence_type`}
                              isRequired={regulatoryApproval?.[entry.key]?.approval_status === 'Approved' ? 
                            "This field is required" : ""}
                            />
                            </td>
                          <td>
                           
                          <FormHookRadioInput
                        parentClass=""
                        fieldClassName="d-flex-wrap flex-row"
                          options={["Approved", "Not Approved", "Not Applicable", "Pending"]}
                          register={register}
                          error={errors?.regulatory_approval?.[entry.key]?.approval_status?.message}
                          regFieldName={`regulatory_approval.${entry.key}.approval_status`}
                          hideLabel={true}
                        />

                          </td>
                         

                          <td>

                          <FormHookInput
                          parentClass="individual-field-micro"
                              hideLabel={true}
                              register={register}
                              error={
                                errors?.regulatory_approval?.[entry.key]
                                  ?.approval_date?.message
                              }
                              type="date"
                              regFieldName={`regulatory_approval.${entry.key}.approval_date`}
                              isRequired={regulatoryApproval?.[entry.key]?.approval_status === 'Approved' ? 
                              "This field is required" : ""}
                            />

                          </td>

                          <td>
                          <FormHookTextArea
                          parentClass="individual-field-micro"
                              hideLabel={true}
                              register={register}
                              error={
                                errors?.regulatory_approval?.[entry.key]
                                  ?.comments?.message
                              }
                              regFieldName={`regulatory_approval.${entry.key}.comments`}
                              isRequired={false}
                              rows={1}
                              cols={30}
                            />
                          </td>
                        </tr>

                      );
                    })}

                  </tbody>
                </table>

              </div>

              </div>
          // </form>
        // </fieldset>
    );
};
