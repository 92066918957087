import React from "react";
import { useFieldArray } from "react-hook-form";
import { FormHookInput,FormHookSelect } from "../../components/FormHook/ReactFormHookFields";
import { yearFromTo } from "../../components/DataSource";


export const SpinyHistoricalIndustryPerformance = ({formState,control, register, onSubmit,shouldRenderInForm=true, isDisabled=false, isAddEnabled=true, isDeleteEnabled=true ,  toShowHeading = true}) => {


    const {errors, isDirty, isValid} = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'historical_industry_performance_spiny',
      });

    return (
      <div>
        <table className="table table-bordered">
          {toShowHeading && (
            <tr>
              <td style={{ textAlign: "center", fontSize: "20px" }} colSpan={6}>
                <b>Historical Industry Performance</b>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={3}>
              <FormHookInput
                register={register}
                error={
                  errors
                    ?.year_applicant_entered_in_industry_spiny?.message
                }
                label="Year Applicant Entered in Spiny Lobster Industry:"
                regFieldName={`year_applicant_entered_in_industry_spiny`}
                placeholder="Year Applicant Entered in Spiny Lobster Industry:"
                type="number"
                isDisabled={isDisabled}
                validations={{
                  maxLength: {
                    value: 4,
                    message: "Max length is 4",
                  },
                }}
              />

            
            </td>

            <td colSpan={2}>
              <FormHookInput
                register={register}
                error={
                  errors?.number_of_years_allocated_quota_spiny?.message
                }
                label="Number of Years In Spiny Lobster Industry:"
                regFieldName={`number_of_years_allocated_quota_spiny`}
                placeholder="Number of Years In Spiny Lobster Industry:"
                type="number"
                isDisabled={isDisabled}
                validations={{
                  maxLength: {
                    value: 4,
                    message: "Max length is 4",
                  },
                }}
              />

            
            </td>
          </tr>

          <tr style={{ fontWeight: 600 }}>
            <td>No.</td>
            <td style={{ maxWidth: "250px" }}>Year</td>
            <td style={{ maxWidth: "250px" }}>
              Total Spiny Lobster Landed (Kg).
            </td>
            <td style={{ maxWidth: "250px" }}>
              Total Exported or Sold Locally
            </td>
          </tr>

          {fields?.map((group, groupIndex) => {
            return (
              <tr key={group.id}>
                <td>{groupIndex + 1}</td>
                <td style={{ maxWidth: "250px" }}>
                  {/* <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_spiny
                        ?.[groupIndex]?.spiny_fishing_season
                        ?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_spiny[${groupIndex}].spiny_fishing_season`}
                    placeholder="2000-2001"
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    validations={{
                      maxLength: {
                        value: 20,
                        message: "Max length is 20",
                      },
                    }}
                  /> */}

                  <FormHookSelect
                    label=""
                    hideLabel={true}
                    register={register}
                    parentClass="individual-field-small"
                    regFieldName={`historical_industry_performance_spiny[${groupIndex}].spiny_fishing_season`}
                    options={yearFromTo(1995)}
                    error={
                      errors?.historical_industry_performance_spiny
                        ?.[groupIndex]?.spiny_fishing_season
                        ?.message
                    }
                    isDisabled={isDisabled}
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_spiny?.[
                        groupIndex
                      ]?.total_spiny_lobster_landed?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_spiny[${groupIndex}].total_spiny_lobster_landed`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    validations={{
                      maxLength: {
                        value: 100,
                        message: "Max length is 100",
                      },
                    }}
                    type="number"
                  />
                </td>

                <td>
                  <FormHookInput
                    register={register}
                    error={
                      errors?.historical_industry_performance_spiny?.[
                        groupIndex
                      ]?.total_exported_or_sold_locally?.message
                    }
                    hideLabel={true}
                    regFieldName={`historical_industry_performance_spiny[${groupIndex}].total_exported_or_sold_locally`}
                    isDisabled={isDisabled}
                    parentClass="individual-field-small"
                    validations={{
                      maxLength: {
                        value: 100,
                        message: "Max length is 100",
                      },
                    }}
                    type="number"
                  />
                </td>

                <td>
                  {!isDisabled && (
                    <button
                      type="button"
                      onClick={() => {
                        remove(groupIndex);
                      }}
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </table>

        <div className="d-flex flex-row-reverse">
          {!isDisabled && (
            <button
              type="button"
              onClick={() => {
                append({ id: Math.random() });
              }}
              className="btn btn-primary">
              Add Historical Performance
            </button>
          )}
        </div>
      </div>
    );
};