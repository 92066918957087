
export const aquaFacilityTypeofLicence = ["Existing Facility", "New Facility"];

export const fisherAquaCategoryofLicence = ["Fresh Water", "Mariculture", "Ornamental Culture"];

export const typeOfTransactions = ["New", "Renewal", "Amendment"];

export const aquaFacilityTypeofApplicant = ["Individual", "Company"];

export const sizeOfAquacultureFacility = ["Small (0-9 Acres)", "Medium (10-49 Acres)", "Large (> 49 Acres)"];

export const ownershipOfFacility = ["Owned", "Informal", "Leased"];


export const FRESH_WATER = "Fresh Water";
export const MARICULTURE = "Mariculture";
export const ORNAMENTAL = "Ornamental";
export const BRACKISH = "Brackish";
export const RECREATIONAL_SERVICES = "Recreational Services";
export const INTEGRATED_FARMING = "Integrated Farming";

const optionsForAll = [
    {
        value: "Hatchery",
        isInput: false
    },
    {
        value:   "Seedstock",
        isInput: false
    },
    {
        value: "Grow-out",
        isInput: false,
    },
    {
        value:   "Nursery",
        isInput: false
    },
    {
        value: "Breeding",
        isInput: false,
    },
    {
        value: "Holding",
        isInput: false,
    },
    {
        value: "Other",
        isInput: true
    }
];


// export const typeOfOperation = {
//     [FRESH_WATER]: optionsForAll,
//     [MARICULTURE]: optionsForAll,
//     [ORNAMENTAL]: optionsForAll,
//     [BRACKISH]: optionsForAll,
//     [RECREATIONAL_SERVICES]: optionsForAll,
//     [INTEGRATED_FARMING]: optionsForAll
// };


export const typeOfOperation = [
  {
    title: FRESH_WATER,
    key: "fresh_water",
    categories: optionsForAll
  },
  {
    title: MARICULTURE,
    key: "mariculture",
    categories: optionsForAll
  },
  {
    title: ORNAMENTAL,
    key: "ornamental",
    categories: optionsForAll
  },
  {
    title: BRACKISH,
    key: "brackish",
    categories: optionsForAll
  },{
    title: RECREATIONAL_SERVICES,
    key: "recreational_services",
    categories: optionsForAll
  },
  {
    title: INTEGRATED_FARMING,
    key: "integrated_farming",
    categories: optionsForAll
  }
];

export const productionUnits = ["Earthen Pond", "HDPE Pond", "Concrete Tank", "Plastic Vat", "Aquaria", "Hansons Racks", "Rafts", "Cage", "Ranching", "Other"];

export const typeOfProductionSystems = ["Extensive", "Semi-Intensive", "RAS System", "Flow-Through", "Intensive", "Super-Intensive", "Monoculture", "Polyculture"];


export const aquacultureGearSite = "Type of Aquaculture Gear on Site";

export const typeOfPowerGeneration = "Type of Power Generation";

export const processingPlant = "Processing Plant";


const commonProcessingCatList = [
    {
      value: "De-Boned",
      isInput: false,
    },
    {
      value: "Fillet",
      isInput: false,
    },
    {
      value: "Slice",
      isInput: false,
    },
    {
      value: "Whole",
      isInput: false,
    },
    {
      value: "Smoked",
      isInput: false,
    },
    {
      value: "Cured",
      isInput: false,
    },
    {
      value: "Pre-Seasoned",
      isInput: false,
    },
  ];

export const investmentAndEmployment = [
  {
    title: aquacultureGearSite,
    key: 'aquaculture_gear_site',
    subcategorized: false,
    categories: [
     {
        value:  "Water Pump",
        isInput: false
     },
      {
        value: "Blower",
        isInput: false
      },
      {
        value: "Net",
        isInput: false
      },
      {
        value: "Transport Tank",
        isInput: false
      },
      {
        value:"Aerator",
        isInput: false
      },
      {
        value: "Generator",
        isInput: false
      },
      {
        value:"Incubation Equipment",
        isInput: false
      },
      {
        value: "Automatic Fish Feeder",
        isInput: false
      },
      {
        value: "Other",
        isInput: true
      },
    ],
  },
  {
    title: typeOfPowerGeneration,
    key: 'aquaculture_power_generation',
    subcategorized: false,
    categories: [
      {
        value: "Grid Tied",
        isInput: false,
      },
      {
        value: "Off-Grid",
        isInput: false,
      },
      {
        value: "Renewable Energy",
        isInput: false,
      },
      {
        value: "Generator",
        isInput: false,
      },
    ],
  },
  {
    title: processingPlant,
    key: 'aquaculture_processing_plant',
    subcategorized: true,
    categories: [
      {
        title: "Frozen",
        key: 'frozen',
        subcategorized: false,
        categories: commonProcessingCatList,
      },
      {
        title: "Fresh",
        key: 'fresh',
        subcategorized: false,
        categories: commonProcessingCatList,
      },
      {
        title: "Chilled",
        key: 'chilled',
        subcategorized: false,
        categories: commonProcessingCatList,
      },
    ],
  },
];

export const marketingStrategyType = [
  {
    title: "Local Wholesale",
    key: "local_wholesale",
  },
  {
    title: "Local Retail",
    key: "local_retail",
  },
  {
    title: "Export",
    key: "export",
  },
  {
    title: "HOREC (hotels, restaurants etc)",
    key: "horec",
  },
  {
    title: "Other",
    key: "other",
    inputField: true
  },
];


export const regulatoryApprovalTypes = [
  {
    title: "NRCA",
    key: 'nrca'
  }, 
  {
    title: "WRA",
    key: 'wra'
  }, 
  {
    title: "VSD",
    key: 'vsd'
  }, 
  {
    title: "Parish Council",
    key: 'parish_council'
  }, 
  {
    title: "Other",
    key: 'other'
  }
];

export const regulatoryApprovalHeadings = [
  {
    heading: 'Entity',
    key: 'entity'
  }, 
  {
    heading: 'Permit/Licence Type',
    key: 'permit_licence_type'
  }, 
  {
    heading: 'Status',
    key: 'approval_status'
  }, 
  {
    heading: 'Approval Date',
    key: 'approval_date'
  }, 
  {
    heading: 'Comments (Optional)',
    key: 'comments'
  }
  
]

export const plannedHistoricalProductionHeadings = [
  {
    heading: 'Year',
    key: 'year'
  }, 
  {
    heading: 'Species',
    key: 'species'
  }, 
  {
    heading: <div><span>Production Unit</span><div>(e.g. cage,vats)</div></div>,
    key: 'production_unit'
  }, 
  {
    heading: 'Output(Kg.)',
    key: 'output_kg'
  }, 
  {
    heading: 'Comments (Optional)',
    key: 'comments'
  }
]


